import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { CiUser } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import "../style/Myprofile.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { editUserProfile, getUserProfile } from "../api/ProfileAPI";
import { useNavigate } from "react-router-dom";

export const MyProfile = () => {
  const [userInformation, setUserInformation] = useState(null);
  const [BookingData, setBookingData] = useState([]);
  const [GetConsultationData, setGetConsultationData] = useState([]);

  const GetUserInformationApi = async () => {
    const userTokenFromCookies = Cookies.get("userToken");
    if (userTokenFromCookies) {
      const res = await getUserProfile(userTokenFromCookies);
      setUserInformation(res?.data);

      if (res?.status === "success") {
        setBookingData(res?.data?.orders);
        setGetConsultationData(res?.data?.consultations);
      }
    }
  };

  useEffect(() => {
    GetUserInformationApi();
  }, []);

  // Function to convert date to "dd Month yyyy"
  function getDateFromString(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0"); // Get day and pad with 0 if needed
    const month = date.toLocaleString("default", { month: "long" }); // Get full month name
    const year = date.getFullYear().toString(); // Get full year
    return `${day} ${month} ${year}`;
  }

  const formattedDateTime = userInformation
    ? `${getDateFromString(userInformation.birthday)}`
    : "";

  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove("userInformation");
    Cookies.remove("userToken");
    Cookies.remove("userMobile");
    window.location.href = "https://exploretriponline.com";
  };

  const parsedUserData = "";

  const [activeItem, setActiveItem] = useState("Profile Info");

  const [showRead, setShowRead] = useState(false);

  const toggleShowRead = () => {
    setShowRead(!showRead);
  };

  const handleProfileSwitch = (profile_item) => {
    setActiveItem(profile_item === activeItem ? "Profile Info" : profile_item);
  };

  const [showEditProfile, setShowEditProfile] = useState(false);

  const handleCloseEditProfile = () => setShowEditProfile(false);
  const handleShowEditProfile = () => setShowEditProfile(true);

  const [showMyBookings, setShowMyBookings] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);

  const handleCloseMyBookings = () => {
    setShowMyBookings(false);
    setSelectedBooking(null); // Reset the selected booking when modal closes
  };

  const handleShowMyBookings = (booking) => {
    setSelectedBooking(booking); // Store the clicked booking data
    setShowMyBookings(true);
  };

  const validationSchemaEditProfile = Yup.object().shape({
    firstName: Yup.string().required("Please enter your first name"),
    lastName: Yup.string().required("Please enter your last name"),
    gender: Yup.string().required("Please select your gender"),
    dob: Yup.date().nullable().required("Please enter your date of birth"),
    email: Yup.string().required("Please enter your email"),
    pincode: Yup.string().required("Please enter your pincode"),
    city: Yup.string().required("Please enter your city"),
  });

  const loremIpsum =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae velit sed nisl posuere luctus. Morbi dapibus nisi vel felis lobortis, vitae sodales lacus interdum. Sed sed nulla a neque volutpat posuere. In eleifend elit at justo dapibus, nec sodales odio posuere. Donec non ex in lacus interdum placerat.";

  const renderProfileSwitch = () => {
    switch (activeItem) {
      case "Profile Info":
        return (
          <>
            <div className="container">
              <div
                className="row my-3"
                style={{ border: "1px solid #04a9fe", borderRadius: "10px" }}
              >
                <div className="col-12">
                  <div className="row pt-3">
                    <div className="col-12 d-flex justify-content-between">
                      <div className="row">
                        <div className="col-12">
                          <p
                            className="m-0"
                            style={{ fontSize: "20px", fontWeight: "600" }}
                          >
                            My Profile
                          </p>
                        </div>
                        <div className="row">
                          <div className="col-md-2">
                            <div className="Section_text_small_underline_mobile"></div>
                          </div>
                        </div>
                      </div>

                      <div onClick={handleShowEditProfile}>
                        <p
                          className="m-0 text-primary"
                          style={{ fontSize: "12px", cursor: "pointer" }}
                        >
                          Edit Profile <MdEdit />
                        </p>
                      </div>
                    </div>
                    <div className="col-12 pt-1">
                      Basic info, for a faster booking experience.
                    </div>
                  </div>

                  <div className="row py-1">
                    <div className="col-12 pt-2">
                      <p
                        className="m-0 text-secondary"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Name
                      </p>
                      <p
                        className="m-0"
                        style={{ fontSize: "16px", fontWeight: "500" }}
                      >
                        {userInformation?.name}
                      </p>
                    </div>

                    <div className="col-12 pt-2">
                      <p
                        className="m-0 text-secondary"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        User Id
                      </p>
                      <p
                        className="m-0"
                        style={{ fontSize: "16px", fontWeight: "500" }}
                      >
                        {userInformation?._id}
                      </p>
                    </div>

                    <div className="col-12 pt-2">
                      <p
                        className="m-0 text-secondary"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Email
                      </p>
                      <p
                        className="m-0"
                        style={{ fontSize: "16px", fontWeight: "500" }}
                      >
                        {userInformation?.email}
                      </p>
                    </div>

                    <div className="col-12 pt-2">
                      <p
                        className="m-0 text-secondary"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Mobile
                      </p>
                      <p
                        className="m-0"
                        style={{ fontSize: "16px", fontWeight: "500" }}
                      >
                        {userInformation?.mobile}
                      </p>
                    </div>

                    <div className="col-12 pt-2">
                      <p
                        className="m-0 text-secondary"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Marital Status
                      </p>
                      <p
                        className="m-0"
                        style={{ fontSize: "16px", fontWeight: "500" }}
                      >
                        {userInformation?.marital_status}
                      </p>
                    </div>

                    <div className="col-12 pt-2">
                      <p
                        className="m-0 text-secondary"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Birthday
                      </p>
                      <p
                        className="m-0"
                        style={{ fontSize: "16px", fontWeight: "500" }}
                      >
                        {formattedDateTime}
                      </p>
                    </div>

                    <div className="col-12 pt-2">
                      <p
                        className="m-0 text-secondary"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Gender
                      </p>
                      <p
                        className="m-0"
                        style={{ fontSize: "16px", fontWeight: "500" }}
                      >
                        {userInformation?.gender}
                      </p>
                    </div>

                    <div className="col-12 pt-2">
                      <p
                        className="m-0 text-secondary"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Address
                      </p>
                      <p
                        className="m-0"
                        style={{ fontSize: "16px", fontWeight: "500" }}
                      >
                        {userInformation?.address}, {userInformation?.pincode}
                      </p>
                    </div>

                    <div className="col-12 pt-2">
                      <p
                        className="m-0 text-secondary"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        State
                      </p>
                      <p
                        className="m-0"
                        style={{ fontSize: "16px", fontWeight: "500" }}
                      >
                        {userInformation?.state}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Modal show={showEditProfile} onHide={handleCloseEditProfile}>
              <Modal.Header
                closeButton
                className="text-white"
                style={{
                  backgroundImage: "linear-gradient(45deg, #1556fc, #04a9fe)",
                }}
              >
                <Modal.Title>Edit Profile</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Formik
                  initialValues={{
                    firstName: userInformation?.name?.split(" ")[0] || "",
                    lastName: userInformation?.name?.split(" ")[1] || "",
                    gender: userInformation?.gender || "",
                    dob: userInformation?.birthday?.split("T")[0] || "",
                    email: userInformation?.email || "",
                    pincode: userInformation?.pincode || "",
                    city: userInformation?.address || "",
                    marital_status: userInformation?.marital_status || "Single",
                    state: userInformation?.state || "",
                  }}
                  validationSchema={validationSchemaEditProfile}
                  onSubmit={async (values, actions) => {
                    try {
                      const userTokenFromCookies = Cookies.get("userToken");
                      const response = await editUserProfile({
                        mobile: userInformation?.mobile,
                        id: userInformation?._id,
                        name: values.firstName + " " + values.lastName,
                        email: values.email,
                        birthday: values.dob,
                        gender: values.gender,
                        marital_status: values.marital_status,
                        address: values.city,
                        pincode: values.pincode,
                        state: values.state,
                      });

                      if (response.success === true) {
                        actions.resetForm();
                        handleCloseEditProfile();
                        window.location.reload();
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="row">
                        <div className="mt-1 col-6" style={{ height: "70px" }}>
                          <p
                            className="m-0"
                            style={{ fontSize: "14px", fontWeight: "500" }}
                          >
                            First Name
                          </p>
                          <div className="input_field d-flex align-items-center">
                            <Field
                              type="text"
                              name="firstName"
                              placeholder="Enter your first name"
                              className="w-100"
                              style={{ fontSize: "12px" }}
                            />
                          </div>
                          <ErrorMessage
                            name="firstName"
                            component="div"
                            className="error text-danger m-0"
                            style={{ fontSize: "10px" }}
                          />
                        </div>

                        <div className="mt-1 col-6" style={{ height: "70px" }}>
                          <p
                            className="m-0"
                            style={{ fontSize: "14px", fontWeight: "500" }}
                          >
                            Last Name
                          </p>
                          <div className="input_field d-flex align-items-center">
                            <Field
                              type="text"
                              name="lastName"
                              placeholder="Enter your last name"
                              className="w-100"
                              style={{ fontSize: "12px" }}
                            />
                          </div>
                          <ErrorMessage
                            name="lastName"
                            component="div"
                            className="error text-danger m-0"
                            style={{ fontSize: "10px" }}
                          />
                        </div>

                        <div
                          className="mt-1 col-md-6 col-12"
                          style={{ height: "70px" }}
                        >
                          <p
                            className="m-0"
                            style={{ fontSize: "14px", fontWeight: "500" }}
                          >
                            Gender
                          </p>
                          <div
                            className="input_field d-flex align-items-center"
                            style={{ fontSize: "12px" }}
                          >
                            <label className="me-2 d-flex align-items-center">
                              <Field type="radio" name="gender" value="Male" />
                              <span className="px-1"> Male</span>
                            </label>
                            <label className="me-2 d-flex align-items-center">
                              <Field
                                type="radio"
                                name="gender"
                                value="Female"
                              />
                              <span className="px-1"> Female</span>
                            </label>
                            <label className="d-flex align-items-center">
                              <Field
                                type="radio"
                                name="gender"
                                value="Transgender"
                              />
                              <span className="px-1"> Other</span>
                            </label>
                          </div>
                          <ErrorMessage
                            name="gender"
                            component="div"
                            className="error text-danger m-0"
                            style={{ fontSize: "10px" }}
                          />
                        </div>

                        <div
                          className="mt-1 col-md-6 col-12"
                          style={{ height: "70px" }}
                        >
                          <p
                            className="m-0"
                            style={{ fontSize: "14px", fontWeight: "500" }}
                          >
                            DOB
                          </p>
                          <div className="input_field d-flex align-items-center">
                            <Field
                              type="date"
                              name="dob"
                              placeholder="Enter your DOB"
                              className="w-100"
                              style={{ fontSize: "12px" }}
                            />
                          </div>
                          <ErrorMessage
                            name="dob"
                            component="div"
                            className="error text-danger m-0"
                            style={{ fontSize: "10px" }}
                          />
                        </div>

                        <div className="mt-1 col-12" style={{ height: "70px" }}>
                          <p
                            className="m-0"
                            style={{ fontSize: "14px", fontWeight: "500" }}
                          >
                            Email
                          </p>
                          <div className="input_field d-flex align-items-center">
                            <Field
                              type="email"
                              name="email"
                              placeholder="Enter your email"
                              className="w-100"
                              style={{ fontSize: "12px" }}
                            />
                          </div>
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error text-danger m-0"
                            style={{ fontSize: "10px" }}
                          />
                        </div>

                        <div className="mt-1 col-6" style={{ height: "70px" }}>
                          <p
                            className="m-0"
                            style={{ fontSize: "14px", fontWeight: "500" }}
                          >
                            Pincode
                          </p>
                          <div className="input_field d-flex align-items-center">
                            <Field
                              type="text"
                              name="pincode"
                              placeholder="Enter your Pincode"
                              className="w-100"
                              style={{ fontSize: "12px" }}
                            />
                          </div>
                          <ErrorMessage
                            name="pincode"
                            component="div"
                            className="error text-danger m-0"
                            style={{ fontSize: "10px" }}
                          />
                        </div>

                        <div className="mt-1 col-6" style={{ height: "70px" }}>
                          <p
                            className="m-0"
                            style={{ fontSize: "14px", fontWeight: "500" }}
                          >
                            City
                          </p>
                          <div className="input_field d-flex align-items-center">
                            <Field
                              type="text"
                              name="city"
                              placeholder="Enter your City name"
                              className="w-100"
                              style={{ fontSize: "12px" }}
                            />
                          </div>
                          <ErrorMessage
                            name="city"
                            component="div"
                            className="error text-danger m-0"
                            style={{ fontSize: "10px" }}
                          />
                        </div>
                      </div>

                      <div className="col-12 p-0 d-flex justify-content-center">
                        <div>
                          <button
                            className="searchButton py-1"
                            disabled={isSubmitting}
                            style={{ width: "200px" }}
                            type="submit"
                          >
                            Update Profile
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Modal.Body>
            </Modal>
          </>
        );

      case "Terms And Conditions":
        return (
          <>
            <div className="container">
              <div
                className="row my-3"
                style={{ border: "1px solid #04a9fe", borderRadius: "10px" }}
              >
                <div className="col-12">
                  <div className="row pt-3">
                    <div className="col-12 d-flex justify-content-between">
                      <div className="row">
                        <div className="col-12">
                          <p
                            className="m-0"
                            style={{ fontSize: "20px", fontWeight: "600" }}
                          >
                            Terms And Conditions
                          </p>
                        </div>

                        <div className="row">
                          <div className="col-md-2">
                            <div className="Section_text_small_underline_mobile"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 pb-3">
                      {showRead ? (
                        <p className="m-0 py-1">{loremIpsum}</p>
                      ) : (
                        <p className="m-0 py-1">
                          {loremIpsum.slice(0, 100)}...
                        </p>
                      )}
                      <button className="bg-white" onClick={toggleShowRead}>
                        {showRead ? (
                          <span className="text-primary">Collapse ^</span>
                        ) : (
                          <span className="text-primary">Read more ...</span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );

      case "Privacy Policy":
        return (
          <>
            <div className="container">
              <div
                className="row my-3"
                style={{ border: "1px solid #04a9fe", borderRadius: "10px" }}
              >
                <div className="col-12">
                  <div className="row pt-3">
                    <div className="col-12 d-flex justify-content-between">
                      <div className="row">
                        <div className="col-12">
                          <p
                            className="m-0"
                            style={{ fontSize: "20px", fontWeight: "600" }}
                          >
                            Privacy Policy
                          </p>
                        </div>

                        <div className="row">
                          <div className="col-md-2">
                            <div className="Section_text_small_underline_mobile"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 pb-3">
                      {showRead ? (
                        <p className="m-0 py-1">{loremIpsum}</p>
                      ) : (
                        <p className="m-0 py-1">
                          {loremIpsum.slice(0, 100)}...
                        </p>
                      )}
                      <button className="bg-white" onClick={toggleShowRead}>
                        {showRead ? (
                          <span className="text-primary">Collapse ^</span>
                        ) : (
                          <span className="text-primary">Read more ...</span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );

      case "Consultation":
        return (
          <>
            <div className="container">
              <div
                className="row my-3"
                style={{ border: "1px solid #04a9fe", borderRadius: "10px" }}
              >
                <div className="col-12">
                  <div className="row pt-3">
                    <div className="col-12 d-flex justify-content-between">
                      <div className="row">
                        <div className="col-12">
                          <p
                            className="m-0"
                            style={{
                              fontSize: "20px",
                              fontWeight: "600",
                            }}
                          >
                            Consultation History
                          </p>
                        </div>

                        <div className="row">
                          <div className="col-md-2">
                            <div className="Section_text_small_underline_mobile"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 pb-3">
                      <div className="table_bookingdata">
                        <table responsive="sm" className="mt-2">
                          <thead>
                            <tr>
                              <th>S.No.</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Phone</th>
                              <th>Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(GetConsultationData) &&
                            GetConsultationData?.length > 0 ? (
                              GetConsultationData.map((ele, index) => (
                                <tr key={ele?._id}>
                                  <td>{index + 1}</td>
                                  <td>{ele?.name}</td>
                                  <td>{ele?.email}</td>
                                  <td>{ele?.mobile}</td>
                                  <td>{ele?.requirement}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="6" className="text-center">
                                  Data Not Available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );

      case "My Bookings":
        return (
          <>
            <Modal show={showMyBookings} onHide={handleCloseMyBookings}>
              <Modal.Header
                closeButton
                className="text-white"
                style={{
                  backgroundImage: "linear-gradient(45deg, #1556fc, #04a9fe)",
                }}
              >
                <Modal.Title>My Booking</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                {selectedBooking ? (
                  <div>
                    <p>
                      <strong>Order ID:</strong> {selectedBooking.orderId}
                    </p>
                    <p>
                      <strong>Tracking ID:</strong> {selectedBooking.trackingId}
                    </p>
                    <p>
                      <strong>Payment Mode:</strong>
                      {selectedBooking.paymentMode}
                    </p>
                    <p>
                      <strong>Amount:</strong> {selectedBooking.amount}
                    </p>
                    <p>
                      <strong>Payment Status:</strong>
                      {selectedBooking.paymentStatus}
                    </p>
                    <p>
                      <strong>Booking Type:</strong>
                      {selectedBooking.bookingType}
                    </p>

                    {/* Show additional booking-specific details */}
                    {selectedBooking.bookingType === "Bus" &&
                      selectedBooking?.orderBookingData?.Result && (
                        <div>
                          <p>
                            <strong>Bus Booking Status:</strong>
                            {
                              selectedBooking.orderBookingData.Result
                                .BusBookingStatus
                            }
                          </p>
                          <p>
                            <strong>Ticket No:</strong>
                            {selectedBooking.orderBookingData.Result.TicketNo}
                          </p>
                          <p>
                            <strong>PNR:</strong>
                            {
                              selectedBooking.orderBookingData.Result
                                .TravelOperatorPNR
                            }
                          </p>
                        </div>
                      )}

                    {selectedBooking.bookingType === "Hotel" &&
                      selectedBooking?.orderBookingData?.BookResult && (
                        <div>
                          <p>
                            <strong>Hotel Booking Status:</strong>
                            {
                              selectedBooking.orderBookingData.BookResult
                                .HotelBookingStatus
                            }
                          </p>
                          <p>
                            <strong>Confirmation No:</strong>
                            {
                              selectedBooking.orderBookingData.BookResult
                                .ConfirmationNo
                            }
                          </p>
                          <p>
                            <strong>Invoice Number:</strong>
                            {
                              selectedBooking.orderBookingData.BookResult
                                .InvoiceNumber
                            }
                          </p>
                        </div>
                      )}
                  </div>
                ) : (
                  <p>No booking details available.</p>
                )}
              </Modal.Body>
            </Modal>

            <div className="container">
              <div
                className="row my-3"
                style={{ border: "1px solid #04a9fe", borderRadius: "10px" }}
              >
                <div className="col-12">
                  <div className="row pt-3">
                    <div className="col-12 d-flex justify-content-between">
                      <div className="row">
                        <div className="col-12">
                          <p
                            className="m-0"
                            style={{ fontSize: "20px", fontWeight: "600" }}
                          >
                            My Bookings
                          </p>
                        </div>

                        <div className="row">
                          <div className="col-md-2">
                            <div className="Section_text_small_underline_mobile"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 pb-3">
                      <div className="table_bookingdata">
                        <table responsive="sm">
                          <thead>
                            <tr>
                              <th>S.No.</th>
                              <th>Order Id</th>
                              <th>Tracking Id</th>
                              <th>Payment Mode</th>
                              <th>Amount</th>
                              <th>Payment Status</th>
                              <th>Booking Type</th>
                              <th>View</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(BookingData) &&
                            BookingData?.length > 0 ? (
                              BookingData.map((ele, index) => (
                                <tr key={ele?._id}>
                                  <td>{index + 1}</td>
                                  <td>{ele?.orderId}</td>
                                  <td>{ele?.trackingId}</td>
                                  <td>{ele?.paymentMode}</td>
                                  <td>₹ {ele?.amount}</td>
                                  <td>{ele?.paymentStatus}</td>
                                  <td>{ele?.bookingType}</td>
                                  <td onClick={() => handleShowMyBookings(ele)}>
                                    View
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="6" className="text-center">
                                  Data Not Available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div className="container-fluid">
      <div className="row d-flex justify-content-center p-4">
        <div className="col-md-3 px-1">
          <div className="row d-flex justify-content-center Section_Container">
            <div className="col-md-12 d-flex justify-content-center">
              <div className="profile_div">
                <CiUser />
              </div>
            </div>
            <div className="col-md-12 pt-2">
              <h5 className="text-center">{parsedUserData?.name}</h5>
              <div className="text-center" style={{ color: "#01a9ff" }}>
                <span>Change your profile photo</span>
                <span>
                  <MdEdit />
                </span>
              </div>
            </div>
            <div className="col-md-12 pt-2" style={{ fontSize: "14px" }}>
              <p
                className={` ${
                  activeItem === "Profile Info"
                    ? "activeProfileItems"
                    : "inactiveProfileItems"
                }`}
                onClick={() => handleProfileSwitch("Profile Info")}
              >
                Profile Info
              </p>

              <p
                className={` ${
                  activeItem === "My Bookings"
                    ? "activeProfileItems"
                    : "inactiveProfileItems"
                }`}
                onClick={() => handleProfileSwitch("My Bookings")}
              >
                My Bookings
              </p>

              <p
                className={` ${
                  activeItem === "Terms And Conditions"
                    ? "activeProfileItems"
                    : "inactiveProfileItems"
                }`}
                onClick={() => handleProfileSwitch("Terms And Conditions")}
              >
                Terms And Conditions
              </p>

              <p
                className={` ${
                  activeItem === "Privacy Policy"
                    ? "activeProfileItems"
                    : "inactiveProfileItems"
                }`}
                onClick={() => handleProfileSwitch("Privacy Policy")}
              >
                Privacy Policy
              </p>

              <p
                className={` ${
                  activeItem === "Consultation"
                    ? "activeProfileItems"
                    : "inactiveProfileItems"
                }`}
                onClick={() => handleProfileSwitch("Consultation")}
              >
                Consultation History
              </p>

              <p
                className={` ${
                  activeItem === "Logout"
                    ? "activeProfileItems"
                    : "inactiveProfileItems"
                }`}
                onClick={() => handleLogout()}
              >
                Logout
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-9">
          <div className="ps-4">
            <div className="row d-flex justify-content-center Section_Profile_Container">
              <div>{renderProfileSwitch()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
