import React from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

export const PrivateRouter = ({ children, adminRoute }) => {
  const token = localStorage.getItem("token"); // assuming the JWT token is stored in localStorage

  if (!token) {
    return <Navigate to="/" />;
  }

  const decodedToken = jwtDecode(token);
  const userRole = decodedToken.role;

  // Check if the user role matches the admin route or user route
  if (adminRoute && userRole !== "admin") {
    return <Navigate to="/" />; // Redirect to user homepage if not admin
  }

  if (!adminRoute && userRole === "admin") {
    return <Navigate to="/admin" />; // Redirect to admin dashboard if admin
  }

  return children;
};
