import React, { useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { HolidayPackageAPIData } from "../../api/HolidayPackageAPI";
import { Loader } from "../Loader";
import { SlCalender } from "react-icons/sl";
import { IoLocationOutline } from "react-icons/io5";
import { Base_Url } from "../../Url/Url";

export const HolidayPackages = () => {
  const [bestTouristPackage, setBestTouristPackage] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await HolidayPackageAPIData();

        if (res?.status === "success") {
          setBestTouristPackage(res?.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const navigate = useNavigate();
  const handleViewAll = () => {
    navigate("/holidayspackage");
  };

  const handleSinglePackageData = (data) => {
    navigate(`/holidayspackage/${data._id}`);
  };

  return (
    <>
      <div className="desktop-view">
        <div className="container-fluid Section_Container">
          <div className="row d-flex justify-content-between px-2">
            <div className="col-md-6">
              <div className="homepage_component_heading_txt">
                Best Holidays Packages
              </div>
              <div className="Section_text_small_underline"></div>
            </div>
            <div
              className="col-md-2 Section_view_all_link d-flex justify-content-end"
              onClick={handleViewAll}
              style={{ cursor: "pointer" }}
            >
              <span className="text-black">View all</span>
              <FaAngleRight />
            </div>
          </div>

          <div>
            {bestTouristPackage ? (
              <div className="row pt-3 px-2">
                {bestTouristPackage?.slice(0, 4)?.map((ele, index) => (
                  <div
                    key={ele?.id || index}
                    className="col-md-3 col-6"
                    onClick={() => handleSinglePackageData(ele)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="Section_Cards1">
                      <img
                        src={`${Base_Url}${ele?.Image}`}
                        alt=""
                        height="150px"
                        className="Section_image"
                      />
                      <div
                        className="Section_text_container"
                        style={{
                          backgroundColor: "#CED7F8",
                          minHeight: "140px",
                        }}
                      >
                        <div style={{ fontWeight: "600" }}>{ele?.title}</div>
                        <div>
                          <span className="text-primary pe-1">
                            <IoLocationOutline />
                          </span>
                          <span>{ele?.destination}</span>
                        </div>
                        <div>
                          <span className="text-primary pe-2">
                            <SlCalender />
                          </span>
                          <span style={{ fontSize: "14px" }}>
                            {new Date(
                              ele?.availableDates[0]
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            })}
                          </span>
                        </div>

                        <div>
                          Starting at
                          {`₹${parseInt(ele?.price).toLocaleString()}`}
                          /person
                        </div>
                        <div className="pt-3 d-flex justify-content-center align-items-end">
                          <div className="Section_text_small_underline_large"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>

      <div className="mobile-view">
        <div className="container-fluid mt-2">
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col-7">
              <div className="homepage_cards_headings">
                Best Holidays Packages
              </div>
            </div>
            <div
              className="col-5 text-end p-0"
              onClick={handleViewAll}
              style={{ cursor: "pointer" }}
            >
              <span className="homepage_cards_exploremore">Explore More</span>
            </div>
          </div>

          <div className="row pt-1">
            {bestTouristPackage?.slice(0, 4)?.map((ele, index) => (
              <div
                key={ele?._id || index}
                className="col-6 p-1"
                onClick={() => handleSinglePackageData(ele)}
                style={{ cursor: "pointer" }}
              >
                <div className="Section_Cards1">
                  <img
                    src={`${Base_Url}${ele?.Image}`}
                    alt=""
                    height="110px"
                    className="Section_image"
                  />
                  <div
                    className="Section_text_container"
                    style={{
                      backgroundColor: "#CED7F8",
                      minHeight: "140px",
                    }}
                  >
                    <div style={{ fontWeight: "600" }}>{ele?.title}</div>
                    <div style={{ fontSize: "12px" }}>{ele?.destination}</div>
                    {new Date(ele?.availableDates[0]).toLocaleDateString(
                      "en-GB",
                      {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                      }
                    )}
                    <div>Starting at ₹{ele?.price}/Person</div>
                    <div className="pt-3 d-flex justify-content-center align-items-end">
                      <div className="Section_text_small_underline_large"></div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
