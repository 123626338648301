import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { Dashboard } from "./pages/Dashboard";
import { Login } from "./component/auth/Login";
import { AllDailyTravelBlogs } from "./pages/AllDailyTravelBlogs";
import { SingleDailyTravelBlogs } from "./pages/SingleDailyTravelBlogs";
import { AllHolidaysPackages } from "./pages/AllHolidaysPackages";
import { SingleHolidaysPackages } from "./pages/SingleHolidaysPackages";
import { SingleSpecialOffer } from "./pages/SingleSpecialOffer";
import { TermsAndConditions } from "./pages/TermsAndConditions";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { Signup } from "./component/auth/Signup";
import { OtpVerification } from "./component/auth/OtpVerification";
import { RefundAndCancellation } from "./pages/RefundAndCancellation";
import { MyProfile } from "./pages/MyProfile";
import { NotFound } from "./pages/NotFound";
import { BusSearch } from "./pages/Bus/BusSearch";
import { CabSearch } from "./pages/Cab/CabSearch";
import { SearchHotels } from "./pages/Hotel/SearchHotels";
import { SingleHotelDetails } from "./pages/Hotel/SingleHotelDetails";
import { EditProfile } from "./pages/EditProfile";
import { SearchFlights } from "./pages/Flight/SearchFlights";
import { Cabcheckout } from "./pages/Cab/Cabcheckout";
import { MyContextFunction } from "./context/MyContext";
import { FlightsCheckout } from "./pages/Flight/FlightsCheckout";
import { BusSeatLayout } from "./pages/Bus/BusSeatLayout";
import { AllSpecialOffers } from "./pages/AllSpecialOffers";
import { FlightStatus } from "./pages/Flight/FlightStatus";
import { Myorders } from "./pages/Myorders";
import { RaiseTicket } from "./pages/RaiseTicket";
import { Feedbackform } from "./pages/Feedbackform";
import { Newsletter } from "./pages/Newsletter";
import { InsuranceSearch } from "./pages/Insurance/InsuranceSearch";
import { RoundTrip } from "./pages/Flight/RoundTrip";
import { InsuranceBook } from "./pages/Insurance/InsuranceBook";
import CCAvenueForm from "./pages/Insurance/CCAvenueForm";
import { useEffect } from "react";
import { BusBooking } from "./pages/Bus/BusBooking";
import { HotelBooking } from "./pages/Hotel/HotelBooking";
import { CabsBooking } from "./pages/Cab/CabsBooking";
import { InsuranceDetails } from "./pages/Insurance/InsuranceDetails";
import CommonLayoutAdmin from "./admin panel/components/layout/CommonLayoutAdmin";
import AdminDashboard from "./admin panel/pages/AdminDashboard";
import { Faq } from "./admin panel/pages/Faq";
import { Testimonials } from "./admin panel/pages/Testimonials";
import { DailyTravelBlogs } from "./admin panel/pages/DailyTravelBlogs";
import Orders from "./admin panel/pages/Orders";
import { UsersData } from "./admin panel/pages/UsersData";
import { CabsOrder } from "./admin panel/pages/CabsOrder";
import { HolidayPackages } from "./admin panel/pages/HolidayPackages";
import { SpecialOffers } from "./admin panel/pages/SpecialOffers";
import { TouristDestination } from "./admin panel/pages/TouristDestination";
import { Payment } from "./pages/Payment";
import { PaymentSuccess } from "./pages/PaymentSuccess";
import { PaymentFailure } from "./pages/PaymentFailure";
import { GetConsultation } from "./pages/GetConsultation";
import { PrivateRouter } from "./privateroute/PrivateRouter";

function App() {
  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  return (
    <>
      <Router>
        <MyContextFunction>
          <Routes>
            <Route path="/" element={<Home />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/:formType" element={<Dashboard />}></Route>
              <Route path="/payment" element={<Payment />} />
              <Route path="/login" element={<Login />} />
              <Route path="/getconsultation" element={<GetConsultation />} />

              <Route path="/paymentfailure" element={<PaymentFailure />} />
              <Route path="/paymentsuccess" element={<PaymentSuccess />} />

              <Route path="/signup" element={<Signup />} />
              <Route path="/otpverification" element={<OtpVerification />} />

              <Route path="/myprofile" element={<MyProfile />} />
              <Route path="/myprofile/editprofile" element={<EditProfile />} />

              <Route
                path="/termsandconditions"
                element={<TermsAndConditions />}
              />
              <Route
                path="/refundandcancellation"
                element={<RefundAndCancellation />}
              />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route
                path="/holidayspackage"
                element={<AllHolidaysPackages />}
              />
              <Route
                path="/holidayspackage/:id"
                element={<SingleHolidaysPackages />}
              />
              <Route path="/blogs" element={<AllDailyTravelBlogs />} />
              <Route path="/blogs/:id" element={<SingleDailyTravelBlogs />} />
              <Route path="/specialoffers" element={<AllSpecialOffers />} />
              <Route
                path="/singlespecialoffers/:id"
                element={<SingleSpecialOffer />}
              />

              <Route
                path="/searchflight/:fromAirportLocationName/:fromData/:toAirportLocationName/:toData/:dateData"
                element={<SearchFlights />}
              />
              <Route
                path="/searchflight/:fromAirportLocationName/:fromData/:toAirportLocationName/:toData/:dateData/:ResultIndex/:SrdvIndex/:SrdvType/:TraceId"
                element={<FlightsCheckout />}
              />
              <Route
                path="/searchflight/flightcheckout"
                element={<FlightsCheckout />}
              />
              <Route path="/flightstatus" element={<FlightStatus />} />
              <Route
                path="/roundtripflights/:fromAirportLocationName/:fromData/:toAirportLocationName/:toData/:departureDate/:arrivalDate"
                element={<RoundTrip />}
              ></Route>

              <Route
                path="/searchcab/:fromData/:toData/:dateData"
                element={<CabSearch />}
              />
              <Route
                path="/searchcab/:fromData/:toData/:dateData/:traceid/:srdvindex"
                element={<Cabcheckout />}
              />
              <Route
                path="/searchcab/:fromData/:toData/:dateData/:traceid/:srdvindex/cabbooking"
                element={<CabsBooking />}
              />

              <Route
                path="/searchhotels/:hotelcity/:checkInDate/:checkOutDate"
                element={<SearchHotels />}
              />
              <Route
                path="/searchhotels/:hotelcity/:checkInDate/:checkOutDate/:hotelname/:hotelcode"
                element={<SingleHotelDetails />}
              />
              <Route
                path="/searchhotels/:hotelcity/:hotelname/:hotelcode/hotelbooking"
                element={<HotelBooking />}
              />

              <Route
                path="/searchbus/:from/:to/:DepartureDate"
                element={<BusSearch />}
              />
              <Route
                path="/searchbus/:from/:to/:DepartureDate/buslayout/:TraceID"
                element={<BusSeatLayout />}
              />
              <Route
                path="/searchbus/buslayout/:TraceID/bookingStatus"
                element={<BusBooking />}
              />

              <Route path="/searchinsurance" element={<InsuranceSearch />} />
              <Route
                path="/searchinsurance/:TraceId/:ResultIndex"
                element={<InsuranceDetails />}
              />
              <Route
                path="/searchinsurance/:TraceId/:ResultIndex/insurancebook"
                element={<InsuranceBook />}
              />

              <Route
                path="/testkitforInsuranceBooking"
                element={<CCAvenueForm />}
              />

              <Route path="/feedback" element={<Feedbackform />} />
              <Route path="/raiseticket" element={<RaiseTicket />} />
              <Route path="/newsletter" element={<Newsletter />} />
              <Route path="/myorders" element={<Myorders />} />
              <Route path="/ticket" element={<Myorders />} />

              <Route path="*" element={<NotFound />} />
            </Route>

            {/* Admin Panel */}

            <Route
              path="/admin"
              element={
                <PrivateRouter adminRoute={true}>
                  <AdminDashboard />
                </PrivateRouter>
              }
            />

            <Route
              path="/admin/testimonials"
              element={
                <CommonLayoutAdmin>
                  <Testimonials />
                </CommonLayoutAdmin>
              }
            />
            <Route
              path="/admin/dailytravelblogs"
              element={
                <CommonLayoutAdmin>
                  <DailyTravelBlogs />
                </CommonLayoutAdmin>
              }
            />
            <Route
              path="/admin/touristdestination"
              element={
                <CommonLayoutAdmin>
                  <TouristDestination />
                </CommonLayoutAdmin>
              }
            />
            <Route
              path="/admin/faq"
              element={
                <CommonLayoutAdmin>
                  <Faq />
                </CommonLayoutAdmin>
              }
            />
            <Route
              path="/admin/specialoffers"
              element={
                <CommonLayoutAdmin>
                  <SpecialOffers />
                </CommonLayoutAdmin>
              }
            />
            <Route
              path="/admin/holidaypackages"
              element={
                <CommonLayoutAdmin>
                  <HolidayPackages />
                </CommonLayoutAdmin>
              }
            />
            <Route
              path="/admin/order"
              element={
                <CommonLayoutAdmin>
                  <Orders />
                </CommonLayoutAdmin>
              }
            />
            <Route
              path="/admin/cabsorder"
              element={
                <CommonLayoutAdmin>
                  <CabsOrder />
                </CommonLayoutAdmin>
              }
            />
            <Route
              path="/admin/user"
              element={
                <CommonLayoutAdmin>
                  <UsersData />
                </CommonLayoutAdmin>
              }
            />
          </Routes>
        </MyContextFunction>
      </Router>
    </>
  );
}

export default App;
