import React from "react";
import "../styles/Table.css";
import { Card, Dropdown, Row, Table } from "react-bootstrap";

const AdminuserTable = () => {
  const handleDeleteuser = (id) => {};
  return (
    <>
      <div className="container">
        <h4>Users</h4>

        <Row>
          <div className="col mt-0 mb-3">
            <Card className="shadow">
              <Table className="align-items-center" responsive="sm">
                <thead className="thead-dark">
                  <tr className="table-dark">
                    <th>ID</th>
                    <th>FullName</th>
                    <th>Email</th>
                    <th>Profile</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>Index</td>
                    <td>firstname</td>
                    <td>email</td>
                    <td className="img_parent">
                      <img src="" alt="" />
                      img
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="light"
                          className="action"
                          id="dropdown-basic"
                        >
                          <i className="fa-solid fa-ellipsis-vertical"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <div onClick={() => handleDeleteuser()}>
                              <i
                                class="fa-solid fa-trash"
                                style={{ color: "red" }}
                              ></i>

                              <span>Delete</span>
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </div>
    </>
  );
};

export default AdminuserTable;
