import React, { useEffect, useState } from "react";
import {
  createTravelBlogs,
  DeleteSingleTravelBlogs,
  GetAllTravelBlogs,
  GetSingleTravelBlogs,
  updateSingleTravelBlogs,
} from "../api/Api";
import Table from "react-bootstrap/Table";
import { IoTrashBinOutline } from "react-icons/io5";
import { HiPencilSquare } from "react-icons/hi2";
import { IoEye, IoStar, IoStarOutline } from "react-icons/io5";
import { GrFormAdd } from "react-icons/gr";
import { formatDistanceToNow } from "date-fns";
import { Toaster, toast } from "react-hot-toast";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import StarRating from "./StarRating";
import axios from "axios";

const DailyTravelBlogsSchema = Yup.object().shape({
  Author: Yup.string().required("Author is required"),
  Image: Yup.string().required("Image URL is required"),
  Description: Yup.string().required("Description is required"),
  Title: Yup.string().required("Title is required"),
});

export const DailyTravelBlogs = () => {
  const [TravelBlogsData, setTravelBlogsData] = useState([]);
  const [SingleTravelBlogsData, setSingleTravelBlogsData] = useState([]);

  const FetchAllTravelBlogs = async () => {
    try {
      const res = await GetAllTravelBlogs();
      console.log(res);
      if (res?.status === "success") {
        setTravelBlogsData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchAllTravelBlogs();
  }, []);

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        i <= rating ? (
          <IoStar key={i} color="orange" />
        ) : (
          <IoStarOutline key={i} />
        )
      );
    }
    return stars;
  };

  const handleDeleteTestimonial = async (ele) => {
    try {
      const res = await DeleteSingleTravelBlogs(ele?._id);
      if (res?.status === "success") {
        setTravelBlogsData(
          TravelBlogsData.filter((item) => item._id !== ele._id)
        );
        toast.success("Daily Travel Blog Deleted Successfully!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSingleTestimonial = async (ele) => {
    try {
    } catch (error) {
      console.log(error);
    }
  };

  const [singleTravelBlog, setSingleTravelBlog] = useState();

  const handleSingleTravelBlog = async (ele) => {
    try {
      console.log(ele);
      handleSingleDailyTravelBlogsModalShow();
      const res = await GetSingleTravelBlogs(ele?._id);
      console.log(res);

      if (res?.status === "success") {
        setSingleTravelBlogsData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const timeSinceCreation = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Invalid date"; // Return a fallback value
    }
    return formatDistanceToNow(date, { addSuffix: true });
  };

  const [showSingleDailyTravelBlogsModal, setShowSingleDailyTravelBlogsModal] =
    useState(false);

  const handleSingleDailyTravelBlogsModalClose = () =>
    setShowSingleDailyTravelBlogsModal(false);
  const handleSingleDailyTravelBlogsModalShow = () =>
    setShowSingleDailyTravelBlogsModal(true);

  const [showAddDailyTravelBlogsModal, setShowAddDailyTravelBlogsModal] =
    useState(false);

  const handleAddDailyTravelBlogsModalClose = () =>
    setShowAddDailyTravelBlogsModal(false);
  const handleAddDailyTravelBlogsModalShow = () =>
    setShowAddDailyTravelBlogsModal(true);

  const [ShowUpdateDailyTravelBlogsModal, setShowUpdateDailyTravelBlogsModal] =
    useState(false);

  // Update Daily Blogs

  const [updateTravelBlogsData, setUpdateTravelBlogsData] = useState(null);

  const handleUpdateDailyTravelBlogsModalClose = () =>
    setShowUpdateDailyTravelBlogsModal(false);
  const handleUpdateDailyTravelBlogsModalShow = () =>
    setShowUpdateDailyTravelBlogsModal(true);

  const handleAddDailyTravelBlog = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("Author", values.Author);
    formData.append("Image", values.Image);
    formData.append("Title", values.Title);
    formData.append("Description", values.Description);

    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      const res = await createTravelBlogs(formData);
      console.log(res);

      if (res?.data?.status === "success") {
        setTravelBlogsData([...TravelBlogsData, res.data]);
        toast.success("Daily Travel Blog Added Successfully!");
        handleAddDailyTravelBlogsModalClose();
        window.location.reload();
      }
    } catch (error) {
      console.error("Error creating testimonial:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleUpdateTravelBlog = async (ele) => {
    try {
      console.log(ele);
      handleUpdateDailyTravelBlogsModalShow();
      const res = await GetSingleTravelBlogs(ele?._id);

      if (res?.status === "success") {
        setUpdateTravelBlogsData(res?.data);
        console.log(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitUpdate = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("Author", values.Author);
    formData.append("Title", values.Title);
    formData.append("Description", values.Description);
  
    if (values.Image instanceof File) {
      formData.append("Image", values.Image);
    } else {
      formData.append("Image", updateTravelBlogsData.Image);
    }
  
    // Debugging: Log the FormData content
    for (const pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }
  
    try {
      const res = await updateSingleTravelBlogs(updateTravelBlogsData._id, formData);

      console.log(res)
      console.log(updateTravelBlogsData?._id)
      console.log(formData)
  
      if (res?.data?.status === "success") {
        setTravelBlogsData((prev) =>
          prev.map((item) =>
            item._id === updateTravelBlogsData._id ? res.data : item
          )
        );
        toast.success("Daily travel Updated Successfully!");
        handleUpdateDailyTravelBlogsModalClose();
      }
    } catch (error) {
      console.error("Error updating travel blog:", error);
      toast.error("Oops! Some error occurred");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="container">
      <Toaster position="top-right" reverseOrder={false} />

      <div className="row">
        <div className="d-flex justify-content-between">
          <div>
            <span style={{ fontWeight: "600", fontSize: "20px" }}>
              Daily Travel Blogs
            </span>
          </div>
          <div>
            <button
              className="addbutton"
              onClick={handleAddDailyTravelBlogsModalShow}
            >
              Add Daily Travel Blogs
              <GrFormAdd style={{ fontWeight: "800", fontSize: "25px" }} />
            </button>
          </div>
        </div>
      </div>

      {/* Table ~ All Daily Travel Blogs  */}

      <div className="row py-4">
        <Table responsive="sm">
          <thead>
            <tr>
              <th>S.no</th>
              <th>Name</th>
              <th>Image</th>
              <th>Title</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(TravelBlogsData) && TravelBlogsData?.length > 0 ? (
              TravelBlogsData?.map((ele, index) => (
                <tr key={ele?._id || index}>
                  <td>{index + 1}</td>
                  <td>{ele?.Author}</td>
                  <td>
                    <img
                      src={`https://api.exploretriponline.com${ele?.Image}`}
                      alt={ele?.Name}
                      style={{ width: "50px", height: "50px" }}
                    />
                  </td>
                  <td>
                    {ele?.Title?.length > 40
                      ? ele?.Title?.slice(0, 35) + "..."
                      : ele?.Title}
                  </td>
                  <td>
                    {ele?.Description?.length > 40
                      ? ele?.Description.slice(0, 35) + "..."
                      : ele?.Description}
                  </td>

                  <td>
                    <div className="d-flex">
                      <div
                        onClick={() => handleSingleTravelBlog(ele)}
                        className="action_button_view"
                      >
                        <IoEye />
                      </div>
                      <div
                        className="px-2 action_button_update"
                        onClick={() => handleUpdateTravelBlog(ele)}
                      >
                        <HiPencilSquare className="text-success" />
                      </div>
                      <div
                        className="action_button_delete"
                        onClick={() => handleDeleteTestimonial(ele)}
                      >
                        <IoTrashBinOutline className="text-danger" />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  Data Not Available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Modal ~ Single Daily Travel Blogs  */}

      <div className="row">
        <Modal
          show={showSingleDailyTravelBlogsModal}
          onHide={handleSingleDailyTravelBlogsModalClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Daily Travel Blogs</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center align-items-center">
              <img
                src={`https://api.exploretriponline.com${SingleTravelBlogsData?.Image}`}
                alt=""
                style={{ height: "40vh", width: "100%" }}
              />
            </div>

            <div className="d-flex align-items-center justify-content-center pt-1"></div>

            <div className="py-2" style={{ fontWeight: "600" }}>
              {SingleTravelBlogsData?.Title}
            </div>
            <div className="py-2">{SingleTravelBlogsData?.Description}</div>

            <div className="d-flex justify-content-between">
              <div>
                <span style={{ fontWeight: "600" }}> Author ~ </span>
                <span>{SingleTravelBlogsData?.Author}</span>
              </div>
              <div>
                <div className="px-1 text-secondary">
                  ~ {timeSinceCreation(SingleTravelBlogsData?.createdAt)}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      {/* Add Daily Travel Blogs Modal */}

      <div className="row">
        <Modal
          show={showAddDailyTravelBlogsModal}
          onHide={handleAddDailyTravelBlogsModalClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Daily Travel Blogs</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                Author: "",
                Image: null,
                Description: "",
                Title: "",
              }}
              validationSchema={DailyTravelBlogsSchema}
              onSubmit={handleAddDailyTravelBlog}
            >
              {({ setFieldValue, values, isSubmitting }) => (
                <Form>
                  <div>
                    <div>Author</div>
                    <Field type="text" name="Author" className="Form_Field" />
                    <ErrorMessage
                      name="Author"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="mt-2">
                    <div>Image</div>
                    <input
                      type="file"
                      name="Image"
                      className="Form_Field"
                      onChange={(event) => {
                        setFieldValue("Image", event.currentTarget.files[0]);
                      }}
                    />
                    <ErrorMessage
                      name="Image"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="mt-2">
                    <div>Title</div>
                    <Field type="text" name="Title" className="Form_Field" />
                    <ErrorMessage
                      name="Title"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="mt-2">
                    <div>Description</div>
                    <Field
                      as="textarea"
                      name="Description"
                      className="Form_Field"
                    />
                    <ErrorMessage
                      name="Description"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <Modal.Footer>
                    <Button
                      onClick={handleAddDailyTravelBlogsModalClose}
                      disabled={isSubmitting}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </div>

      {/* Update  Travel Blogs Modal */}

      <div className="row">
        <Modal
          show={ShowUpdateDailyTravelBlogsModal}
          onHide={handleUpdateDailyTravelBlogsModalClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Testimonial</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {updateTravelBlogsData && (
              <Formik
                initialValues={{
                  Author: updateTravelBlogsData.Author || "",
                  Image: updateTravelBlogsData.Image || null,
                  Title: updateTravelBlogsData.Title || "",
                  Description: updateTravelBlogsData.Description || "",
                }}
                validationSchema={DailyTravelBlogsSchema}
                onSubmit={handleSubmitUpdate} 
              >
                {({ setFieldValue, values, isSubmitting }) => (
                  <Form>
                    <div>
                      <div>Author</div>
                      <Field type="text" name="Author" className="Form_Field" />
                      <ErrorMessage
                        name="Author"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="mt-2">
                      <div>Image</div>
                      <input
                        type="file"
                        name="Image"
                        className="Form_Field"
                        onChange={(event) => {
                          setFieldValue("Image", event.currentTarget.files[0]);
                        }}
                      />
                      <ErrorMessage
                        name="Image"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="mt-2">
                      <div>Title</div>
                      <Field type="text" name="Title" className="Form_Field" />
                      <ErrorMessage
                        name="Title"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="mt-2">
                      <div>Description</div>
                      <Field
                        as="textarea"
                        name="Description"
                        className="Form_Field"
                      />
                      <ErrorMessage
                        name="Description"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handleUpdateDailyTravelBlogsModalClose}
                        disabled={isSubmitting}
                      >
                        Close
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={isSubmitting}
                      >
                        Update
                      </Button>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};
