import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CabsBookingAPI } from "../../api/CabAPI";

export const CabsBooking = () => {
  const { traceid, srdvindex } = useParams();
  const [cabBookingData, setCabBookingData] = useState("");

  const handleCabsBooking = async () => {
    const data = {
      TraceID: traceid,
      SrdvIndex: srdvindex,
    };
    const res = await CabsBookingAPI(data);
    console.log(res?.data?.Result);
    if (res) {
      setCabBookingData(res?.data?.Result);
    }
  };

  useEffect(() => {
    handleCabsBooking();
  }, []);

  return (
    <div>
      <div className="my-4">
        <h3
          className="text-center"
          style={{ color: "green", fontWeight: "600" }}
        >
          Congratulations, your Cab Booking is confirmed
        </h3>
        <div className="d-flex justify-content-center">
          <table className="border strip w-75">
            <tbody>
              <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                <td className="py-2">
                  <span className="ps-3 m-0">Booking ID</span>
                </td>
                <td>
                  <span
                    className="ps-3"
                    style={{ borderLeft: "1px solid lightgray" }}
                  >
                    {cabBookingData?.BookingID}
                  </span>
                </td>
              </tr>
              <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                <td className="py-2">
                  <span className="ps-3 m-0">Operator ID</span>
                </td>
                <td>
                  <span
                    className="ps-3"
                    style={{ borderLeft: "1px solid lightgray" }}
                  >
                    {cabBookingData?.OperatorID}
                  </span>
                </td>
              </tr>
              <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                <td className="py-2">
                  <span className="ps-3 m-0">Ref ID</span>
                </td>
                <td>
                  <span
                    className="ps-3"
                    style={{ borderLeft: "1px solid lightgray" }}
                  >
                    {cabBookingData?.RefID}
                  </span>
                </td>
              </tr>
              <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                <td className="py-2">
                  <span className="ps-3 m-0">Segment ID</span>
                </td>
                <td>
                  <span
                    className="ps-3"
                    style={{ borderLeft: "1px solid lightgray" }}
                  >
                    {cabBookingData?.SegmentID}
                  </span>
                </td>
              </tr>
              <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                <td className="py-2">
                  <span className="ps-3 m-0">Srdv Index</span>
                </td>
                <td>
                  <span
                    className="ps-3"
                    style={{ borderLeft: "1px solid lightgray" }}
                  >
                    {cabBookingData?.SrdvIndex}
                  </span>
                </td>
              </tr>
              <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                <td className="py-2">
                  <span className="ps-3 m-0">Srdv Type</span>
                </td>
                <td>
                  <span
                    className="ps-3"
                    style={{ borderLeft: "1px solid lightgray" }}
                  >
                    {cabBookingData?.SrdvType}
                  </span>
                </td>
              </tr>
              <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                <td className="py-2">
                  <span className="ps-3 m-0">Status</span>
                </td>
                <td>
                  <span
                    className="ps-3"
                    style={{ borderLeft: "1px solid lightgray" }}
                  >
                    {cabBookingData?.Status}
                  </span>
                </td>
              </tr>
              <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                <td className="py-2">
                  <span className="ps-3 m-0">Trace ID</span>
                </td>
                <td>
                  <span
                    className="ps-3"
                    style={{ borderLeft: "1px solid lightgray" }}
                  >
                    {cabBookingData?.TraceID}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
