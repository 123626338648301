import React from "react";
import "../style/TermsAndConditions.css";

export const TermsAndConditions = () => {
    const listStyle = {
        listStyleType: "number",
        fontSize: "1.0rem",
        counterReset: "section",
    };

    return (
        <div class="container py-4">
            <div class="row justify-content-md-center mt-none-30">
                <div class="col-lg-12 col-md-12 mt-30">
                    <h3 className="fw-bold">Terms and Conditions</h3>
                    <p>
                        Welcome to Exploretrip Online Booking! These terms and conditions outline the rules and
                        regulations for the use of Exploretrip Online Booking's Website, located at
                        www.exploretriponline.com.By accessing this website we assume you accept these terms and
                        conditions. Do not continue to use Exploretrip Online Booking if you do not agree to take all of
                        the terms and conditions stated on this page.
                    </p>
                </div>
                <div class="col-lg-12 col-md-12 ">
                    <div className="list_items">
                        <ol style={listStyle}>
                            <li className="mb-2">
                                <strong>Booking and Reservations</strong>
                                <p className="m-0">
                                    By initiating a booking or reservation through our platform, you agree to adhere to
                                    the terms and conditions outlined herein.
                                </p>
                                <p className="m-0">
                                    All bookings are subject to availability and confirmation by Exploretrip Online
                                    Booking.
                                </p>
                            </li>
                            <li className="mb-2">
                                <strong>Payment:</strong>
                                <p className="m-0">
                                    Payment for bookings must be made in full at the time of reservation unless
                                    otherwise specified.
                                </p>
                                <p className="m-0">
                                    We accept various payment methods, including credit/debit cards, electronic funds
                                    transfer, and other approved payment methods.
                                </p>
                            </li>
                            <li className="mb-2">
                                <strong>Cancellation and Refunds:</strong>
                                <p className="m-0">
                                    Cancellation policies vary depending on the type of booking and the terms set by the
                                    service provider.
                                </p>
                                <p className="m-0">
                                    Refunds, if applicable, will be processed according to the cancellation policy and
                                    may be subject to administrative fees.
                                </p>
                            </li>
                            <li className="mb-2">
                                <strong>Changes to Bookings:</strong>
                                <p className="m-0">
                                    Changes to bookings, including dates, times, and passenger details, may be permitted
                                    subject to availability and the policies of the service provider.
                                </p>
                                <p className="m-0">Additional charges may apply for changes made to bookings.</p>
                            </li>
                            <li className="mb-2">
                                <strong>Confirmation:</strong>
                                <p className="m-0">
                                    Booking confirmation will be provided upon successful completion of the reservation
                                    process.
                                </p>
                                <p className="m-0">
                                    It is the responsibility of the user to review booking details for accuracy and
                                    inform us of any discrepancies immediately.
                                </p>
                            </li>
                            <li className="mb-2">
                                <strong>Responsibility:</strong>
                                <p className="m-0">
                                    Exploretrip Online Booking acts solely as an intermediary between the user and the
                                    service provider.
                                </p>
                                <p className="m-0">
                                    We are not liable for any discrepancies, errors, or omissions made by the service
                                    provider or third-party vendors.
                                </p>
                            </li>
                            <li className="mb-2">
                                <strong>Validity:</strong>
                                <p className="m-0">
                                    Bookings and reservations are valid only for the dates, times, and services
                                    specified in the confirmation.
                                </p>
                                <p className="m-0">
                                    Any deviations from the confirmed booking may result in additional charges or
                                    cancellation.
                                </p>
                            </li>
                            <li className="mb-2">
                                <strong>Disputes:</strong>
                                <p className="m-0">
                                    Any disputes regarding bookings or reservations should be addressed directly with
                                    Exploretrip Online Booking within a reasonable timeframe.
                                </p>
                            </li>
                            <li className="mb-2">
                                <strong>Changes to Terms and Conditions:</strong>
                                <p className="m-0">
                                    Exploretrip Online Booking reserves the right to amend or update these terms and
                                    conditions at any time without prior notice.
                                </p>
                                <p className="m-0">
                                    Users are advised to review the terms and conditions regularly for any changes.
                                </p>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 ">
                    <p>
                        By proceeding with a booking or reservation through Exploretrip Online Booking, you acknowledge
                        that you have read, understood, and agree to abide by these terms and conditions.
                    </p>
                </div>
            </div>
        </div>
    );
};
