import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom/dist";
import {
  BookHotelRoom,
  OrderHotel,
  SearchSingleHotelsAPI,
  getSingleHotelRooms,
} from "../../api/HotelAPI";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaStar } from "react-icons/fa";
import "../../../src/style/Hotel.css";
import images from "../../assets/Images";
import Modal from "react-bootstrap/Modal";
import { getUserProfile, sendPaymentDetails } from "../../api/ProfileAPI";

import * as Yup from "yup";
import { Toaster, toast } from "react-hot-toast";

export const SingleHotelDetails = () => {
  const [hotelData, setHotelData] = useState(null);
  const [hotelImages, setHotelImages] = useState(null);
  const [SingleHotelInfo, setSingleHotelInfo] = useState();
  const { hotelcode, checkInDate, checkOutDate } = useParams();
  const [loading, setLoading] = useState(true);

  async function getSingleHotelDetails() {
    try {
      const res = await SearchSingleHotelsAPI(hotelcode);
      if (res) {
        setHotelData(res?.data?.HotelInfoResult?.HotelDetails);
        setHotelImages(res?.data?.HotelInfoResult?.HotelDetails?.Images);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getSingleHotelDetails();
  }, []);

  async function getHotelRooms() {
    try {
      const res = await getSingleHotelRooms();
      if (res) {
        setSingleHotelInfo(res?.data?.GetHotelRoomResult?.HotelRoomsDetails);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getHotelRooms();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      date
    );
    const year = date.getFullYear();
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes();
    const amOrPm = date.getHours() < 12 ? "AM" : "PM";
    const formattedTime = `${hours}:${minutes
      .toString()
      .padStart(2, "0")} ${amOrPm}`;

    return `${day} ${month} ${year} at ${formattedTime}`;
  };

  const renderStarRating = (rating) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < rating) {
        stars.push(<FaStar key={i} style={{ color: "#f7dd00" }} />);
      }
    }
    return stars;
  };

  const [showHotelFacilitiesModal, setShowHotelFacilitiesModal] =
    useState(false);

  const handleHotelFacilitiesModalClose = () =>
    setShowHotelFacilitiesModal(false);
  const handleHotelFacilitiesModalShow = () =>
    setShowHotelFacilitiesModal(true);

  const [selectedRoom, setSelectedRoom] = useState(null);

  const [guestData, setGuestData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    billing_name: "",
    billing_address: "",
    billing_city: "",
    billing_state: "",
    billing_zip: "",
    billing_country: "",
    billing_tel: "",
    billing_email: "",
  });

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Please select a title"),
    billing_name: Yup.string().required("Please enter your name"),
    billing_address: Yup.string().required("Please enter your address"),
    billing_city: Yup.string().required("Please enter your city"),
    billing_state: Yup.string().required("Please enter your state"),
    billing_zip: Yup.string().required("Please enter your ZIP code"),
    billing_country: Yup.string().required("Please enter your country"),
    billing_tel: Yup.string()
      .required("Please enter your phone number")
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits"),
    billing_email: Yup.string()
      .email("Invalid email address")
      .required("Billing email is required"),
  });

  const handleRoomSelection = (room) => {
    setSelectedRoom(room);
  };

  const HandleHotelOrder = async () => {
    if (selectedRoom) {
      try {
        const bookingResponse = await BookHotelRoom();

        if (bookingResponse?.success === true) {
          const data = {
            totalAmount: selectedRoom?.Price?.RoomPrice,
            checkInDate: checkInDate,
            checkOutDate: checkOutDate,
            bookingDetails: selectedRoom?.RoomTypeName,
            BookResult: {
              VoucherStatus: bookingResponse?.data?.BookResult?.VoucherStatus,
              ResponseStatus: bookingResponse?.data?.BookResult?.ResponseStatus,
              TraceId: bookingResponse?.data?.BookResult?.TraceId,
              Status: bookingResponse?.data?.BookResult?.Status,
              HotelBookingStatus:
                bookingResponse?.data?.BookResult?.HotelBookingStatus,
              InvoiceNumber: bookingResponse?.data?.BookResult?.InvoiceNumber,
              ConfirmationNo: bookingResponse?.data?.BookResult?.ConfirmationNo,
              BookingRefNo: bookingResponse?.data?.BookResult?.BookingRefNo,
              BookingId: bookingResponse?.data?.BookResult?.BookingId,
              IsPriceChanged: bookingResponse?.data?.BookResult?.IsPriceChanged,
              IsCancellationPolicyChanged:
                bookingResponse?.data?.BookResult?.IsCancellationPolicyChanged,
            },
            customerInfo: {
              name: "Deepak",
              email: "Deepak@example.com",
              address: "Najafgarh",
              mobile: "7835967867",
              state: "Delhi",
              pincode: "110043",
            },
          };

          const orderResponse = await OrderHotel(data);
        } else {
          console.log("Booking failed, cannot proceed to order.");
        }
      } catch (error) {
        console.error("Error in hotel booking or order process:", error);
      }
    } else {
      console.log("No room selected!");
    }
  };

  const [showSingleHotelModal, setShowSingleHotelModal] = useState(false);
  const [showSingleHotel, setShowSingleHotel] = useState(false);

  const handleSingleHotelModalClose = () => setShowSingleHotelModal(false);
  const handleSingleHotelModalShow = (ele) => {
    setShowSingleHotel(ele);
    setShowSingleHotelModal(true);
  };

  // Fetch User Details

  const [userInformation, setUserInformation] = useState(null);

  const GetUserInformationApi = async () => {
    const userTokenFromCookies = Cookies.get("userToken");

    if (userTokenFromCookies) {
      const res = await getUserProfile(userTokenFromCookies);
      console.log(res, ":getUserProfile");
      setUserInformation(res?.data);
    }
  };

  useEffect(() => {
    GetUserInformationApi();
  }, []);

  // Generate ID

  const generateUniqueOrderId = () => {
    return Math.floor(1000000000 + Math.random() * 9000000000).toString();
  };

  const [OrderId, setOrderId] = useState(generateUniqueOrderId());
  const [isPaymentFormVisible, setIsPaymentFormVisible] = useState(false);

  useEffect(() => {
    generateUniqueOrderId();
  }, []);

  const navigate = useNavigate();

  const handlePayment = async (e) => {
    e.preventDefault();

    if (!userInformation) {
      toast.error("Please Login for Book Bus", {
        duration: 5000,
      });

      setTimeout(() => {
        navigate("/login");
      }, 5000);

      return;
    }

    const formData = {
      order_id: OrderId,
      mobile: userInformation?.mobile,
      amount: "1",
      booking_type: "Hotel",
      customer_name: "John Doe",
      email: "john.doe@example.com",
      payment_status: "Pending",
      tracking_id: "",
      payment_mode: "Unified Payments",
      order_status: "pending",
    };

    try {
      const res = await sendPaymentDetails(formData);

      console.log(res, "savepaymentdetails");
      console.log(formData, "formData");

      if (res?.status === "success") {
        // Make the form visible and submit it
        setIsPaymentFormVisible(true);
        setTimeout(() => {
          document.customerData.submit();
        }, 1000); // Auto-submit the form after showing it
      } else {
        console.error("Error saving payment details");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <Modal
            show={showHotelFacilitiesModal}
            onHide={handleHotelFacilitiesModalClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Room Amenities</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {hotelData && (
                <>
                  <div>
                    {hotelData?.HotelFacilities?.map((ele, index) => {
                      return (
                        <div key={index}>
                          <ul>
                            <li>{ele}</li>
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </Modal.Body>
          </Modal>
        </div>

        <style>
          {`
                        @media (max-width: 767px) {
                            h2 {
                                display: none;
                            }
                            p{
                                font-size:12px;
                            }
                            h4{
                                font-size:16px;
                            }
                            h5{
                                font-size:16px;
                            }
                            h6{
                                font-size:12px;
                            }
                            li{
                                font-size:12px;
                            }
                           
                           
                        }
                    `}
        </style>
        {loading ? (
          <div
            className="  d-flex align-items-center justify-content-center"
            style={{ height: "400px" }}
          >
            <img
              src={images?.Flight_Loader_Transparent}
              height={170}
              width={170}
            ></img>
          </div>
        ) : (
          <>
            <h2 className="text-center mt-3 py-2">Hotels Details</h2>
            <div className="row">
              <div className="col-md-6 p-0">
                <Slider {...settings}>
                  {hotelImages?.map((ele, id) => (
                    <div
                      key={id}
                      className="p-1 col-12"
                      style={{ border: "1px solid gray" }}
                    >
                      <img
                        src={ele}
                        width="100%"
                        height="240px"
                        alt={`Slide ${id}`}
                      />
                    </div>
                  ))}
                </Slider>
              </div>

              <div className="col-md-6 desktop-view">
                <h5 className="m-0 pb-1">
                  {hotelData?.HotelName} {hotelData?.HotelCode} : Standard Room
                  with 2 single beds
                </h5>
                <div className="d-flex pt-1 align-items-center">
                  {renderStarRating(hotelData?.StarRating)}
                  <span className="px-2">({hotelData?.StarRating})</span>
                </div>
                <p className="m-0 pb-1">
                  Room With Free Cancellation | Breakfast only
                </p>
                <p className="m-0 pb-1">{hotelData?.Address}</p>
              </div>

              <div className="col-12 mobile-view">
                <div className="row">
                  <div className="col-12 ">
                    <div
                      className="m-0 d-flex align-items-center"
                      style={{ fontSize: "16px", fontWeight: "600" }}
                    >
                      <span>{hotelData?.HotelName}</span>
                      <span
                        className="Bus_Cards_Star ms-2"
                        style={{ fontSize: "10px" }}
                      >
                        ★ {hotelData?.StarRating}
                      </span>
                    </div>
                    <span
                      className="m-0"
                      style={{ fontSize: "12px", fontWeight: "400" }}
                    >
                      {hotelData?.Address}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Hotel Room Types - Desktop View */}

            <div className="row">
              <div className="desktop-view">
                <h2 className="text-center ">Hotel Rooms Types</h2>
                {SingleHotelInfo?.map((ele, index) => (
                  <div
                    key={index}
                    style={{
                      border: "1px solid lightgray",
                      borderRadius: "10px",
                      overflow: "hidden",
                    }}
                    className="d-flex justify-content-between mb-3"
                    onClick={() => handleRoomSelection(ele)}
                  >
                    <div className="">
                      <img src={hotelImages[3]} alt="" height="140px" />
                    </div>

                    <div>
                      <div style={{ fontSize: "18px", fontWeight: "600" }}>
                        Hotel Amenities
                      </div>
                      <div className="pb-2">
                        {hotelData?.HotelFacilities?.slice(0, 2).map(
                          (ele, index) => {
                            return (
                              <div key={index}>
                                <ul className="m-0">
                                  <li className="p-0 m-0">{ele}</li>
                                </ul>
                              </div>
                            );
                          }
                        )}
                      </div>

                      <div>
                        <button
                          className="d-flex align-items-center loginSignup_div"
                          onClick={handleHotelFacilitiesModalShow}
                        >
                          View More Amenities
                        </button>
                      </div>
                    </div>
                    <div>
                      <div className="d-flex mb-2">
                        <div className="pe-2" style={{ fontWeight: "600" }}>
                          Room Code -
                        </div>
                        <div>{ele?.RoomIndex}</div>
                      </div>
                      <div className="d-flex">
                        <div className="pe-2" style={{ fontWeight: "600" }}>
                          Room Type -
                        </div>
                        <div>{ele?.RoomTypeName}</div>
                      </div>
                    </div>
                    <div>
                      <div className="pe-2" style={{ fontWeight: "600" }}>
                        {`₹${parseInt(ele?.Price?.RoomPrice).toLocaleString()}`}
                      </div>

                      <span></span>

                      <div
                        className="pe-2 pb-2"
                        style={{
                          fontSize: "16px",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleSingleHotelModalShow(ele);
                        }}
                      >
                        Cancellation Policy
                      </div>

                      <button
                        className={
                          selectedRoom === ele
                            ? "selectHotelRoom"
                            : "deselectHotelRoom"
                        }
                      >
                        Select Hotel
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Hotel Room Types - Mobile View */}

            <div className="row">
              <div className="mobile-view">
                <div className="text-center py-2">Hotel Rooms Types</div>
                {SingleHotelInfo?.map((ele, index) => (
                  <div
                    key={index}
                    style={{
                      border: "1px solid lightgray",
                      borderRadius: "10px",
                    }}
                    className="mb-3"
                    onClick={() => handleRoomSelection(ele)}
                  >
                    <div className="col-12">
                      <img
                        src={hotelImages[3]}
                        alt=""
                        height="140px"
                        className="w-100"
                      />
                    </div>

                    <div className="d-flex">
                      <div className="pe-2" style={{ fontWeight: "600" }}>
                        Room Code :
                      </div>
                      <div>{ele?.RoomIndex}</div>
                    </div>
                    <div className="d-flex">
                      <div className="pe-2" style={{ fontWeight: "600" }}>
                        Room Type :
                      </div>
                      <div>{ele?.RoomTypeName}</div>
                    </div>

                    <div className="pe-2" style={{ fontWeight: "600" }}>
                      Room Price :
                      {`₹${parseInt(ele?.Price?.RoomPrice).toLocaleString()}`}
                    </div>

                    <div className="col-12" style={{ fontWeight: "600" }}>
                      Hotel Amenities
                    </div>

                    <div className="col-12">
                      {hotelData?.HotelFacilities?.slice(0, 2).map(
                        (ele, index) => {
                          return (
                            <div key={index}>
                              <ul className="m-0">
                                <li className="p-0 m-0">{ele}</li>
                              </ul>
                            </div>
                          );
                        }
                      )}
                    </div>

                    <div>
                      <button
                        className="d-flex align-items-center loginSignup_div"
                        onClick={handleHotelFacilitiesModalShow}
                      >
                        View More Amenities
                      </button>
                    </div>

                    <div
                      className="pb-2"
                      style={{
                        fontSize: "16px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleSingleHotelModalShow(ele);
                      }}
                    >
                      Cancellation Policy
                    </div>

                    <div className="mb-3">
                      <button
                        className={
                          selectedRoom === ele
                            ? "selectHotelRoom"
                            : "deselectHotelRoom"
                        }
                      >
                        Select Hotel
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div>
              {isPaymentFormVisible && (
                <form
                  method="POST"
                  name="customerData"
                  action="https://exploretriponline.com/api/ccavRequestHandler"
                  style={{ display: "none" }}
                >
                  <table width="40%" height="100" border="1" align="center">
                    <caption>
                      <font size="4" color="blue">
                        <b>Integration Kit</b>
                      </font>
                    </caption>
                  </table>
                  <table width="40%" height="100" border="1" align="center">
                    <tr>
                      <td>Parameter Name:</td>
                      <td>Parameter Value:</td>
                    </tr>
                    <tr>
                      <td colspan="2">Compulsory information</td>
                    </tr>
                    <tr>
                      <td>Merchant Id</td>
                      <td>
                        <input
                          type="text"
                          name="merchant_id"
                          id="merchant_id"
                          value="3401584"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Order Id</td>
                      <td>
                        <input
                          type="text"
                          name="order_id"
                          value={OrderId}
                          readOnly
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Currency</td>
                      <td>
                        <input type="text" name="currency" value="INR" />
                      </td>
                    </tr>
                    <tr>
                      <td>Amount</td>
                      <td>
                        <input type="text" name="amount" value="1.00" />
                      </td>
                    </tr>
                    <tr>
                      <td>Redirect URL</td>
                      <td>
                        <input
                          type="text"
                          name="redirect_url"
                          value="https://exploretriponline.com/api/landing"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Cancel URL</td>
                      <td>
                        <input
                          type="text"
                          name="cancel_url"
                          value="https://exploretriponline.com/api/landing"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Language</td>
                      <td>
                        <input
                          type="text"
                          name="language"
                          id="language"
                          value="EN"
                        />
                      </td>
                    </tr>

                    <tr>
                      <label for="booking_type">Booking Type:</label>
                      <select id="booking_type" name="booking_type">
                        <option value="flight">Flight</option>
                        <option value="hotel">Hotel</option>
                        <option value="cab">Cab</option>
                        <option value="bus">Bus</option>
                        <option value="insurance">Insurance</option>
                      </select>
                    </tr>

                    <tr>
                      <td colspan="2">Billing information(optional):</td>
                    </tr>
                    <tr>
                      <td>Billing Name</td>
                      <td>
                        <input
                          type="text"
                          name="billing_name"
                          value={userInformation?.name}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Billing Address:</td>com
                      <td>
                        <input
                          type="text"
                          name="billing_address"
                          value="Santacruz"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Billing City:</td>
                      <td>
                        <input type="text" name="billing_city" value="Mumbai" />
                      </td>
                    </tr>
                    <tr>
                      <td>Billing State:</td>
                      <td>
                        <input type="text" name="billing_state" value="MH" />
                      </td>
                    </tr>
                    <tr>
                      <td>Billing Zip:</td>
                      <td>
                        <input type="text" name="billing_zip" value="400054" />
                      </td>
                    </tr>
                    <tr>
                      <td>Billing Country:</td>
                      <td>
                        <input
                          type="text"
                          name="billing_country"
                          value="India"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Billing Tel:</td>
                      <td>
                        <input
                          type="text"
                          name="billing_tel"
                          value={userInformation?.mobile}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Billing Email:</td>
                      <td>
                        <input
                          type="text"
                          name="billing_email"
                          value="testing@domain.com"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">Shipping information(optional):</td>
                    </tr>
                    <tr>
                      <td>Shipping Name</td>
                      <td>
                        <input type="text" name="delivery_name" value="Sam" />
                      </td>
                    </tr>
                    <tr>
                      <td>Shipping Address:</td>
                      <td>
                        <input
                          type="text"
                          name="delivery_address"
                          value="Vile Parle"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Shipping City:</td>
                      <td>
                        <input
                          type="text"
                          name="delivery_city"
                          value="Mumbai"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Shipping State:</td>
                      <td>
                        <input
                          type="text"
                          name="delivery_state"
                          value="Maharashtra"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Shipping Zip:</td>
                      <td>
                        <input type="text" name="delivery_zip" value="400038" />
                      </td>
                    </tr>
                    <tr>
                      <td>Shipping Country:</td>
                      <td>
                        <input
                          type="text"
                          name="delivery_country"
                          value="India"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Shipping Tel:</td>
                      <td>
                        <input
                          type="text"
                          name="delivery_tel"
                          value="0123456789"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Merchant Param1</td>
                      <td>
                        <input
                          type="text"
                          name="merchant_param1"
                          value="additional Info."
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Merchant Param2</td>
                      <td>
                        <input
                          type="text"
                          name="merchant_param2"
                          value="additional Info."
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Merchant Param3</td>
                      <td>
                        <input
                          type="text"
                          name="merchant_param3"
                          value="additional Info."
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Merchant Param4</td>
                      <td>
                        <input
                          type="text"
                          name="merchant_param4"
                          value="additional Info."
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Merchant Param5</td>
                      <td>
                        <input
                          type="text"
                          name="merchant_param5"
                          value="additional Info."
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Promo Code:</td>
                      <td>
                        <input type="text" name="promo_code" value="" />
                      </td>
                    </tr>
                    <tr>
                      <td>Customer Id:</td>
                      <td>
                        <input
                          type="text"
                          name="customer_identifier"
                          value=""
                        />
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <input type="submit" value="Checkout" />
                      </td>
                    </tr>
                  </table>
                </form>
              )}
            </div>

            <button onClick={handlePayment} className="payButton my-2">
              Pay Now
            </button>

            <div>
              <div className="row">
                <Modal
                  show={showSingleHotelModal}
                  onHide={handleSingleHotelModalClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Hotel Cancellation Policies</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="row">
                      {showSingleHotel?.CancellationPolicies?.map(
                        (policy, policyIndex) => (
                          <div
                            key={policyIndex}
                            className="col-md-12 py-3 mb-1"
                            style={{
                              border: "1.5px solid lightgray",
                            }}
                          >
                            <div className=" ">
                              <h6 className="">
                                Cancellation Policy - {policy?.ChargeType}
                              </h6>
                              <div
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                <span className="pe-1">
                                  {`₹${parseInt(
                                    policy.Charge
                                  ).toLocaleString()}`}
                                </span>
                                <span className="pe-1">
                                  will be charged, If cancelled between
                                </span>
                                <span className="pe-1">
                                  {formatDate(policy.FromDate)}
                                </span>
                                <span className="pe-1">
                                  {formatDate(policy.ToDate)}
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>

            <div>
              <Toaster position="top-right" reverseOrder={false} />
            </div>
          </>
        )}
      </div>
    </>
  );
};
