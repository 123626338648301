import React, { useEffect, useState } from "react";
import {
  DeleteSingleTouristDestination,
  DeleteSingleTravelBlogs,
  getallOffer,
  getalltouristdestination,
  GetAllTravelBlogs,
  getSingleOffer,
  GetSingleTouristDestination,
  GetSingleTravelBlogs,
} from "../api/Api";
import Table from "react-bootstrap/Table";
import { IoTrashBinOutline } from "react-icons/io5";
import { HiPencilSquare } from "react-icons/hi2";
import { IoEye, IoStar, IoStarOutline } from "react-icons/io5";
import { GrFormAdd } from "react-icons/gr";
import { formatDistanceToNow } from "date-fns";
import Modal from "react-bootstrap/Modal";
import { Toaster, toast } from "react-hot-toast";

export const SpecialOffers = () => {
  const [SpecialOffersData, setSpecialOffersData] = useState([]);
  const [SingleSpecialOffersData, setSingleSpecialOffersData] = useState([]);

  const [showSingleSpecialOfferModal, setShowSingleSpecialOfferModal] =
    useState(false);

  const handleSingleSpecialOfferModalClose = () =>
    setShowSingleSpecialOfferModal(false);
  const handleSingleSpecialOfferModalShow = () =>
    setShowSingleSpecialOfferModal(true);

  const FetchAllSpecialOffers = async () => {
    try {
      const res = await getallOffer();
      if (res?.status === "success") {
        setSpecialOffersData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchAllSpecialOffers();
  }, []);

  const handleDeleteSpecialOffers = async (ele) => {
    try {
      console.log(ele);
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdateSpecialOffers = async (ele) => {
    try {
      console.log(ele);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSingleSpecialOffers = async (ele) => {
    try {
      handleSingleSpecialOfferModalShow();
      const res = await getSingleOffer(ele?._id);
      console.log(res);
      if (res?.status === "success") {
        setSingleSpecialOffersData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const timeSinceCreation = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Invalid date"; // Return a fallback value
    }
    return formatDistanceToNow(date, { addSuffix: true });
  };

  return (
    <div className="container">
      <Toaster position="top-right" reverseOrder={false} />

      <div className="row">
        <div className="d-flex justify-content-between">
          <div>
            <span style={{ fontWeight: "600", fontSize: "20px" }}>
              Special Offers
            </span>
          </div>
          <div>
            <button className="addbutton">
              Add Special Offers
              <GrFormAdd style={{ fontWeight: "800", fontSize: "25px" }} />
            </button>
          </div>
        </div>
      </div>

      <div className="row py-4">
        <Table responsive="sm">
          <thead>
            <tr>
              <th>S.no</th>
              <th>OfferTitle</th>
              <th>Category</th>
              <th>Image</th>
              <th>OfferCouponCode</th>
              <th>Valid Till</th>
              <th>OfferDescription</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(SpecialOffersData) &&
            SpecialOffersData.length > 0 ? (
              SpecialOffersData?.map((ele, index) => (
                <tr key={ele?._id}>
                  <td>{index + 1}</td>
                  <td>
                    {ele?.OfferTitle?.length > 10
                      ? ele?.OfferTitle?.slice(0, 10) + "..."
                      : ele?.OfferTitle}
                  </td>
                  <td>{ele?.OfferCategoryName}</td>
                  <td>
                    <img
                      src={`https://api.exploretriponline.com${ele?.OfferImage}`}
                      alt={ele?.Name}
                      style={{ width: "80px", height: "40px" }}
                    />
                  </td>
                  <td>{ele?.OfferCouponCode}</td>

                  <td>{new Date(ele.OfferDate).toLocaleDateString()}</td>

                  <td>
                    {ele?.OfferDescription?.length > 30
                      ? ele?.OfferDescription?.slice(0, 10) + "..."
                      : ele?.OfferDescription}
                  </td>
                  <td>
                    <div className="d-flex">
                      <div
                        onClick={() => handleSingleSpecialOffers(ele)}
                        className="action_button_view"
                      >
                        <IoEye />
                      </div>
                      <div
                        className="px-2 action_button_update"
                        onClick={() => handleUpdateSpecialOffers(ele)}
                      >
                        <HiPencilSquare className="text-success" />
                      </div>
                      <div
                        className="action_button_delete"
                        onClick={() => handleDeleteSpecialOffers(ele)}
                      >
                        <IoTrashBinOutline className="text-danger" />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  Data Not Available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      <div className="row">
        <Modal
          show={showSingleSpecialOfferModal}
          onHide={handleSingleSpecialOfferModalClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Special Offer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100">
              <img
                src={`https://api.exploretriponline.com${SingleSpecialOffersData?.OfferImage}`}
                alt=""
                className="w-100"
              />
            </div>

            <p
              className="text-center m-0"
              style={{ fontSize: "20px", fontWeight: "600" }}
            >
              {SingleSpecialOffersData?.OfferTitle}
            </p>
            <p
              className="text-center m-0 py-2"
              style={{ fontSize: "18px", fontWeight: "500" }}
            >
              {SingleSpecialOffersData?.OfferDescription}
            </p>

            <div className="col-md-12 d-flex justify-content-center">
              <div className="d-flex" style={{ fontSize: "24px" }}>
                <div>
                  <p
                    className="text-white px-2"
                    style={{ backgroundColor: "blue" }}
                  >
                    Use Code
                  </p>
                </div>
                <div>
                  <p
                    className="text-black px-2"
                    style={{
                      backgroundColor: "white",
                      border: "1px dashed red",
                    }}
                  >
                    {SingleSpecialOffersData?.OfferCouponCode}
                  </p>
                </div>
              </div>
            </div>

            <p
              className="text-center m-0 py-2"
              style={{ fontSize: "16px", fontWeight: "500" }}
            >
              Valid till ~
              {new Date(SingleSpecialOffersData.OfferDate).toLocaleDateString()}
            </p>

            <div className="d-flex align-items-center justify-content-center pt-1">
              <div className="px-1 text-secondary">
                {timeSinceCreation(SingleSpecialOffersData?.createdAt)}
              </div>
            </div>
            <div className="py-2">{SingleSpecialOffersData?.Description}</div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};
