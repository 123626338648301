import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../../style/Login.css";
import { useNavigate } from "react-router-dom";
import { FaPhoneAlt, FaRegUser } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import Cookies from "js-cookie";
import images from "../../assets/Images";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const Signup = () => {
    const navigate = useNavigate();
    const [signupApiMsg, setSignupApiMsg] = useState("");

    const validationSignupSchema = Yup.object().shape({
        name: Yup.string()
            .required("Please enter your full name")
            .matches(/^[a-zA-Z\s]+$/, "Full name must contain alphabetic characters only"),
        email: Yup.string().email("Invalid email address").required("Please enter your email address"),
        mobile: Yup.string()
            .required("Please enter your mobile number")
            .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits"),
        termsAndConditions: Yup.boolean().oneOf([true], "Please accept the terms and conditions"),
    });

    const handleTermsandConditions = () => {
        navigate("/termsandconditions");
    };

    const handlePrivacyPolicy = () => {
        navigate("/privacypolicy");
    };

    const handleLogin = () => {
        navigate("/login");
    };

    const LoginSlider = [
        { id: 1, imgPath: images.Slider1, title: "Slider 1" },
        { id: 2, imgPath: images.Slider2, title: "Slider 2" },
        { id: 3, imgPath: images.Slider3, title: "Slider 3" },
    ];

    var LoginSliderSettings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
    };

    return (
        <div className="container">
            <div className="row d-flex justify-content-center p-2">
                <div className="col-md-3 m-0 p-0 desktop-view">
                    <Slider {...LoginSliderSettings}>
                        {LoginSlider?.map((ele) => (
                            <div key={ele.id} className="col-md-3 col-12">
                                <div className="">
                                    <img src={ele.imgPath} alt="" height="450px" className="w-100 p-0"></img>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
                <div className="col-md-5 Section_Container px-3 py-0">
                    <Formik
                        initialValues={{
                            name: "",
                            email: "",
                            mobile: "",
                            termsAndConditions: false,
                        }}
                        validationSchema={validationSignupSchema}
                        onSubmit={async (values, actions, handleChange) => {
                            try {
                                const res = await fetch(
                                    `https://exploretriponline.com/custom_api/accounts/signUp.php?mobile=${values.mobile}&name=${values.name}&email=${values.email}`,
                                    {
                                        method: "GET",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                    }
                                );
                                const response = await res.json();
                                console.log(response.data);
                                setSignupApiMsg(response);
                                actions.resetForm();

                                if (response.success && response.message === "OTP sent on given number.") {
                                    setSignupApiMsg("");
                                    // setGivenMobileNumber(response.data);
                                    Cookies.set("userMobile", response?.data, {
                                        expires: 30,
                                    });
                                    navigate("/otpverification");
                                }
                            } catch (error) {
                                console.error("Error:", error);
                            }
                        }}
                    >
                        {({ isSubmitting, values, handleChange }) => (
                            <Form>
                                <div>
                                    <div className="d-flex justify-content-center">
                                        <img src={images.logo_mark} alt="logo" width="65px" height="40px" />
                                    </div>
                                    <div>
                                        <h5 className="text-center pt-2 m-0">Welcome to the Explore Trip Online</h5>
                                        <p className="m-0 text-center" style={{ fontSize: "13px" }}>
                                            Please Enter your details below.
                                        </p>
                                    </div>
                                    <div className="mt-1" style={{ height: "70px" }}>
                                        <p className="m-0" style={{ fontSize: "14px", fontWeight: "500" }}>
                                            User Name
                                        </p>
                                        <div className="input_field d-flex align-items-center">
                                            <Field
                                                type="text"
                                                name="name"
                                                placeholder="Enter your full name"
                                                className="w-100"
                                            />
                                        </div>
                                        <ErrorMessage
                                            name="name"
                                            component="div"
                                            className="error text-danger m-0"
                                            style={{ fontSize: "10px" }}
                                        />
                                    </div>

                                    <div className="mt-0" style={{ height: "70px" }}>
                                        <p className="m-0" style={{ fontSize: "14px", fontWeight: "500" }}>
                                            Email
                                        </p>
                                        <div className="input_field d-flex align-items-center">
                                            <Field
                                                type="email"
                                                name="email"
                                                placeholder="Enter your email address"
                                                className="w-100"
                                            />
                                        </div>
                                        <ErrorMessage
                                            name="email"
                                            component="div"
                                            className="error text-danger m-0"
                                            style={{ fontSize: "10px" }}
                                        />
                                    </div>

                                    <div style={{ height: "70px" }}>
                                        <p className="m-0" style={{ fontSize: "14px", fontWeight: "500" }}>
                                            Mobile Number
                                        </p>
                                        <div className="input_field d-flex align-items-center">
                                            <span className="pe-2" style={{ borderRight: "1px solid black" }}>
                                                {/* <FaPhoneAlt className="text-black" /> */}
                                                +91
                                            </span>
                                            <Field
                                                type="text"
                                                name="mobile"
                                                placeholder="Enter your Mobile Number"
                                                maxLength={10}
                                                className="ps-2 w-100 bg-white"
                                            />
                                        </div>
                                        <ErrorMessage
                                            name="mobile"
                                            component="div"
                                            className="error text-danger m-0"
                                            style={{ fontSize: "10px" }}
                                        />
                                    </div>

                                    <div>
                                        <div>
                                            {signupApiMsg.message === "OTP sent on given number." ? (
                                                <p className="text-center text-success">{signupApiMsg.message}</p>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <div>
                                            {signupApiMsg.message === "User is already registered." ? (
                                                <p className="text-center text-danger">{signupApiMsg.message}</p>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>

                                    <div style={{ fontSize: "14px", height: "60px" }}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="termsAndConditions"
                                                checked={values.termsAndConditions}
                                                onChange={handleChange}
                                            />
                                            <span className="ps-2">
                                                <span>I have read and agree to the </span>
                                                <span
                                                    onClick={handleTermsandConditions}
                                                    className="cursor-pointer text-primary"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    terms and conditions
                                                </span>
                                                <span> and </span>
                                                <span
                                                    onClick={handlePrivacyPolicy}
                                                    className="cursor-pointer text-primary"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    privacy policy
                                                </span>
                                            </span>
                                        </label>
                                        <ErrorMessage
                                            name="termsAndConditions"
                                            component="div"
                                            className="error text-danger"
                                            style={{ fontSize: "10px" }}
                                        />
                                    </div>

                                    <button type="submit" className="login_btn mt-2" disabled={isSubmitting}>
                                        Proceed
                                    </button>

                                    <div className="text-center pt-1" style={{ fontSize: "14px" }}>
                                        <span>Already have an account then </span>
                                        <span
                                            onClick={handleLogin}
                                            style={{ cursor: "pointer" }}
                                            className="text-primary cursor-pointer text-underline"
                                        >
                                            Login up
                                        </span>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};
