import React, { useEffect, useState } from "react";
import {
  BookInsurance,
  Get_Single_Insurance,
  Insurance_Book,
} from "../../api/InsuranceApi";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

export const InsuranceBook = () => {
  const { TraceId, ResultIndex, id } = useParams();
  const [selectedInsuranceData, setselectedInsuranceData] = useState("");

  const getInsuranceCheckoutData = async () => {
    try {
      const singleInsurancedata = await Get_Single_Insurance(id);
      if (singleInsurancedata) {
        setselectedInsuranceData(singleInsurancedata?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInsuranceCheckoutData();
  }, [TraceId]);

  const initialValues = {
    pickUpAddress: "",
    dropOffAddress: "",
    name: "",
    gender: "",
    email: "",
    phone: "",
    pincode: "",
    state: "",
    address: "",
  };

  const validationSchema = Yup.object().shape({
    pickUpAddress: Yup.string().required("Pick-up address is required"),
    dropOffAddress: Yup.string().required("Drop-off address is required"),
    name: Yup.string().required("Name is required"),
    gender: Yup.string().required("Gender is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone number is required"),
    pincode: Yup.string().required("Pincode is required"),
    state: Yup.string().required("State is required"),
    address: Yup.string().optional(),
  });

  const [BookedInsuranceData, setBookedInsuranceData] = useState("");
  const navigate = useNavigate();
  const insuranceBook = async () => {
    const data = {
      ResultIndex,
      TraceId,
    };
    const res = await BookInsurance(data);
    console.log(res);
    console.log(res?.data?.Response?.Itinerary);
    if (res?.status === "success") {
      setBookedInsuranceData(res?.data?.Response?.Itinerary);
    }
    // if (res?.data?.Response?.Itinerary === undefined) {
    //   navigate("/T%20Insurance");
    // }
  };

  useEffect(() => {
    insuranceBook();
  }, []);

  useEffect(() => {
    if (BookedInsuranceData === undefined) {
      navigate("/");
    }
  }, [BookedInsuranceData, navigate]);

  return (
    <div>
      <div className="container ">
        <h3
          className="text-center"
          style={{ color: "green", fontWeight: "600" }}
        >
          Congratulations, your Insurance Booking is confirmed
        </h3>

        <div className="d-flex justify-content-center">
          {BookedInsuranceData ? (
            <table className="border strip w-100 my-3">
              <tbody>
                <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                  <td className="py-2">
                    <span className="ps-3 m-0">Booking ID</span>
                  </td>
                  <td>
                    <span
                      className="ps-3"
                      style={{ borderLeft: "1px solid lightgray" }}
                    >
                      {BookedInsuranceData.BookingId}
                    </span>
                  </td>
                </tr>
                <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                  <td className="py-2">
                    <span className="ps-3 m-0">Insurance ID</span>
                  </td>
                  <td>
                    <span
                      className="ps-3"
                      style={{ borderLeft: "1px solid lightgray" }}
                    >
                      {BookedInsuranceData.InsuranceId}
                    </span>
                  </td>
                </tr>
                <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                  <td className="py-2">
                    <span className="ps-3 m-0">Plan Name</span>
                  </td>
                  <td>
                    <span
                      className="ps-3"
                      style={{ borderLeft: "1px solid lightgray" }}
                    >
                      {BookedInsuranceData.PlanName}
                    </span>
                  </td>
                </tr>
                <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                  <td className="py-2">
                    <span className="ps-3 m-0">Plan Type</span>
                  </td>
                  <td>
                    <span
                      className="ps-3"
                      style={{ borderLeft: "1px solid lightgray" }}
                    >
                      {BookedInsuranceData.PlanType}
                    </span>
                  </td>
                </tr>
                <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                  <td className="py-2">
                    <span className="ps-3 m-0">Plan Coverage</span>
                  </td>
                  <td>
                    <span
                      className="ps-3"
                      style={{ borderLeft: "1px solid lightgray" }}
                    >
                      {BookedInsuranceData.PlanCoverage}
                    </span>
                  </td>
                </tr>
                <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                  <td className="py-2">
                    <span className="ps-3 m-0">Policy Start Date</span>
                  </td>
                  <td>
                    <span
                      className="ps-3"
                      style={{ borderLeft: "1px solid lightgray" }}
                    >
                      {BookedInsuranceData.PolicyStartDate}
                    </span>
                  </td>
                </tr>
                <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                  <td className="py-2">
                    <span className="ps-3 m-0">Policy End Date</span>
                  </td>
                  <td>
                    <span
                      className="ps-3"
                      style={{ borderLeft: "1px solid lightgray" }}
                    >
                      {BookedInsuranceData.PolicyEndDate}
                    </span>
                  </td>
                </tr>
                {/* Add more rows as needed based on your data structure */}
              </tbody>
            </table>
          ) : (
            <p></p>
          )}
        </div>
      </div>
    </div>
  );
};
