import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SingleHolidayPackageAPIData } from "../api/HolidayPackageAPI";
import { Loader } from "../component/Loader";
import { Base_Url } from "../Url/Url";

export const SingleHolidaysPackages = () => {
  const { id } = useParams();
  const [packageData, setPackageData] = useState(null);

  const fetchPackageData = async () => {
    try {
      const res = await SingleHolidayPackageAPIData(id);
      console.log(res);
      if (res?.status === "success") {
        setPackageData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPackageData();
  }, []);

  if (!packageData) {
    return <Loader />;
  }

  return (
    <>
      <div className="desktop-view">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-md-12">
              <div className="row d-flex justify-content-between mt-3">
                <div
                  className="col p-0"
                  style={{ fontSize: "30px", fontWeight: "600" }}
                >
                  {packageData?.title}
                </div>
                <div className="col p-0 d-flex align-items-center justify-content-end">
                  <div className="d-flex">
                    <div className="d-flex align-items-center px-3">
                      {`₹${parseInt(packageData?.price).toLocaleString()}`}
                      /person
                    </div>
                    <div className="payButton">Book Now!</div>
                  </div>
                </div>
              </div>
              <div className="row py-4">
                <div
                  className="col-md-12 p-0 Section_Container"
                  style={{ overflow: "hidden" }}
                >
                  <img
                    src={`${Base_Url}${packageData?.Image}`}
                    alt="img"
                    height="450px"
                    className="w-100"
                    style={{ objectFit: "fill" }}
                  />
                </div>
              </div>

              <div className="row singleDetailBorder">
                <div
                  className="col-6"
                  style={{ borderRight: "1px solid lightgray" }}
                >
                  <div
                    className="text-center"
                    style={{ fontSize: "24px", fontWeight: "600" }}
                  >
                    Inclusions
                  </div>
                  <p className="p-0" style={{ fontSize: "18px" }}>
                    {packageData?.inclusions?.map((ele, index) => {
                      return (
                        <div className="mb-3" key={index}>
                          <ul>
                            <li>{ele}</li>
                          </ul>
                        </div>
                      );
                    })}
                  </p>
                </div>

                <div className="col-6">
                  <div
                    className="text-center"
                    style={{ fontSize: "24px", fontWeight: "600" }}
                  >
                    exclusions
                  </div>
                  <p className="p-0" style={{ fontSize: "18px" }}>
                    {packageData?.exclusions?.map((ele, index) => {
                      return (
                        <div className="mb-3" key={index}>
                          <ul>
                            <li>{ele}</li>
                          </ul>
                        </div>
                      );
                    })}
                  </p>
                </div>
              </div>

              <div className="row singleDetailBorder">
                <div
                  className="text-center"
                  style={{ fontSize: "24px", fontWeight: "600" }}
                >
                  Detailed Day Wise Itinerary
                </div>
                <p className="p-0" style={{ fontSize: "18px" }}>
                  {packageData?.itinerary?.map((ele, index) => {
                    return (
                      <div
                        className="mb-3"
                        key={index}
                        style={{ borderBottom: "1px solid lightgray" }}
                      >
                        <span
                          className="py-1 px-2"
                          style={{
                            border: "1px solid gray",
                            backgroundColor: "lightblue",
                          }}
                        >
                          Day - {index + 1}
                        </span>
                        <p className="m-0 m-0 py-2">{ele}</p>
                      </div>
                    );
                  })}
                </p>
              </div>

              <div className="row singleDetailBorder">
                <div
                  className="text-center"
                  style={{ fontSize: "24px", fontWeight: "600" }}
                >
                  About Destination
                </div>

                <div>
                  <span
                    className="pe-1"
                    style={{ fontSize: "20px", fontWeight: "600" }}
                  >
                    {packageData?.title}
                  </span>
                </div>

                <div>
                  <span className="pe-1" style={{ fontWeight: "600" }}>
                    Available Dates :
                  </span>
                  <span>
                    {new Date(packageData?.availableDates).toLocaleDateString(
                      "en-GB",
                      {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                      }
                    )}
                  </span>
                </div>
                <div>
                  <span className="pe-1" style={{ fontWeight: "600" }}>
                    Stay Plan :
                  </span>
                  <span className="">{packageData?.duration} Days</span>
                </div>
                <div>
                  <span className="pe-1" style={{ fontWeight: "600" }}>
                    Accommodation :
                  </span>
                  <span className="">{packageData?.accommodation} Days</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-view">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-md-12">
              <div className="row mt-3">
                <div
                  className="p-0"
                  style={{ fontSize: "16px", fontWeight: "600" }}
                >
                  {packageData?.title}
                </div>
                <div className="p-0 d-flex justify-content-between">
                  <div className="d-flex ">
                    {`₹${parseInt(packageData?.price).toLocaleString()}`}
                    /person
                  </div>
                  <div className="payButton">Book Now!</div>
                </div>
              </div>
              <div className="row py-4">
                <div
                  className="col-md-12 p-0 Section_Container"
                  style={{ overflow: "hidden" }}
                >
                  <img
                    src={`${Base_Url}${packageData?.Image}`}
                    alt="img"
                    height="220px"
                    className="w-100"
                    style={{ objectFit: "fill" }}
                  />
                </div>
              </div>

              <div className="row singleDetailBorder">
                <div
                  className="col-12"
                  style={{ borderRight: "1px solid lightgray" }}
                >
                  <div
                    className="text-center"
                    style={{ fontSize: "18px", fontWeight: "600" }}
                  >
                    Inclusions
                  </div>
                  <p className="p-0">
                    {packageData?.inclusions?.map((ele, index) => {
                      return (
                        <div className="mb-3" key={index}>
                          <ul>
                            <li>{ele}</li>
                          </ul>
                        </div>
                      );
                    })}
                  </p>
                </div>

                <div className="col-12">
                  <div
                    className="text-center"
                    style={{ fontSize: "18px", fontWeight: "600" }}
                  >
                    exclusions
                  </div>
                  <p className="p-0">
                    {packageData?.exclusions?.map((ele, index) => {
                      return (
                        <div className="mb-3" key={index}>
                          <ul>
                            <li>{ele}</li>
                          </ul>
                        </div>
                      );
                    })}
                  </p>
                </div>
              </div>

              <div className="row singleDetailBorder">
                <div
                  className="text-center"
                  style={{ fontSize: "18px", fontWeight: "600" }}
                >
                  Detailed Day Wise Itinerary
                </div>
                <p className="p-0" style={{ fontSize: "18px" }}>
                  {packageData?.itinerary?.map((ele, index) => {
                    return (
                      <div
                        className="mb-3 p-2"
                        key={index}
                        style={{ borderBottom: "1px solid lightgray" }}
                      >
                        <span
                          className="py-1 px-2"
                          style={{
                            border: "1px solid gray",
                            backgroundColor: "lightblue",
                          }}
                        >
                          Day - {index + 1}
                        </span>
                        <p className="m-0 m-0 py-2">{ele}</p>
                      </div>
                    );
                  })}
                </p>
              </div>

              <div className="row singleDetailBorder pb-5">
                <div
                  className="text-center"
                  style={{ fontSize: "18px", fontWeight: "600" }}
                >
                  About Destination
                </div>

                <div>
                  <span
                    className="pe-1"
                    style={{ fontSize: "20px", fontWeight: "600" }}
                  >
                    {packageData?.title}
                  </span>
                </div>

                <div>
                  <span className="pe-1" style={{ fontWeight: "600" }}>
                    Available Dates :
                  </span>
                  <span>
                    {new Date(packageData?.availableDates).toLocaleDateString(
                      "en-GB",
                      {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                      }
                    )}
                  </span>
                </div>
                <div>
                  <span className="pe-1" style={{ fontWeight: "600" }}>
                    Stay Plan :
                  </span>
                  <span className="">{packageData?.duration} Days</span>
                </div>
                <div>
                  <span className="pe-1" style={{ fontWeight: "600" }}>
                    Accommodation :
                  </span>
                  <span className="">{packageData?.accommodation} Days</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
