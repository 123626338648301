import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import {
  createFaq,
  DeleteSingleFaq,
  getallFaq,
  getSingleFaq,
  updateFaq,
} from "../api/Api";
import Table from "react-bootstrap/Table";
import { IoTrashBinOutline } from "react-icons/io5";
import { HiPencilSquare } from "react-icons/hi2";
import { IoEye } from "react-icons/io5";
import { GrFormAdd } from "react-icons/gr";
import { formatDistanceToNow } from "date-fns";
import Modal from "react-bootstrap/Modal";
import { Toaster, toast } from "react-hot-toast";
import axios from "axios";

const FaqSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
});

export const Faq = () => {
  const [FaqData, setFaqData] = useState([]);
  const [SingleFaqData, setSingleFaqData] = useState(null);
  const [showSingleFaqModal, setShowSingleFaqModal] = useState(false);
  const [showUpdateFaqModal, setShowUpdateFaqModal] = useState(false);
  const [showCreateFaqModal, setShowCreateFaqModal] = useState(false);
  const [showUpdateFaqData, setShowUpdateFaqData] = useState(null);

  const handleSingleFaqModalClose = () => setShowSingleFaqModal(false);
  const handleSingleFaqModalShow = () => setShowSingleFaqModal(true);

  const handleUpdateFaqModalClose = () => setShowUpdateFaqModal(false);
  const handleUpdateFaqModalShow = () => setShowUpdateFaqModal(true);

  const handleCreateFaqModalClose = () => setShowCreateFaqModal(false);
  const handleCreateFaqModalShow = () => setShowCreateFaqModal(true);

  const FetchAllFaq = async () => {
    try {
      const res = await getallFaq();
      if (res?.status === "success") {
        setFaqData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching FAQs:", error);
    }
  };

  useEffect(() => {
    FetchAllFaq();
  }, []);

  const handleDeleteFaq = async (ele) => {
    try {
      const res = await DeleteSingleFaq(ele?._id);
      if (res?.status === "success") {
        setFaqData(FaqData.filter((item) => item._id !== ele._id));
        toast.success("FAQ Deleted Successfully!");
      }
    } catch (error) {
      console.log("Error deleting FAQ:", error);
    }
  };

  const handleSingleFaq = async (ele) => {
    try {
      const res = await getSingleFaq(ele?._id);
      if (res?.status === "success") {
        setSingleFaqData(res?.data);
        handleSingleFaqModalShow();
      }
    } catch (error) {
      console.log("Error fetching single FAQ:", error);
    }
  };

  const handleUpdateFaq = async (ele) => {
    try {
      const res = await getSingleFaq(ele?._id);
      handleUpdateFaqModalShow();
      if (res?.status === "success") {
        setShowUpdateFaqData(res?.data);
        handleUpdateFaqModalShow();
      }
    } catch (error) {
      console.log("Error fetching FAQ for update:", error);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const res = await createFaq(values); // Send values directly as JSON

      if (res?.data?.status === "success") {
        setFaqData([...FaqData, res.data.faq]); // Update state with new FAQ
        toast.success("FAQ Added Successfully!");
        handleCreateFaqModalClose();
        window.location.reload();
      }
    } catch (error) {
      console.error("Error creating FAQ:", error);
      toast.error("Oops! Some error occurred");
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmitUpdate = async (values, { setSubmitting }) => {
    const formData = {
      title: values.title,
      description: values.description,
    };

    try {
      const res = await updateFaq(showUpdateFaqData._id, formData);

      if (res?.data?.status === "success") {
        const updatedFaqData = FaqData.map((faq) =>
          faq._id === showUpdateFaqData._id ? res.data.updatedFaq : faq
        );
        setFaqData(updatedFaqData);
        toast.success("FAQ Updated Successfully!");
        handleUpdateFaqModalClose();
        window.location.reload();
      }
    } catch (error) {
      console.error("Error updating FAQ:", error);
      toast.error("Failed to update FAQ");
    } finally {
      setSubmitting(false);
    }
  };

  const timeSinceCreation = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime())
      ? "Invalid date"
      : formatDistanceToNow(date, { addSuffix: true });
  };

  return (
    <div className="container">
      <Toaster position="top-right" reverseOrder={false} />

      <div className="row">
        <div className="d-flex justify-content-between">
          <div>
            <span style={{ fontWeight: "600", fontSize: "20px" }}>FAQ's</span>
          </div>
          <div>
            <button className="addbutton" onClick={handleCreateFaqModalShow}>
              Add FAQ
              <GrFormAdd style={{ fontWeight: "800", fontSize: "25px" }} />
            </button>
          </div>
        </div>
      </div>

      <div className="row py-4">
        <Table responsive="sm">
          <thead>
            <tr>
              <th>S.no</th>
              <th>Title</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(FaqData) && FaqData.length > 0 ? (
              FaqData?.map((ele, index) => (
                <tr key={ele?._id}>
                  <td>{index + 1}</td>
                  <td>
                    {ele?.title?.length > 100
                      ? ele?.title?.slice(0, 100) + " ..."
                      : ele?.title}
                  </td>
                  <td>
                    {ele?.description?.length > 30
                      ? ele?.description?.slice(0, 130) + " ..."
                      : ele?.description}
                  </td>
                  <td>
                    <div className="d-flex">
                      <div
                        onClick={() => handleSingleFaq(ele)}
                        className="action_button_view"
                      >
                        <IoEye />
                      </div>
                      <div
                        className="px-2 action_button_update"
                        onClick={() => handleUpdateFaq(ele)}
                      >
                        <HiPencilSquare className="text-success" />
                      </div>
                      <div
                        className="action_button_delete"
                        onClick={() => handleDeleteFaq(ele)}
                      >
                        <IoTrashBinOutline className="text-danger" />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  Data Not Available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Single FAQ Modal */}
      <Modal
        show={showSingleFaqModal}
        onHide={handleSingleFaqModalClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>FAQ's </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {SingleFaqData && (
            <>
              <div className="py-2" style={{ fontWeight: "600" }}>
                {SingleFaqData?.title}
              </div>
              <div className="py-2">{SingleFaqData?.description}</div>
              <div className="d-flex justify-content-between">
                <div className="px-1 text-secondary">
                  ~ {timeSinceCreation(SingleFaqData?.createdAt)}
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>

      {/* Update FAQ Modal */}
      <Modal
        show={showUpdateFaqModal}
        onHide={handleUpdateFaqModalClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showUpdateFaqData && (
            <Formik
              initialValues={{
                title: showUpdateFaqData.title || "",
                description: showUpdateFaqData.description || "",
              }}
              validationSchema={FaqSchema}
              onSubmit={handleSubmitUpdate}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div>
                    <div>Title</div>
                    <Field type="text" name="title" className="Form_Field" />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="mt-2">
                    <div>Description</div>
                    <Field
                      type="textarea"
                      name="description"
                      className="Form_Field"
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={handleUpdateFaqModalClose}
                      disabled={isSubmitting}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting}
                    >
                      Update
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          )}
        </Modal.Body>
      </Modal>

      {/* Add Testimonial Modal */}

      <div className="row">
        <Modal
          show={showCreateFaqModal}
          onHide={handleCreateFaqModalClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Testimonial</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                title: "",
                description: "",
              }}
              validationSchema={FaqSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values, isSubmitting }) => (
                <Form>
                  <div>
                    <div>Title</div>
                    <Field type="text" name="title" className="Form_Field" />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="mt-2">
                    <div>Description</div>
                    <Field
                      type="textarea"
                      name="description"
                      className="Form_Field"
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={handleCreateFaqModalClose}
                      disabled={isSubmitting}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};
