import React from 'react'

export const RefundAndCancellation = () => {
  const listStyle = {
    listStyleType: "number",
    fontSize: "1.0rem",
    counterReset: "section",
  };
  return (
    <div class="container py-4">
      <div class="row justify-content-md-center mt-none-30">
        <div class="col-lg-12 col-md-12 mt-30">
          <h3 className="fw-bold">Refund and Cancellation Policy</h3>
          <p>
            Welcome to Exploretrip Online Booking's Refund and
            Cancellation Policy. This policy outlines the procedures and
            guidelines regarding cancellations, refunds, and related
            matters for bookings made through our platform. By using our
            services, you agree to abide by the terms and conditions
            outlined herein.
          </p>
        </div>
        <div class="col-lg-12 col-md-12 ">
          <div>
            <h4 className="fw-bold">Cancellation Policy</h4>
            <div className="list_items">
              <ol style={listStyle}>
                <li className="mb-2">
                  <strong>General Cancellation Guidelines:</strong>
                  <p className="m-0">
                    Cancellation policies may vary depending on the type
                    of booking, service provider, and specific terms and
                    conditions associated with each reservation.
                  </p>
                  <p className="m-0">
                    It is important to review the cancellation policy
                    associated with your booking before initiating any
                    cancellation requests.
                  </p>
                </li>
                <li className="mb-2">
                  <strong>Cancellation Process:</strong>
                  <p className="m-0">
                    To cancel a booking, you must contact Exploretrip
                    Online Booking through the designated channels
                    provided on our website or mobile application.
                  </p>
                  <p className="m-0">
                    Please provide relevant booking details, including
                    reservation ID, name, and dates, when submitting a
                    cancellation request.
                  </p>
                </li>
                <li className="mb-2">
                  <strong>Cancellation Fees:</strong>
                  <p className="m-0">
                    Cancellation fees may apply based on the timing of the
                    cancellation and the policies set forth by the service
                    provider.
                  </p>
                  <p className="m-0">
                    The amount of the cancellation fee, if applicable,
                    will be deducted from any eligible refund.
                  </p>
                </li>
                <li className="mb-2">
                  <strong>Non-Refundable Bookings:</strong>
                  <p className="m-0">
                    Some bookings may be non-refundable, meaning that no
                    refund will be issued for cancellations.
                  </p>
                  <p className="m-0">
                    Non-refundable bookings are clearly indicated at the
                    time of reservation, and users are advised to review
                    this information carefully.
                  </p>
                </li>
                <li className="mb-2">
                  <strong>Refundable Bookings:</strong>
                  <p className="m-0">
                    Refunds for cancellable bookings are subject to the
                    terms and conditions of the service provider and may
                    vary based on factors such as the timing of the
                    cancellation and any applicable fees.
                  </p>
                  <p className="m-0">
                    Exploretrip Online Booking will process eligible
                    refunds within a reasonable timeframe, typically in
                    accordance with the policies of the service provider.
                  </p>
                </li>
              </ol>
            </div>
          </div>
          <div>
            <h4 className="fw-bold">Refund Policy</h4>
            <div className="list_items">
              <ol style={listStyle}>
                <li className="mb-2">
                  <strong>Refund Eligibility:</strong>
                  <p className="m-0">
                    Refunds are typically issued for cancellable bookings
                    that meet the criteria outlined in the cancellation
                    policy.
                  </p>
                  <p className="m-0">
                    To be eligible for a refund, the cancellation must
                    comply with the terms and conditions specified by the
                    service provider and Exploretrip Online Booking.
                  </p>
                </li>
                <li className="mb-2">
                  <strong>Refund Processing Time:</strong>
                  <p className="m-0">
                    Refunds will be processed within a reasonable
                    timeframe following the cancellation of the booking.
                  </p>
                  <p className="m-0">
                    The exact processing time may vary depending on
                    factors such as the payment method used and the
                    policies of the service provider.
                  </p>
                </li>
                <li className="mb-2">
                  <strong>Refund Methods:</strong>
                  <p className="m-0">
                    Refunds will be issued using the same payment method
                    used for the original booking, unless otherwise
                    specified.
                  </p>
                  <p className="m-0">
                    Exploretrip Online Booking reserves the right to issue
                    refunds via alternative methods at its discretion.
                  </p>
                </li>
                <li className="mb-2">
                  <strong>Refund Status Updates:</strong>
                  <p className="m-0">
                    Users can track the status of their refund through
                    their Exploretrip Online Booking account or by
                    contacting our customer support team.
                  </p>
                  <p className="m-0">
                    We strive to provide timely updates regarding the
                    progress of refund requests and any associated
                    communications with the service provider.
                  </p>
                </li>
              </ol>
            </div>
          </div>

          <div>
            <h4 className="fw-bold">Exceptional Circumstances</h4>
            <div className="list_items">
              <ol style={listStyle}>
                <li className="mb-2">
                  <strong>Force Majeure Events:</strong>
                  <p className="m-0">
                    In the event of unforeseen circumstances beyond our
                    control, such as natural disasters, strikes, or
                    government actions, Exploretrip Online Booking
                    reserves the right to modify or waive cancellation and
                    refund policies as deemed necessary.
                  </p>
                  <p className="m-0">
                    We will make reasonable efforts to accommodate
                    affected customers and communicate any changes to the
                    refund and cancellation policies promptly.
                  </p>
                </li>
                <li className="mb-2">
                  <strong>Service Provider Disputes:</strong>
                  <p className="m-0">
                    Disputes regarding refunds or cancellations arising
                    from actions or decisions made by the service provider
                    should be addressed directly with the relevant entity.
                  </p>
                  <p className="m-0">
                    Exploretrip Online Booking acts solely as an
                    intermediary and is not liable for any discrepancies
                    or disputes between users and service providers.
                  </p>
                </li>
              </ol>
            </div>
          </div>

          <div>
            <h4 className="fw-bold">Policy Updates</h4>
            <div className="list_items">
              <ol style={listStyle}>
                <li className="mb-2">
                  <strong>Policy Revisions:</strong>
                  <p className="m-0">
                    Exploretrip Online Booking reserves the right to
                    revise, amend, or update this Refund and Cancellation
                    Policy at any time without prior notice.
                  </p>
                  <p className="m-0">
                    Users are encouraged to review the policy regularly to
                    stay informed of any changes or updates.
                  </p>
                </li>
                <li className="mb-2">
                  <strong>Effective Date:</strong>
                  <p className="m-0">
                    The effective date of this policy is indicated at the
                    top of the page. Any changes made after this date will
                    apply to all bookings made thereafter.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
