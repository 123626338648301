import React from "react";
import { PopularDestinationsItems } from "../../data/FooterItems";
import { Link } from "react-router-dom";

export const PopularDestinations = () => {
  return (
    <div>
      <p className="fw-bold">A guide to explore popular destinations.</p>
      <div className="row">
        {PopularDestinationsItems.map((ele) => {
          return (
            <div key={ele.id} className="col-md-3 col-6 pb-2 pointerUnderLine">
              <Link to="/getconsultation">
                <p className="m-0 text-white">{ele.title}</p>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};
