import { Base_Url } from "../Url/Url";

export const FaqAPIData = async () => {
    const res = await fetch(`${Base_Url}/faq/getallfaq`, {
        method: "Get",
        headers: {
            "Content-type": "application/json",
        },
    });
    return await res.json();
};
