import React, { useState } from "react";
import "../../../style/Recharge.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import images from "../../../assets/Images";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

export const RechargeForm = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [activeItem, setActiveItem] = useState("Prepaid");
    const [activeItemRechargeType, setActiveItemRechargeType] = useState("3G/4G");

    const handleFormType = (recharge_type) => {
        setActiveItem(recharge_type === activeItem ? "Prepaid" : recharge_type);
    };

    const handleRechargeType = (recharge_type) => {
        setActiveItemRechargeType(recharge_type === activeItemRechargeType ? "3G/4G" : recharge_type);
    };

    const renderRechargeType = () => {
        switch (activeItemRechargeType) {
            case "3G/4G":
                return (
                    <>
                        <div className="col-md-12">
                            <table className="border strip w-100 mt-3">
                                <tbody>
                                    <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                                        <td className="py-2">
                                            <p className="ps-3 m-0" style={{ fontWeight: "600" }}>
                                                Description
                                            </p>
                                        </td>
                                        <td className="py-2">
                                            <p
                                                className="ps-3 m-0"
                                                style={{ borderLeft: "1px solid lightgray", fontWeight: "600" }}
                                            >
                                                Validity
                                            </p>
                                        </td>
                                        <td>
                                            <p
                                                className="ps-3 m-0"
                                                style={{ borderLeft: "1px solid lightgray", fontWeight: "600" }}
                                            >
                                                Amount
                                            </p>
                                        </td>
                                    </tr>
                                    <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                                        <td className="py-2">
                                            <p className="ps-3 m-0">
                                                Get Unlimited Voice Calls + Unlimited Video Calls + Unlimited 1 GB/day
                                                Data.
                                            </p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                14 Days
                                            </p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                ₹ 265
                                            </p>
                                        </td>
                                    </tr>
                                    <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                                        <td className="py-2">
                                            <p className="ps-3 m-0">
                                                Get Unlimited Local/STD Calls to Any Network + 10 GB Data.+BSNL Tunes.
                                            </p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                90 Days
                                            </p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                ₹ 765
                                            </p>
                                        </td>
                                    </tr>
                                    <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                                        <td className="py-2">
                                            <p className="ps-3 m-0">
                                                Get Unlimited Voice Calls + Unlimited 1 GB/day Data.
                                            </p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                28 Days
                                            </p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                ₹ 365
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                );

            case "Rate cutter":
                return (
                    <>
                        <div className="col-md-12">
                            <table className="border strip w-100 mt-3">
                                <tbody>
                                    <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                                        <td className="py-2">
                                            <p className="ps-3 m-0" style={{ fontWeight: "600" }}>
                                                Description
                                            </p>
                                        </td>
                                        <td className="py-2">
                                            <p
                                                className="ps-3 m-0"
                                                style={{ borderLeft: "1px solid lightgray", fontWeight: "600" }}
                                            >
                                                Validity
                                            </p>
                                        </td>
                                        <td>
                                            <p
                                                className="ps-3 m-0"
                                                style={{ borderLeft: "1px solid lightgray", fontWeight: "600" }}
                                            >
                                                Amount
                                            </p>
                                        </td>
                                    </tr>
                                    <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                                        <td className="py-2">
                                            <p className="ps-3 m-0">Get Talktime of Rs. 7.47</p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                14 Days
                                            </p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                ₹ 26
                                            </p>
                                        </td>
                                    </tr>
                                    <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                                        <td className="py-2">
                                            <p className="ps-3 m-0">Get Talktime of Rs. 14.95</p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                7 Days
                                            </p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                ₹ 76
                                            </p>
                                        </td>
                                    </tr>
                                    <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                                        <td className="py-2">
                                            <p className="ps-3 m-0">Get Talktime of Rs. 98.69</p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                28 Days
                                            </p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                ₹ 99
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                );

            case "Roaming":
                return (
                    <>
                        <div className="col-md-12">
                            <table className="border strip w-100 mt-3">
                                <tbody>
                                    <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                                        <td className="py-2">
                                            <p className="ps-3 m-0" style={{ fontWeight: "600" }}>
                                                Description
                                            </p>
                                        </td>
                                        <td className="py-2">
                                            <p
                                                className="ps-3 m-0"
                                                style={{ borderLeft: "1px solid lightgray", fontWeight: "600" }}
                                            >
                                                Validity
                                            </p>
                                        </td>
                                        <td>
                                            <p
                                                className="ps-3 m-0"
                                                style={{ borderLeft: "1px solid lightgray", fontWeight: "600" }}
                                            >
                                                Amount
                                            </p>
                                        </td>
                                    </tr>
                                    <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                                        <td className="py-2">
                                            <p className="ps-3 m-0">
                                                Get Unlimited Voice Calls + Unlimited Video Calls + Unlimited 1 GB/day
                                                Data.
                                            </p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                14 Days
                                            </p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                ₹ 265
                                            </p>
                                        </td>
                                    </tr>
                                    <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                                        <td className="py-2">
                                            <p className="ps-3 m-0">
                                                Get Unlimited Local/STD Calls to Any Network + 10 GB Data.+BSNL Tunes.
                                            </p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                90 Days
                                            </p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                ₹ 765
                                            </p>
                                        </td>
                                    </tr>
                                    <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                                        <td className="py-2">
                                            <p className="ps-3 m-0">
                                                Get Unlimited Voice Calls + Unlimited 1 GB/day Data.
                                            </p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                28 Days
                                            </p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                ₹ 365
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                );

            case "Combo":
                return (
                    <>
                        <div className="col-md-12">
                            <table className="border strip w-100 mt-3">
                                <tbody>
                                    <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                                        <td className="py-2">
                                            <p className="ps-3 m-0" style={{ fontWeight: "600" }}>
                                                Description
                                            </p>
                                        </td>
                                        <td className="py-2">
                                            <p
                                                className="ps-3 m-0"
                                                style={{ borderLeft: "1px solid lightgray", fontWeight: "600" }}
                                            >
                                                Validity
                                            </p>
                                        </td>
                                        <td>
                                            <p
                                                className="ps-3 m-0"
                                                style={{ borderLeft: "1px solid lightgray", fontWeight: "600" }}
                                            >
                                                Amount
                                            </p>
                                        </td>
                                    </tr>
                                    <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                                        <td className="py-2">
                                            <p className="ps-3 m-0">
                                                Get Unlimited Calls (Local/STD/Roaming Calls incl data)
                                            </p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                1 Days
                                            </p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                ₹ 265
                                            </p>
                                        </td>
                                    </tr>
                                    <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                                        <td className="py-2">
                                            <p className="ps-3 m-0">
                                                Get Unlimited Local/STD Calls to Any Network + 10 GB Data.+BSNL Tunes.
                                            </p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                90 Days
                                            </p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                ₹ 765
                                            </p>
                                        </td>
                                    </tr>
                                    <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                                        <td className="py-2">
                                            <p className="ps-3 m-0">
                                                Get Unlimited Voice Calls + Unlimited 1 GB/day Data.
                                            </p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                28 Days
                                            </p>
                                        </td>
                                        <td>
                                            <p className="ps-3 m-0" style={{ borderLeft: "1px solid lightgray" }}>
                                                ₹ 365
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    const validationSchemaLogin = Yup.object().shape({
        mobileNumber: Yup.string()
            .required("Please enter your number")
            .matches(/^[0-9]{10}$/, "Number must be 10 digits"),
        termsAndConditions: Yup.boolean().oneOf([true], "Please accept the terms and conditions"),
    });

    const renderForm = () => {
        switch (activeItem) {
            case "Prepaid":
                return (
                    <>
                        <div className="col-md-12">
                            <Formik
                                initialValues={{
                                    mobileNumber: "",
                                }}
                                validationSchema={validationSchemaLogin}
                            >
                                {({ isSubmitting, values, handleChange }) => (
                                    <Form>
                                        <div className="row pt-4">
                                            <div
                                                className="col"
                                                style={{ borderRight: "2px solid #01a9ff", height: "75px" }}
                                            >
                                                <div>
                                                    <p className="m-0" style={{ fontSize: "14px", fontWeight: "500" }}>
                                                        Mobile Number
                                                    </p>
                                                    <div className="input_field  d-flex align-items-center">
                                                        <span
                                                            className="pe-2"
                                                            style={{ borderRight: "1px solid black" }}
                                                        >
                                                            +91
                                                        </span>
                                                        <Field
                                                            type="text"
                                                            name="mobileNumber"
                                                            placeholder="Enter your Prepaid Mobile Number"
                                                            maxLength={10}
                                                            className="ps-2 py-1 w-100 bg-white"
                                                        />
                                                    </div>
                                                    <ErrorMessage
                                                        name="mobileNumber"
                                                        component="div"
                                                        className="error text-danger m-0"
                                                        style={{ fontSize: "10px" }}
                                                    />
                                                </div>
                                            </div>

                                            <div
                                                className="col"
                                                style={{ borderRight: "2px solid #01a9ff", height: "75px" }}
                                            >
                                                <p className="m-0" style={{ fontSize: "14px", fontWeight: "500" }}>
                                                    Select your operator
                                                </p>
                                                <select
                                                    id="meal"
                                                    name="meal"
                                                    defaultValue="Add meal"
                                                    className="p-2 input_field"
                                                    style={{
                                                        width: "100%",
                                                        fontSize: "14px",
                                                        border: "1px solid lightgray",
                                                    }}
                                                >
                                                    <option value="Add meal" disabled hidden>
                                                        VI
                                                    </option>
                                                    <option value="vegMeal1">AIRTEL</option>
                                                    <option value="vegMeal2">JIO</option>
                                                    <option value="vegMeal3">BSNL</option>
                                                    <option value="vegMeal3">MTNL</option>
                                                </select>
                                            </div>

                                            <div className="col">
                                                <p className="m-0" style={{ fontSize: "14px", fontWeight: "500" }}>
                                                    Choose your circle
                                                </p>
                                                <select
                                                    id="meal"
                                                    name="meal"
                                                    defaultValue="Add meal"
                                                    className="p-2 input_field"
                                                    style={{
                                                        width: "100%",
                                                        fontSize: "14px",
                                                        border: "1px solid lightgray",
                                                    }}
                                                >
                                                    <option value="Add meal" disabled hidden>
                                                        Delhi
                                                    </option>
                                                    <option value="vegMeal1">Uttar pradesh</option>
                                                    <option value="vegMeal2">Haryana</option>
                                                    <option value="vegMeal3">Punjab</option>
                                                    <option value="vegMeal3">Chandigarh</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row pt-2">
                                            <div
                                                className="col-md-6"
                                                style={{ borderRight: "2px solid #01a9ff", height: "75px" }}
                                            >
                                                <div>
                                                    <p className="m-0" style={{ fontSize: "14px", fontWeight: "500" }}>
                                                        Enter Amount
                                                    </p>
                                                    <div className="input_field  d-flex align-items-center">
                                                        <span
                                                            className="pe-2"
                                                            style={{ borderRight: "1px solid black" }}
                                                        >
                                                            ₹
                                                        </span>
                                                        <Field
                                                            type="text"
                                                            name="mobileNumber"
                                                            placeholder="Enter Amount"
                                                            maxLength={10}
                                                            className="ps-2 py-1 w-100 bg-white"
                                                        />
                                                    </div>
                                                    <ErrorMessage
                                                        name="mobileNumber"
                                                        component="div"
                                                        className="error text-danger m-0"
                                                        style={{ fontSize: "10px" }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6 pb-3 px-3">
                                                <p className="m-0" style={{ fontSize: "14px", fontWeight: "500" }}>
                                                    View Plans
                                                </p>
                                                <button
                                                    className="py-2"
                                                    style={{
                                                        border: " 1.5px solid #01a9ff",
                                                        borderRadius: "10px",
                                                        width: "200px",
                                                    }}
                                                    onClick={handleShow}
                                                >
                                                    Plans
                                                </button>
                                            </div>
                                        </div>

                                        <div>
                                            <Modal show={show} onHide={handleClose} size="lg">
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Plan Details</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="col-md-12">
                                                        <div className="reachargetype_div">
                                                            <span
                                                                className={
                                                                    "reachargeplantype_div" +
                                                                    (activeItemRechargeType === "3G/4G"
                                                                        ? " reachargeplan_active"
                                                                        : "reachargeplan_inactive")
                                                                }
                                                                onClick={() => handleRechargeType("3G/4G")}
                                                            >
                                                                3G/4G
                                                            </span>
                                                            <span
                                                                className={
                                                                    "reachargeplantype_div" +
                                                                    (activeItemRechargeType === "Rate cutter"
                                                                        ? " reachargeplan_active"
                                                                        : "reachargeplan_inactive")
                                                                }
                                                                onClick={() => handleRechargeType("Rate cutter")}
                                                            >
                                                                Rate cutter
                                                            </span>
                                                            <span
                                                                className={
                                                                    "reachargeplantype_div" +
                                                                    (activeItemRechargeType === "Roaming"
                                                                        ? " reachargeplan_active"
                                                                        : "reachargeplan_inactive")
                                                                }
                                                                onClick={() => handleRechargeType("Roaming")}
                                                            >
                                                                Roaming
                                                            </span>
                                                            <span
                                                                className={
                                                                    "reachargeplantype_div" +
                                                                    (activeItemRechargeType === "Combo"
                                                                        ? " reachargeplan_active"
                                                                        : "reachargeplan_inactive")
                                                                }
                                                                onClick={() => handleRechargeType("Combo")}
                                                            >
                                                                Combo
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div>{renderRechargeType()}</div>
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </>
                );

            case "Postpaid":
                return (
                    <>
                        <div className="col-md-12">
                            <Formik
                                initialValues={{
                                    mobileNumber: "",
                                }}
                                validationSchema={validationSchemaLogin}
                            >
                                {({ isSubmitting, values, handleChange }) => (
                                    <Form>
                                        <div className="row pt-4">
                                            <div className="col" style={{ borderRight: "2px solid #01a9ff" }}>
                                                <div>
                                                    <p className="m-0" style={{ fontSize: "14px", fontWeight: "500" }}>
                                                        Mobile Number
                                                    </p>
                                                    <div className="input_field  d-flex align-items-center">
                                                        <span
                                                            className="pe-2"
                                                            style={{ borderRight: "1px solid black" }}
                                                        >
                                                            +91
                                                        </span>
                                                        <Field
                                                            type="text"
                                                            name="mobileNumber"
                                                            placeholder="Enter your Postpaid Mobile Number"
                                                            maxLength={10}
                                                            className="ps-2 py-1 w-100 bg-white"
                                                        />
                                                    </div>
                                                    <ErrorMessage
                                                        name="mobileNumber"
                                                        component="div"
                                                        className="error text-danger m-0"
                                                        style={{ fontSize: "14px" }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col" style={{ borderRight: "2px solid #01a9ff" }}>
                                                <p className="m-0" style={{ fontSize: "14px", fontWeight: "500" }}>
                                                    Select your operator
                                                </p>
                                                <select
                                                    id="meal"
                                                    name="meal"
                                                    defaultValue="Add meal"
                                                    className="p-2 input_field"
                                                    style={{
                                                        width: "100%",
                                                        fontSize: "14px",
                                                        border: "1px solid lightgray",
                                                    }}
                                                >
                                                    <option value="Add meal" disabled hidden>
                                                        VI
                                                    </option>
                                                    <option value="vegMeal1">AIRTEL</option>
                                                    <option value="vegMeal2">JIO</option>
                                                    <option value="vegMeal3">BSNL</option>
                                                    <option value="vegMeal3">MTNL</option>
                                                </select>
                                            </div>

                                            <div className="col">
                                                <p className="m-0" style={{ fontSize: "14px", fontWeight: "500" }}>
                                                    Choose your circle
                                                </p>
                                                <select
                                                    id="meal"
                                                    name="meal"
                                                    defaultValue="Add meal"
                                                    className="p-2 input_field"
                                                    style={{
                                                        width: "100%",
                                                        fontSize: "14px",
                                                        border: "1px solid lightgray",
                                                    }}
                                                >
                                                    <option value="Add meal" disabled hidden>
                                                        Delhi
                                                    </option>
                                                    <option value="vegMeal1">Uttar pradesh</option>
                                                    <option value="vegMeal2">Haryana</option>
                                                    <option value="vegMeal3">Punjab</option>
                                                    <option value="vegMeal3">Chandigarh</option>
                                                </select>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </>
                );

            case "Electricity":
                return (
                    <>
                        <div className="col-md-12">
                            <Formik
                                initialValues={{
                                    mobileNumber: "",
                                }}
                                validationSchema={validationSchemaLogin}
                            >
                                {({ isSubmitting, values, handleChange }) => (
                                    <Form>
                                        <div className="row pt-4">
                                            <div className="col" style={{ borderRight: "2px solid #01a9ff" }}>
                                                <div>
                                                    <p className="m-0" style={{ fontSize: "14px", fontWeight: "500" }}>
                                                        CA Number
                                                    </p>
                                                    <div className="input_field  d-flex align-items-center">
                                                        <Field
                                                            type="text"
                                                            name="mobileNumber"
                                                            placeholder="Enter your CA Number"
                                                            maxLength={10}
                                                            className="ps-2 py-1 w-100 bg-white"
                                                        />
                                                    </div>
                                                    <ErrorMessage
                                                        name="mobileNumber"
                                                        component="div"
                                                        className="error text-danger m-0"
                                                        style={{ fontSize: "14px" }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col" style={{ borderRight: "2px solid #01a9ff" }}>
                                                <p className="m-0" style={{ fontSize: "14px", fontWeight: "500" }}>
                                                    Select your Electricity provider
                                                </p>
                                                <select
                                                    id="meal"
                                                    name="meal"
                                                    defaultValue="Add meal"
                                                    className="p-2 input_field"
                                                    style={{
                                                        width: "100%",
                                                        fontSize: "14px",
                                                        border: "1px solid lightgray",
                                                    }}
                                                >
                                                    <option value="Add meal" disabled hidden>
                                                        BSES - Rajdhani
                                                    </option>
                                                    <option value="Add meal" disabled hidden>
                                                        BSES - Yamuna
                                                    </option>
                                                    <option value="vegMeal2">Tata-Power</option>
                                                    <option value="vegMeal1">Reliance</option>
                                                </select>
                                            </div>

                                            <div className="col">
                                                <p className="m-0" style={{ fontSize: "14px", fontWeight: "500" }}>
                                                    Choose your state
                                                </p>
                                                <select
                                                    id="meal"
                                                    name="meal"
                                                    defaultValue="Add meal"
                                                    className="p-2 input_field"
                                                    style={{
                                                        width: "100%",
                                                        fontSize: "14px",
                                                        border: "1px solid lightgray",
                                                    }}
                                                >
                                                    <option value="Add meal" disabled hidden>
                                                        Delhi
                                                    </option>
                                                    <option value="vegMeal1">Uttar pradesh</option>
                                                    <option value="vegMeal2">Haryana</option>
                                                    <option value="vegMeal3">Punjab</option>
                                                    <option value="vegMeal3">Chandigarh</option>
                                                </select>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </>
                );

            case "DTH":
                return (
                    <>
                        <div className="col-md-12">
                            <Formik
                                initialValues={{
                                    mobileNumber: "",
                                }}
                                validationSchema={validationSchemaLogin}
                            >
                                {({ isSubmitting, values, handleChange }) => (
                                    <Form>
                                        <div className="row pt-4">
                                            <div className="col" style={{ borderRight: "2px solid #01a9ff" }}>
                                                <div>
                                                    <p className="m-0" style={{ fontSize: "14px", fontWeight: "500" }}>
                                                        Card Number
                                                    </p>
                                                    <div className="input_field  d-flex align-items-center">
                                                        <Field
                                                            type="text"
                                                            name="mobileNumber"
                                                            placeholder="Enter your Card Number"
                                                            maxLength={10}
                                                            className="ps-2 py-1 w-100 bg-white"
                                                        />
                                                    </div>
                                                    <ErrorMessage
                                                        name="mobileNumber"
                                                        component="div"
                                                        className="error text-danger m-0"
                                                        style={{ fontSize: "14px" }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col" style={{ borderRight: "2px solid #01a9ff" }}>
                                                <p className="m-0" style={{ fontSize: "14px", fontWeight: "500" }}>
                                                    Select your operator
                                                </p>
                                                <select
                                                    id="meal"
                                                    name="meal"
                                                    defaultValue="Add meal"
                                                    className="p-2 input_field"
                                                    style={{
                                                        width: "100%",
                                                        fontSize: "14px",
                                                        border: "1px solid lightgray",
                                                    }}
                                                >
                                                    <option value="Add meal" disabled hidden>
                                                        Videocon
                                                    </option>
                                                    <option value="vegMeal1">AIRTEL</option>
                                                    <option value="vegMeal2">D2H</option>
                                                    <option value="vegMeal3">TATA SKY</option>
                                                </select>
                                            </div>

                                            <div className="col">
                                                <p className="m-0" style={{ fontSize: "14px", fontWeight: "500" }}>
                                                    Choose your circle
                                                </p>
                                                <select
                                                    id="meal"
                                                    name="meal"
                                                    defaultValue="Add meal"
                                                    className="p-2 input_field"
                                                    style={{
                                                        width: "100%",
                                                        fontSize: "14px",
                                                        border: "1px solid lightgray",
                                                    }}
                                                >
                                                    <option value="Add meal" disabled hidden>
                                                        Delhi
                                                    </option>
                                                    <option value="vegMeal1">Uttar pradesh</option>
                                                    <option value="vegMeal2">Haryana</option>
                                                    <option value="vegMeal3">Punjab</option>
                                                    <option value="vegMeal3">Chandigarh</option>
                                                </select>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </>
                );

            default:
                return null;
        }
    };

    return (
        <>
            <div className="container desktop-view">
                <div
                    className="row p-3 d-flex align-items-center"
                    style={{
                        borderRadius: "7px",
                        border: "2px solid #01a9ff",
                        backgroundColor: "white",
                        height: "auto",
                    }}
                >
                    <div className="col-md-12">
                        <div className="reachargetype_div">
                            <span
                                className={
                                    "reachargeplantype_div" +
                                    (activeItem === "Prepaid" ? " reachargeplan_active" : "reachargeplan_inactive")
                                }
                                onClick={() => handleFormType("Prepaid")}
                            >
                                Prepaid
                            </span>
                            <span
                                className={
                                    "reachargeplantype_div" +
                                    (activeItem === "Postpaid" ? " reachargeplan_active" : "reachargeplan_inactive")
                                }
                                onClick={() => handleFormType("Postpaid")}
                            >
                                Postpaid
                            </span>
                            <span
                                className={
                                    "reachargeplantype_div" +
                                    (activeItem === "DTH" ? " reachargeplan_active" : "reachargeplan_inactive")
                                }
                                onClick={() => handleFormType("DTH")}
                            >
                                DTH
                            </span>
                            <span
                                className={
                                    "reachargeplantype_div" +
                                    (activeItem === "Electricity" ? " reachargeplan_active" : "reachargeplan_inactive")
                                }
                                onClick={() => handleFormType("Electricity")}
                            >
                                Electricity
                            </span>
                        </div>
                        <div>{renderForm()}</div>
                    </div>
                </div>

                <div className="d-flex pt-3 justify-content-center">
                    <div className="col-md-3 pb-3 px-3 d-flex justify-content-center">
                        <button className="searchButton py-2">Explore Recharges</button>
                    </div>
                </div>
            </div>

            <div className="container mobile-view">
                <div
                    className="row p-3 d-flex align-items-center"
                    style={{
                        borderRadius: "7px",
                        backgroundColor: "white",
                        height: "auto",
                    }}
                >
                    <div className="col-md-12">
                        <div className="reachargetype_div">
                            <span
                                className={
                                    "reachargeplantype_div" +
                                    (activeItem === "Prepaid" ? " reachargeplan_active" : "reachargeplan_inactive")
                                }
                                onClick={() => handleFormType("Prepaid")}
                            >
                                Prepaid
                            </span>
                            <span
                                className={
                                    "reachargeplantype_div" +
                                    (activeItem === "Postpaid" ? " reachargeplan_active" : "reachargeplan_inactive")
                                }
                                onClick={() => handleFormType("Postpaid")}
                            >
                                Postpaid
                            </span>
                            <span
                                className={
                                    "reachargeplantype_div" +
                                    (activeItem === "DTH" ? " reachargeplan_active" : "reachargeplan_inactive")
                                }
                                onClick={() => handleFormType("DTH")}
                            >
                                DTH
                            </span>
                            {/* <span
                                className={
                                    "reachargeplantype_div" +
                                    (activeItem === "Electricity" ? " reachargeplan_active" : "reachargeplan_inactive")
                                }
                                onClick={() => handleFormType("Electricity")}
                            >
                                Electricity
                            </span> */}
                        </div>
                        <div>{renderForm()}</div>
                    </div>
                </div>

                <div className="d-flex pt-3 justify-content-center">
                    <div className="col-md-3 pb-3 px-3 d-flex justify-content-center" style={{ width: "280px" }}>
                        <button className="searchButton py-2">Explore Recharges</button>
                    </div>
                </div>
            </div>
        </>
    );
};
