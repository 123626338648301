import React, { useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { DailyTravelBlogsAPIData } from "../../api/DailyTravelBlogsAPI";
import { Loader } from "../Loader";
import { Base_Url } from "../../Url/Url";

export const DailyTravelBlogs = () => {
  const [blogsData, setBlogsData] = useState();

  async function BlogsAPICall() {
    try {
      const res = await DailyTravelBlogsAPIData();

      if (res?.status === "success") {
        setBlogsData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    BlogsAPICall();
  }, []);

  const navigate = useNavigate();

  const handleSingleBlogsData = (data) => {
    navigate(`/blogs/${data?._id}`);
  };

  return (
    <>
      <div className="desktop-view">
        <div className="container-fluid Section_Container ">
          <div className="row px-2 d-flex justify-content-between">
            <div className="col-md-6">
              <div className="homepage_component_heading_txt">
                Daily Travel Blogs
              </div>
              <div className="Section_text_small_underline"></div>
            </div>
            <div className="col-md-2  Section_view_all_link d-flex justify-content-end">
              <a
                href="/blogs"
                className="text-black"
                style={{ textDecoration: "none" }}
              >
                <span className="">View all</span>
                <span>
                  <FaAngleRight />
                </span>
              </a>
            </div>
          </div>

          <div>
            {blogsData ? (
              <div className="row pt-3 px-2">
                {blogsData?.slice(0, 4)?.map((ele, index) => {
                  return (
                    <div key={ele?.id || index} className="col-md-3 col-6">
                      <div onClick={() => handleSingleBlogsData(ele)}>
                        <div className="Section_Cards1">
                          <img
                            src={`${Base_Url}${ele?.Image}`}
                            alt="img"
                            height="150px"
                            className="Section_image"
                          ></img>
                          <div
                            className="Section_text_container "
                            style={{
                              backgroundColor: "rgba(133, 60, 191, 0.11)",
                            }}
                          >
                            <span style={{ fontWeight: "600" }}>
                              {ele?.Title?.length > 50
                                ? ele?.Title.slice(0, 40) + "..."
                                : ele?.Title}
                            </span>

                            <div className="row d-flex align-items-center justify-content-between">
                              <div className="col-md-10">
                                {ele?.Description?.length > 20 ? (
                                  <>
                                    {ele?.Description?.slice(0, 55)}...
                                    <a
                                      href={`/blogs/${ele._id}`}
                                      style={{
                                        color: "#01a9ff",
                                        fontSize: "14px",
                                      }}
                                    >
                                      read more
                                    </a>
                                  </>
                                ) : (
                                  ele?.Description
                                )}
                              </div>

                              <div className="col-md-2 d-flex align-items-center justify-content-center ">
                                <span className="arrow_div">
                                  <FaAngleRight className="fs-6" />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>

      <div className="mobile-view mt-1">
        <div className="container-fluid">
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col-6">
              <div className="homepage_cards_headings">Daily Travel Blogs</div>
            </div>
            <div className="col-5 text-end">
              <a className="homepage_cards_exploremore" href="/blogs">
                Explore More
              </a>
            </div>
          </div>

          <div className="row pt-1">
            {blogsData?.slice(0, 4)?.map((ele, index) => {
              return (
                <div key={ele?._id || index} className="col-6 p-1">
                  <div onClick={() => handleSingleBlogsData(ele)}>
                    <div className="Section_Cards1_mobile">
                      <img
                        src={`${Base_Url}${ele?.Image}`}
                        alt="img"
                        height="110px"
                        className="Section_image"
                      ></img>
                      <div
                        className="Section_text_container "
                        style={{
                          backgroundColor: "rgba(133, 60, 191, 0.11)",
                        }}
                      >
                        <span style={{ fontWeight: "600", fontSize: "14px" }}>
                          {ele?.Title?.length > 50
                            ? ele?.Title.slice(0, 40) + "..."
                            : ele?.Title}
                        </span>

                        <div className="row d-flex align-items-center justify-content-between">
                          <div
                            className="col-md-10"
                            style={{ fontWeight: "500", fontSize: "13px" }}
                          >
                            {ele?.Description?.length > 20 ? (
                              <>
                                {ele?.Description?.slice(0, 40)}...
                                <a
                                  href={`/blogs/${ele._id}`}
                                  style={{
                                    color: "#01a9ff",
                                    fontSize: "14px",
                                  }}
                                >
                                  read more
                                </a>
                              </>
                            ) : (
                              ele?.Description
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
