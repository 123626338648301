import React from 'react'
import { ConnectWithUsSocialMedia } from '../../data/FooterItems';

export const ConnectWithUs = () => {
  return (
    <div>
      <p className="fw-bold">Quick solutions for all your travel needs.</p>
      <div className="row ">
        {ConnectWithUsSocialMedia.map((ele) => {
          return (
            <div key={ele.id} className="col-md-3 col-6 pb-2 pointerUnderLine" >
              {React.createElement(ele.icon, { style: { backgroundColor: ele.backgroundColor }, className: 'fs-6' })}
              <a href={ele.link} className='text-white px-2' target='#' style={{ textDecoration: "none" }}>{ele.title}</a>
            </div>
          );
        })}
      </div>
    </div>
  )
}
