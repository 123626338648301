import { Base_Url } from "../Url/Url";

export const getFlightsFrom = async (data) => {
  try {
    const res = await fetch(
      `${Base_Url}/flightscity/fetch_flight_city?city=${data.city}`,
      {
        method: "Get",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const getAirlineLogo = async (data) => {
  try {
    const res = await fetch(
      `https://exploretriponline.com/custom_api/airline_logo.php?airline=${data}`,
      {
        method: "Get",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const flightToGetData = async (data) => {
  try {
    const res = await fetch(
      `${Base_Url}/flightsoneway/onewayflightsearch?flightType=1&origin=${data?.originSend}&destination=${data?.departureSend}&departuretime=${data?.dateSend}&adult=1&child=0&Infants=0&class=1&fare=1`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Check if the response is okay
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    return await res.json();
  } catch (error) {
    console.error("Error:", error);
    return { status: "failed", message: error.message };
  }
};

export const getFlightsFare = async (data) => {
  try {
    const res = await fetch(
      `${Base_Url}/flightsoneway/getfare?SrdvType=${data?.SrdvType}&SrdvIndex=${data?.SrdvIndex}&TraceId=${data?.TraceId}&ResultIndex=${data?.ResultIndex}`,
      {
        method: "POST", // Ensure this matches the server's expected method
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const SelectSeats = async (data) => {
  try {
    const res = await fetch(
      `${Base_Url}/flightsoneway/selectseats?SrdvType=${data?.SrdvType}&SrdvIndex=${data?.SrdvIndex}&TraceId=${data?.TraceId}&ResultIndex=${data?.ResultIndex}`,
      {
        method: "POST", // Ensure this matches the server's expected method
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const ssrAPI = async (data) => {
  try {
    const res = await fetch(
      `${Base_Url}/flightsoneway/ssr?TraceId=${data?.TraceId}&ResultIndex=${data?.TraceId}`,
      {
        method: "POST", // Ensure this matches the server's expected method
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const BookFlightsSeatLCC = async (data) => {
  try {
    const res = await fetch(`${Base_Url}/flightsoneway/booklcc`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-token": "GeniusTS@2@22", // Replace with your actual API token
      },
      body: JSON.stringify(data),
    });
    console.log(res);
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const roundTripflightsApi = async (data) => {
  try {
    const res = await fetch(
      `${Base_Url}/flightsreturn/returnflightsearch?origin=${data?.originSend}&destination=${data?.departureSend}&departuretime=${data?.departureDate}&returntime=${data?.arrivalDate}&adult=1&child=0&infants=0&class=1&fare=1`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
      }
    );

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const responseData = await res.json();
    console.log("API Response:", responseData); // Add this line to log the response
    return responseData;
  } catch (error) {
    console.log("Failed to fetch round trip flights:", error);
    throw error; // Re-throw the error if you want to handle it upstream
  }
};
