import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaStar } from "react-icons/fa6";
import { HolidayPackageAPIData } from "../api/HolidayPackageAPI";
import { Loader } from "../component/Loader";

export const AllHolidaysPackages = () => {
  const [packageData, setPackageData] = useState();
  const [imgBaseUrl, setImgBaseUrl] = useState("");
  const navigate = useNavigate();

  const handlePackageData = async () => {
    try {
      const res = await HolidayPackageAPIData();
      if (res?.data) {
        setPackageData(res?.data);
        setImgBaseUrl(res?.URL);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderStarRating = (rating) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < rating) {
        stars.push(<FaStar key={i} style={{ color: "goldenrod" }} />);
      }
    }
    return stars;
  };

  useEffect(() => {
    handlePackageData();
  }, []);

  const handleResetAll = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="container-fluid mobile-view">
        <div
          className="row d-flex justify-content-between"
          style={{ position: "relative" }}
        >
          <h3 className="text-center py-2 m-0">All Packages Details</h3>
          <div
            className="col-md-3 Section_Sidebar_Container desktop-view"
            style={{
              border: "1.5px solid rgb(1,128,253)",
              overflow: "hidden",
              position: "sticky",
              top: "80px",
              maxHeight: "calc(100vh - 60px)",
            }}
          >
            <div
              className="row text-white d-flex justify-content-between py-3"
              style={{
                background: " rgb(25, 36, 186)",
                borderBottom: "1.5px solid rgb(1,128,253)",
              }}
            >
              <div className="col-12">
                <h5 className="m-0">Packages Categories</h5>
              </div>
            </div>

            <div
              className="row d-flex justify-content-between  py-3"
              style={{ background: "#fff" }}
            >
              <div className="col-md-12 p-0">
                <ul className="blogsItems">
                  <li>All</li>
                  <li>Adventure Tours</li>
                  <li>Cultural Tours</li>
                  <li>Beach and Resort Packages</li>
                  <li>City Breaks</li>
                  <li>Wildlife and Nature Tours</li>
                  <li>Luxury Escapes</li>
                  <li>Family-Friendly Tours</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-12 p-0 px-1 mb-5">
            {packageData ? (
              <div className="row m-0 p-0">
                {packageData?.map((ele) => {
                  return (
                    <div className="col-6 p-0 p-1">
                      <a
                        href={`/holidayspackage/${ele?._id}`}
                        className="text-black"
                        style={{ textDecoration: "none" }}
                      >
                        <div className="Section_Cards1">
                          <img
                            src={`https://api.exploretriponline.com${ele?.Image}`}
                            alt=""
                            height="150px"
                            className="Section_image"
                          ></img>
                          <div
                            className="Section_text_container"
                            style={{
                              backgroundColor: "#CED7F8",
                              minHeight: "140px",
                            }}
                          >
                            <div style={{ fontWeight: "600" }}>
                              {ele?.title}
                            </div>
                            <div>{ele?.destination}</div>
                            {new Date(
                              ele?.availableDates[0]
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "long",
                              year: "numeric",
                            })}

                            <div>Starting at ₹{ele?.price}/Person</div>

                            <div className="pt-3 d-flex justify-content-center align-items-end">
                              <div className="Section_text_small_underline_large"></div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>

      <div className="container-fluid desktop-view">
        <div
          className="row d-flex justify-content-between px-4"
          style={{ position: "relative" }}
        >
          <h3 className="text-center py-4">All Packages Details</h3>
          {/* <div
            className="col-md-3 Section_Sidebar_Container desktop-view"
            style={{
              border: "1.5px solid rgb(1,128,253)",
              overflow: "hidden",
              position: "sticky",
              top: "80px",
              maxHeight: "calc(100vh - 60px)",
            }}
          >
            <div
              className="row text-white d-flex justify-content-between py-3"
              style={{
                background: " rgb(25, 36, 186)",
                borderBottom: "1.5px solid rgb(1,128,253)",
              }}
            >
              <div className="col-12">
                <h5 className="m-0">Packages Categories</h5>
              </div>
            </div>

            <div
              className="row d-flex justify-content-between  py-3"
              style={{ background: "#fff" }}
            >
              <div className="col-md-12 p-0">
                <ul className="blogsItems">
                  <li>All</li>
                  <li>Adventure Tours</li>
                  <li>Cultural Tours</li>
                  <li>Beach and Resort Packages</li>
                  <li>City Breaks</li>
                  <li>Wildlife and Nature Tours</li>
                  <li>Luxury Escapes</li>
                  <li>Family-Friendly Tours</li>
                </ul>
              </div>
            </div>
          </div> */}

          <div className="col-md-12 pe-0">
            {packageData ? (
              <div className="row m-0 p-0 py-3">
                {packageData?.map((ele) => {
                  return (
                    <div className="col-md-3  m-0">
                      <a
                        href={`/holidayspackage/${ele?._id}`}
                        className="text-black"
                        style={{ textDecoration: "none" }}
                      >
                        <div className="Section_Cards1">
                          <img
                            src={`https://api.exploretriponline.com${ele?.Image}`}
                            alt=""
                            height="150px"
                            className="Section_image"
                          ></img>
                          <div
                            className="Section_text_container"
                            style={{
                              backgroundColor: "#CED7F8",
                              minHeight: "140px",
                            }}
                          >
                            <div style={{ fontWeight: "600" }}>
                              {ele?.title}
                            </div>
                            <div>{ele?.destination}</div>
                            {new Date(
                              ele?.availableDates[0]
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "long",
                              year: "numeric",
                            })}

                            <div>
                              {`₹${parseInt(ele?.price).toLocaleString()}`}
                              /person
                            </div>

                            <div className="pt-3 d-flex justify-content-center align-items-end">
                              <div className="Section_text_small_underline_large"></div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
