import "../../style/FlightsCheckout.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import { MdFlight } from "react-icons/md";
import Cookies from "js-cookie";
import { MyContexts } from "../../context/MyContext";
import {
  BookFlightsSeatLCC,
  getAirlineLogo,
  getFlightsFare,
  SelectSeats,
  ssrAPI,
} from "../../api/FlightsAPI";
import { useParams } from "react-router-dom";
import images from "../../assets/Images";
import { getUserProfile, sendPaymentDetails } from "../../api/ProfileAPI";

export const FlightsCheckout = () => {
  const { selectedFlightData } = useContext(MyContexts);

  const { ResultIndex, SrdvIndex, SrdvType, TraceId } = useParams();

  const [fareDetails, setFareDetails] = useState([]);
  const [seatsSelectData, setSeatsSelectData] = useState([]);
  const [flightsSeatsError, setFlightsSeatsError] = useState("");
  const [mealData, setMealData] = useState([]);
  const [noMealMessage, setNoMealMessage] = useState("");

  const flightFare = async () => {
    try {
      const dataForFlightsFare = {
        ResultIndex,
        SrdvIndex,
        SrdvType,
        TraceId,
      };

      const SpecialServiceRequest = await ssrAPI(dataForFlightsFare);

      const data = await getFlightsFare(dataForFlightsFare);

      if (data?.data?.Error?.ErrorCode === "1000") {
        setFlightsSeatsError(data?.data?.Error?.ErrorMessage);
        return; // Exit the function if the flight is no longer available
      }

      if (data?.data?.Results) {
        setFareDetails(data?.data?.Results);

        const res = await SelectSeats(dataForFlightsFare);
        console.log(res);

        if (
          res?.data?.Error?.ErrorCode === "1000" ||
          res?.data?.Error?.ErrorCode === "1"
        ) {
          setFlightsSeatsError(res?.data?.Error?.ErrorMessage);
        } else {
          setSeatsSelectData(res?.data?.Results?.[0]);

          const SpecialServiceRequest = await ssrAPI(dataForFlightsFare);
          console.log(SpecialServiceRequest);

          if (
            SpecialServiceRequest?.data?.MealDynamic &&
            SpecialServiceRequest?.data?.MealDynamic.length > 0
          ) {
            // Show meal data

            if (Array.isArray(SpecialServiceRequest?.data?.MealDynamic)) {
              setMealData(SpecialServiceRequest.data.MealDynamic);
            }
          } else {
            // Show "no meal available" message
            setMealData([]);
            setNoMealMessage("No meal available in this flight.");
          }
        }
      }
    } catch (error) {
      console.log(error);
      setFlightsSeatsError(
        "An error occurred while fetching flight fare. Please try again."
      );
    }
  };

  useEffect(() => {
    flightFare();
  }, []);

  var FlightsSettings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const convertDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const hours = dateTime.getHours().toString().padStart(2, "0");
    const minutes = dateTime.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  // Function to calculate time difference
  const calculateTimeDifference = (start, end) => {
    const startTime = new Date(start);
    const endTime = new Date(end);
    const diffInMs = endTime - startTime; // Difference in milliseconds
    const diffInMinutes = Math.floor(diffInMs / 60000); // Difference in minutes
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;
    return `${hours}h ${minutes}m`;
  };

  const departureTime = fareDetails?.Segments?.[0]?.[0]?.DepTime;
  const arrivalTime = fareDetails?.Segments?.[0]?.[0]?.ArrTime;
  const duration = calculateTimeDifference(departureTime, arrivalTime);

  const formatDate = (dateString) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} `;
  };

  const [logo, setLogo] = useState();

  async function handleFlightLogo() {
    try {
      const airlineCode = fareDetails?.Segments?.[0]?.[0]?.Airline?.AirlineCode;
      if (airlineCode) {
        const res = await getAirlineLogo(airlineCode);
        setLogo(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleFlightLogo();
  }, []);

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    calculateTotalPrice();
  }, []);

  const calculateTotalPrice = () => {
    if (!selectedFlightData || !selectedFlightData.FareDataMultiple) return 0;

    const basePrice = parseInt(
      selectedFlightData.FareDataMultiple?.slice(0, 1)?.map(
        (ele1) => ele1?.Fare?.BaseFare
      ) || 0
    );

    const offeredFare = parseInt(
      selectedFlightData.FareDataMultiple?.slice(0, 1)?.map(
        (ele1) => ele1?.Fare?.OfferedFare
      ) || 0
    );

    const tax = parseInt(
      selectedFlightData.FareDataMultiple?.slice(0, 1)?.map(
        (ele1) => ele1?.Fare?.Tax
      ) || 0
    );

    const total = basePrice + offeredFare + tax;
    setTotalPrice(total);
  };

  const calculateDuration = (depTime, arrTime) => {
    const startTime = new Date(depTime);
    const endTime = new Date(arrTime);
    const diffInMs = endTime - startTime;
    const diffInMinutes = Math.floor(diffInMs / 60000);
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;
    return `${hours}h ${minutes}m`;
  };

  const calculateLayoverTime = (arrivalTime, departureTime) => {
    const arrTime = new Date(arrivalTime);
    const depTime = new Date(departureTime);
    const diffMs = depTime - arrTime;
    const diffHrs = Math.floor(diffMs / 1000 / 60 / 60);
    const diffMins = Math.floor((diffMs / 1000 / 60) % 60);
    return `${diffHrs}h ${diffMins}m`;
  };

  const [selectedMeals, setSelectedMeals] = useState([]);

  const handleSelectMeals = (meal) => {
    const isSelected = selectedMeals.find(
      (selectedMeal) => selectedMeal.Code === meal.Code
    );

    if (isSelected) {
      setSelectedMeals(
        selectedMeals.filter((selectedMeal) => selectedMeal.Code !== meal.Code)
      );
    } else {
      setSelectedMeals([...selectedMeals, meal]);
    }
  };

  const isMealSelected = (meal) => {
    return selectedMeals.some(
      (selectedMeal) => selectedMeal.Code === meal.Code
    );
  };

  const [selectedSeats, setSelectedSeats] = useState([]);

  const handleSelectSeats = (ele) => {
    const seatNumber = ele;
    setSelectedSeats((prevSelectedSeats) =>
      prevSelectedSeats.includes(seatNumber)
        ? prevSelectedSeats.filter((seat) => seat !== seatNumber)
        : [...prevSelectedSeats, seatNumber]
    );
  };

  const isSeatSelected = (seat) => {
    return selectedSeats.includes(seat);
  };

  const renderSeats = () => {
    if (flightsSeatsError) {
      return <p>{flightsSeatsError}</p>;
    }

    if (!seatsSelectData || !seatsSelectData.Seats) {
      return <p>No seat data available.</p>;
    }

    const seatRows = Object.values(seatsSelectData?.Seats);

    return (
      <div className="p-3" style={{ border: "1px solid gray" }}>
        <div className="p-3" style={{ border: "1px solid gray" }}>
          {seatRows.map((row, rowIndex) => (
            <div key={rowIndex} className="row">
              <div className="d-flex justify-content-center">
                <span
                  className="pe-3"
                  style={{ fontSize: "18px", fontWeight: "600" }}
                >
                  R {rowIndex + 1}
                </span>
                <div className="row">
                  {Object.values(row).map((seat, seatIndex) => (
                    <div
                      key={seatIndex}
                      className={`col mx-2 my-1 ${
                        isSeatSelected(seat) ? "selected-seat" : ""
                      }`}
                      style={{
                        border: "1px solid lightgray",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSelectSeats(seat)}
                    >
                      <div>
                        <span>{seat?.SeatNumber}</span>
                        <span>{seat?.Amount}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const [flightSeatBookingConfirmation, setflightSeatBookingConfirmation] =
    useState("");
  const [errorMessages, setErrorMessages] = useState(true);

  const handleBooking = async () => {
    try {
      const passengers = [
        {
          // Fill in passenger details as needed
          Title: "Mr",
          FirstName: "First",
          LastName: "Name",
          PaxType: 1,
          DateOfBirth: "",
          Gender: "1",
          PassportNo: "",
          PassportExpiry: "",
          PassportIssueDate: "",
          AddressLine1: "A152 Ashok Nagar",
          City: "Delhi",
          CountryCode: "IN",
          CountryName: "INDIA",
          ContactNo: "xxxxxxxxxxxxx",
          Email: "navneet@srdvtechnologies.com",
          Baggage: [],
          MealDynamic: selectedMeals || "",
          Seat: selectedSeats,
          IsLeadPax: 1,
          Fare: {
            BaseFare: 3665,
            Tax: 845,
            TransactionFee: "0",
            YQTax: "",
            AdditionalTxnFeeOfrd: "",
            AdditionalTxnFeePub: "",
            AirTransFee: "0",
          },
        },
      ];

      const requestData = {
        TraceId: TraceId, // Replace with actual TraceId
        ResultIndex: ResultIndex,
        Passengers: passengers,
      };

      const response = await BookFlightsSeatLCC(requestData);

      if (
        response.status === "success" &&
        response.data.Error?.ErrorCode == "0"
      ) {
        setflightSeatBookingConfirmation(response.data.Response);
        setErrorMessages(false);
      } else {
        setflightSeatBookingConfirmation(response.data.Error);
        setErrorMessages(true);
      }

      console.log("Booking successful:", response);
    } catch (error) {
      console.error("Error booking flight:", error);
    }
  };

  // Fetch User Details

  const [userInformation, setUserInformation] = useState(null);

  const GetUserInformationApi = async () => {
    const userTokenFromCookies = Cookies.get("userToken");
    if (userTokenFromCookies) {
      const parsedUserData = JSON.parse(userTokenFromCookies);
      const res = await getUserProfile(parsedUserData);
      console.log(res, "getUserProfile");
      setUserInformation(res?.data);
    }
  };

  useEffect(() => {
    GetUserInformationApi();
  }, []);

  // Generate Order Id

  const generateUniqueOrderId = () => {
    return Math.floor(1000000000 + Math.random() * 9000000000).toString();
  };

  const [OrderId, setOrderId] = useState(generateUniqueOrderId());
  const [isPaymentFormVisible, setIsPaymentFormVisible] = useState(false);

  useEffect(() => {
    generateUniqueOrderId();
  }, []);

  const handlePayment = async (e) => {
    e.preventDefault();

    const formData = {
      order_id: OrderId,
      mobile: userInformation?.mobile,
      amount: "1",
      booking_type: "Flight",
      customer_name: "John Doe",
      email: "john.doe@example.com",
      payment_status: "Pending",
      tracking_id: "",
      payment_mode: "Unified Payments",
      order_status: "pending",
    };

    try {
      const res = await sendPaymentDetails(formData);

      console.log(res, "savepaymentdetails");

      if (res?.status === "success") {
        // Make the form visible and submit it
        setIsPaymentFormVisible(true);
        setTimeout(() => {
          document.customerData.submit();
        }, 1000); // Auto-submit the form after showing it
      } else {
        console.error("Error saving payment details");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <section className=" pt-2 pb-2 pt-md-3 pb-md-3">
      <div className="container"></div>

      <div className="container">
        <div className="row">
          <p
            className="text-center"
            style={{ fontSize: "30px", fontWeight: "500" }}
          >
            Review your booking
          </p>

          <div className="row ">
            {fareDetails ? (
              <>
                <div className="col-lg-9 col-md-8">
                  <div className="flight-booking-paul-left">
                    <div>
                      {fareDetails?.Segments?.map(
                        (segmentGroup, groupIndex) => (
                          <div key={groupIndex} className=" mb-4">
                            {segmentGroup.map((segment, index) => (
                              <div>
                                <div
                                  key={index}
                                  className="p-3"
                                  style={{
                                    border: "1px solid lightgray",
                                  }}
                                >
                                  <div className="d-flex justify-content-between">
                                    <div>
                                      <span>
                                        {logo ? (
                                          <img
                                            src={logo}
                                            height="40px"
                                            width="50px"
                                            alt={`logo`}
                                          />
                                        ) : (
                                          <MdFlight className="fs-3 text-info" />
                                        )}
                                      </span>
                                      <span className="px-2">
                                        {segment.Airline.AirlineName}
                                      </span>
                                      <span
                                        style={{ borderLeft: "1px solid gray" }}
                                      ></span>
                                      <span className="px-1">
                                        {segment.Airline.AirlineCode}
                                      </span>
                                      <span>
                                        {segment.Airline.FlightNumber}
                                      </span>
                                    </div>

                                    <div>
                                      <span>{segment.CabinClassName}</span>
                                    </div>
                                  </div>

                                  <div
                                    className="d-flex justify-content-between pb-2"
                                    style={{
                                      backgroundColor: "white",
                                      borderBottom: "1px solid gray",
                                    }}
                                  >
                                    <div>
                                      <p
                                        className="m-0"
                                        style={{
                                          fontSize: "24px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {convertDateTime(segment.DepTime)}
                                      </p>
                                      <p
                                        className="m-0"
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {segment.Origin.AirportCode}
                                      </p>
                                      <p
                                        className="m-0"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {segment.Origin.CityName}
                                      </p>
                                      <div
                                        style={{
                                          fontSize: "13px",
                                          fontWeight: "400",
                                          color: "gray",
                                        }}
                                      >
                                        <span className="m-0">
                                          {segment.Origin.AirportName}
                                        </span>
                                        <span className="m-0 ps-1">
                                          ,Terminal - {segment.Origin.Terminal}
                                        </span>
                                        <span className="m-0 ps-1">
                                          , {segment.Origin.CountryName}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="text-center">
                                      <div>
                                        {calculateDuration(
                                          segment.DepTime,
                                          segment.ArrTime
                                        )}
                                      </div>
                                    </div>

                                    <div>
                                      <p
                                        className="m-0 text-end"
                                        style={{
                                          fontSize: "24px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {convertDateTime(segment.ArrTime)}
                                      </p>
                                      <p
                                        className="m-0 text-end"
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {segment.Destination.AirportCode}
                                      </p>
                                      <p
                                        className="m-0 text-end"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {segment.Destination.CityName}
                                      </p>
                                      <div
                                        style={{
                                          fontSize: "13px",
                                          fontWeight: "400",
                                          color: "gray",
                                        }}
                                      >
                                        <span className="m-0">
                                          {segment.Destination.AirportName}
                                        </span>
                                        <span className="m-0 ps-1">
                                          ,Terminal -
                                          {segment.Destination.Terminal}
                                        </span>
                                        <span className="m-0 ps-1">
                                          , {segment.Destination.CountryName}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="d-flex justify-content-between pt-2">
                                    <div>
                                      Baggage - {segment.Baggage} (1 piece only)
                                    </div>
                                    <div>
                                      Cabin - {segment.CabinBaggage} (1 piece
                                      only)
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="py-1"
                                  style={{ backgroundColor: "#f1f1f1" }}
                                >
                                  <p
                                    className="m-0 text-center"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {index < segmentGroup.length - 1 && (
                                      <div
                                        className="p-3 text-center"
                                        style={{
                                          fontSize: "13px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {calculateLayoverTime(
                                          segment.ArrTime,
                                          segmentGroup[index + 1].DepTime
                                        )}
                                        Layover in
                                        {
                                          segmentGroup[index + 1].Origin
                                            .CityName
                                        }
                                      </div>
                                    )}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        )
                      )}
                    </div>

                    <div>
                      <form
                        action="https://mmrtrip.com/flight/save_customer_data"
                        method="post"
                        id="travellerdetail"
                      >
                        <input
                          type="hidden"
                          name="csrf_token"
                          defaultValue="7a72fefd60f092aade31bdb0fca6d230"
                        />
                        <div className="flight-passeng-details mt-2 mt-md-4">
                          <div className="alert_for_error_msg" />
                          <h3>Traveller Details</h3>
                          <div
                            className="flight-pass-dts"
                            style={{ fontSize: "14px" }}
                          >
                            <h6 className="heading-3">Adult 1</h6>

                            <div className="row">
                              <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                  <label htmlFor>Select Title</label>
                                  <input
                                    type="hidden"
                                    name="sessionid"
                                    defaultValue=""
                                  />
                                  <select
                                    className="form-control custom-select pax_validation_field title_auto_fill for_pop_data"
                                    pestype="Adult"
                                    forend={0}
                                    name="AdultTitle_1"
                                    error_msg="Please select Title for Adult"
                                    key_unique="Adult1"
                                    required="required"
                                  >
                                    <option value>Select Title</option>
                                    <option value="Mr">Mr</option>
                                    <option value="Mrs">Mrs</option>
                                    <option value="Miss">Miss</option>
                                    <option value="Ms">Ms</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                  <label htmlFor>First Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue=""
                                    pestype="Adult"
                                    forend={1}
                                    id="AdultFirstName_1"
                                    placeholder="First Name"
                                    onKeyPress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)"
                                    name="AdultFirstName_1"
                                    error_msg="Please enter first name for Adult"
                                    style={{ fontSize: "14px" }}
                                  />
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                  <label htmlFor>Last name</label>
                                  <input
                                    type="text"
                                    className="form-control pax_validation_field for_pop_data name_length"
                                    pestype="Adult"
                                    id="AdultLastName_1"
                                    placeholder="Last Name"
                                    onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)"
                                    name="AdultLastName_1"
                                    error_msg="Please enter Last name for Adult"
                                    min_length={3}
                                    style={{ fontSize: "14px" }}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                  <label htmlFor>Gender</label>
                                  <input
                                    type="text"
                                    className="form-control pax_validation_field gender_auto_fill co_gander for_pop_data"
                                    forend={2}
                                    id="AdultGender_1"
                                    placeholder="Gender"
                                    name="AdultGender_1"
                                    error_msg="Please select gender  for Adult"
                                    key_unique="Adult1"
                                    style={{ fontSize: "14px" }}
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div>

                            <div>
                              <h3>Add-on (Optional)</h3>
                              <p className="pt-2 m-0">
                                Pre-booked Meals and Baggage are 30% cheaper
                                than on-board price.
                              </p>
                              <div className="row">
                                <div className="col-lg-5 col-md-5 col-sm-6 flight-area">
                                  <div className="flight-pass-dts">
                                    <h6>Add Baggage ( DEL - BOM )</h6>
                                    <select
                                      id="baggage"
                                      name="baggage"
                                      defaultValue="Add Extra Baggage"
                                      className="p-2"
                                      style={{
                                        width: "100%",
                                        fontSize: "14px",
                                        border: "1px solid lightgray",
                                      }}
                                    >
                                      <option
                                        value="Add Extra Baggage"
                                        disabled
                                        hidden
                                      >
                                        Add Extra Baggage
                                      </option>
                                      <option value="3kg">
                                        3Kg - Price (1515)
                                      </option>
                                      <option value="5kg">
                                        5Kg - Price (2525)
                                      </option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-5 col-md-5 col-sm-6 flight-area">
                                  <div className="flight-pass-dts">
                                    <h6>Add Meal ( DEL - BOM )</h6>
                                    <select
                                      id="meal"
                                      name="meal"
                                      defaultValue="Add meal"
                                      className="p-2"
                                      style={{
                                        width: "100%",
                                        fontSize: "14px",
                                        border: "1px solid lightgray",
                                      }}
                                    >
                                      <option value="Add meal" disabled hidden>
                                        Add Meal
                                      </option>
                                      <option value="vegMeal1">
                                        Vegan Delight Salad - Price (500)
                                      </option>
                                      <option value="vegMeal2">
                                        Grilled Vegetable Sandwich - Price (550)
                                      </option>
                                      <option value="vegMeal3">
                                        Mediterranean Veggie Wrap - Price (600)
                                      </option>
                                      <option value="vegMeal4">
                                        Quinoa and Avocado Salad - Price (650)
                                      </option>
                                      <option value="vegMeal5">
                                        Vegetarian Sushi Platter - Price (700)
                                      </option>
                                      {/* Add more options as needed */}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="Baggage-section"></div>
                            <div className="clearfix" />
                            <div className="flight-details-listing">
                              <div className="flight-booking-list">
                                <h3
                                  style={{
                                    backgroundImage:
                                      "linear-gradient(45deg, #1556FC, #04A9FE)",
                                  }}
                                >
                                  Travel Insurance
                                </h3>

                                <div className="wrap_border">
                                  <div className="insurance-details">
                                    <h4>
                                      <span className="text-success">
                                        ₹ 199
                                      </span>
                                      <span className="traveller_content">
                                        /Traveller(18% GST Included)
                                      </span>
                                    </h4>

                                    <div className="travel-carousel owl-carousel">
                                      <Slider {...FlightsSettings}>
                                        <div className="pe-2">
                                          <div className="card travel-insur-type content_insurance">
                                            <ul className="list-unstyled list-inline">
                                              <li className="list-inline-item icon-sec">
                                                <i className="icofont-airplane-alt" />
                                              </li>
                                              <li className="list-inline-item">
                                                <div className="price-section">
                                                  <h4 className="text-success text-center">
                                                    Save ₹ 10,000
                                                  </h4>
                                                  <p
                                                    className="text-center"
                                                    style={{ fontSize: "14px" }}
                                                  >
                                                    Loss of Deposit or
                                                    Cancellation (Hotel &amp;
                                                    Airline)
                                                  </p>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>

                                        <div className="pe-2">
                                          <div className="card travel-insur-type content_insurance">
                                            <ul className="list-unstyled list-inline">
                                              <li className="list-inline-item icon-sec">
                                                <i className="icofont-airplane-alt" />
                                              </li>
                                              <li className="list-inline-item">
                                                <div className="price-section">
                                                  <h4 className="text-success text-center">
                                                    Save ₹ 20,000
                                                  </h4>
                                                  <p
                                                    className="text-center"
                                                    style={{ fontSize: "14px" }}
                                                  >
                                                    Total Loss of Checked-in
                                                    Baggage
                                                  </p>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>

                                        <div className="pe-2">
                                          <div className="card travel-insur-type content_insurance">
                                            <ul className="list-unstyled list-inline">
                                              <li className="list-inline-item icon-sec">
                                                <i className="icofont-airplane-alt" />
                                              </li>
                                              <li className="list-inline-item">
                                                <div className="price-section">
                                                  <h4 className="text-success text-center">
                                                    Save ₹ 50,000
                                                  </h4>
                                                  <p
                                                    className="text-center"
                                                    style={{ fontSize: "14px" }}
                                                  >
                                                    Loss of Deposit or
                                                    Cancellation (Hotel &amp;
                                                    Airline)
                                                  </p>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </Slider>
                                    </div>
                                  </div>

                                  <div className=" mb-3">
                                    <div className="">
                                      <div className="" />
                                      <div className="insur-col-bottom">
                                        <div className="row">
                                          <div className="col-md-6 insurnce_btn">
                                            <div className="insur-bt-lt">
                                              <ul className="list-inline mb-0">
                                                <li className="list-inline-item">
                                                  <a
                                                    data-toggle="modal"
                                                    href="#conver-break1"
                                                    className="badge"
                                                  >
                                                    Cover Details
                                                  </a>
                                                </li>
                                                <li className="list-inline-item">
                                                  <a
                                                    data-toggle="modal"
                                                    href="#prc-break1"
                                                    className="badge"
                                                  >
                                                    Price Break Up
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <div className="col-md-12 mt-2">
                                            <label className="block radio append_bottom15">
                                              <input
                                                className="add_ins secur-type-btn"
                                                name="ins_depart"
                                                type="radio"
                                                defaultValue="yes"
                                              />
                                              <span
                                                className="outer marR8 secur-type"
                                                data-ischecked="false"
                                              >
                                                <span className="inner" />
                                              </span>
                                              <span>
                                                
                                                Yes, secure my trip.
                                              </span>
                                            </label>
                                          </div>
                                          <div className="col-md-12 terms_div">
                                            <label className="block radio append_bottom15">
                                              <input
                                                defaultChecked
                                                className="add_ins secur-type-btn"
                                                type="radio"
                                                name="ins_depart"
                                                defaultValue
                                              />
                                              <span className="secure_mytrip">
                                                I do not wish to secure my trip.
                                              </span>
                                            </label>
                                            <span>
                                              By adding insurance you agree to
                                              the
                                              <a
                                                href="/termsandconditions"
                                                rel=""
                                                target="_blank"
                                                className="px-2"
                                              >
                                                Terms &amp; Conditions
                                              </a>
                                              and confirm all passengers are
                                              between 2 to 70 years of age.
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* cover Details Popup */}
                              <div className="modal fade" id="prc-break1">
                                <div className="modal-dialog">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h4 className="modal-title">
                                        Price Break Up
                                      </h4>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-hidden="true"
                                      ></button>
                                    </div>
                                    <div className="modal-body">
                                      <div role="tabpanel">
                                        {/* Nav tabs */}
                                        <ul
                                          className="nav nav-tabs"
                                          role="tablist"
                                        >
                                          <li className="active">
                                            <a
                                              href="#pax-details-1"
                                              role="tab"
                                              data-toggle="tab"
                                            >
                                              Pax Details
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href="#price-fare-1"
                                              role="tab"
                                              data-toggle="tab"
                                            >
                                              Price Fare
                                            </a>
                                          </li>
                                        </ul>
                                        {/* Tab panes */}
                                        <div className="tab-content">
                                          <div
                                            role="tabpanel"
                                            className="tab-pane active"
                                            id="pax-details-1"
                                          >
                                            <div className="table-responsive">
                                              <table className="table table-striped table-bordered">
                                                <thead>
                                                  <tr>
                                                    <th>Passenger Count</th>
                                                    {/* <th>Min Age</th>
                                                                                  <th>Max Age</th> */}
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td>1</td>
                                                    {/* <td>0</td>
                                                                                      <td>70 </td> */}
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                          <div
                                            role="tabpanel"
                                            className="tab-pane"
                                            id="price-fare-1"
                                          >
                                            <div className="table-responsive">
                                              <table className="table table-striped table-bordered">
                                                <thead>
                                                  <tr>
                                                    <th>Price</th>
                                                    <th>Cancellation Charge</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td>199</td>
                                                    <td>0</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-sm"
                                        data-dismiss="modal"
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* cover Details Popup End */}
                              <div className="modal fade" id="conver-break1">
                                <div className="modal-dialog">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h4 className="modal-title">
                                        Coverage Details
                                      </h4>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-hidden="true"
                                      >
                                        ×
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      <div className="table-responsive">
                                        <table className="table table-bordered">
                                          <tbody>
                                            <tr>
                                              <th>Coverage</th>
                                              <th>SumInsured</th>
                                              <th>Excess</th>
                                            </tr>
                                            <tr>
                                              <td>A) Assistance Services </td>
                                              <td>- </td>
                                              <td>- </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                1) Domestic Roadside Assistance
                                              </td>
                                              <td>Covered </td>
                                              <td>- </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                2) Domestic Medical Assistance
                                              </td>
                                              <td>Covered </td>
                                              <td>- </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                3) Lifestyle Assistance &amp;
                                                India
                                              </td>
                                              <td>NA </td>
                                              <td>- </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                4) Travel Insurance Policy
                                              </td>
                                              <td>Covered </td>
                                              <td>- </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                B) INSURANCE BENEFITS : Coverage
                                                (In INR)
                                              </td>
                                              <td>- </td>
                                              <td>- </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                1) Emergency Medical Expenses
                                              </td>
                                              <td>200000 </td>
                                              <td>- </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                2) Emergency Medical Expenses *
                                                (Accidental OPD Expenses)
                                              </td>
                                              <td>10000 </td>
                                              <td>- </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                3) Emergency Medical Evacuation
                                                &amp; Repartration of Mortal
                                                Remains
                                              </td>
                                              <td>Included* </td>
                                              <td>- </td>
                                            </tr>
                                            <tr>
                                              <td>C) Personal Accident </td>
                                              <td>- </td>
                                              <td>- </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                1) Personal
                                                Accident(Death+PTD+PPD)
                                              </td>
                                              <td>750000 </td>
                                              <td>- </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                2) Total Loss of Checked-in
                                                Baggage
                                              </td>
                                              <td>20000 </td>
                                              <td>- </td>
                                            </tr>
                                            <tr>
                                              <td>3) Missed Connection </td>
                                              <td>10000 </td>
                                              <td>- </td>
                                            </tr>
                                            <tr>
                                              <td>4) Deductibles </td>
                                              <td>3 Hours </td>
                                              <td>- </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                5) Loss of Deposit or
                                                Cancellation (Hotel &amp;
                                                Airline)
                                              </td>
                                              <td>20000 </td>
                                              <td>- </td>
                                            </tr>
                                            <tr>
                                              <td>6) Deductibles (in INR) </td>
                                              <td>500 </td>
                                              <td>- </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                7) Trip Cancellation and /or
                                                Interruption
                                              </td>
                                              <td>25000 </td>
                                              <td>- </td>
                                            </tr>
                                            <tr>
                                              <td>8) Trip Delay </td>
                                              <td>10500 </td>
                                              <td>- </td>
                                            </tr>
                                            <tr>
                                              <td>9) Deductibles </td>
                                              <td>6 HRS </td>
                                              <td>- </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                ) *Emergency Medical Cover
                                              </td>
                                              <td>
                                                Included in Emergency Accidental
                                              </td>
                                              <td>- </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                ) *Adventurous Sports Cover
                                              </td>
                                              <td>
                                                Included in Emergency Accidental
                                              </td>
                                              <td>- </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                D) Assist Charges 0-70 years (in
                                                INR inclusive of GST@ 18%)
                                              </td>
                                              <td>- </td>
                                              <td>- </td>
                                            </tr>
                                            <tr>
                                              <td>1) 30 Days </td>
                                              <td>199 </td>
                                              <td>- </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-sm"
                                        data-dismiss="modal"
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <h6 className="heading-3">Contact Details</h6>
                            <div className="row">
                              <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="form-group">
                                  <label htmlFor>Enter Your Email</label>
                                  <input
                                    type="email"
                                    name="cust_email"
                                    error_msg="Please enter email ID"
                                    placeholder="Enter Your Email"
                                    className="form-control pax_validation_field for_email_confirm"
                                    id="email"
                                    style={{ fontSize: "14px" }}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="form-group">
                                  <label htmlFor>Select CountryCode</label>
                                  <select
                                    name="cust_country_code"
                                    error_msg="Please select Country Code"
                                    placeholder="Select Country Code"
                                    className="form-control pax_validation_field for_countrycode_confirm"
                                    id="cust_country_code"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <option value>
                                      --Select CountryCode--
                                    </option>
                                    <option value={93}>Afghanistan(93)</option>
                                    <option value={98}>India(91)</option>
                                    <option value={260}>Zambia(260)</option>
                                    <option value={263}>Zimbabwe(263)</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="form-group">
                                  <label htmlFor>Enter Your Mobile</label>
                                  <input
                                    type="number"
                                    name="cust_mobile_no"
                                    error_msg="Please enter phone number"
                                    placeholder="Enter Your Number"
                                    className="form-control pax_validation_field for_contact_confirm"
                                    id="cust_mobile_no"
                                    maxLength={10}
                                    style={{ fontSize: "14px" }}
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                  />
                                </div>
                              </div>
                              <div className="clearfix" />
                            </div>

                            <div className="checkbox">
                              <label>
                                <input
                                  name="GSTAllowed"
                                  className="gstAllowed"
                                  defaultValue="gst_data_filed"
                                  type="checkbox"
                                />
                                GST Not Mandatory for this Airline
                              </label>
                            </div>

                            <div className="col-md-12 no-padding gst_details review_title mt-3">
                              <h6 className="heading-3">GST Detail </h6>
                            </div>
                            <div className="row airlines gst_details ">
                              <div className="col-lg-4 col-md-6 col-sm-6  pb-2">
                                <div className="form-group search-en-col">
                                  <label htmlFor="GSTCompanyName">
                                    Company Name:
                                  </label>
                                  <input
                                    type="text"
                                    id="GSTCompanyName"
                                    name="GSTCompanyName"
                                    error_msg="Please enter Company Name"
                                    className="form-control"
                                    defaultValue=""
                                    placeholder="Company Name"
                                    style={{ fontSize: "14px" }}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-6  pb-2">
                                <div className="form-group search-en-col">
                                  <label htmlFor="GSTNumber">GST Number:</label>
                                  <input
                                    type="text"
                                    id="GSTNumber"
                                    name="GSTNumber"
                                    error_msg="Please enter GST Number"
                                    className="form-control"
                                    defaultValue=""
                                    placeholder="GST Number"
                                    style={{ fontSize: "14px" }}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-6  pb-2">
                                <div className="form-group search-en-col">
                                  <label htmlFor="GSTCompanyAddress">
                                    Company Address:
                                  </label>
                                  <input
                                    type="text"
                                    data-validation="GSTCompanyAddress"
                                    name="GSTCompanyAddress"
                                    error_msg="Please Enter Company Address"
                                    defaultValue=""
                                    className="form-control"
                                    placeholder="Company Address"
                                    style={{ fontSize: "14px" }}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="form-group search-en-col">
                                  <label htmlFor="GSTCompanyContactNumber">
                                    Company Contact Number:
                                  </label>
                                  <input
                                    type="text"
                                    id="GSTCompanyContactNumber"
                                    name="GSTCompanyContactNumber"
                                    error_msg="Please enter Company Contact Number"
                                    className="form-control"
                                    defaultValue=""
                                    placeholder="Company Contact Number"
                                    style={{ fontSize: "14px" }}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="form-group search-en-col">
                                  <label htmlFor="GSTCompanyEmail">
                                    Company Email:
                                  </label>
                                  <input
                                    type="text"
                                    id="GSTCompanyEmail"
                                    name="GSTCompanyEmail"
                                    error_msg="Please enter Company Email"
                                    className="form-control"
                                    defaultValue=""
                                    placeholder="Company Email"
                                    style={{ fontSize: "14px" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flight-passeng-details mt-2 mt-md-4">
                              <h3>Acknowledgment</h3>
                              <div className="flight-pass-dts">
                                <div className="checkbox custm-checkbox">
                                  <label className htmlFor="tterms">
                                    <input
                                      type="checkbox"
                                      className="term_condition"
                                      error_msg="please check terms and condtion checkbox"
                                      id="tterms"
                                      name="terms_conditions"
                                      required
                                    />
                                    <span>
                                      Yes, I agree to the rules and restrictions
                                      of this fare, and the terms of the portal
                                    </span>
                                  </label>
                                </div>

                                <div className="btn-flght-bottom text-center text-md-right">
                                  <ul className="list-inline">
                                    <li className="list-inline-item">
                                      <button
                                        className="mt-2 btn btn-com pax_validation_continue"
                                        type="button"
                                        data-toggle="modal"
                                        data-target="#confirm-booking-pop"
                                      >
                                        Preview &amp; Book
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div
                  className="col-lg-3 col-md-4"
                  style={{
                    overflow: "hidden",
                    position: "sticky",
                    top: "95px",
                  }}
                >
                  <div className="sidebar-flight mb-3">
                    <h4>Fare Details</h4>
                    <div className="contant-fare" style={{ fontSize: "14px" }}>
                      <table className="border strip w-100">
                        <tbody>
                          <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                            <td className="py-2">
                              <p className="ps-3 m-0">Base Prices</p>
                            </td>
                            <td>
                              <p
                                className="ps-3"
                                style={{ borderLeft: "1px solid lightgray" }}
                              >{`₹${parseInt(
                                fareDetails?.Fare?.BaseFare
                              ).toLocaleString()}`}</p>
                            </td>
                          </tr>

                          <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                            <td className="py-2">
                              <p className="ps-3 m-0">Tax</p>
                            </td>
                            <td>
                              <p
                                className="ps-3"
                                style={{ borderLeft: "1px solid lightgray" }}
                              >{`₹${parseInt(
                                fareDetails?.Fare?.Tax
                              ).toLocaleString()}`}</p>
                            </td>
                          </tr>

                          <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                            <td className="py-2">
                              <p className="ps-3 m-0">Commission Earned</p>
                            </td>
                            <td>
                              <p
                                className="ps-3"
                                style={{ borderLeft: "1px solid lightgray" }}
                              >{`₹${parseInt(
                                fareDetails?.Fare?.CommissionEarned
                              ).toLocaleString()}`}</p>
                            </td>
                          </tr>

                          <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                            <td className="py-2">
                              <p className="ps-3 m-0">Tds On Commission</p>
                            </td>
                            <td>
                              <p
                                className="ps-3"
                                style={{ borderLeft: "1px solid lightgray" }}
                              >{`₹${parseInt(
                                fareDetails?.Fare?.TdsOnCommission
                              ).toLocaleString()}`}</p>
                            </td>
                          </tr>

                          <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                            <td className="py-2">
                              <p className="ps-3 m-0">Offered Fare</p>
                            </td>
                            <td>
                              <p
                                className="ps-3"
                                style={{ borderLeft: "1px solid lightgray" }}
                              >{`₹${parseInt(
                                fareDetails?.Fare?.OfferedFare
                              ).toLocaleString()}`}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="sidebar-flight">
                    <h4>Apply Coupon</h4>
                    <div className="contant-fare text-center">
                      <div className="form-group">
                        <div id="massegealert"> </div>
                      </div>
                      <input
                        type="text"
                        id="user_coupon"
                        name="coupon"
                        autoComplete="off"
                        className="form-control"
                        placeholder="Enter Coupon code"
                        style={{ fontSize: "14px" }}
                      />
                      <button
                        id="add_coupon"
                        className="btn btn-com mt-md-2"
                        type="button"
                        style={{ fontSize: "14px", borderRadius: "20px" }}
                      >
                        Add coupon
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div
                className="  d-flex align-items-center justify-content-center"
                style={{ height: "200px" }}
              >
                <img
                  src={images?.Flight_Loader_Transparent}
                  height={170}
                  width={170}
                ></img>
              </div>
            )}
          </div>

          <div className="row ">
            <div>
              <p
                className="text-center m-0 py-2"
                style={{ fontSize: "30px", fontWeight: "500" }}
              >
                Seat Selection
              </p>
              <div>{renderSeats()}</div>
            </div>
          </div>

          <div className="row">
            <p
              className="text-center m-0 py-2"
              style={{ fontSize: "30px", fontWeight: "500" }}
            >
              Meals Available
            </p>
            <div>
              {mealData?.length === 0 ? (
                <p>No meals available</p>
              ) : (
                mealData.map((meal, index) => (
                  <div key={index} className="row" style={{ margin: "3px" }}>
                    {meal.map((ele, ind) => (
                      <div
                        onClick={() => handleSelectMeals(ele)}
                        key={ind}
                        className="col-4 p-1"
                        style={{ padding: "3px" }}
                      >
                        <div
                          className={`flightsMealsCards ${
                            isMealSelected(ele) ? "selectedCard" : ""
                          }`}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <label>
                              <span>{ele.Origin}</span>
                              <span className="px-1">&#8594;</span>
                              <span>{ele.Destination}</span>
                            </label>
                          </div>
                          <div>
                            <label>Airline Description :</label>
                            {ele.AirlineDescription}
                          </div>
                          <div>
                            <label>Meal Code :</label>
                            {ele.Code}
                          </div>
                          <div>
                            <label>Description :</label>
                            {ele.Description}
                          </div>
                          <div>
                            <label>Quantity :</label>
                            {ele.Quantity}
                          </div>
                          <div>
                            <label>Price :</label>
                            <span className="px-1">{ele.Price}</span>
                            <span>{ele.Currency}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))
              )}
            </div>
          </div>

          <div className="row">
            <div className="d-flex justify-content-center py-3">
              <button
                style={{
                  backgroundColor: "lightblue",
                  border: "1.5px solid black",
                  borderRadius: "10px",
                }}
                className="py-2 px-4"
                onClick={handleBooking}
              >
                Book Flight
              </button>
            </div>
          </div>

          <div className="row">
            {errorMessages ? (
              <div className="">
                <p
                  className="text-center text-danger"
                  style={{ fontSize: "20px", fontWeight: "600" }}
                >
                  {flightSeatBookingConfirmation?.ErrorMessage}
                </p>
              </div>
            ) : (
              <div>
                <h3
                  className="text-center"
                  style={{ color: "green", fontWeight: "600" }}
                >
                  Congratulations, your Flight Seat Booking is confirmed
                </h3>
                <div className="d-flex justify-content-center">
                  <table className="border strip w-75">
                    <tbody>
                      <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                        <td className="py-2">
                          <span className="ps-3 m-0">Booking ID</span>
                        </td>
                        <td>
                          <span
                            className="ps-3"
                            style={{ borderLeft: "1px solid lightgray" }}
                          >
                            {flightSeatBookingConfirmation?.BookingId}
                          </span>
                        </td>
                      </tr>
                      <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                        <td className="py-2">
                          <span className="ps-3 m-0">PNR</span>
                        </td>
                        <td>
                          <span
                            className="ps-3"
                            style={{ borderLeft: "1px solid lightgray" }}
                          >
                            {flightSeatBookingConfirmation?.PNR}
                          </span>
                        </td>
                      </tr>
                      <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                        <td className="py-2">
                          <span className="ps-3 m-0">Status</span>
                        </td>
                        <td>
                          <span
                            className="ps-3"
                            style={{ borderLeft: "1px solid lightgray" }}
                          >
                            {flightSeatBookingConfirmation?.Status}
                          </span>
                        </td>
                      </tr>
                      <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                        <td className="py-2">
                          <span className="ps-3 m-0">Ticket Status</span>
                        </td>
                        <td>
                          <span
                            className="ps-3"
                            style={{ borderLeft: "1px solid lightgray" }}
                          >
                            {flightSeatBookingConfirmation?.TicketStatus}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-12">
              <div>
                {isPaymentFormVisible && (
                  <form
                    method="POST"
                    name="customerData"
                    action="https://exploretriponline.com/api/ccavRequestHandler"
                  >
                    <table width="40%" height="100" border="1" align="center">
                      <caption>
                        <font size="4" color="blue">
                          <b>Integration Kit</b>
                        </font>
                      </caption>
                    </table>
                    <table width="40%" height="100" border="1" align="center">
                      <tr>
                        <td>Parameter Name:</td>
                        <td>Parameter Value:</td>
                      </tr>
                      <tr>
                        <td colspan="2">Compulsory information</td>
                      </tr>
                      <tr>
                        <td>Merchant Id</td>
                        <td>
                          <input
                            type="text"
                            name="merchant_id"
                            id="merchant_id"
                            value="3401584"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Order Id</td>
                        <td>
                          <input
                            type="text"
                            name="order_id"
                            value={OrderId}
                            readOnly
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Currency</td>
                        <td>
                          <input type="text" name="currency" value="INR" />
                        </td>
                      </tr>
                      <tr>
                        <td>Amount</td>
                        <td>
                          <input type="text" name="amount" value="1.00" />
                        </td>
                      </tr>
                      <tr>
                        <td>Redirect URL</td>
                        <td>
                          <input
                            type="text"
                            name="redirect_url"
                            value="https://exploretriponline.com/api/ccavResponseHandler"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Cancel URL</td>
                        <td>
                          <input
                            type="text"
                            name="cancel_url"
                            value="https://exploretriponline.com/api/ccavResponseHandler"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Language</td>
                        <td>
                          <input
                            type="text"
                            name="language"
                            id="language"
                            value="EN"
                          />
                        </td>
                      </tr>

                      <tr>
                        <label for="booking_type">Booking Type:</label>
                        <select id="booking_type" name="booking_type">
                          <option value="flight">Flight</option>
                          <option value="hotel">Hotel</option>
                          <option value="cab">Cab</option>
                          <option value="bus">Bus</option>
                          <option value="insurance">Insurance</option>
                        </select>
                      </tr>

                      <tr>
                        <td colspan="2">Billing information(optional):</td>
                      </tr>
                      <tr>
                        <td>Billing Name</td>
                        <td>
                          <input
                            type="text"
                            name="billing_name"
                            value="Peter"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Billing Address:</td>com
                        <td>
                          <input
                            type="text"
                            name="billing_address"
                            value="Santacruz"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Billing City:</td>
                        <td>
                          <input
                            type="text"
                            name="billing_city"
                            value="Mumbai"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Billing State:</td>
                        <td>
                          <input type="text" name="billing_state" value="MH" />
                        </td>
                      </tr>
                      <tr>
                        <td>Billing Zip:</td>
                        <td>
                          <input
                            type="text"
                            name="billing_zip"
                            value="400054"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Billing Country:</td>
                        <td>
                          <input
                            type="text"
                            name="billing_country"
                            value="India"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Billing Tel:</td>
                        <td>
                          <input
                            type="text"
                            name="billing_tel"
                            value="7835967867"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Billing Email:</td>
                        <td>
                          <input
                            type="text"
                            name="billing_email"
                            value="testing@domain.com"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">Shipping information(optional):</td>
                      </tr>
                      <tr>
                        <td>Shipping Name</td>
                        <td>
                          <input type="text" name="delivery_name" value="Sam" />
                        </td>
                      </tr>
                      <tr>
                        <td>Shipping Address:</td>
                        <td>
                          <input
                            type="text"
                            name="delivery_address"
                            value="Vile Parle"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Shipping City:</td>
                        <td>
                          <input
                            type="text"
                            name="delivery_city"
                            value="Mumbai"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Shipping State:</td>
                        <td>
                          <input
                            type="text"
                            name="delivery_state"
                            value="Maharashtra"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Shipping Zip:</td>
                        <td>
                          <input
                            type="text"
                            name="delivery_zip"
                            value="400038"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Shipping Country:</td>
                        <td>
                          <input
                            type="text"
                            name="delivery_country"
                            value="India"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Shipping Tel:</td>
                        <td>
                          <input
                            type="text"
                            name="delivery_tel"
                            value="0123456789"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Merchant Param1</td>
                        <td>
                          <input
                            type="text"
                            name="merchant_param1"
                            value="additional Info."
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Merchant Param2</td>
                        <td>
                          <input
                            type="text"
                            name="merchant_param2"
                            value="additional Info."
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Merchant Param3</td>
                        <td>
                          <input
                            type="text"
                            name="merchant_param3"
                            value="additional Info."
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Merchant Param4</td>
                        <td>
                          <input
                            type="text"
                            name="merchant_param4"
                            value="additional Info."
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Merchant Param5</td>
                        <td>
                          <input
                            type="text"
                            name="merchant_param5"
                            value="additional Info."
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Promo Code:</td>
                        <td>
                          <input type="text" name="promo_code" value="" />
                        </td>
                      </tr>
                      <tr>
                        <td>Customer Id:</td>
                        <td>
                          <input
                            type="text"
                            name="customer_identifier"
                            value=""
                          />
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          <input type="submit" value="Checkout" />
                        </td>
                      </tr>
                    </table>
                  </form>
                )}
              </div>

              <button onClick={handlePayment} className="payButton my-2">
                  Pay Now
                </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
