import React, { useEffect, useState } from "react";
import "../../style/Bus.css";
import { IoIosArrowForward } from "react-icons/io";
import { FaStar } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom/dist";
import { getAllBuses } from "../../api/BusAPI";
import images from "../../assets/Images";
import { Button, Modal } from "react-bootstrap";

const formatTime = (date) => {
  const hours = date?.getHours();
  const minutes = date?.getMinutes();
  const amPm = hours >= 12 ? "PM" : "AM";
  const displayHours = hours % 12 === 0 ? 12 : hours % 12;
  return `${displayHours}:${minutes.toString().padStart(2, "0")} ${amPm}`;
};

export const BusSearch = () => {
  const [filteredBusData, setFilteredBusData] = useState([]);
  const [priceRange, setPriceRange] = useState({ min: 0, max: 0 });
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [seatsRange, setSeatsRange] = useState({ min: 0, max: 0 });
  const [selectedSeats, setSelectedSeats] = useState(0);
  const [loading, setLoading] = useState(true);
  const [bus, setBus] = useState([]);

  const { from, to, DepartureDate } = useParams();
  const [minSeats, setMinSeats] = useState(1);
  console.log(DepartureDate)

  const [TraceID, setTraceID] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchBusesData = async () => {
      const res = await getAllBuses(DepartureDate);
      const data = res?.data?.Result?.BusResults ?? [];
      const traceID = await res?.data?.Result?.TraceId;
      if (Array.isArray(data)) {
        setBus(data);
        setFilteredBusData(data);
        setLoading(false);
        setTraceID(traceID);
        // Set price and seats range
        const prices = data?.map((bus) => bus?.Price?.BasePrice);
        const maxPrice = Math?.max(...prices);
        const minPrice = Math?.min(...prices);
        setPriceRange({ min: minPrice, max: maxPrice });
        setSelectedPrice(maxPrice);

        const seats = data?.map((bus) => bus?.AvailableSeats);
        const maxSeats = Math?.max(...seats);
        const minSeats = Math?.min(...seats);
        setSeatsRange({ min: minSeats, max: maxSeats });
        setSelectedSeats(minSeats);
      } else {
        console.error("Data is not an array:", data);
      }
    };

    fetchBusesData();
  }, [DepartureDate]);

  const handlePriceChange = (e) => {
    setSelectedPrice(parseInt(e.target.value));
  };

  const handleSeatsChange = (e) => {
    setSelectedSeats(parseInt(e.target.value));
  };

  useEffect(() => {
    const applyFilters = () => {
      try {
        const filtered = bus?.filter((bus) => {
          return (
            (selectedPrice === 0 || bus?.Price?.BasePrice <= selectedPrice) &&
            (selectedSeats === 0 || bus?.AvailableSeats >= selectedSeats)
          );
        });
        setFilteredBusData(filtered);
      } catch (error) {
        console.log(error);
      }
    };

    if (Array.isArray(bus)) {
      applyFilters();
    }
  }, [selectedPrice, selectedSeats, bus]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      date
    );
    const year = date.getFullYear();
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes();
    const amOrPm = date.getHours() < 12 ? "AM" : "PM";
    const formattedTime = `${hours}:${minutes
      .toString()
      .padStart(2, "0")} ${amOrPm}`;

    return `${formattedTime}\n${day} ${month} ${year}`;
  };

  const calculateTotalDuration = (departureTimeString, arrivalTimeString) => {
    const departureTime = new Date(departureTimeString);
    const arrivalTime = new Date(arrivalTimeString);

    // Calculate the time difference in milliseconds
    const timeDifferenceInMillis = arrivalTime - departureTime;

    // Convert milliseconds to hours and minutes
    const hours = Math.floor(timeDifferenceInMillis / (1000 * 60 * 60));
    const minutes = Math.floor(
      (timeDifferenceInMillis % (1000 * 60 * 60)) / (1000 * 60)
    );

    // Format the duration
    const formattedDuration = `${hours}h:${minutes
      .toString()
      .padStart(2, "0")}m`;

    return formattedDuration;
  };

  function handleOfferedPrice(price) {
    const roundedPrice = (price * 1.1).toFixed(0);
    return parseFloat(roundedPrice);
  }

  function handleOffPrice(price) {
    const roundedPrice = (price * 0.1).toFixed(0);
    return parseFloat(roundedPrice);
  }

  function handleResetAll() {
    window.location.reload();
  }

  const [showBusFilterModal, setShowBusFilterModal] = useState(false);

  const handleCloseBusFilterModal = () => setShowBusFilterModal(false);
  const handleShowBusFilterModal = () => setShowBusFilterModal(true);

  if (!Array.isArray(filteredBusData)) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div
        className="container-fluid desktop-view"
        style={{ position: "relative" }}
      >
        <h2 className="text-center mt-4">
          Showing Buses {from}
          <span className="px-1">
            <i class="fa fa-arrow-right"></i>
          </span>
          {to}
        </h2>
        <div className="row bg-info"></div>
        <div className="row d-flex justify-content-between px-4 ">
          {loading ? (
            <div
              className="  d-flex align-items-center justify-content-center"
              style={{ height: "400px" }}
            >
              <img
                src={images?.Flight_Loader_Transparent}
                height={170}
                width={170}
              ></img>
            </div>
          ) : (
            <div className="row px-2">
              <div
                className="col-md-3 mt-4 mb-3 Section_Sidebar_Container"
                style={{
                  border: "1.5px solid rgb(1,128,253)",
                  overflow: "hidden",
                  position: "sticky",
                  top: "110px",
                  maxHeight: "350px",
                }}
              >
                <div
                  className="row text-white d-flex justify-content-between py-3"
                  style={{
                    backgroundImage: "linear-gradient(45deg, #1556FC, #04A9FE)",
                    borderBottom: "1.5px solid rgb(1,128,253)",
                  }}
                >
                  <div className="col-5">
                    <h5 className="m-0">Filter by</h5>
                  </div>
                  <div
                    className="col-6 d-flex justify-content-end align-items-center"
                    onClick={handleResetAll}
                    style={{ cursor: "pointer" }}
                  >
                    <span className="pe-2">Resets all </span>
                    <img
                      src={images?.reset}
                      alt=""
                      width="20px"
                      height="20px"
                    />
                  </div>
                </div>

                <div
                  className="row d-flex justify-content-between  py-3 px-2"
                  style={{ background: "#fff" }}
                >
                  <div
                    className="m-0 p-0 row py-2"
                    style={{ borderBottom: "2px solid #e9e9e9" }}
                  >
                    <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                      PRICE
                    </p>
                    <div className="p-0 d-flex justify-content-between">
                      <span>
                        Min {`₹${parseInt(priceRange.min)?.toLocaleString()}`}
                      </span>
                      <span>
                        Max {`₹${parseInt(priceRange.max)?.toLocaleString()}`}
                      </span>
                    </div>
                    <div className="py-2 px-0 d-flex justify-content-between">
                      <input
                        type="range"
                        max={priceRange?.max}
                        min={priceRange?.min}
                        value={selectedPrice}
                        className="w-100"
                        onChange={handlePriceChange}
                      />
                      <span className="px-2">{`₹${parseInt(
                        selectedPrice
                      )?.toLocaleString()}`}</span>
                    </div>
                  </div>

                  <div
                    className="m-0 p-0 row py-2 pb-2"
                    style={{ borderBottom: "2px solid #e9e9e9" }}
                  >
                    <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                      Available Seats
                    </p>
                    <div className="p-0 d-flex justify-content-between">
                      <span>
                        Min. {`${parseInt(seatsRange.min)?.toLocaleString()}`}
                      </span>
                      <span>
                        Max. {`${parseInt(seatsRange.max)?.toLocaleString()}`}
                      </span>
                    </div>

                    <div className="py-2 px-0 d-flex justify-content-between">
                      <input
                        type="range"
                        className="w-100"
                        min={seatsRange.min}
                        max={seatsRange.max}
                        value={selectedSeats}
                        onChange={handleSeatsChange}
                      />
                      <span className="px-2">{`${parseInt(
                        selectedSeats
                      )?.toLocaleString()}`}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-9">
                {filteredBusData?.length > 0 ? (
                  <div>
                    {filteredBusData?.map((ele) => {
                      const boardingPoints = ele.BoardingPoints.map(
                        (point, i) => (
                          <ul
                            style={{
                              listStyleType: "square",
                              fontSize: "14px",
                            }}
                          >
                            <li>
                              <p key={i} className="m-0">
                                {formatTime(new Date(point.CityPointTime))} -
                                {point.CityPointName}
                              </p>
                            </li>
                          </ul>
                        )
                      );

                      // Render dropping points
                      const droppingPoints = ele.DroppingPoints.map(
                        (point, i) => (
                          <ul
                            style={{
                              listStyleType: "square",
                              fontSize: "14px",
                            }}
                          >
                            <li>
                              <p key={i} className="m-0">
                                {formatTime(new Date(point.CityPointTime))} -
                                {point.CityPointName}
                              </p>
                            </li>
                          </ul>
                        )
                      );

                      const cancellationPolicies = ele.CancellationPolicies.map(
                        (policy, i) => (
                          <div key={i} className="card mb-3 p-2">
                            <p className="m-0 pb-1">
                              PolicyString - {policy.PolicyString}
                            </p>
                            <p className="m-0 pb-1">
                              CancellationCharge - {policy.CancellationCharge}
                            </p>
                            <p className="m-0 pb-1">
                              Time Before Dept - {policy.TimeBeforeDept}
                            </p>
                          </div>
                        )
                      );

                      const handleSelectSeats = () => {
                        navigate(
                          `/searchbus/${from}/${to}/${DepartureDate}/buslayout/${TraceID}`
                        );
                      };

                      return (
                        <div className="col-md-12 pe-0 ">
                          <div className="Section_Container">
                            <div className="Bus_Cards ">
                              <div
                                style={{
                                  borderBottom:
                                    "1px solid rgba(201, 228, 255, 1)",
                                }}
                              >
                                <div className="row d-flex justify-content-between px-3 pt-2">
                                  <div className="col-md-5">
                                    <p className="m-0 fw-bold">
                                      {ele?.TravelName}
                                    </p>
                                    <p
                                      className="text-secondary m-0"
                                      style={{ fontSize: "14px" }}
                                    >
                                      {ele?.BusType}
                                    </p>
                                  </div>
                                  <div className="col-md-4 d-flex justify-content-end">
                                    <div>
                                      <span className="text-decoration-line-through text-secondary ">{`₹${parseInt(
                                        handleOfferedPrice(
                                          ele?.Price?.BasePrice
                                        )
                                      ).toLocaleString()}`}</span>

                                      <span
                                        className="ps-2 fw-bold text-success"
                                        style={{ fontSize: "18px" }}
                                      >{`₹${parseInt(
                                        ele?.Price?.BasePrice
                                      ).toLocaleString()}`}</span>
                                      <p
                                        className="text-success m-0 p-0 text-end"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <span>Save </span>
                                        <span className="fw-bold">
                                          {`₹${parseInt(
                                            handleOffPrice(
                                              ele?.Price?.BasePrice
                                            )
                                          ).toLocaleString()}`}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="row d-flex justify-content-between px-3 py-2">
                                  <div
                                    className="col-md-2 d-flex align-items-center"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <div className="Bus_Cards_Star">
                                      <div className="d-flex align-items-center">
                                        <span className=" d-flex align-items-center">
                                          <FaStar className="text-white" />
                                        </span>
                                        <span className="ps-0"> 4.8</span>
                                      </div>
                                    </div>
                                    <p className="m-0 ps-2">116 Ratings</p>
                                  </div>

                                  <div
                                    className="col-md-6"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <div className="row ">
                                      <div className="col-md-4">
                                        <p className="text-center m-0">
                                          {/* <span className="fw-bold">22:00 </span>
                                                                        <span>11 APR</span> */}

                                          <span className="pe-1">
                                            {formatDate(ele?.ArrivalTime)
                                              .split("\n")
                                              .map((item, key) => {
                                                return (
                                                  <React.Fragment key={key}>
                                                    {item}
                                                    <br />
                                                  </React.Fragment>
                                                );
                                              })}
                                          </span>
                                        </p>
                                        <p className="text-secondary text-center p-0 m-0 ">
                                          DELHI
                                        </p>
                                      </div>
                                      <div className="col-md-4 bg-white">
                                        <div className="horizontal-line">
                                          <div className="line"></div>
                                          <div>
                                            <p className="m-0 text-secondary text-center">
                                              {/* <span>08hrs 30mins</span> */}

                                              <span>
                                                {calculateTotalDuration(
                                                  ele?.ArrivalTime,
                                                  ele?.DepartureTime
                                                )}
                                              </span>
                                            </p>
                                            <p
                                              className="m-0 text-secondary text-center"
                                              style={{ fontSize: "14px" }}
                                            >
                                              TRIP DURATION
                                            </p>
                                          </div>
                                          <div className="line"></div>
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <p className="text-center m-0">
                                          <span className="pe-1">
                                            {formatDate(ele?.DepartureTime)
                                              .split("\n")
                                              .map((item, key) => {
                                                return (
                                                  <React.Fragment key={key}>
                                                    {item}
                                                    <br />
                                                  </React.Fragment>
                                                );
                                              })}
                                          </span>
                                        </p>
                                        <p className="text-secondary text-center m-0">
                                          {to}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-3 d-flex justify-content-end align-items-center">
                                    <span className="text-success">
                                      {ele?.AvailableSeats} seats left
                                    </span>
                                    {/* <span className="px-1"> | </span>
                                                            <span> 15 window seats</span> */}
                                  </div>

                                  <div className="row">
                                    <div className="col-md-6 ">
                                      <p className="fw-bold m-0 p-2">
                                        Boarding Points:
                                      </p>
                                      <div className="card py-3">
                                        <span>{boardingPoints}</span>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <p className="fw-bold m-0 p-2">
                                        Dropping Points:
                                      </p>
                                      <div className="card py-3">
                                        <span>{droppingPoints}</span>
                                      </div>
                                    </div>

                                    <div className="mt-3">
                                      <p className="fw-bold">
                                        Cancellation Policies:
                                      </p>
                                      <div>{cancellationPolicies}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row d-flex justify-content-between px-3 py-2">
                                <div className="col-md-5 d-flex align-items-center">
                                  <span className="Bus_Cards_items fs-6">
                                    <a
                                      href="/privacypolicy"
                                      style={{
                                        textDecoration: "none",
                                        color: "black",
                                      }}
                                    >
                                      Policies
                                    </a>
                                  </span>
                                </div>
                                <div className="col-md-3 d-flex justify-content-end">
                                  <span
                                    className="px-2 activeOfferBtnClass"
                                    onClick={() => handleSelectSeats(ele)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Select Seats
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div
                    className="  d-flex align-items-center justify-content-center"
                    style={{ height: "400px" }}
                  >
                    <img
                      src={images.Flight_Loader_Transparent}
                      height={170}
                      width={170}
                    ></img>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        className="container-fluid mobile-view mt-2"
        style={{ position: "relative" }}
      >
        <style>
          {`
                        @media (max-width: 767px) {
                            p{
                                font-size:12px;
                            }                         
                        }
                    `}
        </style>
        <div className="d-flex align-items-center pt-2 mt-2">
          <div
            className="p-1"
            style={{
              fontSize: "13px",
              border: "1px solid #04a9fe",
              borderRadius: "5px",
            }}
          >
            <div className="d-flex align-items-center justify-content-center">
              <span>Showing Buses</span>
              <span className="ps-1" style={{ fontWeight: "600" }}>
                {from}
              </span>
              <span className="px-1">
                <i class="fa fa-arrow-right"></i>
              </span>
              <span className="ps-1" style={{ fontWeight: "600" }}>
                {to}
              </span>
            </div>
          </div>

          <div className="px-2" onClick={handleShowBusFilterModal}>
            <img src={images?.filter} alt="" height="20px" width="20px"></img>
          </div>

          <Modal show={showBusFilterModal} onHide={handleCloseBusFilterModal}>
            <Modal.Header
              closeButton
              style={{
                backgroundImage: "linear-gradient(45deg, #1556FC, #04A9FE)",
                borderBottom: "1.5px solid rgb(1,128,253)",
              }}
            >
              <Modal.Title>
                <div className="d-flex text-white" style={{ fontSize: "16px" }}>
                  <div>Filter by</div>
                  <div className="px-5" onClick={handleResetAll}>
                    <span className="pe-2">Resets all </span>
                    <img
                      src={images?.reset}
                      alt=""
                      width="20px"
                      height="20px"
                    />
                  </div>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                className="row d-flex justify-content-between  py-3 px-2"
                style={{ background: "#fff" }}
              >
                <div
                  className="m-0 p-0 row py-2"
                  style={{ borderBottom: "2px solid #e9e9e9" }}
                >
                  <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                    PRICE
                  </p>
                  <div className="p-0 d-flex justify-content-between">
                    <span>
                      Min {`₹${parseInt(priceRange.min)?.toLocaleString()}`}
                    </span>
                    <span>
                      Max {`₹${parseInt(priceRange.max)?.toLocaleString()}`}
                    </span>
                  </div>
                  <div className="py-2 px-0 d-flex justify-content-between">
                    <input
                      type="range"
                      max={priceRange?.max}
                      min={priceRange?.min}
                      value={selectedPrice}
                      className="w-100"
                      onChange={handlePriceChange}
                    />
                    <span className="px-2">{`₹${parseInt(
                      selectedPrice
                    )?.toLocaleString()}`}</span>
                  </div>
                </div>

                <div
                  className="m-0 p-0 row py-2 pb-2"
                  style={{ borderBottom: "2px solid #e9e9e9" }}
                >
                  <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                    Available Seats
                  </p>
                  <div className="p-0 d-flex justify-content-between">
                    <span>
                      Min {`₹${parseInt(seatsRange.min)?.toLocaleString()}`}
                    </span>
                    <span>
                      Max {`₹${parseInt(seatsRange.max)?.toLocaleString()}`}
                    </span>
                  </div>

                  <div className="py-2 px-0 d-flex justify-content-between">
                    <input
                      type="range"
                      className="w-100"
                      min={seatsRange.min}
                      max={seatsRange.max}
                      value={selectedSeats}
                      onChange={handleSeatsChange}
                    />
                    <span className="px-2">{`₹${parseInt(
                      selectedSeats
                    )?.toLocaleString()}`}</span>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseBusFilterModal}>
                Apply
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <div className="row d-flex justify-content-between">
          <div className="col-12 pt-0 pb-5">
            {loading ? (
              <div
                className="  d-flex align-items-center justify-content-center"
                style={{ height: "400px" }}
              >
                <img
                  src={images.Flight_Loader_Transparent}
                  height={170}
                  width={170}
                ></img>
              </div>
            ) : (
              <div>
                {filteredBusData?.map((ele) => {
                  const boardingPoints = ele.BoardingPoints.map((point, i) => (
                    <ul
                      style={{
                        listStyleType: "square",
                        fontSize: "12px",
                        padding: "2px 12px",
                        margin: "0px",
                      }}
                    >
                      <li>
                        <p key={i} className="m-0 p-0">
                          {formatTime(new Date(point.CityPointTime))} -
                          {point.CityPointName}
                        </p>
                      </li>
                    </ul>
                  ));

                  // Render dropping points
                  const droppingPoints = ele.DroppingPoints.map((point, i) => (
                    <ul
                      style={{
                        listStyleType: "square",
                        fontSize: "12px",
                        padding: "2px 12px",
                        margin: "0px",
                      }}
                    >
                      <li>
                        <p key={i} className="m-0 p-0">
                          {formatTime(new Date(point.CityPointTime))} -
                          {point.CityPointName}
                        </p>
                      </li>
                    </ul>
                  ));

                  const cancellationPolicies = ele.CancellationPolicies.map(
                    (policy, i) => (
                      <div
                        key={i}
                        className="mb-3 p-2 card"
                        style={{ width: "100%" }}
                      >
                        <p className="m-0 pb-1">
                          PolicyString - {policy.PolicyString}
                        </p>
                        <p className="m-0 pb-1">
                          CancellationCharge - {policy.CancellationCharge}
                        </p>
                        <p className="m-0 pb-1">
                          Time Before Dept - {policy.TimeBeforeDept}
                        </p>
                      </div>
                    )
                  );

                  const handleSelectSeats = (ele) => {
                    navigate(`/searchbus/buslayout/${TraceID}`);
                  };

                  return (
                    <div className="col-md-12 pe-0 ">
                      <div className="Section_Container ">
                        <div className="Bus_Cards ">
                          <div
                            style={{
                              borderBottom: "1px solid rgba(201, 228, 255, 1)",
                            }}
                          >
                            <div className="row d-flex justify-content-between px-3 pt-2">
                              <div className="col-6">
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {ele?.TravelName}
                                </p>
                                <p
                                  className="text-secondary m-0"
                                  style={{ fontSize: "12px" }}
                                >
                                  {ele?.BusType}
                                </p>
                              </div>
                              <div className="col-6 d-flex justify-content-end">
                                <div>
                                  <span
                                    className="text-decoration-line-through text-secondary"
                                    style={{ fontSize: "12px" }}
                                  >{`₹${parseInt(
                                    handleOfferedPrice(ele?.Price?.BasePrice)
                                  ).toLocaleString()}`}</span>

                                  <span
                                    className="ps-2 fw-bold text-success"
                                    style={{ fontSize: "14px" }}
                                  >{`₹${parseInt(
                                    ele?.Price?.BasePrice
                                  ).toLocaleString()}`}</span>
                                  <p
                                    className="text-success m-0 p-0 text-end"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <span>Save </span>
                                    <span className="fw-bold">
                                      {`₹${parseInt(
                                        handleOffPrice(ele?.Price?.BasePrice)
                                      ).toLocaleString()}`}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="row d-flex justify-content-between px-3 py-2">
                              <div
                                className="col-6 d-flex align-items-center"
                                style={{ fontSize: "12px" }}
                              >
                                <div className="Bus_Cards_Star">
                                  <div className="d-flex align-items-center">
                                    <span className=" d-flex align-items-center">
                                      <FaStar className="text-white" />
                                    </span>
                                    <span className="ps-0"> 4.8</span>
                                  </div>
                                </div>
                                <p className="m-0 ps-2">116 Ratings</p>
                              </div>

                              <div className="col-6 d-flex justify-content-end align-items-center">
                                <span
                                  className="text-success"
                                  style={{ fontSize: "12px" }}
                                >
                                  {ele?.AvailableSeats} seats left
                                </span>
                              </div>

                              <div
                                className="col-12 pt-3"
                                style={{ fontSize: "14px" }}
                              >
                                <div className="row ">
                                  <div className="col-4">
                                    <p className="text-center m-0">
                                      <span className="pe-1">
                                        {formatDate(ele?.ArrivalTime)
                                          .split("\n")
                                          .map((item, key) => {
                                            return (
                                              <React.Fragment key={key}>
                                                {item}
                                                <br />
                                              </React.Fragment>
                                            );
                                          })}
                                      </span>
                                    </p>
                                    <p className="text-secondary text-center p-0 m-0 ">
                                      DELHI
                                    </p>
                                  </div>
                                  <div className="col-4 bg-white">
                                    <div className="horizontal-line">
                                      <div className="line"></div>
                                      <div>
                                        <p className="m-0 text-secondary text-center">
                                          {/* <span>08hrs 30mins</span> */}

                                          <span>
                                            {calculateTotalDuration(
                                              ele?.DepartureTime,
                                              ele?.ArrivalTime
                                            )}
                                          </span>
                                        </p>
                                        <p
                                          className="m-0 text-secondary text-center"
                                          style={{ fontSize: "12px" }}
                                        >
                                          TRIP DURATION
                                        </p>
                                      </div>
                                      <div className="line"></div>
                                    </div>
                                  </div>

                                  <div className="col-4">
                                    <p className="text-center m-0">
                                      <span className="pe-1">
                                        {formatDate(ele?.DepartureTime)
                                          .split("\n")
                                          .map((item, key) => {
                                            return (
                                              <React.Fragment key={key}>
                                                {item}
                                                <br />
                                              </React.Fragment>
                                            );
                                          })}
                                      </span>
                                    </p>
                                    <p className="text-secondary text-center m-0">
                                      {to}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-6 pt-1">
                                  <p
                                    className="m-0"
                                    style={{ fontWeight: "600" }}
                                  >
                                    Boarding Points:
                                  </p>
                                  <div className="px-0 py-1">
                                    <span>{boardingPoints}</span>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <p className="fw-bold m-0 pt-1">
                                    Dropping Points:
                                  </p>
                                  <div className="">
                                    <span>{droppingPoints}</span>
                                  </div>
                                </div>

                                <div className="mt-3">
                                  <p className="fw-bold">
                                    Cancellation Policies:
                                  </p>
                                  <div>{cancellationPolicies}</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row d-flex justify-content-center px-3 py-2">
                            <div className="d-flex justify-content-center">
                              <span
                                className="px-2 activeOfferBtnClass"
                                onClick={() => handleSelectSeats(ele)}
                                style={{ cursor: "pointer" }}
                              >
                                Select Seats
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
