import React from "react";
import images from "../../assets/Images";

export const ETOApp = () => {
    return (
        <>
            <div className="desktop-view container-fluid Section_Container">
                <div className="row d-flex align-items-center justify-content-between">
                    <div className="col-md-6 ">
                        <img src={images.ETO_App} alt="" width="100%" height="460px"></img>
                    </div>
                    <div className="col-md-5 d-flex justify-content-end">
                        <div className="row">
                            <div className="col-md-12 ">
                                <h1 className="fw-bold m-0">Download ETO App</h1>
                                <p className="m-0" style={{ fontSize: "25px" }}>
                                    Get upto 500 OFF* on Flights
                                </p>
                                <p className="m-0" style={{ fontSize: "25px" }}>
                                    Flat 10% OFF* on Hotel Tickets
                                </p>
                            </div>

                            <div className="col-md-12 d-flex mt-2">
                                <div className="row">
                                    <div className="col-md-4" style={{ fontSize: "14px" }}>
                                        <span>Use Code </span>
                                        <span className="fw-bold"> ETOFLIGHTS </span>
                                        <span>and get upto RS 1200 off on your first Domestic Flight Booking</span>
                                    </div>
                                    <div className="col-md-3 p-0">
                                        <img src={images.appstore} alt="" width="140px" height="40px"></img>
                                        <img
                                            className="pt-1"
                                            src={images.playstore}
                                            alt=""
                                            width="140px"
                                            height="40px"
                                        ></img>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 p-0">
                                <ul>
                                    <li>Get exclusive offers and prices</li>
                                    <li>One click bookings</li>
                                    <li>Trip notifications</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
