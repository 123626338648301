import React, { useState } from "react";
import { MdEmail } from "react-icons/md";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Toaster } from "react-hot-toast";

export const Newsletter = () => {
    const [subscriptionMsg, setSubscriptionMsg] = useState("");

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
    });

    const handleSubscription = async (values, actions) => {
        // Handle subscription submission here
        setSubscriptionMsg(`Thank you for subscribing, ${values.email}!`);
        actions.resetForm();
    };
    return (
        <div className="container" style={{ minHeight: "100vh" }}>
            <div className="row d-flex justify-content-center p-4">
                <div className="col-md-5 Section_Container">
                    <Formik
                        initialValues={{
                            email: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubscription}
                    >
                        {({ isSubmitting, values, handleChange }) => (
                            <Form>
                                <div>
                                    <div className="d-flex justify-content-center">
                                        <h5 className="text-center pt-2 m-0">Subscribe to Our Newsletter</h5>
                                    </div>

                                    <div className="mt-3">
                                        <p className="m-0" style={{ fontSize: "14px", fontWeight: "500" }}>
                                            Email
                                        </p>
                                        <div className="input_field d-flex align-items-center ">
                                            <span className="pe-2">
                                                <MdEmail />
                                            </span>
                                            <Field
                                                type="email"
                                                name="email"
                                                placeholder="Enter your email"
                                                className="w-100"
                                            />
                                        </div>
                                        <ErrorMessage
                                            name="email"
                                            component="div"
                                            className="error text-danger m-0 py-2"
                                        />
                                    </div>

                                    <div className="d-flex justify-content-center">
                                        <button type="submit" className="login_btn mt-2 w-50" disabled={isSubmitting}>
                                            Subscribe
                                        </button>
                                    </div>

                                    {subscriptionMsg && (
                                        <div className="text-center mt-3">
                                            <p className="text-success">{subscriptionMsg}</p>
                                        </div>
                                    )}

                                    <Toaster position="top-right" reverseOrder={false} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}
