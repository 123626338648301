import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCarFrom } from "../../../api/CabAPI";
import { Col } from "react-bootstrap";
import { LiaBusAltSolid } from "react-icons/lia";

export const BusForm = () => {
  const [searchTermFrom, setSearchTermFrom] = useState("New Delhi");
  const [isOpenFrom, setIsOpenFrom] = useState(false);
  const [searchTermTo, setSearchTermTo] = useState("Jaipur");
  const [isOpenTo, setIsOpenTo] = useState(false);
  const [checkInDate, setCheckInDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [filteredCarToData, setFilteredCarToData] = useState([]);
  const [filteredCarData, setFilteredCarData] = useState([]);
  const [selectedOptionFrom, setSelectedOptionFrom] = useState(null);
  const navigate = useNavigate();

  const handleInputFromChange = (e) => {
    setSearchTermFrom(e.target.value);
    setIsOpenFrom(true);
  };

  const handleInputToChange = (e) => {
    setSearchTermTo(e.target.value);
    setIsOpenTo(true);
  };

  const handleOptionClickFrom = (filteredCabs) => {
    setSearchTermFrom(filteredCabs.name);
    setSelectedOptionFrom(filteredCabs.name);
    setIsOpenFrom(false);
  };

  const handleOptionClickTo = (filteredCabs) => {
    setSearchTermTo(filteredCabs.name);
    setIsOpenTo(false);
  };

  const handleReverseLocations = (event) => {
    event.preventDefault();
    if (searchTermFrom && searchTermTo) {
      setSearchTermFrom(searchTermTo);
      setSearchTermTo(searchTermFrom);
    } else {
      window.alert("Please enter the location");
    }
  };

  const handleBusSearchNavigate = () => {
    const destinationTo = "Jaipur";
    const destinationFrom = selectedOptionFrom || "New Delhi";

    if (destinationFrom && destinationTo) {
      navigate(`/searchbus/${destinationFrom}/${destinationTo}/${checkInDate}`);
    } else {
      window.alert("Please Enter Valid Details");
    }
  };

  useEffect(() => {
    const getFilteredCars = async () => {
      try {
        const res = await getCarFrom({ city: searchTermFrom });

        if (res.status === "success") {
          setFilteredCarData(res.data);
        } else {
          console.log("Data not fetched properly");
        }
      } catch (error) {
        console.log("Error fetching 'from' data: ", error);
      }
    };

    if (searchTermFrom !== "") {
      getFilteredCars();
    } else {
      setFilteredCarData([]);
    }
  }, [searchTermFrom]);

  useEffect(() => {
    const getFilteredCarsTo = async () => {
      try {
        const res = await getCarFrom({ city: searchTermTo });
        if (res.status === "success") {
          setFilteredCarToData(res.data);
        } else {
          console.log("Data not fetched properly");
        }
      } catch (error) {
        console.log("Error fetching 'to' data: ", error);
      }
    };

    if (searchTermTo !== "") {
      getFilteredCarsTo();
    } else {
      setFilteredCarToData([]);
    }
  }, [searchTermTo]);

  return (
    <>
      <div className="container desktop-view" style={{ minHeight: "200px" }}>
        <div
          className="row p-3 d-flex align-items-center"
          style={{
            borderRadius: "7px",
            border: "2px solid #01a9ff",
            backgroundColor: "white",
            height: "100px",
          }}
        >
          <div className="col-md-4">
            <p className="form_fields_Heading">BUS WHERE FROM</p>
            <input
              type="text"
              className="w-100"
              value={searchTermFrom}
              onChange={handleInputFromChange}
              placeholder="Search..."
              style={{ fontSize: "25px", fontWeight: "600" }}
            />
          </div>

          <div className="col-md-1 d-flex justify-content-center align-items-end">
            <button className="bg-white" onClick={handleReverseLocations}>
              <img
                src="../images/icons/reverse.png"
                alt=""
                height="100%"
                width="37px"
              />
            </button>
          </div>

          <div
            className="col-md-4"
            style={{ borderRight: "2px solid #01a9ff" }}
          >
            <p className="form_fields_Heading">BUS WHERE TO</p>
            <input
              type="text"
              className="w-100"
              value={searchTermTo}
              onChange={handleInputToChange}
              placeholder="Search..."
              style={{ fontSize: "25px", fontWeight: "600" }}
            />
          </div>

          <div className="col-md-3">
            <p className="form_fields_Heading">PICK-UP DATE</p>
            <input
              type="date"
              value={checkInDate}
              min={new Date().toISOString().split("T")[0]}
              onChange={(e) => setCheckInDate(e.target.value)}
              placeholder="Select a date"
              className="bg-white"
            />
          </div>
        </div>

        <div className="row">
          <div
            className="col-md-4 p-0"
            style={{ position: "absolute", zIndex: 2 }}
          >
            {isOpenFrom && (
              <div
                style={{
                  maxHeight: "150px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {filteredCarData.length === 0 ? (
                  <div className="bg-white p-2 cursor-pointer">
                    No city found
                  </div>
                ) : (
                  filteredCarData.map((ele, index) => (
                    <div
                      key={index}
                      onClick={() => handleOptionClickFrom(ele)}
                      className="bg-white"
                    >
                      <div
                        className="p-2 cursor-pointer"
                        style={{ borderBottom: "1px solid #f1f1f1" }}
                      >
                        <span>
                          <LiaBusAltSolid className="text-info" />
                        </span>
                        <span className="px-2">{ele.name}, </span>
                        <span>{ele.state}</span>
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        </div>

        <div className="row d-flex justify-content-center">
          <Col
            className="col-md-4 p-0"
            style={{ position: "absolute", zIndex: 2 }}
          >
            {isOpenTo && (
              <div
                style={{
                  maxHeight: "150px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {filteredCarToData.length === 0 ? (
                  <div className="bg-white p-2 cursor-pointer">
                    No city found
                  </div>
                ) : (
                  filteredCarToData.map((ele, index) => (
                    <div
                      key={index}
                      onClick={() => handleOptionClickTo(ele)}
                      className="bg-white"
                    >
                      <div
                        className="p-2 cursor-pointer"
                        style={{ borderBottom: "1px solid #f1f1f1" }}
                      >
                        <span>
                          <LiaBusAltSolid className="text-info" />
                        </span>
                        <span className="px-2">{ele.name}, </span>
                        <span>{ele.state}</span>
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </Col>
        </div>

        <div className="row d-flex justify-content-center">
          <div className="col-2 p-0 d-flex justify-content-center">
            <button
              className="searchButton py-2 mt-3"
              onClick={handleBusSearchNavigate}
            >
              Explore Buses
            </button>
          </div>
        </div>
      </div>

      <div className="container mobile-view">
        <div
          className="row pt-2 d-flex align-items-center"
          style={{
            borderRadius: "7px",
            backgroundColor: "white",
            height: "auto",
          }}
        >
          <div className="col-12 hotelformBorder">
            <div
              className="row d-flex justify-content-between align-items-center pt-2"
              style={{ fontSize: "14px" }}
            >
              <div className="col-4">
                <p className="form_fields_Heading m-0">BUS WHERE FROM</p>
              </div>

              <div className="col-4">
                <p className="form_fields_Heading m-0">BUS WHERE TO</p>
              </div>
            </div>

            <div className="row d-flex justify-content-between align-items-center py-1">
              <div className="col-4">
                <input
                  type="text"
                  className="w-100"
                  value={searchTermFrom}
                  onChange={handleInputFromChange}
                  placeholder="Search..."
                  style={{ fontSize: "15px", fontWeight: "600" }}
                />
              </div>

              <div className="col-4 d-flex justify-content-center align-items-end ">
                <button className="bg-white" onClick={handleReverseLocations}>
                  {/* <img
                    src={images.reverse}
                    alt=""
                    height="100%"
                    width="37px"
                  ></img> */}
                </button>
              </div>

              <div className="col-4">
                <input
                  type="text"
                  className="w-100"
                  value={searchTermTo}
                  onChange={handleInputToChange}
                  placeholder="Search..."
                  style={{ fontSize: "15px", fontWeight: "600" }}
                />
              </div>
            </div>
          </div>

          <div
            className="col-11 p-0"
            style={{
              position: "absolute",
              zIndex: 2,
              top: "40%",
            }}
          >
            {isOpenTo && (
              <div
                style={{
                  maxHeight: "150px",
                  backgroundColor: "lightpink",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  border: "1px solid lightgray",
                }}
              >
                {filteredCarToData.length === 0 ? (
                  <div
                    className=" p-2 cursor-pointer"
                    style={{
                      border: "1px solid lightgray",
                      backgroundColor: "#f1f1f1",
                    }}
                  >
                    No city found
                  </div>
                ) : (
                  <div>
                    {filteredCarToData.map((ele, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => handleOptionClickTo(ele)}
                          style={{
                            border: "1px solid lightgray",
                            backgroundColor: "#f1f1f1",
                          }}
                        >
                          <div
                            className="p-2 cursor-pointer"
                            style={{ borderBottom: "1px solid #f1f1f1" }}
                          >
                            <span>
                              <LiaBusAltSolid className="text-info" />
                            </span>
                            <span className="px-2">
                              {ele.caoncitlst_city_name} ,
                            </span>
                            <span>{ele.caoncitlst_state}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>

          <div
            className="col-10 p-0"
            style={{
              position: "absolute",
              zIndex: 2,
              top: "40%",
            }}
          >
            {isOpenFrom && (
              <div
                style={{
                  maxHeight: "150px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {filteredCarData.length === 0 ? (
                  <div
                    className="p-2 cursor-pointer"
                    style={{
                      border: "1px solid lightgray",
                      backgroundColor: "#f1f1f1",
                    }}
                  >
                    No city found
                  </div>
                ) : (
                  <div>
                    {filteredCarData.map((ele, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => handleOptionClickFrom(ele)}
                          style={{
                            border: "1px solid lightgray",
                            backgroundColor: "#f1f1f1",
                          }}
                        >
                          <div
                            className="p-2 cursor-pointer"
                            style={{ borderBottom: "1px solid #f1f1f1" }}
                          >
                            <span>
                              <LiaBusAltSolid className="text-info" />
                            </span>
                            <span className="px-2">
                              {ele.caoncitlst_city_name} ,
                            </span>
                            <span>{ele.caoncitlst_state}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="col-12 hotelformBorder py-2">
            <p className="form_fields_Heading m-0 py-1">PICKUP DATE</p>
            {/* <input
              type="date"
              value={checkInDate}
              min={formatDate(today)}
              onChange={handleCheckInDateChange}
              placeholder="Select a date"
              className="bg-white"
            /> */}
          </div>

          <div className="col-12 p-0 d-flex justify-content-center">
            <button
              className="searchButton py-2"
              onClick={handleBusSearchNavigate}
              style={{ width: "200px" }}
            >
              Explore Buses
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
