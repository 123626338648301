import React from "react";
import { TfiFaceSad } from "react-icons/tfi";
export const NotFound = () => {
    return (
        <div className="container">
            <div className="row d-flex justify-content-center py-4 fs-1">
                <div className="col-md-5 Section_Container">
                    <div className="text-center py-3">
                        <TfiFaceSad className="text-danger" style={{ fontSize: "100px" }} />
                    </div>
                    <h1 className="text-center ">404</h1>
                    <p className="text-center m-0">Page not founds</p>
                    <p className="text-center"><a href="/" className="fs-5"> Back to the Home</a></p>
                </div>
            </div>
        </div>
    );
};
