import { Base_Url } from "../Url/Url";

export const TopTouristAttractionAPIData = async () => {
  const res = await fetch(
    `${Base_Url}/touristdestination/getalltouristdestination`,
    {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    }
  );
  return await res.json();
};
