import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import images from "../assets/Images";

export const Feedbackform = () => {
  const navigate = useNavigate();
  const [feedbackMsg, setFeedbackMsg] = useState("");

  const handleTermsAndConditions = () => {
    navigate("/termsandconditions");
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    feedback: Yup.string().required("Feedback is required"),
    termsAndConditions: Yup.boolean().oneOf(
      [true],
      "Please accept the terms and conditions"
    ),
  });

  return (
    <div className="container">
      <div className="row d-flex justify-content-center p-4">
        <div className="col-md-6 Section_Container">
          <Formik
            initialValues={{
              email: "",
              feedback: "",
              termsAndConditions: false,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
              // Handle form submission here
              setFeedbackMsg("Feedback submitted successfully!");
              actions.resetForm();
            }}
          >
            {({ isSubmitting, values, handleChange }) => (
              <Form>
                <div>
                  <div className="d-flex justify-content-center">
                    <img
                      src={images?.logo_mark}
                      alt="logo"
                      width="100px"
                      height="80px"
                    />
                  </div>

                  <div>
                    <h5 className="text-center pt-2 m-0">Feedback Form</h5>
                    <p className="m-0 text-center" style={{ fontSize: "13px" }}>
                      We appreciate your feedback. Please share your thoughts
                      below.
                    </p>
                  </div>

                  <div className="mt-3">
                    <p
                      className="m-0"
                      style={{ fontSize: "14px", fontWeight: "500" }}
                    >
                      Email
                    </p>
                    <div className="input_field d-flex align-items-center">
                      <Field
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        className="w-100"
                      />
                    </div>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error text-danger m-0 py-2"
                    />
                  </div>

                  <div className="mt-3">
                    <p
                      className="m-0"
                      style={{ fontSize: "14px", fontWeight: "500" }}
                    >
                      Feedback
                    </p>
                    <div className="input_field d-flex align-items-center ">
                      <Field
                        as="textarea"
                        name="feedback"
                        placeholder="Enter your feedback"
                        className="w-100"
                        rows="2"
                      />
                    </div>
                    <ErrorMessage
                      name="feedback"
                      component="div"
                      className="error text-danger m-0 py-2"
                    />
                  </div>

                  <div className="pt-3">
                    <label>
                      <input
                        type="checkbox"
                        name="termsAndConditions"
                        checked={values.termsAndConditions}
                        onChange={handleChange}
                      />
                      <span className="ps-2">
                        <span>I have read and agree to the </span>
                        <span
                          onClick={handleTermsAndConditions}
                          className="cursor-pointer text-primary"
                          style={{ cursor: "pointer" }}
                        >
                          terms and conditions
                        </span>
                      </span>
                    </label>
                    <ErrorMessage
                      name="termsAndConditions"
                      component="div"
                      className="error text-danger pt-2"
                    />
                  </div>

                  <div className="d-flex justify-content-center">
                    <button
                      type="submit"
                      className="login_btn mt-2 w-75"
                      disabled={isSubmitting}
                    >
                      Submit Feedback
                    </button>
                  </div>

                  {feedbackMsg && (
                    <div className="text-center mt-3">
                      <p className="text-success">{feedbackMsg}</p>
                    </div>
                  )}

                  <Toaster position="top-right" reverseOrder={false} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
