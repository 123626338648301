import React from "react";
import { Offers } from "../../data/FooterItems";
import { Link, NavLink, useNavigate } from "react-router-dom";

export const OffersNew = () => {
  const navigate = useNavigate();
  return (
    <div>
      <p className="fw-bold">A guide to connect with the world.</p>
      <div className="row"></div>
    </div>
  );
};
