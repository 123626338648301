import React, { useEffect, useState } from "react";
import { Header } from "../component/layout/Header";
import { Outlet, useLocation } from "react-router-dom";
import { Footer } from "../component/layout/Footer";
import images from "../assets/Images";
import { Header2 } from "../component/layout/Header2";
import { HeaderRinku } from "../component/layout/HeaderRinku";
import { TopHeader } from "../component/layout/TopHeader";
import { HomeButtons } from "../component/layout/HomeButtons";

export const Home = () => {
    return (
        <>

            {/* <div
                style={{
                    position: "sticky",
                    top: "0px",
                    backgroundColor: "white",
                    zIndex: "10",
                    width: "100%",
                    boxShadow: "1px 1px 10px rgba(20, 6, 50, 0.22)",
                    borderTop: "2px solid #01a9ff"
                }}
            >
                <Header />
            </div> */}

            <ScrollToTopButton />

            <div>
                <Outlet />
            </div>

            <div className="bg-dark">
                <Footer />
            </div>
        </>
    );
};

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [pathname]);

    return null;
}

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const [Header2Visible, setHeader2Visible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            setIsVisible(scrollTop > 0); // Show button only if scrollTop is greater than 0
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        const handleScrollheader2 = () => {
            const scrollTopHeader2 = window.scrollY;
            if (scrollTopHeader2 >= 150) {
                setHeader2Visible(true);
            } else {
                setHeader2Visible(false);
            }
        };

        window.addEventListener("scroll", handleScrollheader2);
        return () => {
            window.removeEventListener("scroll", handleScrollheader2);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <>
            <div style={{ position: "fixed", bottom: "9%", right: "3%", zIndex: 5 }}>
                {isVisible && (
                    <div
                        className={`scroll-to-top`}
                        onClick={scrollToTop}
                        style={{
                            transition: "opacity 0.9s ease-in-out",
                            background: "rgba(255, 255, 255, 0.7)",
                            borderRadius: "50%",
                            cursor: "pointer"
                        }}
                    >
                        <img
                            src={images?.ScrollToTop1}
                            alt=""
                            width="37px"
                            height="35px"
                            className="bg-none"
                        />
                    </div>
                )}
            </div>

            
            <div
                style={{
                    position: "sticky",
                    top: "0px",
                    backgroundColor: "white",
                    zIndex: "10",
                    width: "100%",
                    boxShadow: "1px 1px 10px rgba(20, 6, 50, 0.22)",
                    transition: "all 1s ease-in-out"
                }}>

                <div>
                    <TopHeader />
                    <HeaderRinku />
                    <div className="mobile-view">
                        <HomeButtons />
                    </div>
                </div>
                {/* {Header2Visible ? (
                    <Header2 />
                ) : (<HeaderRinku />)
                } */}
            </div>
        </>
    );
}
