import React, { useEffect, useState } from "react";
import { Sidenavbar } from "./Sidenavbar";
import "../../style/HeaderRinku.css";
import images from "../../assets/Images";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { getUserProfile } from "../../api/ProfileAPI";

// Manual JWT parser function
const parseJwt = (token) => {
  try {
    const base64Url = token.split(".")[1]; // Get the payload part
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Error parsing JWT:", error);
    return null;
  }
};

export const HeaderRinku = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const userTokenFromCookies = Cookies.get("userToken");

  const GetUserInformationApi = async () => {
    if (userTokenFromCookies) {
      const parsedUserData = parseJwt(userTokenFromCookies); // Using manual JWT parser

      if (parsedUserData) {
        try {
          const res = await getUserProfile(userTokenFromCookies); // Pass the token to API

          if (res?.status === "success") {
            console.log("API Response: ", res);
            const hasRefreshed = localStorage.getItem("headerHasRefreshed");
            if (!hasRefreshed && !res?.data?.name) {
              localStorage.setItem("headerHasRefreshed", "true");
            }
            setUserData(res.data);
          } else {
            console.error("Failed to fetch user profile:", res);
          }
        } catch (error) {
          console.error("Error fetching user profile:", error);
        }
      }
    }
  };

  useEffect(() => {
    GetUserInformationApi();
  }, []);

  const handleLogout = () => {
    navigate("/");
    Cookies.remove("userInformation");
    Cookies.remove("userToken");
    localStorage.removeItem("headerHasRefreshed");
    setUserData(null);
  };

  const [searchbaropen, setsearchbaropen] = useState(false);
  const handleSearchbar = () => {
    setsearchbaropen(true);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light sticky-top">
        <div className="container" style={{ border: "0" }}>
          <a className="navbar-brand" href="/">
            <img
              src={images?.logo}
              height="10px"
              width="100%"
              className="img-fluid"
              alt=""
            />
          </a>

          <div className="phone_view_icons_div">
            <a
              href="#sidenavbar"
              className="offcanvas_btn"
              data-bs-toggle="offcanvas"
              aria-controls="offcanvasScrolling"
            >
              <img
                src={images?.hamburger}
                alt=""
                height="15px"
                width="15px"
              ></img>
            </a>
          </div>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto d-flex align-items-center">
              <div className="px-1" style={{ cursor: "pointer" }}>
                {userData ? (
                  <div className="d-flex">
                    <Link
                      to="/myprofile"
                      className="text-black"
                      style={{ textDecoration: "none" }}
                    >
                      <div className="h-login d-flex align-items-center px-2 cursor-pointer">
                        <FaRegUser />

                        {userData?.name ? (
                          <span className="single-line-text ps-1">
                            {userData?.name?.length > 10
                              ? userData?.name?.slice(0, 10) + "..."
                              : userData?.name}
                          </span>
                        ) : (
                          <span>{userData?.mobile}</span>
                        )}
                      </div>
                    </Link>
                    <div
                      className="d-flex ps-3 align-items-center cursor-pointer"
                      onClick={handleLogout}
                    >
                      <span>
                        <MdLogout />
                      </span>
                      <span> Logout</span>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex">
                    <Link
                      to="/login"
                      className="text-black me-2"
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        className="px-4 py-2 d-flex align-items-center loginSignup_div"
                        style={{ fontSize: "13px" }}
                      >
                        Login or Signup
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            </ul>
          </div>
        </div>
      </nav>

      <Sidenavbar />
    </>
  );
};
