import React from "react";
import { OurOfferingsItems } from "../../data/FooterItems";
import { useNavigate } from "react-router-dom";

export const OurOfferings = () => {

    const navigate = useNavigate();

    const handleClick = (ele) => {
        navigate(`/${ele?.title}`);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    return (
        <div className="">
            <p className="fw-bold">Make your travel easy with a wide range of products and services.</p>
            <div className="row">
                {OurOfferingsItems.map((ele) => {
                    return (
                        <div
                            key={ele.id}
                            onClick={() => { handleClick(ele) }}
                            className="col-md-3 col-6 pb-2 pointerUnderLine"
                        >
                            <p className="m-0">{ele.title}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
