import React, { useEffect, useState } from "react";
import { MdPassword } from "react-icons/md";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { OtpVerify } from "../../api/AuthAPI";
import Cookies from "js-cookie";
import { Toaster, toast } from "react-hot-toast";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import images from "../../assets/Images";

export const OtpVerification = () => {
  const navigate = useNavigate();
  const [OtpVerifyApiMsg, setOtpVerifyApiMsg] = useState("");
  const [givenMobileNumber, setGivenMobileNumber] = useState("");

  const handleTermsandConditions = () => {
    navigate("/termsandconditions");
  };

  const handlePrivacyPolicy = () => {
    navigate("/privacypolicy");
  };

  const validationSchemaOtpVerification = Yup.object().shape({
    otp: Yup.string()
      .required("Please enter OTP")
      .matches(/^[0-9]{5}$/, "Otp number must be 5 digits"),
    termsAndConditions: Yup.boolean().oneOf(
      [true],
      "Please accept the terms and conditions"
    ),
  });

  const userDataFromCookies = Cookies.get("userMobile");
  const parsedUserData = userDataFromCookies
    ? JSON.parse(userDataFromCookies)
    : null;

  useEffect(() => {
    setGivenMobileNumber(parsedUserData);
  }, [parsedUserData]);

  const LoginSliderSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  const LoginSlider = [
    { id: 1, imgPath: images.Slider1, title: "Slider 1" },
    { id: 2, imgPath: images.Slider2, title: "Slider 2" },
    { id: 3, imgPath: images.Slider3, title: "Slider 3" },
  ];

  return (
    <div className="container">
      <div className="row d-flex justify-content-center p-4">
        <div className="col-md-3 m-0 p-0 desktop-view">
          <Slider {...LoginSliderSettings}>
            {LoginSlider?.map((ele) => (
              <div key={ele.id} className="col-md-3 col-12">
                <div className="">
                  <img
                    src={ele.imgPath}
                    alt=""
                    height="425px"
                    className="w-100 p-0"
                  ></img>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        <div className="col-md-5 Section_Container">
          <Formik
            initialValues={{
              otp: "",
              termsAndConditions: false,
            }}
            validationSchema={validationSchemaOtpVerification}
            onSubmit={async (values, actions) => {
              try {
                const response = await OtpVerify({
                  mobile: givenMobileNumber,
                  otpmanual: values.otp,
                });

                console.log("Response from OTP verification:", response);

                if (response?.status === "success") {
                  if (response.data && response.token) {
                    // Log the data before saving
                    console.log("User Data:", response.data);
                    console.log("Token:", response.token);

                    // Set cookies
                    Cookies.set(
                      "userInformation",
                      JSON.stringify(response.data),
                      {
                        expires: 30,
                        path: "/",
                      }
                    );
                    Cookies.set("userToken", response.token, {
                      // No need to stringify the token
                      expires: 30,
                      path: "/",
                    });

                    toast.success("Redirecting to profile...", {
                      duration: 3000,
                    });
                    setTimeout(() => {
                      window.location.href = "https://exploretriponline.com";
                    }, 2000);
                  } else {
                    toast.error("No user information or token received!", {
                      duration: 3000,
                    });
                  }
                } else {
                  toast.error(response.message || "Enter Correct OTP!", {
                    duration: 3000,
                  });
                  actions.resetForm();
                }
              } catch (error) {
                console.error("Error:", error);
                toast.error("An error occurred. Please try again.", {
                  duration: 3000,
                });
              }
              actions.setSubmitting(false);
            }}
          >
            {({ isSubmitting, values, handleChange }) => (
              <Form>
                <div>
                  <div className="d-flex justify-content-center">
                    <img
                      src={images?.logo_mark}
                      alt="logo"
                      width="100px"
                      height="80px"
                    />
                  </div>

                  <div>
                    <h5 className="text-center pt-2 m-0">
                      Welcome to the Explore Trip Online
                    </h5>
                    <p className="m-0 text-center" style={{ fontSize: "13px" }}>
                      Please Enter the received Verification Code below.
                    </p>
                  </div>

                  <div className="mt-3" style={{ height: "85px" }}>
                    <p
                      className="m-0 pb-2"
                      style={{ fontSize: "14px", fontWeight: "500" }}
                    >
                      OTP Code
                    </p>
                    <div className="input_field d-flex align-items-center ">
                      <span className="pe-2">
                        <MdPassword />
                      </span>
                      <Field
                        type="text"
                        name="otp"
                        placeholder="Enter OTP Number"
                        maxLength={5}
                        className="w-100"
                      />
                    </div>
                    <div>
                      <ErrorMessage
                        name="otp"
                        component="div"
                        className="error text-danger m-0 "
                      />
                    </div>
                  </div>

                  <div>
                    {OtpVerifyApiMsg ===
                    "OTP Verified. User can Login Successfully." ? (
                      <p className="text-success">{OtpVerifyApiMsg}</p>
                    ) : (
                      ""
                    )}

                    {OtpVerifyApiMsg === "Incorrect OTP. Please try again." ? (
                      <p className="text-danger">{OtpVerifyApiMsg}</p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="pt-2">
                    <label>
                      <input
                        type="checkbox"
                        name="termsAndConditions"
                        checked={values.termsAndConditions}
                        onChange={handleChange}
                      />
                      <span className="ps-2">
                        <span>I have read and agree to the </span>
                        <span
                          onClick={handleTermsandConditions}
                          className="cursor-pointer text-primary"
                          style={{ cursor: "pointer" }}
                        >
                          terms and conditions
                        </span>
                        <span> and </span>
                        <span
                          onClick={handlePrivacyPolicy}
                          className="cursor-pointer text-primary"
                          style={{ cursor: "pointer" }}
                        >
                          privacy policy
                        </span>
                      </span>
                    </label>
                    <ErrorMessage
                      name="termsAndConditions"
                      component="div"
                      className="error text-danger pt-2"
                    />
                  </div>

                  <div className="d-flex justify-content-center">
                    <button
                      type="submit"
                      className="login_btn mt-2 w-50"
                      disabled={isSubmitting}
                    >
                      Verify OTP
                    </button>
                  </div>

                  <Toaster position="top-right" reverseOrder={false} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
