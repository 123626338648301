import React from "react";
import { QuickLinksItems } from "../../data/FooterItems";

export const QuickLinks = () => {
    return (
        <div>
            <p className="fw-bold">Quick solutions for all your travel needs.</p>
            <div className="row ">
                {QuickLinksItems.map((ele) => {
                    return (
                        <div key={ele.id} className="col-md-3 col-6 pb-2 pointerUnderLine" >
                            <p className="m-0">{ele.title}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
