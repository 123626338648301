import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CiUser } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import images from '../assets/Images';
import { useNavigate } from "react-router-dom";

export const EditProfile = () => {
    const navigate = useNavigate();
    const [signupApiMsg, setSignupApiMsg] = useState("");

    const validationSignupSchema = Yup.object().shape({
        name: Yup.string()
            .required("Please enter your full name")
            .matches(/^[a-zA-Z\s]+$/, "Full name must contain alphabetic characters only"),
        email: Yup.string().email("Invalid email address").required("Please enter your email address"),
        mobile: Yup.string()
            .required("Please enter your mobile number")
            .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits"),
        termsAndConditions: Yup.boolean().oneOf([true], "Please accept the terms and conditions"),
    });

    const handleTermsandConditions = () => {
        navigate("/termsandconditions");
    };

    const handlePrivacyPolicy = () => {
        navigate("/privacypolicy");
    };

    return (
        <>
            <div className="container desktop-view" >
                <div className="row d-flex justify-content-center py-4" >
                    <div className="col-md-3 card">
                        <div className="d-flex justify-content-center">
                            <CiUser style={{ fontSize: "300px" }} />
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div style={{ fontSize: "20px" }}>
                            <div className="pb-2">
                                <span className="fw-bold">Customer Name : </span>
                                <input type='text' placeholder='enter your name'></input>
                            </div>
                            <div className="pb-2">
                                <span className="fw-bold">Customer Email : </span>
                                <input type='email' placeholder='enter your email'></input>

                            </div>
                            <div className="pb-2">
                                <span className="fw-bold">Customer mobile : </span>
                                <input type='text' placeholder='enter your number'></input>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-2">
                        <a href="/myprofile">
                            <button className="activeOfferBtnClass py-2 px-4">
                                <span>Back to Profile</span>
                                <span> <MdEdit /></span>
                            </button>
                        </a>
                    </div>
                </div>
            </div>

            <div className='container mobile-view'>

                <div className='row'>
                    <div className=''>
                        <div className='d-flex justify-content-center' >
                            <a href="" className="text-black" >
                                <img src={images?.user1} alt="" width="100px" height="100px"></img>
                            </a>
                        </div>
                        <p className='m-0 text-center text-primary' style={{ fontSize: "14px" }}>Change profile photo</p>
                    </div>
                </div>


                <div className="row">
                    <div className="col-12">
                        <Formik
                            initialValues={{
                                name: "",
                                email: "",
                                mobile: "",
                                termsAndConditions: false,
                            }}
                            validationSchema={validationSignupSchema}
                            onSubmit={async (values, actions, handleChange) => {

                            }}
                        >
                            {({ isSubmitting, values, handleChange }) => (
                                <Form>
                                    <div>
                                        <div className="mt-1" style={{ height: "70px" }}>
                                            <p className="m-0" style={{ fontSize: "14px", fontWeight: "500" }}>
                                                User Name
                                            </p>
                                            <div className="input_field d-flex align-items-center">
                                                <Field
                                                    type="text"
                                                    name="name"
                                                    placeholder="Enter your full name"
                                                    className="w-100"
                                                />
                                            </div>
                                            <ErrorMessage
                                                name="name"
                                                component="div"
                                                className="error text-danger m-0"
                                                style={{ fontSize: "10px" }}
                                            />
                                        </div>

                                        <div className="mt-0" style={{ height: "70px" }}>
                                            <p className="m-0" style={{ fontSize: "14px", fontWeight: "500" }}>
                                                Email
                                            </p>
                                            <div className="input_field d-flex align-items-center">
                                                <Field
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter your email address"
                                                    className="w-100"
                                                />
                                            </div>
                                            <ErrorMessage
                                                name="email"
                                                component="div"
                                                className="error text-danger m-0"
                                                style={{ fontSize: "10px" }}
                                            />
                                        </div>

                                        <div style={{ height: "70px" }}>
                                            <p className="m-0" style={{ fontSize: "14px", fontWeight: "500" }}>
                                                Mobile Number
                                            </p>
                                            <div className="input_field d-flex align-items-center">
                                                <span className="pe-2" style={{ borderRight: "1px solid black" }}>
                                                    +91
                                                </span>
                                                <Field
                                                    type="text"
                                                    name="mobile"
                                                    placeholder="Enter your Mobile Number"
                                                    maxLength={10}
                                                    className="ps-2 w-100 bg-white"
                                                />
                                            </div>
                                            <ErrorMessage
                                                name="mobile"
                                                component="div"
                                                className="error text-danger m-0"
                                                style={{ fontSize: "10px" }}
                                            />
                                        </div>

                                        <div>
                                            <div>
                                                {signupApiMsg.message === "OTP sent on given number." ? (
                                                    <p className="text-center text-success">{signupApiMsg.message}</p>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div>
                                                {signupApiMsg.message === "User is already registered." ? (
                                                    <p className="text-center text-danger">{signupApiMsg.message}</p>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>

                                        <div style={{ fontSize: "14px", height: "60px" }}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="termsAndConditions"
                                                    checked={values.termsAndConditions}
                                                    onChange={handleChange}
                                                />
                                                <span className="ps-2">
                                                    <span>I have read and agree to the </span>
                                                    <span
                                                        onClick={handleTermsandConditions}
                                                        className="cursor-pointer text-primary"
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        terms and conditions
                                                    </span>
                                                    <span> and </span>
                                                    <span
                                                        onClick={handlePrivacyPolicy}
                                                        className="cursor-pointer text-primary"
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        privacy policy
                                                    </span>
                                                </span>
                                            </label>
                                            <ErrorMessage
                                                name="termsAndConditions"
                                                component="div"
                                                className="error text-danger"
                                                style={{ fontSize: "10px" }}
                                            />
                                        </div>

                                        <div className="col-12 p-0 d-flex justify-content-center">
                                            <div>
                                                <button className="searchButton py-2" disabled={isSubmitting} style={{ width: "200px" }}>
                                                    Edit Profile
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}
