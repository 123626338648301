import { Base_Url } from "../Url/Url";

export const DailyTravelBlogsAPIData = async () => {
    const res = await fetch(`${Base_Url}/travelblogs/getalltravelblogs`, {
        method: "Get",
        headers: {
            "Content-type": "application/json",
        },
    });
    return await res.json();
};

export const SingleDailyTravelBlogsAPIData = async (id) => {
    const res = await fetch(`${Base_Url}/travelblogs/${id}`, {
        method: "Get",
        headers: {
            "Content-type": "application/json",
        },
    });
    return await res.json();
};
