import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../../style/Login.css";
import { useNavigate } from "react-router-dom";
import { LoginAPI } from "../../api/AuthAPI";
import { Toaster, toast } from "react-hot-toast";
import images from "../../assets/Images";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Cookies from "js-cookie";

export const Login = () => {
  const navigate = useNavigate();

  const validationSchemaLogin = Yup.object().shape({
    mobileNumber: Yup.string()
      .required("Please enter your mobile number")
      .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits"),
    termsAndConditions: Yup.boolean().oneOf(
      [true],
      "Please accept the terms and conditions"
    ),
  });

  const LoginSlider = [
    { id: 1, imgPath: images.Slider1, title: "Slider 1" },
    { id: 2, imgPath: images.Slider2, title: "Slider 2" },
    { id: 3, imgPath: images.Slider3, title: "Slider 3" },
  ];

  var LoginSliderSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <div className="container">
      <div className="row d-flex justify-content-center p-4">
        <div className="col-md-3 m-0 p-0 desktop-view">
          <Slider {...LoginSliderSettings}>
            {LoginSlider?.map((ele) => (
              <div key={ele.id} className="col-md-3 col-12">
                <div className="">
                  <img
                    src={ele.imgPath}
                    alt=""
                    height="420px"
                    className="w-100 p-0"
                  ></img>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="col-md-5 px-4  Section_Container">
          <Toaster position="top-right" reverseOrder={false} />
          <Formik
            initialValues={{
              mobileNumber: "",
              termsAndConditions: false,
            }}
            validationSchema={validationSchemaLogin}
            onSubmit={async (values, actions) => {
              try {
                const response = await LoginAPI({
                  mobile: values.mobileNumber,
                });

                console.log(response);
                if (
                  response.success === true &&
                  response.message === "OTP sent on given number"
                ) {
                  toast.success("OTP Sent Successfully!", { duration: 3000 });
                  Cookies.set("userMobile", JSON.stringify(response.data), {
                    expires: 30,
                  });
                  setTimeout(() => {
                    navigate("/otpverification");
                  }, 3000); // 3 seconds delay to allow the toast message to show
                } else {
                  toast.error("Incorrect Details!", { duration: 3000 });
                }
              } catch (error) {
                console.error("Error:", error);
                toast.error("An error occurred. Please try again.");
              }
              actions.setSubmitting(false);
            }}
          >
            {({ isSubmitting, values, handleChange }) => (
              <Form>
                <div>
                  <div className="d-flex justify-content-center">
                    <img
                      src={images.logo_mark}
                      alt="logo"
                      width="100px"
                      height="80px"
                    />
                  </div>

                  <div>
                    <h5 className="text-center pt-2 m-0">
                      Welcome to the Explore Trip Online
                    </h5>
                    <p className="m-0 text-center" style={{ fontSize: "13px" }}>
                      Please Enter Registered Mobile Number below.
                    </p>
                  </div>

                  <div className="mt-3 " style={{ height: "85px" }}>
                    <p
                      className="m-0 pb-2"
                      style={{ fontSize: "14px", fontWeight: "500" }}
                    >
                      Mobile Number
                    </p>
                    <div className="input_field d-flex align-items-center">
                      <span
                        className="pe-2"
                        style={{ borderRight: "1px solid black" }}
                      >
                        +91
                      </span>
                      <Field
                        type="text"
                        name="mobileNumber"
                        placeholder="Enter your Mobile Number"
                        maxLength={10}
                        className="ps-2 w-100 bg-white"
                      />
                    </div>
                    <ErrorMessage
                      name="mobileNumber"
                      component="div"
                      className="error text-danger m-0 py-2"
                      style={{ fontSize: "14px" }}
                    />
                  </div>

                  <div style={{ fontSize: "14px", height: "60px" }}>
                    <label>
                      <input
                        type="checkbox"
                        name="termsAndConditions"
                        checked={values.termsAndConditions}
                        onChange={handleChange}
                      />
                      <span className="ps-2">
                        <span>I have read and agree to the </span>
                        <span
                          onClick={() => navigate("/termsandconditions")}
                          className="cursor-pointer text-primary"
                        >
                          terms and conditions
                        </span>
                        <span> and </span>
                        <span
                          onClick={() => navigate("/privacypolicy")}
                          className="cursor-pointer text-primary"
                        >
                          privacy policy
                        </span>
                      </span>
                    </label>
                    <ErrorMessage
                      name="termsAndConditions"
                      component="div"
                      className="error text-danger "
                    />
                  </div>

                  <div className="d-flex justify-content-center">
                    <button
                      type="submit"
                      className="login_btn mt-2 w-50"
                      disabled={isSubmitting}
                    >
                      Get Otp
                    </button>
                  </div>

                  <div className="text-center pt-3">
                    {/* <span>Don't have an account then </span>
                    <span
                      onClick={() => navigate("/signup")}
                      style={{ cursor: "pointer" }}
                      className="text-primary cursor-pointer text-underline"
                    >
                      Sign up
                    </span> */}
                  </div>
                </div>
                <Toaster position="top-right" reverseOrder={false} />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
