import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import images from '../../assets/Images';

export const Banner2 = () => {
    var BannersSettings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const bannerPhotos = [
        { id: 1, imgPath: images?.banner1, title: "Slider 1" },
        { id: 2, imgPath: images?.banner2, title: "Slider 2" },
        { id: 3, imgPath: images?.banner3, title: "Slider 3" },
        { id: 4, imgPath: images?.banner4, title: "Slider 4" },
    ];
    return (
        <div className='mobile-view '>
            <Slider {...BannersSettings}>
                {bannerPhotos?.map((ele) => (
                    <div key={ele.id} className="col-md-3 col-12">
                        <div className="">
                            <img src={ele?.imgPath} alt="" style={{ border: "1.5px solid #f1f1f1", objectFit: "cover" }} className="w-100 p-0"></img>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    )
}
