export const AirlinesBrand = [
    {
        id: 1,
        imgPath: 'https://mmrtrip.com/assets/images/spice-jet.png',
        title: 'Spicejet',
    },
    {
        id: 2,
        imgPath: 'https://mmrtrip.com/assets/images/vistara.png',
        title: 'Vistara',
    },
    {
        id: 3,
        imgPath: 'https://mmrtrip.com/assets/images/air_india_logo.jpg',
        title: 'Air India',
    },
    {
        id: 4,
        imgPath: 'https://mmrtrip.com/assets/images/go-air.png',
        title: 'GO Air',
    },
    {
        id: 5,
        imgPath: 'https://mmrtrip.com/assets/images/jet-airway.png',
        title: 'Jet Airways',
    },
    {
        id: 6,
        imgPath: 'https://mmrtrip.com/assets/images/air-asia.png',
        title: 'Air Asia',
    },
]