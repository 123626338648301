import React, { useState } from "react";
import { InternationalDestinations } from "../FooterItems/InternationalDestinations";
import { CurrentOpenings } from "../FooterItems/CurrentOpenings";
import { OurOfferings } from "../FooterItems/OurOfferings";
import { QuickLinks } from "../FooterItems/QuickLinks";
import { PopularDestinations } from "../FooterItems/PopularDestinations";
import { PopularAirlines } from "../FooterItems/PopularAirlines";
import { ConnectWithUs } from "../FooterItems/ConnectWithUs";
import { OffersNew } from "../FooterItems/OffersNew";
import { Media } from "../FooterItems/Media";
import { InvestorsRelations } from "../FooterItems/InvestorsRelations";
import { FooterItemsList } from "../../data/FooterItems";
import { ETOInsight } from "../FooterItems/ETOInsight";

export const Footer = () => {
  const [activeItem, setActiveItem] = useState(3);

  const handleItemClick = (id) => {
    setActiveItem(id === activeItem ? 3 : id);
  };

  const renderForm = () => {
    switch (activeItem) {
      case 1:
        return <OurOfferings />;
      case 2:
        return <QuickLinks />;
      case 3:
        return <PopularDestinations />;
      case 4:
        return <InternationalDestinations />;
      case 5:
        return <PopularAirlines />;
      case 6:
        return <ConnectWithUs />;
      case 7:
        return <OffersNew />;
      case 8:
        return <ETOInsight />;
      case 9:
        return <Media />;
      case 10:
        return <InvestorsRelations />;
      case 11:
        return <CurrentOpenings />;
      default:
        return null;
    }
  };
  return (
    <>
      <div className="desktop-view">
        <div className="container-fluid pt-3">
          <div className="row px-4 text-white" style={{ padding: "15px 0px" }}>
            <div className="col-md-3 col-5">
              <div className="row">
                {FooterItemsList.map((ele) => {
                  return (
                    <div
                      key={ele.id}
                      style={{
                        borderBottom: "1px solid gray",
                        padding: "5px 5px",
                        cursor: "pointer",
                      }}
                      className={` d-flex align-items-center cursor-pointer ${
                        ele.id === activeItem ? "text-white bg-info" : ""
                      }`}
                      onClick={() => handleItemClick(ele.id)}
                    >
                      <div className="m-0">{ele.title}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-md-9 col-7">
              <div className="row d-flex justify-content-center">
                {activeItem !== null && (
                  <div className="row ">
                    <div className="col-md-12 pt-2">
                      <div>{renderForm()}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            className="row py-2 text-white"
            style={{ borderTop: "1px solid gray" }}
          >
            <div className="col-md-12 d-flex justify-content-center">
              <div>
                <span className="text-center m-0">
                  Copyright © 2014 Explore Trip Online.{" "}
                </span>
                <span className="text-center m-0">
                  | Powered by
                  <a
                    href="https://www.geniustravelservice.com/"
                    target="_blank"
                    className="text-white"
                  >
                    {" "}
                    Genius Travel Service.
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="mobile-view" style={{ fontSize: "12px" }}>
                <div className="container-fluid">
                    <div
                        className="row text-white py-2"
                        style={{ borderBottom: "1px solid gray", borderTop: "1px solid gray" }}
                    >
                        <div className="col-12">
                            <div className="pb-2" style={{ borderBottom: "1px solid gray" }}>
                                <img src={images?.logo_red_white} alt="Logo" width="50%" height="100%" />
                            </div>
                        </div>

                        <div className="col-12">
                            <p className="pt-2 m-0 pb-1">Download our Mobile App</p>

                            <div className="d-flex align-items-center">
                                <a href="https://play.google.com/" target="_blank" className="text-white">
                                    <img
                                        src="../images/icons/playstore.png"
                                        alt="Logo"
                                        width="100%"
                                        height="100%"
                                    />
                                </a>
                                <a href="https://www.apple.com/in/app-store/" target="_blank" className="ps-1 text-white">
                                    <img
                                        src="../images/icons/appstore.png"
                                        alt="Logo"
                                        width="100%"
                                        height="100%"

                                    />
                                </a>
                            </div>

                            <div className="d-flex justify-content-center mt-2 pt-1 pb-1">
                                <div className="px-1">
                                    <img src={images?.linkedin} alt="" height="40px" width="40px"></img>
                                </div>
                                <div className="px-1">
                                    <img src={images?.instagram} alt="" height="40px" width="40px"></img>
                                </div>
                                <div className="px-1">
                                    <img src={images?.youtube} alt="" height="40px" width="40px"></img>
                                </div>
                                <div className="px-1">
                                    <img src={images?.facebook} alt="" height="40px" width="40px"></img>
                                </div>
                                <div className="px-1">
                                    <img src={images?.x} alt="" height="40px" width="40px"></img>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row py-2 text-white d-flex justify-content-center">
                        <div>
                            <div className="text-center m-0">Copyright © 2014 Explore Trip Online. </div>
                        </div>
                    </div>
                </div>
            </div > */}
    </>
  );
};
