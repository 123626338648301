import { Base_Url } from "../Url/Url";

export const SearchInsurance = async () => {
  try {
    const response = await fetch(`${Base_Url}/insurance/insurancesearch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-token": "GeniusTS@2@22",
      },
      body: JSON.stringify({
        TravelStartDate: "2024-08-26",
        PlanCategory: "1",
        PlanType: "1",
        PlanCoverage: "4",
        NoOfPax: 1,
        PaxAge: [25],
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const BookInsurance = async (Details) => {
  try {
    const response = await fetch(`${Base_Url}/insurance/insurancebook`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-token": "GeniusTS@2@22",
      },
      body: JSON.stringify({
        TraceId: Details?.TraceId,
        ResultIndex: Details?.ResultIndex,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const Insurance_Search1 = async (insurance_data) => {
  try {
    const res = await fetch(`${Base_Url}/insurance/getallinsurance`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(insurance_data),
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const Get_Single_Insurance = async (id) => {
  try {
    const res = await fetch(`${Base_Url}/insurance/${id}`);
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const getInsuranceCity = async (data) => {
  try {
    const res = await fetch(
      `${Base_Url}/insurancecity/fetch_hotel_city?city=${data.city}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res.json();
  } catch (error) {
    console.log(error);
  }
};
