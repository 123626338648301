import React from "react";
import "../style/Payment.css";

export const PaymentFailure = () => {
  return (
    <div>
      <div
        className="swal2-icon swal2-error swal2-animate-error-icon"
        style={{ display: "flex" }}
      >
        <span className="swal2-x-mark">
          <span className="swal2-x-mark-line-left"></span>
          <span className="swal2-x-mark-line-right"></span>
        </span>
      </div>

      <div className="d-flex justify-content-center">
        <h3 className="text-gray-600 my-2">
          Opps! Some Error Occurs During Transection.
        </h3>
      </div>
    </div>
  );
};
