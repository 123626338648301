import React, { useState } from 'react'
import '../style/Mybookings.css'
import images from '../assets/Images';
import { SlCalender } from 'react-icons/sl';

export const Myorders = () => {
  const [activeItem, setActiveItem] = useState("upcoming");

  const handleItemClick = (formType) => {
    setActiveItem(formType === activeItem ? "upcoming" : formType);
  };

  const renderForm = () => {
    switch (activeItem) {
      case "upcoming":
        return (
          <>
            <div className='container'>

              <div className='row d-flex align-items-center p-2' style={{ borderBottom: "2px solid lightgray" }}>
                <div className='col-4 p-0'>
                  <p className='m-0'>Booking Date</p>
                  <p className='m-0' style={{ fontSize: '12px' }}>
                    <SlCalender style={{ color: "#04a9fe" }} />
                    <span className='ps-1'>15th May 2024</span>
                  </p>
                </div>
                <div className="col-8" style={{ border: "2px solid #04a9fe", borderRadius: "10px" }}>
                  <div className='align-items-center p-1'>
                    <div className='m-0 d-flex'>
                      <div>
                        Flight
                      </div>
                      <div className='ps-1'>
                        <img src={images?.flight_color} alt='' height="15px" width="15px">
                        </img>
                      </div>
                    </div>
                    <span className='m-0'>Mumbai to Jaipur</span>
                    <span className='m-0 ps-1' style={{ fontSize: "10px" }}>(15th May 2024)</span>
                  </div>
                </div>
              </div>

              <div className='row d-flex align-items-center p-2' style={{ borderBottom: "2px solid lightgray" }}>
                <div className='col-4 p-0'>
                  <p className='m-0'>Booking Date</p>
                  <p className='m-0' style={{ fontSize: '12px' }}>
                    <SlCalender style={{ color: "#04a9fe" }} />
                    <span className='ps-1'>10th May 2024</span>
                  </p>
                </div>
                <div className="col-8" style={{ border: "2px solid #04a9fe", borderRadius: "10px" }}>
                  <div className='align-items-center p-1'>
                    <div className='m-0 d-flex'>
                      <div>
                        Hotel
                      </div>
                      <div className='ps-1'>
                        <img src={images?.hotel_color} alt='' height="15px" width="15px">
                        </img>
                      </div>
                    </div>
                    <span className='m-0'>Taj Hotel,Delhi</span>
                    <span className='m-0 ps-1' style={{ fontSize: "10px" }}>(15th June 2024)</span>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case "cancelled":
        return (
          <>
            <div className='container'>

              <div className='row d-flex align-items-center p-2' style={{ borderBottom: "2px solid lightgray" }}>
                <div className='col-4 p-0'>
                  <p className='m-0'>Booking Date</p>
                  <p className='m-0' style={{ fontSize: '12px' }}>
                    <SlCalender style={{ color: "red" }} />
                    <span className='ps-1'>15th May 2024</span>
                  </p>
                </div>
                <div className="col-8" style={{ border: "2px solid red", borderRadius: "10px" }}>
                  <div className='align-items-center p-1'>
                    <div className='m-0 d-flex'>
                      <div>
                        Flight
                      </div>
                      <div className='ps-1'>
                        <img src={images?.flight_color} alt='' height="15px" width="15px">
                        </img>
                      </div>
                    </div>
                    <span className='m-0'>Mumbai to Jaipur</span>
                    <span className='m-0 ps-1' style={{ fontSize: "10px" }}>(15th May 2024)</span>
                  </div>
                </div>
              </div>

              <div className='row d-flex align-items-center p-2' style={{ borderBottom: "2px solid lightgray" }}>
                <div className='col-4 p-0'>
                  <p className='m-0'>Booking Date</p>
                  <p className='m-0' style={{ fontSize: '12px' }}>
                    <SlCalender style={{ color: "red" }} />
                    <span className='ps-1'>10th May 2024</span>
                  </p>
                </div>
                <div className="col-8" style={{ border: "2px solid red", borderRadius: "10px" }}>
                  <div className='align-items-center p-1'>
                    <div className='m-0 d-flex'>
                      <div>
                        Hotel
                      </div>
                      <div className='ps-1'>
                        <img src={images?.hotel_color} alt='' height="15px" width="15px">
                        </img>
                      </div>
                    </div>
                    <span className='m-0'>Taj Hotel,Delhi</span>
                    <span className='m-0 ps-1' style={{ fontSize: "10px" }}>(15th June 2024)</span>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case "completed":
        return (
          <>
            <div className='container'>

              <div className='row d-flex align-items-center p-2' style={{ borderBottom: "2px solid lightgray" }}>
                <div className='col-4 p-0'>
                  <p className='m-0'>Booking Date</p>
                  <p className='m-0' style={{ fontSize: '12px' }}>
                    <SlCalender />
                    <span className='ps-1'>15th May 2024</span>
                  </p>
                </div>
                <div className="col-8" style={{ border: "2px solid lightgreen", borderRadius: "10px" }}>
                  <div className='align-items-center p-1'>
                    <div className='m-0 d-flex'>
                      <div>
                        Flight
                      </div>
                      <div className='ps-1'>
                        <img src={images?.flight_color} alt='' height="15px" width="15px">
                        </img>
                      </div>
                    </div>
                    <span className='m-0'>Mumbai to Jaipur</span>
                    <span className='m-0 ps-1' style={{ fontSize: "10px" }}>(15th May 2024)</span>
                  </div>
                </div>
              </div>

              <div className='row d-flex align-items-center p-2' style={{ borderBottom: "2px solid lightgray" }}>
                <div className='col-4 p-0'>
                  <p className='m-0'>Booking Date</p>
                  <p className='m-0' style={{ fontSize: '12px' }}>
                    <SlCalender />
                    <span className='ps-1'>10th May 2024</span>
                  </p>
                </div>
                <div className="col-8" style={{ border: "2px solid lightgreen", borderRadius: "10px" }}>
                  <div className='align-items-center p-1'>
                    <div className='m-0 d-flex'>
                      <div>
                        Hotel
                      </div>
                      <div className='ps-1'>
                        <img src={images?.hotel_color} alt='' height="15px" width="15px">
                        </img>
                      </div>
                    </div>
                    <span className='m-0'>Taj Hotel,Delhi</span>
                    <span className='m-0 ps-1' style={{ fontSize: "10px" }}>(15th June 2024)</span>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case "unsuccessful":
        return (
          <>
            <div className='container'>

              <div className='row d-flex align-items-center p-2' style={{ borderBottom: "2px solid lightgray" }}>
                <div className='col-4 p-0'>
                  <p className='m-0'>Booking Date</p>
                  <p className='m-0' style={{ fontSize: '12px' }}>
                    <SlCalender />
                    <span className='ps-1'>15th May 2024</span>
                  </p>
                </div>
                <div className="col-8" style={{ border: "2px solid orange", borderRadius: "10px" }}>
                  <div className='align-items-center p-1'>
                    <div className='m-0 d-flex'>
                      <div>
                        Flight
                      </div>
                      <div className='ps-1'>
                        <img src={images?.flight_color} alt='' height="15px" width="15px">
                        </img>
                      </div>
                    </div>
                    <span className='m-0'>Mumbai to Jaipur</span>
                    <span className='m-0 ps-1' style={{ fontSize: "10px" }}>(15th May 2024)</span>
                  </div>
                </div>
              </div>

              <div className='row d-flex align-items-center p-2' style={{ borderBottom: "2px solid lightgray" }}>
                <div className='col-4 p-0'>
                  <p className='m-0'>Booking Date</p>
                  <p className='m-0' style={{ fontSize: '12px' }}>
                    <SlCalender />
                    <span className='ps-1'>10th May 2024</span>
                  </p>
                </div>
                <div className="col-8" style={{ border: "2px solid orange", borderRadius: "10px" }}>
                  <div className='align-items-center p-1'>
                    <div className='m-0 d-flex'>
                      <div>
                        Hotel
                      </div>
                      <div className='ps-1'>
                        <img src={images?.hotel_color} alt='' height="15px" width="15px">
                        </img>
                      </div>
                    </div>
                    <span className='m-0'>Taj Hotel,Delhi</span>
                    <span className='m-0 ps-1' style={{ fontSize: "10px" }}>(15th June 2024)</span>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className='container' style={{ minHeight: "100vh" }}>
      <div className='row my-2'>
        <p className='m-0 text-center' style={{ fontSize: "20px", fontWeight: "600" }}>My Bookings</p>
      </div>

      <div className='row'>
        <div className='d-flex p-0' style={{overflow:"scroll"}}>
          <div
            className={`justify-content-center align-items-center ${activeItem === "upcoming"
              ? "Mybooking_Items_active"
              : "Mybooking_Items_inactive"
              }`}
            onClick={() => handleItemClick("upcoming")}>
            UPCOMING
          </div>
          <div
            className={`justify-content-center align-items-center ${activeItem === "cancelled"
              ? "Mybooking_Items_active"
              : "Mybooking_Items_inactive"
              }`}
            onClick={() => handleItemClick("cancelled")}>
            CANCELLED
          </div>
          <div
            className={`justify-content-center align-items-center ${activeItem === "completed"
              ? "Mybooking_Items_active"
              : "Mybooking_Items_inactive"
              }`}
            onClick={() => handleItemClick("completed")}>
            COMPLETED
          </div>
          <div
            className={`justify-content-center align-items-center ${activeItem === "unsuccessful"
              ? "Mybooking_Items_active"
              : "Mybooking_Items_inactive"
              }`}
            onClick={() => handleItemClick("unsuccessful")}>
            UNSUCCESSFUL
          </div>
        </div>
      </div>

      <div className="row d-flex justify-content-center" style={{ marginTop: "3px" }}>
        <div className="col p-0">
          <div className="">{renderForm()}</div>
        </div>
      </div>
    </div>
  )
}
