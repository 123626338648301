import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SingleDailyTravelBlogsAPIData } from "../api/DailyTravelBlogsAPI";
import { Loader } from "../component/Loader";
import { FaUser } from "react-icons/fa";
import { Base_Url } from "../Url/Url";

export const SingleDailyTravelBlogs = () => {
  const { id } = useParams();
  const [BlogsData, setBlogsData] = useState(null);
  const SingleBlogsData = async () => {
    try {
      const res = await SingleDailyTravelBlogsAPIData(id);
      if (res?.status === "success") {
        setBlogsData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    SingleBlogsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="desktop-view ">
        <div className="container ">
          <div className="row py-2 d-flex justify-content-center align-items-center">
            {BlogsData !== null ? (
              <div>
                <div>
                  <div className="col-md-12">
                    <div className="row py-4">
                      <div
                        className="col-md-6 p-0 "
                        style={{ overflow: "hidden" }}
                      >
                        <img
                          src={`${Base_Url}${BlogsData?.Image}`}
                          alt="img"
                          height="350px"
                          className="Section_image"
                          style={{ objectFit: "cover" }}
                        ></img>
                      </div>

                      <div className="col-md-6">
                        <div>
                          <div>
                            <p className="fs-5" style={{ fontWeight: "500" }}>
                              {BlogsData?.Title}
                            </p>
                          </div>
                          <div className="p-0 m-0 text-danger">
                            {/* <span>
                              <SlCalender />
                            </span>
                            <span className="px-1"> {BlogsData?.Date}</span> */}
                            <span>
                              <FaUser />
                            </span>
                            <span className="px-1"> {BlogsData?.Author}</span>
                          </div>
                          <p className="pt-3" style={{ fontSize: "18px" }}>
                            {BlogsData?.Description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* {BlogsData?.map((ele) => {
                  return (
                    <div key={ele.Id} className="col-md-12">
                      <div className="row py-4">
                        <div
                          className="col-md-6 p-0 "
                          style={{ overflow: "hidden" }}
                        >
                          <img
                            src={`${imgBaseUrl}${ele?.Image}`}
                            alt="img"
                            height="350px"
                            className="Section_image"
                            style={{ objectFit: "cover" }}
                          ></img>
                        </div>

                        <div className="col-md-6">
                          <div>
                            <div>
                              <p className="fs-5" style={{ fontWeight: "500" }}>
                                {ele?.Title}
                              </p>
                            </div>
                            <div className="p-0 m-0 text-danger">
                              <span>
                                <SlCalender />
                              </span>
                              <span className="px-1"> {ele?.Date}</span>
                              <span className="ms-3">
                                <FaUser />
                              </span>
                              <span className="px-1"> {ele?.Addby}</span>
                            </div>
                            <p className="pt-3" style={{ fontSize: "18px" }}>
                              {ele?.Description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })} */}
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>

      <div className="mobile-view ">
        <div className="container ">
          <div className="row  d-flex justify-content-center align-items-center">
            {BlogsData !== null ? (
              <div>
                <div>
                  <div className="col-md-12">
                    <div className="row">
                      <div
                        className="col-md-6 p-0 "
                        style={{ overflow: "hidden" }}
                      >
                        <img
                          src={`${Base_Url}${BlogsData?.Image}`}
                          alt="img"
                          height="250px"
                          className="Section_image"
                          style={{ objectFit: "cover" }}
                        ></img>
                      </div>

                      <div className="col-md-6">
                        <div>
                          <div>
                            <p className="fs-5" style={{ fontWeight: "500" }}>
                              {BlogsData?.Title}
                            </p>
                          </div>
                          <div className="p-0 m-0 text-danger">
                            {/* <span>
                              <SlCalender />
                            </span>
                            <span className="px-1"> {BlogsData?.Date}</span> */}
                            <span>
                              <FaUser />
                            </span>
                            <span className="px-1"> {BlogsData?.Author}</span>
                          </div>
                          <p className="pt-3" style={{ fontSize: "18px" }}>
                            {BlogsData?.Description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* {BlogsData?.map((ele) => {
                  return (
                    <div key={ele.Id} className="col-md-12">
                      <div className="row py-4">
                        <div
                          className="col-md-6 p-0 "
                          style={{ overflow: "hidden" }}
                        >
                          <img
                            src={`${imgBaseUrl}${ele?.Image}`}
                            alt="img"
                            height="350px"
                            className="Section_image"
                            style={{ objectFit: "cover" }}
                          ></img>
                        </div>

                        <div className="col-md-6">
                          <div>
                            <div>
                              <p className="fs-5" style={{ fontWeight: "500" }}>
                                {ele?.Title}
                              </p>
                            </div>
                            <div className="p-0 m-0 text-danger">
                              <span>
                                <SlCalender />
                              </span>
                              <span className="px-1"> {ele?.Date}</span>
                              <span className="ms-3">
                                <FaUser />
                              </span>
                              <span className="px-1"> {ele?.Addby}</span>
                            </div>
                            <p className="pt-3" style={{ fontSize: "18px" }}>
                              {ele?.Description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })} */}
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
