import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom/dist";
import images from "../../assets/Images";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { SearchAllHotelsAPI } from "../../api/HotelAPI";

export const SearchHotels = () => {
  const { hotelcity, checkOutDate, checkInDate } = useParams();
  const navigate = useNavigate();

  // State variables
  const [HotelsData, setHotelsData] = useState([]);
  const [filteredHotelsData, setFilteredHotelsData] = useState([]);
  const [priceRange, setPriceRange] = useState({ min: 0, max: 0 });
  const [selectedPrice, setSelectedPrice] = useState(priceRange.max);
  const [selectedStars, setSelectedStars] = useState([]);
  const [loading, setLoading] = useState(true);

  const HotelSearchApi = async () => {
    try {
      const response = await SearchAllHotelsAPI();

      if (response) {
        setHotelsData(response?.data?.Results);
        setFilteredHotelsData(response?.data?.Results);
        setLoading(false);
      } else {
        console.log("API request unsuccessful");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (HotelsData?.length > 0) {
      const prices = HotelsData.map(
        (hotel) => hotel?.Price?.OfferedPriceRoundedOff
      );
      const maxPrice = Math.max(...prices);
      const minPrice = Math.min(...prices);
      setSelectedPrice(minPrice);
      setPriceRange({ min: minPrice, max: maxPrice });
    }
  }, [HotelsData]);

  useEffect(() => {
    if (HotelsData?.length > 0) {
      const maxPrice = Math.max(
        ...HotelsData?.map((hotel) => hotel?.Price?.OfferedPriceRoundedOff)
      );
      setSelectedPrice(maxPrice);
    }
  }, [HotelsData]);

  const handlePriceChange = (e) => {
    const newPrice = parseInt(e.target.value);
    setSelectedPrice(newPrice);
  };

  const applyHotelFilters = () => {
    let filteredHotels = HotelsData;

    filteredHotels = filteredHotels?.filter((item) => {
      return item?.Price?.OfferedPrice <= selectedPrice;
    });

    if (selectedStars.length > 0) {
      filteredHotels = filteredHotels?.filter((item) => {
        return selectedStars?.includes(item?.StarRating);
      });
    }
    setFilteredHotelsData(filteredHotels);
  };

  useEffect(() => {
    HotelSearchApi();
  }, []);

  useEffect(() => {
    applyHotelFilters();
  }, [selectedPrice, selectedStars]);

  const handleStarFilterChange = (e) => {
    const star = parseInt(e.target.value);
    if (selectedStars?.includes(star)) {
      setSelectedStars([]);
    } else {
      setSelectedStars([star]);
    }
  };

  const handleSingleHotelData = (data) => {
    navigate(
      `/searchhotels/${hotelcity}/${checkInDate}/${checkOutDate}/${data?.HotelName}/${data?.HotelCode}`
    );
  };

  function handleResetAll() {
    window.location.reload();
  }

  function handleOfferedPrice(price) {
    const roundedPrice = (price * 1.1).toFixed(0);
    return parseFloat(roundedPrice);
  }

  function handleOffPrice(price) {
    const roundedPrice = (price * 0.1).toFixed(0);
    return parseFloat(roundedPrice);
  }

  const [showHotelFilterModal, setShowHotelFilterModal] = useState(false);

  const handleCloseHotelFilterModal = () => setShowHotelFilterModal(false);
  const handleShowHotelFilterModal = () => setShowHotelFilterModal(true);

  return (
    <>
      <Container fluid className="desktop-view">
        <Row>
          <Col>
            <h2 className="text-center mt-4">
              Showing Hotels in {hotelcity} ...
            </h2>
            <div>
              {loading ? (
                <div
                  className="  d-flex align-items-center justify-content-center"
                  style={{ height: "400px" }}
                >
                  <img
                    src={images?.Flight_Loader_Transparent}
                    height={170}
                    width={170}
                  ></img>
                </div>
              ) : (
                <section className="row px-2">
                  <div
                    className="col-md-3 mt-4 mb-3 Section_Sidebar_Container"
                    style={{
                      border: "1.5px solid rgb(1,128,253)",
                      overflow: "hidden",
                      position: "sticky",
                      top: "110px",
                      maxHeight: "350px",
                    }}
                  >
                    <div
                      className="row text-white d-flex justify-content-between py-3"
                      style={{
                        backgroundImage:
                          "linear-gradient(45deg, #1556FC, #04A9FE)",
                        borderBottom: "1.5px solid rgb(1,128,253)",
                      }}
                    >
                      <div className="col-5">
                        <h5 className="m-0">Filter by</h5>
                      </div>
                      <div
                        className="col-6 d-flex justify-content-end align-items-center"
                        onClick={handleResetAll}
                        style={{ cursor: "pointer" }}
                      >
                        <span className="pe-2">Resets all </span>
                        <img
                          src={images?.reset}
                          alt=""
                          width="20px"
                          height="20px"
                        />
                      </div>
                    </div>

                    <div
                      className="row d-flex justify-content-between px-2"
                      style={{ background: "#fff" }}
                    >
                      <div
                        className="m-0 p-0 row py-2"
                        style={{ borderBottom: "2px solid #e9e9e9" }}
                      >
                        <div className="p-0 m-0 d-flex justify-content-between">
                          <span>PRICE</span>
                          <span className="px-2">{`₹ ${parseInt(
                            selectedPrice
                          )?.toLocaleString()}`}</span>
                        </div>

                        <div className="py-2 px-0 d-flex justify-content-between">
                          <input
                            type="range"
                            max={priceRange?.max}
                            min={priceRange?.min}
                            value={selectedPrice}
                            className="w-100"
                            onChange={handlePriceChange}
                          />
                        </div>
                      </div>

                      <div
                        className="m-0 p-0 row py-2"
                        style={{ borderBottom: "2px solid #e9e9e9" }}
                      >
                        <div className="p-0" style={{fontWeight:"600"}}>STAR RATING</div>

                        <div className="p-0">
                          {[1, 2, 3, 4, 5]?.map((star) => (
                            <div
                              key={star}
                              className="d-flex align-items-center"
                            >
                              <input
                                type="checkbox"
                                id={`star_${star}`}
                                value={star}
                                style={{ marginRight: "5px" }}
                                checked={selectedStars?.includes(star)}
                                onChange={handleStarFilterChange}
                              />
                              <label
                                htmlFor={`star_${star}`}
                                className={`px-2 ${
                                  selectedStars.includes(star) ? "text-red" : ""
                                }`}
                              >
                                {star} Star
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-9 ">
                    <div>
                      <div>
                        {filteredHotelsData?.length > 0 ? (
                          <div>
                            {filteredHotelsData
                              ?.filter((ele) => ele?.StarRating != 0)
                              .map((ele, id) => {
                                let stars;
                                if (ele?.StarRating === 0) {
                                  stars = <span>0 stars</span>;
                                } else {
                                  stars = [];
                                  for (let i = 0; i < ele?.StarRating; i++) {
                                    stars.push(
                                      <i
                                        key={i}
                                        className="fa fa-star text-warning"
                                      ></i>
                                    );
                                  }
                                }
                                return (
                                  <div
                                    className="Section_Container mb-3"
                                    key={id}
                                  >
                                    <div className="row">
                                      <div className="col-md-3">
                                        <div>
                                          <img
                                            src={ele?.HotelPicture}
                                            alt=""
                                            height="140px"
                                            width="100%"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div>
                                          <p className="fw-bold m-0">
                                            <span>{ele?.HotelName} </span>
                                            <span className="hotel-name">
                                              - ({ele?.HotelCode})
                                            </span>
                                          </p>

                                          <p
                                            className="m-0 text-secondary"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {ele?.HotelAddress}
                                          </p>
                                          <p>
                                            <span>
                                              {ele?.HotelDescription.length >
                                              200
                                                ? ele?.HotelDescription?.slice(
                                                    0,
                                                    130
                                                  ) + "..."
                                                : ele?.HotelDescription}
                                            </span>
                                            <span
                                              className="text-primary pointerUnderLine px-2"
                                              onClick={() => {
                                                handleSingleHotelData(ele);
                                              }}
                                            >
                                              view more
                                            </span>
                                          </p>
                                        </div>
                                      </div>

                                      <div className="col-md-3">
                                        <div className="d-flex justify-content-end ">
                                          <div>
                                            <p className="m-0">
                                              <span className="text-decoration-line-through text-secondary ">{`₹${parseInt(
                                                handleOfferedPrice(
                                                  ele?.Price
                                                    ?.OfferedPriceRoundedOff
                                                )
                                              ).toLocaleString()}`}</span>

                                              <span
                                                className="ps-2 fw-bold text-success"
                                                style={{ fontSize: "18px" }}
                                              >{`₹${parseInt(
                                                ele?.Price
                                                  ?.OfferedPriceRoundedOff
                                              ).toLocaleString()}`}</span>

                                              <span
                                                style={{ fontSize: "14px" }}
                                              >
                                                (Per Night)
                                              </span>
                                              <p className="text-end">
                                                <span
                                                  className="pe-2 text-success"
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  Save ₹
                                                  {handleOffPrice(
                                                    ele?.Price
                                                      ?.OfferedPriceRoundedOff
                                                  )}
                                                </span>
                                                <span className="Bus_Cards_Star mt-2">
                                                  ★ {ele?.StarRating}
                                                </span>
                                              </p>
                                            </p>
                                          </div>
                                        </div>

                                        <div className="d-flex justify-content-end ">
                                          <button
                                            className="Bus_search_btn d-flex justify-content-end "
                                            onClick={() => {
                                              handleSingleHotelData(ele);
                                            }}
                                          >
                                            Explore Hotel
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        ) : (
                          <div className="text-center mt-5">
                            <h6>Sorry, No Hotels for this Search</h6>
                            <p className="text-secondary">
                              We cannot find any Hotels for your search. Please
                              modify your search criteria and try again.
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid className="mobile-view">
        <Row>
          <Col>
            <div className="d-flex align-items-center mt-3">
              <div style={{ border: "1px solid #04a9fe", borderRadius: "5px" }}>
                <p className="text-center m-0 px-2 py-1">
                  Showing Hotels in {hotelcity} ...
                </p>
              </div>
              <div className="px-2" onClick={handleShowHotelFilterModal}>
                <img
                  src={images?.filter}
                  alt=""
                  height="20px"
                  width="20px"
                ></img>
              </div>
            </div>

            <Modal
              show={showHotelFilterModal}
              onHide={handleCloseHotelFilterModal}
            >
              <Modal.Header
                closeButton
                style={{
                  backgroundImage: "linear-gradient(45deg, #1556FC, #04A9FE)",
                  borderBottom: "1.5px solid rgb(1,128,253)",
                }}
              >
                <Modal.Title>
                  <div
                    className="d-flex text-white"
                    style={{ fontSize: "16px" }}
                  >
                    <div>Filter by</div>
                    <div className="px-5" onClick={handleResetAll}>
                      <span className="pe-2">Resets all </span>
                      <img
                        src="../images/icons/reset.png"
                        alt=""
                        width="20px"
                        height="20px"
                      />
                    </div>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div
                  className="row d-flex justify-content-between px-2"
                  style={{ background: "#fff" }}
                >
                  <div
                    className="m-0 p-0 row py-0"
                    style={{ borderBottom: "2px solid #e9e9e9" }}
                  >
                    <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                      PRICE
                    </p>
                    <div className="p-0 d-flex justify-content-between">
                      <span>
                        Min {`₹${parseInt(priceRange.min)?.toLocaleString()}`}
                      </span>
                      <span>
                        Max {`₹${parseInt(priceRange.max)?.toLocaleString()}`}
                      </span>
                    </div>
                    <div className="py-2 px-0 d-flex justify-content-between">
                      <input
                        type="range"
                        max={priceRange?.max}
                        min={priceRange?.min}
                        value={selectedPrice}
                        className="w-100"
                        onChange={handlePriceChange}
                      />
                      <span className="px-2">{`₹${parseInt(
                        selectedPrice
                      )?.toLocaleString()}`}</span>
                    </div>
                  </div>

                  <div
                    className="m-0 p-0 row py-2"
                    style={{ borderBottom: "2px solid #e9e9e9" }}
                  >
                    <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                      STAR RATING
                    </p>

                    <div className="p-0">
                      {[1, 2, 3, 4, 5]?.map((star) => (
                        <div key={star} className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            id={`star_${star}`}
                            value={star}
                            style={{ marginRight: "5px" }}
                            checked={selectedStars?.includes(star)}
                            onChange={handleStarFilterChange}
                          />
                          <label
                            htmlFor={`star_${star}`}
                            className={`px-2 ${
                              selectedStars.includes(star) ? "text-red" : ""
                            }`}
                          >
                            {star} Star
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleCloseHotelFilterModal}>
                  Apply
                </Button>
              </Modal.Footer>
            </Modal>

            <div>
              {loading ? (
                <div
                  className="  d-flex align-items-center justify-content-center"
                  style={{ height: "400px" }}
                >
                  <img
                    src={images?.Flight_Loader_Transparent}
                    height={170}
                    width={170}
                  ></img>
                </div>
              ) : (
                <section className="row m-0">
                  <div className="col-12 p-0">
                    <div>
                      {filteredHotelsData?.length > 0 ? (
                        <div>
                          {filteredHotelsData
                            ?.filter((ele) => ele?.StarRating != 0)
                            .map((ele, id) => {
                              let stars;
                              if (ele?.StarRating === 0) {
                                stars = <span>0 stars</span>;
                              } else {
                                stars = [];
                                for (let i = 0; i < ele?.StarRating; i++) {
                                  stars.push(
                                    <i
                                      key={i}
                                      className="fa fa-star text-warning"
                                    ></i>
                                  );
                                }
                              }
                              return (
                                <div
                                  className="Section_HotelCards_Container"
                                  key={id}
                                  onClick={() => {
                                    handleSingleHotelData(ele);
                                  }}
                                >
                                  <div className="row">
                                    <div className="col-12">
                                      <div>
                                        <img
                                          src={ele?.HotelPicture}
                                          alt=""
                                          height="180px"
                                          width="100%"
                                        />
                                      </div>
                                    </div>

                                    <div className="col-12">
                                      <div className="row px-3 py-2">
                                        <div className="col-7">
                                          <p
                                            className="m-0"
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "600",
                                            }}
                                          >
                                            <span>{ele?.HotelName} </span>
                                          </p>

                                          <p
                                            className="m-0 text-secondary"
                                            style={{ fontSize: "10px" }}
                                          >
                                            {ele?.HotelAddress}
                                          </p>
                                        </div>
                                        <div className="col-5">
                                          <div className="d-flex justify-content-end align-items-end">
                                            <span
                                              style={{ fontSize: "10px" }}
                                              className="text-decoration-line-through text-secondary "
                                            >{`₹${parseInt(
                                              handleOfferedPrice(
                                                ele?.Price
                                                  ?.OfferedPriceRoundedOff
                                              )
                                            ).toLocaleString()}`}</span>

                                            <span
                                              className="ps-2 fw-bold text-success"
                                              style={{ fontSize: "14px" }}
                                            >
                                              {`₹${parseInt(
                                                ele?.Price
                                                  ?.OfferedPriceRoundedOff
                                              ).toLocaleString()}`}
                                            </span>
                                          </div>
                                          <div className="d-flex justify-content-end align-items-end">
                                            <span style={{ fontSize: "10px" }}>
                                              (Per Night)
                                            </span>
                                          </div>
                                          <div className="d-flex justify-content-end align-items-end">
                                            <div>
                                              <span
                                                className="pe-2"
                                                style={{ fontSize: "10px" }}
                                              >
                                                {/* Save ₹
                                                                                                {handleOffPrice(
                                                                                                    ele?.Price?.OfferedPriceRoundedOff
                                                                                                )} */}
                                                120 Ratings
                                              </span>
                                              <span
                                                className="Bus_Cards_Star"
                                                style={{ fontSize: "10px" }}
                                              >
                                                ★ {ele?.StarRating}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      ) : (
                        <div className="text-center mt-5">
                          <h6>Sorry, No Hotels for this Search</h6>
                          <p className="text-secondary">
                            We cannot find any Hotels for your search. Please
                            modify your search criteria and try again.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
