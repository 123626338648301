export const BookingsItems = [
    {
        id: 1,
        imgPath: "../images/header_icons/flight.png",
        imgPath_color: "../images/header_icons/flight_color.png",
        imgPath_desktop_color: "../images/icons/flight_color.png",
        imgPath_desktop_black: "../images/icons/flight_color.png",
        title: "Flights",
        title_mobile: "Flight Booking",
    },
    {
        id: 2,
        imgPath: "../images/header_icons/cab.png",
        imgPath_color: "../images/header_icons/cab_color.png",
        imgPath_desktop_color: "../images/icons/cab_color.png",
        imgPath_desktop_black: "../images/icons/cab_color.png",
        title: "Cabs",
        title_mobile: "Cab Booking",
    },
    {
        id: 3,
        imgPath: "../images/header_icons/bus.png",
        imgPath_color: "../images/header_icons/bus_color.png",
        imgPath_desktop_color: "../images/icons/bus_color.png",
        imgPath_desktop_black: "../images/icons/bus_color.png",
        title: "Buses",
        title_mobile: "Bus Booking",
    },
    {
        id: 4,
        imgPath: "../images/header_icons/hotel.png",
        imgPath_color: "../images/header_icons/hotel_color.png",
        imgPath_desktop_color: "../images/icons/hotel_color.png",
        imgPath_desktop_black: "../images/icons/hotel_color.png",
        title: "Hotels",
        title_mobile: "Hotel Booking",
    },
    {
        id: 5,
        imgPath: "../images/header_icons/hotel.png",
        imgPath_color: "../images/header_icons/mobile_color.png",
        imgPath_desktop_color: "../images/icons/mobile_color.png",
        imgPath_desktop_black: "../images/icons/mobile_color.png",
        title: "Recharge",
        title_mobile: "Mobile Recharge",
    },
    {
        id: 6,
        imgPath: "../images/header_icons/hotel.png",
        imgPath_color: "../images/header_icons/mobile_color.png",
        imgPath_desktop_color: "../images/icons/mobile_color.png",
        imgPath_desktop_black: "../images/icons/mobile_color.png",
        title: "T Insurance",
        title_mobile: "T Insurance",
    },
];

