import React from "react";
import { AirlinesBrand } from "../../data/AirlinesBrands";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const AirlineBrand = () => {
    var AirlineBrandSettings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
    };

    return (
        <div >
            <div className="container-fluid desktop-view Section_Container">
                <div className="row px-2">
                    <div className="col-md-6">
                        <div className="homepage_component_heading_txt">Associated Airlines</div>
                        <div className="Section_text_small_underline"></div>
                    </div>
                </div>

                <div className="row pt-3 px-2">
                    <Slider {...AirlineBrandSettings}>
                        {AirlinesBrand?.map((ele) => (
                            <div key={ele.id} className="col-md-3  px-2 col-12">
                                <div className="">
                                    <img
                                        src={ele.imgPath}
                                        alt=""
                                        height="130px"
                                        className="Section_image p-0"
                                        style={{ border: "1px solid gray", borderRadius: "15px" }}
                                    ></img>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>

            <div className="mobile-view mt-3 px-3">
                <div className="row d-flex justify-content-between align-items-center">
                    <div className="col-12 d-flex justify-content-center">
                        <p className="m-0" style={{ fontSize: "16px", fontWeight: "600" }}>Associated Airlines</p>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-2 d-flex justify-content-center">
                            <div className="Section_text_small_underline_mobile"></div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12 p-0 pb-5 m-0">
                        <Slider {...AirlineBrandSettings}>
                            {AirlinesBrand?.map((ele) => (
                                <div key={ele.id} className="col-3 p-1 col-12">
                                    <img
                                        src={ele.imgPath}
                                        alt=""
                                        height="60px"
                                        className="Section_image p-0"
                                        style={{ border: "1px solid lightgray", borderRadius: "10px" }}
                                    ></img>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>

        </div>
    );
};
