import React from "react";

export const Loader = () => {
    return (
        <div className="container ">
            <div className="row d-flex justify-content-center">
                <div className="col-md-5 py-3 ">
                    <div className="d-flex justify-content-center py-3">
                        {/* <Bars
                            height="80"
                            width="80"
                            color="rgb(45,137,200)"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        /> */}
                    </div>
                    <p className="text-center m-0">Please await ...</p>
                    {/* <p className="text-center">We are searching the best flights for you</p>
                    <p className="text-center text-danger">Do not refresh or close the Window</p> */}
                </div>
            </div>
        </div>
    );
};
