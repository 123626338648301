import React from "react";
import "../style/PrivacyPolicy.css";

export const PrivacyPolicy = () => {
    const listStyle = {
        listStyleType: "number",
        fontSize: "1.0rem",
        counterReset: "section",
    };
    return (
        <div class="container py-4">
            <div class="row justify-content-md-center mt-none-30">
                <div class="col-lg-12 col-md-12 mt-30">
                    <h3 className="fw-bold">Privacy Policy</h3>
                    <p>
                        At Explore Trip Online, we are committed to protecting the privacy and security of our users'
                        personal information. This Privacy Policy outlines the types of information we collect, how we
                        use and protect it, and the choices you have regarding your data.
                    </p>
                </div>
                <div class="col-lg-12 col-md-12 ">
                    <div>
                        <h4 className="fw-bold">Information We Collect:</h4>
                        <div className="list_items">
                            <ol style={listStyle}>
                                <li className="mb-2">
                                    <strong>Information We Collect:</strong>
                                    <p className="m-0">
                                        Personal Information: When you use our services, we may collect personal
                                        information such as your name, email address, phone number, and payment details
                                        for processing bookings and providing customer support.
                                    </p>
                                    <p className="m-0">
                                        Usage Information: We automatically collect information about how you interact
                                        with our website and services, including your IP address, browser type, device
                                        information, and usage patterns.
                                    </p>
                                    <p className="m-0">
                                        Cookies: We use cookies and similar tracking technologies to enhance your
                                        browsing experience, analyze trends, and personalize content.
                                    </p>
                                </li>
                                <li className="mb-2">
                                    <strong>How We Use Your Information:</strong>
                                    <p className="m-0">
                                        We use the information we collect for various purposes, including:
                                    </p>
                                    <p className="m-0">Providing and improving our services</p>
                                    <p className="m-0">Communicating with you</p>
                                    <p className="m-0">Analyzing usage data </p>
                                </li>
                                <li className="mb-2">
                                    <strong>Data Security: </strong>
                                    <p className="m-0">
                                        We employ industry-standard security measures to safeguard your personal
                                        information from unauthorized access, disclosure, alteration, or destruction.
                                        However, no method of transmission over the internet or electronic storage is
                                        100% secure, and we cannot guarantee absolute security.
                                    </p>
                                </li>
                                <li className="mb-2">
                                    <strong>Third-Party Services:</strong>
                                    <p className="m-0">
                                        We may use third-party service providers to assist with website functionality,
                                        payment processing, analytics, and marketing. These providers may have access to
                                        your personal information only to perform these tasks on our behalf and are
                                        obligated not to disclose or use it for any other purpose.
                                    </p>
                                </li>
                                <li className="mb-2">
                                    <strong>Your Choices:</strong>
                                    <p className="m-0">You have the right to:</p>
                                    <p className="m-0">Opt-out of receiving promotional communications</p>
                                    <p className="m-0">Control cookies through your browser settings</p>
                                </li>
                                <li className="mb-2">
                                    <strong>Children's Privacy:</strong>
                                    <p className="m-0">
                                        We do not knowingly collect personal information from children under the age of
                                        13. If you believe that we have inadvertently collected information from a
                                        child, please contact us immediately to request deletion.
                                    </p>
                                </li>
                                <li className="mb-2">
                                    <strong>Changes to This Privacy Policy:</strong>
                                    <p className="m-0">
                                        We may update this Privacy Policy from time to time to reflect changes in our
                                        practices or legal requirements. We encourage you to review this page
                                        periodically for any updates.
                                    </p>
                                </li>
                                <li className="mb-2">
                                    <strong>Contact Us:</strong>
                                    <p className="m-0">
                                        If you have any questions, concerns, or requests regarding this Privacy Policy
                                        or our privacy practices, please contact us at support@exploretiponline.com.
                                    </p>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
