import React, { useEffect, useState } from 'react'
import { IoMdDownload } from 'react-icons/io';
import images from '../../assets/Images';

export const TopHeader = () => {
    const [os, setOs] = useState("unknown");

    const [showDownloadSection, setShowDownloadSection] = useState(true);

    const handleCloseDownloadSection = () => {
        setShowDownloadSection(false);
    };

    useEffect(() => {
        const getMobileOperatingSystem = () => {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;

            if (/windows phone/i.test(userAgent)) {
                return "Windows Phone";
            }

            if (/android/i.test(userAgent)) {
                return "Android";
            }

            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }
            return "unknown";
        };

        setOs(getMobileOperatingSystem());
    }, []);

    let storeLink;

    if (os === "Android") {
        storeLink = "https://play.google.com/";
    } else if (os === "iOS") {
        storeLink = "https://apps.apple.com/";
    } else {
        storeLink = "#";
    }


    return (
        <>
            <style>
                {`
                        @media (max-width: 767px) {
                            .top_download_btn{
                                font-size:12px;
                                font-weight:600
                            }                         
                        }
                        @media (max-width: 450px) {
                            .top_download_btn{
                                font-size:11px;
                                font-weight:600
                            }                         
                        }

                        @media (max-width: 400px) {
                            .top_download_btn{
                                font-size:9px;
                                font-weight:600
                            }                         
                        }
                        }
                    `}
            </style>

            {/* {showDownloadSection && (
                <div className="mobile-view">
                    <div className="row m-0 p-0 py-1 alert alert-dismissible d-flex align-items-center" style={{ backgroundImage: `linear-gradient(90deg, rgba(172,227,255,1) 6%, rgba(160,187,255,1) 90%)`, borderRadius: "0px" }}>

                        <div className='col-6'>
                            <span style={{ fontSize: "12px", fontWeight: "600" }}>Get exclusive offers!</span>
                            <div style={{ fontSize: "8px" }}>
                                <span className='pe-1' style={{ fontWeight: "400" }}>Use code</span>
                                <span className='pe-1' style={{ fontWeight: "600" }}>WELCOMETO</span>
                                <span className='ps-1' style={{ fontWeight: "400" }}>on your first booking with </span>
                                <span className='pe-1' style={{ fontWeight: "600" }}>Explore Trip Online app.</span>
                            </div>
                        </div>

                        <div className='col-5'>
                            <div style={{ border: "1px solid #1924bb", color: "#1924bb", padding: "12px 5px", borderRadius: "5px" }} className='d-flex justify-content-center top_download_btn'>
                                <a href={storeLink}
                                >Download App Now <IoMdDownload className='fs-6' /> </a>
                            </div>
                        </div>

                        <div className='col-1 p-0 m-0' style={{ backgroundColor: "none" }}>
                            <button className="w-100" onClick={handleCloseDownloadSection} style={{ fontSize: "10px", backgroundColor: "transparent" }}>
                                <img src={images?.cancel} alt='' style={{ height: "23px", width: "23px" }}>
                                </img>
                            </button>
                        </div>
                   
                    </div>
                </div>
            )} */}
        </>
    )
}
