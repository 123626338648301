export const WhyBookUs = [
    {
        id: 1,
        imgPath: '../images/icons/easy_booking.png',
        title: 'Easy Booking',
        content: 'We offer easy flight bookings and attractive offers'
    },
    {
        id: 2,
        imgPath: '../images/icons/special_deal.png',
        title: 'Special Deals',
        content: 'We offer easy flight bookings and attractive offers'
    },
    {
        id: 3,
        imgPath: '../images/icons/support.png',
        title: '24/7 support',
        content: 'We offer easy flight bookings and attractive offers'
    },
    {
        id: 4,
        imgPath: '../images/icons/lowest_price.png',
        title: 'Lowest Price',
        content: 'We offer easy flight bookings and attractive offers'
    },
]