import React, { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { getallCabsOrdersData } from "../api/Api";

export const CabsOrder = () => {
  const [CabsOrdersData, SetCabsOrdersData] = useState([]);
  const [SelectedCabsData, SetSelectedCabsData] = useState([]);

  const [showCabsOrderDetailsModal, setShowCabsOrderDetailsModal] =
    useState(false);

  const handleCabsOrderDetailsModalClose = () => {
    setShowCabsOrderDetailsModal(false);
    setActiveItem("TripDetails");
  };

  const handleCabsOrderDetailsModalShow = (order) => {
    setShowCabsOrderDetailsModal(true);
    console.log(order);
    SetSelectedCabsData(order);
  };

  const FetchAllCabsOrders = async () => {
    try {
      const res = await getallCabsOrdersData();
      console.log(res);
      if (res?.status === "success") {
        SetCabsOrdersData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [activeItem, setActiveItem] = useState("TripDetails");

  useEffect(() => {
    FetchAllCabsOrders();
  }, []);

  const renderCabsOrderSwitch = () => {
    switch (activeItem) {
      case "TripDetails":
        return (
          <>
            <div>
              <div className="switchPillsTabs">
                <label>SrdvIndex - </label>
                <span> {SelectedCabsData?.TripDetails?.SrdvIndex}</span>
              </div>
              <div className="switchPillsTabs">
                <label>Category - </label>
                <span> {SelectedCabsData?.TripDetails?.Category}</span>
              </div>
              <div className="switchPillsTabs">
                <label>PickUpLocation - </label>
                <span> {SelectedCabsData?.TripDetails?.PickUpLocation}</span>
              </div>
              <div className="switchPillsTabs">
                <label>DropOffLocation - </label>
                <span> {SelectedCabsData?.TripDetails?.DropOffLocation}</span>
              </div>
            </div>
          </>
        );

      case "TravellerInformation":
        return (
          <>
            <div>
              <div className="switchPillsTabs">
                <label>TravellerName - </label>
                <span>
                  {SelectedCabsData?.TravellerInformation?.TravellerName}
                </span>
              </div>
              <div className="switchPillsTabs">
                <label>TravellerGender - </label>
                <span>
                  {SelectedCabsData?.TravellerInformation?.TravellerGender}
                </span>
              </div>
              <div className="switchPillsTabs">
                <label>TravellerGender - </label>
                <span>
                  {SelectedCabsData?.TravellerInformation?.TravellerGender}
                </span>
              </div>
              <div className="switchPillsTabs">
                <label>TravellerEmail - </label>
                <span>
                  {SelectedCabsData?.TravellerInformation?.TravellerEmail}
                </span>
              </div>
              <div className="switchPillsTabs">
                <label>TravellerContact - </label>
                <span>
                  {SelectedCabsData?.TravellerInformation?.TravellerContact}
                </span>
              </div>
            </div>
          </>
        );

      case "BookResult":
        return (
          <>
            <div>
              <div className="switchPillsTabs">
                <label>BookingID - </label>
                <span>{SelectedCabsData?.BookResult?.BookingID}</span>
              </div>
              <div className="switchPillsTabs">
                <label>OperatorID - </label>
                <span>{SelectedCabsData?.BookResult?.OperatorID}</span>
              </div>
              <div className="switchPillsTabs">
                <label>RefID - </label>
                <span>{SelectedCabsData?.BookResult?.RefID}</span>
              </div>
              <div className="switchPillsTabs">
                <label>SegmentID - </label>
                <span>{SelectedCabsData?.BookResult?.SegmentID}</span>
              </div>
              <div className="switchPillsTabs">
                <label>SrdvIndex - </label>
                <span>{SelectedCabsData?.BookResult?.SrdvIndex}</span>
              </div>
              <div className="switchPillsTabs">
                <label>SrdvType - </label>
                <span>{SelectedCabsData?.BookResult?.SrdvType}</span>
              </div>
              <div className="switchPillsTabs">
                <label>Status - </label>
                <span>{SelectedCabsData?.BookResult?.Status}</span>
              </div>
              <div className="switchPillsTabs">
                <label>TraceID - </label>
                <span>{SelectedCabsData?.BookResult?.TraceID}</span>
              </div>
            </div>
          </>
        );

      case "customerInfo":
        return (
          <>
            <div>
              <div className="switchPillsTabs">
                <label>name - </label>
                <span> {SelectedCabsData?.customerInfo?.name}</span>
              </div>
              <div className="switchPillsTabs">
                <label>email - </label>
                <span> {SelectedCabsData?.customerInfo?.email}</span>
              </div>
              <div className="switchPillsTabs">
                <label>mobile - </label>
                <span> {SelectedCabsData?.customerInfo?.mobile}</span>
              </div>
              <div className="switchPillsTabs">
                <label>address - </label>
                <span> {SelectedCabsData?.customerInfo?.address}</span>
              </div>
              <div className="switchPillsTabs">
                <label>state - </label>
                <span> {SelectedCabsData?.customerInfo?.state}</span>
              </div>
              <div className="switchPillsTabs">
                <label>pincode - </label>
                <span> {SelectedCabsData?.customerInfo?.pincode}</span>
              </div>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  const handleCabsOrderSwitch = (profile_item) => {
    setActiveItem(profile_item === activeItem ? "Profile Info" : profile_item);
  };

  return (
    <div>
      <div className="container">
        <Toaster position="top-right" reverseOrder={false} />

        <div className="row">
          <div className="d-flex justify-content-between">
            <div>
              <span style={{ fontWeight: "600", fontSize: "20px" }}>
                Cabs Orders Management
              </span>
            </div>
          </div>
        </div>

        <div className="row py-4 table_user_data" style={{ overflow: "auto" }}>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>S.no</th>
                <th>User Information</th>
                <th>Order Amount</th>
                <th>Date</th>
                <th>Status</th>
                <th>actions</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(CabsOrdersData) && CabsOrdersData.length > 0 ? (
                CabsOrdersData.filter((ele) => ele?.status !== 2) // Filter out rows with status = 2
                  .map((ele, index) => (
                    <tr key={ele?._id}>
                      <td>{index + 1}</td>

                      <td>
                        <div>
                          <span style={{ fontWeight: "600" }}>Name -</span>
                          <span className="ps-1">
                            {ele?.customerInfo?.name}
                          </span>
                        </div>
                        <div>
                          <span style={{ fontWeight: "600" }}>Email -</span>
                          <span className="ps-1">
                            {ele?.customerInfo?.email}
                          </span>
                        </div>
                        <div>
                          <span style={{ fontWeight: "600" }}>Mobile -</span>
                          <span className="ps-1">
                            {ele?.customerInfo?.mobile}
                          </span>
                        </div>

                        <div>
                          <span style={{ fontWeight: "600" }}>Address -</span>
                          <span className="ps-1">
                            {ele?.customerInfo?.address},
                            {ele?.customerInfo?.state},
                            {ele?.customerInfo?.pincode}
                          </span>
                        </div>
                      </td>

                      <td>₹ {ele?.TotalAmount}</td>

                      <td>
                        <div>
                          <span style={{ fontWeight: "600" }}>
                            Pickup Date -
                          </span>
                          <span className="ps-1">
                            {new Date(ele?.PickupDate).toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                          </span>
                        </div>

                        <div>
                          <span style={{ fontWeight: "600" }}>
                            Date of Booking -
                          </span>
                          <span className="ps-1">
                            {new Date(ele?.createdAt).toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                          </span>
                        </div>

                        <div>
                          <span style={{ fontWeight: "600" }}>
                            Time of Booking -
                          </span>
                          <span className="ps-1">
                            {new Date(ele?.createdAt).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              }
                            )}
                          </span>
                        </div>
                      </td>

                      <td>{ele?.BookResult?.HotelBookingStatus}</td>
                      <td>
                        <div
                          className="d-flex px-2 py-1"
                          style={{
                            border: "1px solid gray",
                            cursor: "pointer",
                          }}
                          onClick={() => handleCabsOrderDetailsModalShow(ele)}
                        >
                          Show Details
                        </div>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="12" className="text-center">
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        <div className="row">
          <Modal
            show={showCabsOrderDetailsModal}
            onHide={handleCabsOrderDetailsModalClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Hotel Order Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="col-md-12 pt-2" style={{ fontSize: "14px" }}>
                <div className="d-flex">
                  <div
                    className={` ${
                      activeItem == "TripDetails"
                        ? "activeProfileItems"
                        : "inactiveProfileItems"
                    }`}
                    onClick={() => handleCabsOrderSwitch("TripDetails")}
                  >
                    TripDetails
                  </div>

                  <div
                    className={` ${
                      activeItem == "TravellerInformation"
                        ? "activeProfileItems"
                        : "inactiveProfileItems"
                    }`}
                    onClick={() =>
                      handleCabsOrderSwitch("TravellerInformation")
                    }
                  >
                    TravellerInformation
                  </div>

                  <div
                    className={` ${
                      activeItem == "BookResult"
                        ? "activeProfileItems"
                        : "inactiveProfileItems"
                    }`}
                    onClick={() => handleCabsOrderSwitch("BookResult")}
                  >
                    BookResult
                  </div>

                  <div
                    className={` ${
                      activeItem == "customerInfo"
                        ? "activeProfileItems"
                        : "inactiveProfileItems"
                    }`}
                    onClick={() => handleCabsOrderSwitch("customerInfo")}
                  >
                    customerInfo
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-center Section_Profile_Container">
                <div>{renderCabsOrderSwitch()}</div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};
