import { Base_Url } from "../Url/Url";

export const getAllBuses = async (date) => {
  try {
    const res = await fetch(
      `${Base_Url}/bus/searchbus?source_city=Mumbai&source_code=3534&destination_city=Pune&destination_code=9771&depart_date=2020-06-14`,
      {
        method: "Post",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const GetSeatLayout = async (date) => {
  try {
    const res = await fetch(`${Base_Url}/bus/getseatlayout`, {
      method: "Post",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const BoardingPointDetails = async () => {
  try {
    const res = await fetch(`${Base_Url}/bus/boardingpointdetails`, {
      method: "Post",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const BookBusSeat = async () => {
  try {
    const res = await fetch(`${Base_Url}/bus/bookbusseat`, {
      method: "Post",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};
