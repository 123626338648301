import React, { useEffect, useState } from "react";
import { BookHotelRoom } from "../../api/HotelAPI";

export const HotelBooking = () => {
  const [bookingData, setBookingData] = useState();
  const HandleHotelRoomBooking = async () => {
    const res = await BookHotelRoom();
    if (res?.success == true) {
      setBookingData(res?.data?.BookResult);
    }
  };

  useEffect(() => {
    HandleHotelRoomBooking();
  }, []);
  return (
    <div className="my-4">
      <h3 className="text-center" style={{ color: "green", fontWeight: "600" }}>
        Congratulations, your Hotel Room Booking is confirmed
      </h3>
      <div className="d-flex justify-content-center">
        <table className="border strip w-75">
          <tbody>
            <tr style={{ borderTop: "1px solid #f1f1f1" }}>
              <td className="py-2">
                <span className="ps-3 m-0">Booking Ref No</span>
              </td>
              <td>
                <span
                  className="ps-3"
                  style={{ borderLeft: "1px solid lightgray" }}
                >
                  {bookingData?.BookingRefNo}
                </span>
              </td>
            </tr>
            <tr style={{ borderTop: "1px solid #f1f1f1" }}>
              <td className="py-2">
                <span className="ps-3 m-0">Invoice Number</span>
              </td>
              <td>
                <span
                  className="ps-3"
                  style={{ borderLeft: "1px solid lightgray" }}
                >
                  {bookingData?.InvoiceNumber}
                </span>
              </td>
            </tr>
            <tr style={{ borderTop: "1px solid #f1f1f1" }}>
              <td className="py-2">
                <span className="ps-3 m-0">Confirmation No</span>
              </td>
              <td>
                <span
                  className="ps-3"
                  style={{ borderLeft: "1px solid lightgray" }}
                >
                  {bookingData?.ConfirmationNo}
                </span>
              </td>
            </tr>
            <tr style={{ borderTop: "1px solid #f1f1f1" }}>
              <td className="py-2">
                <span className="ps-3 m-0">Booking Ref No</span>
              </td>
              <td>
                <span
                  className="ps-3"
                  style={{ borderLeft: "1px solid lightgray" }}
                >
                  {bookingData?.BookingRefNo}
                </span>
              </td>
            </tr>
            <tr style={{ borderTop: "1px solid #f1f1f1" }}>
              <td className="py-2">
                <span className="ps-3 m-0">Booking Id</span>
              </td>
              <td>
                <span
                  className="ps-3"
                  style={{ borderLeft: "1px solid lightgray" }}
                >
                  {bookingData?.BookingId}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
