import React, { useState } from "react";
import "../style/Mybookings.css";
import images from "../assets/Images";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";

export const RaiseTicket = () => {
  const [activeItem, setActiveItem] = useState("all");

  const handleItemClick = (formType) => {
    setActiveItem(formType === activeItem ? "all" : formType);
  };

  const navigate = useNavigate();
  const [feedbackMsg, setFeedbackMsg] = useState("");

  const handleTermsAndConditions = () => {
    navigate("/termsandconditions");
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    mobile: Yup.string()
      .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
      .required("Mobile number is required"),
    subject: Yup.string().required("Subject is required"),
    description: Yup.string().required("Description is required"),
    termsAndConditions: Yup.boolean().oneOf(
      [true],
      "Please accept the terms and conditions"
    ),
  });

  const renderForm = () => {
    switch (activeItem) {
      case "all":
        return (
          <>
            <div>
              <style>
                {`
                    @media (max-width: 767px) {
                        .table-mobile td {
                            font-size: 12px;
                        }

                        .table-mobile th {
                            font-size: 12px;
                            font-weight:600
                        }
                    }
                `}
              </style>

              <table
                className="table table-striped table-bordered table-mobile"
                style={{ border: "1.5px solid lightgray" }}
              >
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Subject</th>
                    <th>Created</th>
                    <th>Updated</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>please provide best offer for renewal - testing</td>
                    <td>03 May 2024 13:16 PM</td>
                    <td>06 May 2024 17:52 PM</td>
                    <td>
                      <span
                        style={{
                          background: "lightgreen",
                          color: "white",
                          borderRadius: "5px",
                          padding: "2px 5px",
                        }}
                      >
                        Answered
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>Test Data - Message from web flow india</td>
                    <td>03 May 2024 17:15 PM</td>
                    <td>06 May 2024 16:52 PM</td>
                    <td>
                      <span
                        style={{
                          background: "lightgreen",
                          color: "white",
                          borderRadius: "5px",
                          padding: "2px 5px",
                        }}
                      >
                        Answered
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>3.</td>
                    <td>Test Data - Message from web flow india</td>
                    <td>03 May 2024 17:54 PM</td>
                    <td>06 May 2024 17:55 PM</td>
                    <td>
                      <span
                        style={{
                          background: "orange",
                          color: "white",
                          borderRadius: "5px",
                          padding: "2px 5px",
                        }}
                      >
                        Open
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        );
      case "open":
        return (
          <>
            <div>
              <style>
                {`
                    @media (max-width: 767px) {
                        .table-mobile th,
                        .table-mobile td {
                            font-size: 12px;
                        }
                    }
                `}
              </style>
              <table
                className="table table-striped table-bordered table-mobile"
                style={{ border: "1.5px solid lightgray" }}
              >
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Subject</th>
                    <th>Created</th>
                    <th>Updated</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>please provide best offer for renewal - testing</td>
                    <td>03 May 2024 13:16 PM</td>
                    <td>06 May 2024 17:52 PM</td>
                    <td>
                      <span
                        style={{
                          background: "orange",
                          color: "white",
                          borderRadius: "5px",
                          padding: "2px 5px",
                        }}
                      >
                        open
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>Test Data - Message from web flow india</td>
                    <td>03 May 2024 17:15 PM</td>
                    <td>06 May 2024 16:52 PM</td>
                    <td>
                      <span
                        style={{
                          background: "orange",
                          color: "white",
                          borderRadius: "5px",
                          padding: "2px 5px",
                        }}
                      >
                        open
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>3.</td>
                    <td>Test Data - Message from web flow india</td>
                    <td>03 May 2024 17:54 PM</td>
                    <td>06 May 2024 17:55 PM</td>
                    <td>
                      <span
                        style={{
                          background: "orange",
                          color: "white",
                          borderRadius: "5px",
                          padding: "2px 5px",
                        }}
                      >
                        Open
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        );
      case "answered":
        return (
          <>
            <div>
              <style>
                {`
                    @media (max-width: 767px) {
                        .table-mobile th,
                        .table-mobile td {
                            font-size: 12px;
                        }
                    }
                `}
              </style>
              <table
                className="table table-striped table-bordered table-mobile"
                style={{ border: "1.5px solid lightgray" }}
              >
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Subject</th>
                    <th>Created</th>
                    <th>Updated</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>please provide best offer for renewal - testing</td>
                    <td>03 May 2024 13:16 PM</td>
                    <td>06 May 2024 17:52 PM</td>
                    <td>
                      <span
                        style={{
                          background: "lightgreen",
                          color: "white",
                          borderRadius: "5px",
                          padding: "2px 5px",
                        }}
                      >
                        answered
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>Test Data - Message from web flow india</td>
                    <td>03 May 2024 17:15 PM</td>
                    <td>06 May 2024 16:52 PM</td>
                    <td>
                      <span
                        style={{
                          background: "lightgreen",
                          color: "white",
                          borderRadius: "5px",
                          padding: "2px 5px",
                        }}
                      >
                        answered
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>3.</td>
                    <td>Test Data - Message from web flow india</td>
                    <td>03 May 2024 17:54 PM</td>
                    <td>06 May 2024 17:55 PM</td>
                    <td>
                      <span
                        style={{
                          background: "lightgreen",
                          color: "white",
                          borderRadius: "5px",
                          padding: "2px 5px",
                        }}
                      >
                        answered
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        );
      case "close":
        return (
          <>
            <div>
              <style>
                {`
                    @media (max-width: 767px) {
                        .table-mobile th,
                        .table-mobile td {
                            font-size: 12px;
                        }
                    }
                `}
              </style>
              <table
                className="table table-striped table-bordered table-mobile"
                style={{ border: "1.5px solid lightgray" }}
              >
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Subject</th>
                    <th>Created</th>
                    <th>Updated</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>No tickets found</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        );
      case "createticket":
        return (
          <>
            <style>
              {`
                    @media (max-width: 767px) {
                        .form-container p,
                        .form-container input,
                        .form-container textarea,
                        .form-container select,
                        .form-container label,
                        .form-container button {
                            font-size: 12px !important;
                        }
                    }
                `}
            </style>
            <div className="container form-container">
              <div className="row d-flex justify-content-center pt-2 mb-3">
                <div className="col-md-6 Section_Container">
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      mobile: "",
                      subject: "",
                      description: "",
                      termsAndConditions: false,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, actions) => {
                      // Handle form submission here
                      setFeedbackMsg("Ticket created successfully!");
                      actions.resetForm();
                    }}
                  >
                    {({ isSubmitting, values, handleChange }) => (
                      <Form>
                        <div>
                          <div className="d-flex justify-content-center">
                            <img
                              src={images?.logo_mark}
                              alt="logo"
                              width="80px"
                              height="50px"
                            />
                          </div>

                          <div>
                            <h5 className="text-center pt-2 m-0">
                              Create Ticket
                            </h5>
                            <p
                              className="m-0 text-center"
                              style={{ fontSize: "13px" }}
                            >
                              Please fill out the form to create a support
                              ticket.
                            </p>
                          </div>

                          <div className="row">
                            <div className="col-6">
                              <div className="mt-2">
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Name
                                </p>
                                <div className="input_field d-flex align-items-center bg-white">
                                  <Field
                                    type="text"
                                    name="name"
                                    placeholder="Enter your name"
                                    className="w-100"
                                  />
                                </div>
                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="error text-danger m-0 py-2"
                                />
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mt-2">
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Email
                                </p>
                                <div className="input_field d-flex align-items-center">
                                  <Field
                                    type="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    className="w-100 bg-white"
                                  />
                                </div>
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="error text-danger m-0 py-2"
                                />
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mt-2">
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Mobile
                                </p>
                                <div className="input_field d-flex align-items-center">
                                  <Field
                                    type="text"
                                    name="mobile"
                                    placeholder="Enter your mobile number"
                                    className="w-100"
                                  />
                                </div>
                                <ErrorMessage
                                  name="mobile"
                                  component="div"
                                  className="error text-danger m-0 py-2"
                                />
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mt-2">
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Subject
                                </p>
                                <div className="input_field d-flex align-items-center">
                                  <Field
                                    as="select"
                                    name="subject"
                                    className="w-100 bg-white"
                                  >
                                    <option value="">Select a subject</option>
                                    <option value="Technical Support">
                                      Technical Support
                                    </option>
                                    <option value="Billing">Billing</option>
                                    <option value="General Inquiry">
                                      General Inquiry
                                    </option>
                                  </Field>
                                </div>
                                <ErrorMessage
                                  name="subject"
                                  component="div"
                                  className="error text-danger m-0 py-2"
                                />
                              </div>
                            </div>

                            <div className="col-12">
                              <div className="mt-2">
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Description
                                </p>
                                <div className="input_field d-flex align-items-center ">
                                  <Field
                                    as="textarea"
                                    name="description"
                                    placeholder="Enter your description"
                                    className="w-100"
                                    rows="2"
                                  />
                                </div>
                                <ErrorMessage
                                  name="description"
                                  component="div"
                                  className="error text-danger m-0 py-2"
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="pt-3">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="termsAndConditions"
                                    checked={values.termsAndConditions}
                                    onChange={handleChange}
                                  />
                                  <span className="ps-2">
                                    <span>I have read and agree to the </span>
                                    <span
                                      onClick={handleTermsAndConditions}
                                      className="cursor-pointer text-primary"
                                      style={{ cursor: "pointer" }}
                                    >
                                      terms and conditions
                                    </span>
                                  </span>
                                </label>
                                <ErrorMessage
                                  name="termsAndConditions"
                                  component="div"
                                  className="error text-danger pt-2"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="d-flex justify-content-center">
                            <button
                              type="submit"
                              className="login_btn mt-2 w-50"
                              disabled={isSubmitting}
                            >
                              Submit Ticket
                            </button>
                          </div>

                          {feedbackMsg && (
                            <div className="text-center mt-2">
                              <p className="text-success">{feedbackMsg}</p>
                            </div>
                          )}

                          <Toaster position="top-right" reverseOrder={false} />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container-fluid" style={{ minHeight: "100vh" }}>
      <div className="row pt-3">
        <p className="m-0" style={{ fontSize: "20px", fontWeight: "500" }}>
          My Tickets
        </p>
      </div>

      <div className="row">
        <style>
          {`
                        @media (max-width: 767px) {
                            .ticket_fonts{
                                font-size:13px;
                            }                         
                        }

                        @media (max-width: 400px) {
                            .ticket_fonts{
                                font-size:10px;
                            }                         
                        }
                        }
                    `}
        </style>
        <div className="col-md-12">
          <div className="row px-3 py-2">
            <div className="d-flex p-0">
              <div
                className={`justify-content-center align-items-center ticket_fonts ${
                  activeItem === "all"
                    ? "Myticket_Items_active"
                    : "Myticket_Items_inactive"
                }`}
                onClick={() => handleItemClick("all")}
              >
                All
              </div>
              <div
                className={`justify-content-center align-items-center ticket_fonts ${
                  activeItem === "open"
                    ? "Myticket_Items_active"
                    : "Myticket_Items_inactive"
                }`}
                onClick={() => handleItemClick("open")}
              >
                Open
              </div>
              <div
                className={`justify-content-center align-items-center ticket_fonts ${
                  activeItem === "answered"
                    ? "Myticket_Items_active"
                    : "Myticket_Items_inactive"
                }`}
                onClick={() => handleItemClick("answered")}
              >
                Answered
              </div>
              <div
                className={`justify-content-center align-items-center ticket_fonts ${
                  activeItem === "close"
                    ? "Myticket_Items_active"
                    : "Myticket_Items_inactive"
                }`}
                onClick={() => handleItemClick("close")}
              >
                Closed
              </div>
              <div
                className={`justify-content-center align-items-center ticket_fonts ${
                  activeItem === "createticket"
                    ? "Myticket_Items_active"
                    : "Myticket_Items_inactive"
                }`}
                onClick={() => handleItemClick("createticket")}
              >
                Create Ticket
              </div>
            </div>
          </div>

          <div
            className="row d-flex justify-content-center"
            style={{ marginTop: "3px" }}
          >
            <div className="col" style={{ padding: "0px 10px" }}>
              <div>{renderForm()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
