import React from "react";
import { WhyBookUs } from "../../data/WhyBookUsData";

export const WhyBookWithUs = () => {
    return (
        <>
            <div className="container-fluid desktop-view Section_Container">
                <div className="row px-2">
                    <div className="col-md-6">
                        <div className="homepage_component_heading_txt">Why Book with us ?</div>
                        <div className="Section_text_small_underline"></div>
                    </div>
                </div>

                <div className="row pt-3 px-2">
                    {WhyBookUs.map((ele) => {
                        return (
                            <div key={ele.id} className="col-md-3 col-6">
                                <div className="Section_Cards1">
                                    <img
                                        src={ele.imgPath}
                                        alt=""
                                        className="Section_image py-3"
                                        height="110px"
                                        width="110px"
                                        style={{ objectFit: "contain" }}
                                    ></img>
                                    <div className="Section_text_container">
                                        <p className="fw-bold text-center m-0">{ele.title}</p>
                                        <p className="text-center m-0">{ele.content}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="container-fluid mobile-view mt-2">

                <div className="row d-flex justify-content-between align-items-center">
                    <div className="col-12 d-flex justify-content-center">
                        <p className="m-0" style={{ fontSize: "16px", fontWeight: "600" }}>Why Book with us ?</p>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-2 d-flex justify-content-center">
                            <div className="Section_text_small_underline_mobile"></div>
                        </div>
                    </div>
                </div>

                <div className="row pt-1">
                    {WhyBookUs.map((ele) => {
                        return (
                            <div key={ele.id} className="col-3 p-1">
                                <div className="">
                                    <img
                                        src={ele.imgPath}
                                        alt=""
                                        className="Section_image p-2"
                                        height="60px"
                                        width="60px"
                                        style={{ objectFit: "contain" }}
                                    ></img>
                                    <div>
                                        <p className="text-center m-0 pb-1" style={{ fontSize: "10px", fontWeight: "500" }}>{ele.title}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};
