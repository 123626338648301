import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../../styles/Sidebar.css";
import { Dropdown } from "react-bootstrap";
import "../../styles/UserData.css";

import { LuUsers } from "react-icons/lu";
import { LuMessagesSquare } from "react-icons/lu";
import { GrBlog } from "react-icons/gr";
import { TfiLocationPin } from "react-icons/tfi";
import { MdOutlineLocalOffer } from "react-icons/md";
import { FiPackage } from "react-icons/fi";
import { BsQuestionDiamond } from "react-icons/bs";
import { LuLayoutDashboard } from "react-icons/lu";

export const Sidebar = ({ children }) => {
  const [isSidebarActive, setSidebarActive] = useState(false);

  const toggleSidebar = () => {
    setSidebarActive(!isSidebarActive);
  };

  const handleLogout = () => {
    // dispatch(AdminLogout()).then((res) => {
    //     navigate("/admin/login")
    // }).catch((error) => {
    //     navigate("/admin/login")
    // })
  };

  return (
    <div>
      <div className={`sidebar ${isSidebarActive ? "active" : ""}`}>
        {/* sidebar code */}
        <div className="logo-details">
          <NavLink to="/admin" className="text-decoration-none">
            <i className="fa-solid fa-user"></i>
            <span className="links_name">Admin</span>
          </NavLink>
        </div>
        <ul className="nav-links p-0">
          <li>
            <NavLink to="/admin">
              <div>
                <LuLayoutDashboard />
              </div>
              <span className="links_name">Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/user">
              <div>
                <LuUsers />
              </div>
              <span className="links_name">User Management</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/order">
              <div>
                <LuUsers />
              </div>
              <span className="links_name">Order Management</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/cabsorder">
              <div>
                <LuUsers />
              </div>
              <span className="links_name">Cabs Order</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/testimonials">
              <div>
                <LuMessagesSquare />
              </div>
              <span className="links_name">Testimonials</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/admin/dailytravelblogs">
              <div>
                <GrBlog />
              </div>
              <span className="links_name">Daily Travel Blogs</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/touristdestination">
              <div>
                <TfiLocationPin />
              </div>
              <span className="links_name">Top Tourist Destination</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/faq">
              <div>
                <BsQuestionDiamond />
              </div>
              <span className="links_name">FAQ's</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/specialoffers">
              <div>
                <MdOutlineLocalOffer />
              </div>
              <span className="links_name">Special Offers</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/holidaypackages">
              <div>
                <FiPackage />
              </div>
              <span className="links_name">Holiday Packages</span>
            </NavLink>
          </li>
        </ul>
      </div>

      {/* header */}

      <section className="home-section">
        <nav>
          <div className="sidebar-button">
            <i className="fa-solid fa-bars" onClick={toggleSidebar}></i>
            <span className="dashboard">Dashboard</span>
          </div>
          <div className="search-box">
            <input type="text" name="" placeholder="Search..." id="" />
            <i className="bx bx-search"></i>
          </div>

          <div className="profile-details">
            0 <span className="admin_name">User name</span>
            {/* 0            <span className="admin_name">{adminLoggedINData[0]?.name}</span> */}
            <Dropdown className="text-center">
              <Dropdown.Toggle className="dropdown_btn" id="dropdown-basic">
                {/* <img src={adminLoggedINData[0]?.profile} alt="" /> */}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={handleLogout}>
                  <i class="fa-solid fa-right-from-bracket"></i>
                  &nbsp;&nbsp;&nbsp; Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </nav>
        <div className="home-content">{children}</div>
      </section>
    </div>
  );
};
