import React, { useEffect, useState } from "react";
import images from "../../assets/Images";
import { MdFlight } from "react-icons/md";
import { getAirlineLogo, roundTripflightsApi } from "../../api/FlightsAPI";
import { useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { PiSunHorizonLight } from "react-icons/pi";
import { LiaSun } from "react-icons/lia";
import { PiCloudSunDuotone } from "react-icons/pi";
import { PiCloudMoon } from "react-icons/pi";
import { FaArrowRightLong } from "react-icons/fa6";

export const RoundTrip = () => {
  const [loading, setLoading] = useState(true);

  const [modalShow, setModalShow] = useState(false);

  const handleRoundTripModal = () => {
    setModalShow(true);
  };

  const [switchModalShow, setSwitchModalShow] = useState("switchModal-1");

  const handleFormSelection = (formName) => {
    setSwitchModalShow(formName);
  };

  const [selectedFlight, setSelectedFlight] = useState(null);
  const [selectedReturnFlight, setSelectedReturnFlight] = useState(null);

  const handleFlightSelection = (flight) => {
    setSelectedFlight(flight);
  };
  const handleReturnFlightSelection = (flight) => {
    setSelectedReturnFlight(flight);
  };

  const handleResetAll = () => {
    window.location.reload();
  };

  const convertDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const hours = dateTime.getHours().toString().padStart(2, "0");
    const minutes = dateTime.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  function convertMinutesToHHMM(minutes) {
    if (typeof minutes !== "number" || minutes < 0) {
      throw new Error(
        "Invalid input. Please provide a non-negative number of minutes."
      );
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(remainingMinutes).padStart(2, "0");
    return `${formattedHours} h:${formattedMinutes} m`;
  }

  const [OneWayData, setOneWayData] = useState([]);
  const [TwoWayData, setTwoWayData] = useState([]);

  function getDateFromString(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${year}-${month}-${day}`;
  }

  const {
    fromAirportLocationName,
    fromData,
    toAirportLocationName,
    toData,
    departureDate,
    arrivalDate,
  } = useParams();

  const [maxOnwardFlightPrice, setMaxOnwardFlightPrice] = useState(0);
  const [minOnwardFlightPrice, setMinOnwardFlightPrice] = useState(0);
  const [maxOnwardPrice, setMaxOnwardPrice] = useState(maxOnwardFlightPrice);
  const [flightNames, setFlightNames] = useState([]);
  const [selectedFlightNames, setSelectedFlightNames] = useState([]);

  const [maxReturnFlightPrice, setMaxReturnFlightPrice] = useState(0);
  const [minReturnFlightPrice, setMinReturnFlightPrice] = useState(0);
  const [maxReturnPrice, setMaxReturnPrice] = useState(maxReturnFlightPrice);
  const [flightNamesReturn, setFlightNamesReturn] = useState([]);
  const [selectedFlightNamesReturn, setSelectedFlightNamesReturn] = useState(
    []
  );

  useEffect(() => {
    setMaxOnwardPrice(maxOnwardFlightPrice);
  }, [maxOnwardFlightPrice]);

  useEffect(() => {
    setMaxReturnPrice(maxReturnFlightPrice);
  }, [maxReturnFlightPrice]);

  async function RoundTripFlightApiCall() {
    try {
      const parsedDepartureDate = getDateFromString(departureDate);
      const parsedArrivalDate = getDateFromString(arrivalDate);
      const originSend = fromData;
      const departureSend = toData;

      const Round_Trip_Data = {
        originSend,
        departureSend,
        departureDate: parsedDepartureDate,
        arrivalDate: parsedArrivalDate,
      };

      if (parsedDepartureDate) {
        setdepardate(parsedDepartureDate);
      }

      console.log(Round_Trip_Data)

      const data = await roundTripflightsApi(Round_Trip_Data);
      console.log(data);


    if (data?.status === "success" && Array.isArray(data?.data?.Results)) {
        const RoundTrip_OneWay_flights = data?.data?.Results[0];
        const RoundTrip_TwoWay_flights = data?.data?.Results[1];
        setOneWayData(RoundTrip_OneWay_flights);
        setTwoWayData(RoundTrip_TwoWay_flights);

        const prices = RoundTrip_OneWay_flights.map((flight) =>
          parseInt(flight?.OfferedFare)
        );
        setMinOnwardFlightPrice(Math.min(...prices));
        setMaxOnwardFlightPrice(Math.max(...prices));

        const Returnprices = RoundTrip_TwoWay_flights.map((flight) =>
          parseInt(flight?.OfferedFare)
        );
        setMinReturnFlightPrice(Math.min(...Returnprices));
        setMaxReturnFlightPrice(Math.max(...Returnprices));

        const uniqueFlightNames = [
          ...new Set(
            RoundTrip_OneWay_flights?.map(
              (flight) => flight?.Segments?.[0]?.[0]?.Airline?.AirlineName
            )
          ),
        ];
        setFlightNames(uniqueFlightNames);

        const uniqueFlightNamesReturn = [
          ...new Set(
            RoundTrip_TwoWay_flights?.map(
              (flight) => flight?.Segments?.[0]?.[0]?.Airline?.AirlineName
            )
          ),
        ];
        setFlightNamesReturn(uniqueFlightNamesReturn);
      } else {
        console.error("Unexpected data format", data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  }
  useEffect(() => {
    RoundTripFlightApiCall();
  }, []);

  // Flights Details popup

  const [showOnwardFlights, setShowOnwardFlights] = useState(false);
  const [showReturnFlights, setShowReturnFlights] = useState(false);

  const handleOnwardFlightModalClose = () => setShowOnwardFlights(false);
  const handleOnwardFlightModalShow = () => setShowOnwardFlights(true);
  const handleReturnFlightModalClose = () => setShowReturnFlights(false);
  const handleReturnFlightModalShow = () => setShowReturnFlights(true);

  const handleOnwardFlightDetails = async (flight) => {
    console.log(flight);
    handleOnwardFlightModalShow();
  };

  const handleReturnFlightDetails = async (flight) => {
    console.log(flight);
    handleReturnFlightModalShow();
  };

  const [flightDetails, setFlightDetails] = useState("flightdetails");
  const handleItemClick = (id) => {
    setFlightDetails(id === flightDetails ? "flightdetails" : id);
  };

  const [returnFlightDetails, setReturnFlightDetails] =
    useState("flightdetails");

  const handleReturnItemClick = (id) => {
    setReturnFlightDetails(id === returnFlightDetails ? "flightdetails" : id);
  };

  const calculateLayoverTime = (arrivalTime, departureTime) => {
    const arrTime = new Date(arrivalTime);
    const depTime = new Date(departureTime);
    const diffMs = depTime - arrTime;
    const diffHrs = Math.floor(diffMs / 1000 / 60 / 60);
    const diffMins = Math.floor((diffMs / 1000 / 60) % 60);
    return `${diffHrs}h ${diffMins}m`;
  };

  const [depardate, setdepardate] = useState();

  const formatDate = (dateString) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const renderReturnFlightDetails = () => {
    switch (returnFlightDetails) {
      case "flightdetails":
        return (
          <>
            {selectedReturnFlight?.Segments?.map((segment, index) => (
              <div className="row px-3" key={index}>
                {segment?.map((ele1, idx) => (
                  <div key={idx}>
                    <div className="row d-flex justify-content-between pb-1">
                      <div
                        className="col-md-12 d-flex justify-content-between"
                        style={{ border: "1px solid lightgray" }}
                      >
                        <div className="d-flex align-items-center py-2">
                          <div>
                            {logo[ele1?.Airline?.AirlineCode] ? (
                              <img
                                src={logo[ele1?.Airline?.AirlineCode]}
                                alt={`${ele1?.Airline?.AirlineCode} logo`}
                                height="50px"
                                width="50px"
                              />
                            ) : (
                              <MdFlight className="fs-3 text-info" />
                            )}
                          </div>

                          <div>
                            <p className="m-0">
                              <div className="px-2">
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {ele1?.Airline?.AirlineName}
                                </p>
                                <p className="m-0" style={{ fontSize: "10px" }}>
                                  {ele1?.Airline?.AirlineCode} |
                                  {ele1?.Airline?.FlightNumber}
                                </p>
                              </div>
                            </p>
                          </div>
                        </div>

                        <div className="d-flex align-items-center">
                          <div className="" style={{ fontSize: "13px" }}>
                            <span className="text-center p-0 m-0">
                              {selectedReturnFlight?.Segments[0]
                                ?.slice(0, 1)
                                ?.map((ele1) => ele1?.Origin?.CityName)}
                            </span>
                            <span className="px-1">
                              <i className="fa fa-arrow-right"></i>
                            </span>
                            <span>
                              {(() => {
                                let arrayLastIndex =
                                  selectedReturnFlight?.Segments[0].length - 1;
                                let cityName =
                                  selectedReturnFlight?.Segments[0][
                                    arrayLastIndex
                                  ]?.Destination?.CityName;
                                return cityName ? (
                                  <span>{cityName}</span>
                                ) : (
                                  <span>No city found</span>
                                );
                              })()}
                            </span>
                          </div>

                          <div className="ps-2">
                            <p
                              className="text-end m-0"
                              style={{ fontSize: "13px", fontWeight: "600" }}
                            >
                              {formatDate(depardate)}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-md-12 py-2 d-flex justify-content-between"
                        style={{ border: "1px solid lightgray" }}
                      >
                        <div className="row w-100 d-flex justify-content-between">
                          <div
                            className="col-md-4 col-5 m-0 px-3"
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            <p className="p-0 m-0">{ele1?.Origin?.CityName}</p>
                            <p className="p-0 m-0">
                              {ele1?.Origin?.AirportName}
                            </p>
                            <p className="m-0">
                              {convertDateTime(ele1?.DepTime)}
                            </p>
                            <p
                              className="m-0"
                              style={{ fontSize: "13px", fontWeight: "600" }}
                            >
                              {formatDate(depardate)}
                            </p>
                          </div>

                          <div className="col-md-4 col-1 fs-1 text-center">
                            <span className="px-1">
                              <i className="fa fa-arrow-right fs-5"></i>
                            </span>
                            <span className="bg-info">
                              {segment[idx + 1] && (
                                <div className="col-md-12">
                                  <p
                                    className="m-0 text-center"
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "500",
                                      color: "#04a9fe",
                                    }}
                                  >
                                    Change of planes :
                                  </p>
                                  <p
                                    className="m-0 text-center"
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {calculateLayoverTime(
                                      ele1?.ArrTime,
                                      segment[idx + 1]?.DepTime
                                    )}
                                    Layover in
                                    {segment[idx + 1]?.Origin?.CityName}
                                  </p>
                                </div>
                              )}
                            </span>
                          </div>

                          <div
                            className="col-md-4 col-5 m-0 p-0 text-end"
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            <p className="p-0 m-0">
                              {ele1?.Destination?.CityName}
                            </p>
                            <p className="p-0 m-0">
                              {ele1?.Destination?.AirportName}
                            </p>
                            <p className="p-0 m-0">
                              {convertDateTime(ele1?.ArrTime)}
                            </p>
                            <p
                              className="m-0"
                              style={{ fontSize: "13px", fontWeight: "600" }}
                            >
                              {formatDate(depardate)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </>
        );

      case "faredetails":
        return (
          <>
            <table className="border strip w-100">
              <tbody>
                <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                  <td className="py-2">
                    <p className="ps-3 m-0">Base Price</p>
                  </td>
                  <td>
                    <p
                      className="ps-3"
                      style={{ borderLeft: "1px solid lightgray" }}
                    >{`₹${parseInt(
                      selectedReturnFlight?.FareDataMultiple?.slice(0, 1)?.map(
                        (ele1) => ele1?.Fare?.BaseFare
                      )
                    ).toLocaleString()}`}</p>
                  </td>
                </tr>
                <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                  <td className="py-2">
                    <p className="ps-3 m-0">Offered Fare</p>
                  </td>
                  <td>
                    <p
                      className="ps-3"
                      style={{ borderLeft: "1px solid lightgray" }}
                    >{`₹${parseInt(
                      selectedReturnFlight?.FareDataMultiple?.slice(0, 1)?.map(
                        (ele1) => ele1?.Fare?.OfferedFare
                      )
                    ).toLocaleString()}`}</p>
                  </td>
                </tr>
                <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                  <td className="py-2">
                    <p className="ps-3 m-0">Tax</p>
                  </td>
                  <td>
                    <p
                      className="ps-3"
                      style={{ borderLeft: "1px solid lightgray" }}
                    >{`₹${parseInt(
                      selectedReturnFlight?.FareDataMultiple?.slice(0, 1)?.map(
                        (ele1) => ele1?.Fare?.Tax
                      )
                    ).toLocaleString()}`}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        );

      case "luggagedetails":
        return (
          <>
            <table className="border strip w-100">
              <tbody>
                <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                  <td className="py-2">
                    <p className="ps-3 m-0">Luggage(Kg)</p>
                  </td>
                  <td>
                    <p
                      className="m-0 ps-3"
                      style={{ borderLeft: "1px solid lightgray" }}
                    >
                      {selectedReturnFlight?.FareDataMultiple[0]
                        ?.FareSegments[0]?.Baggage
                        ? selectedReturnFlight?.FareDataMultiple[0]
                            ?.FareSegments[0]?.Baggage
                        : "6kg"}
                    </p>
                  </td>
                </tr>
                <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                  <td className="py-2">
                    <p className="ps-3 m-0">Cabin(Kg)</p>
                  </td>
                  <td>
                    <p
                      className="m-0 ps-3"
                      style={{ borderLeft: "1px solid lightgray" }}
                    >
                      
                      {
                        selectedReturnFlight?.FareDataMultiple[0]
                          ?.FareSegments[0]?.CabinBaggage
                      }
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        );

      default:
        return null;
    }
  };

  const renderForm = () => {
    switch (flightDetails) {
      case "flightdetails":
        return (
          <>
            {selectedFlight.Segments.map((segment, index) => (
              <div className="row px-3" key={index}>
                {segment.map((ele1, idx) => (
                  <div key={idx}>
                    <div className="row d-flex justify-content-between pb-1">
                      <div
                        className="col-md-12 d-flex justify-content-between"
                        style={{ border: "1px solid lightgray" }}
                      >
                        <div className="d-flex align-items-center py-2">
                          <div>
                            {logo[ele1?.Airline?.AirlineCode] ? (
                              <img
                                src={logo[ele1?.Airline?.AirlineCode]}
                                alt={`${ele1?.Airline?.AirlineCode} logo`}
                                height="50px"
                                width="50px"
                              />
                            ) : (
                              <MdFlight className="fs-3 text-info" />
                            )}
                          </div>

                          <div>
                            <p className="m-0">
                              <div className="px-2">
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {ele1?.Airline?.AirlineName}
                                </p>
                                <p className="m-0" style={{ fontSize: "10px" }}>
                                  {ele1?.Airline?.AirlineCode} |
                                  {ele1?.Airline?.FlightNumber}
                                </p>
                              </div>
                            </p>
                          </div>
                        </div>

                        <div className="d-flex align-items-center">
                          <div className="" style={{ fontSize: "13px" }}>
                            <span className="text-center p-0 m-0">
                              {selectedFlight?.Segments[0]
                                ?.slice(0, 1)
                                ?.map((ele1) => ele1?.Origin?.CityName)}
                            </span>
                            <span className="px-1">
                              <i className="fa fa-arrow-right"></i>
                            </span>
                            <span>
                              {(() => {
                                let arrayLastIndex =
                                  selectedFlight?.Segments[0].length - 1;
                                let cityName =
                                  selectedFlight?.Segments[0][arrayLastIndex]
                                    ?.Destination?.CityName;
                                return cityName ? (
                                  <span>{cityName}</span>
                                ) : (
                                  <span>No city found</span>
                                );
                              })()}
                            </span>
                          </div>

                          <div className="ps-2">
                            <p
                              className="text-end m-0"
                              style={{ fontSize: "13px", fontWeight: "600" }}
                            >
                              {formatDate(depardate)}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-md-12 py-2 d-flex justify-content-between"
                        style={{ border: "1px solid lightgray" }}
                      >
                        <div className="row w-100 d-flex justify-content-between">
                          <div
                            className="col-md-4 col-5 m-0 px-3"
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            <p className="p-0 m-0">{ele1?.Origin?.CityName}</p>
                            <p className="p-0 m-0">
                              {ele1?.Origin?.AirportName}
                            </p>
                            <p className="m-0">
                              {convertDateTime(ele1?.DepTime)}
                            </p>
                            <p
                              className="m-0"
                              style={{ fontSize: "13px", fontWeight: "600" }}
                            >
                              {formatDate(depardate)}
                            </p>
                          </div>

                          <div className="col-md-4 col-1 fs-1 text-center">
                            <span className="px-1">
                              <i className="fa fa-arrow-right fs-5"></i>
                            </span>
                            <span className="bg-info">
                              {segment[idx + 1] && (
                                <div className="col-md-12">
                                  <p
                                    className="m-0 text-center"
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "500",
                                      color: "#04a9fe",
                                    }}
                                  >
                                    Change of planes :
                                  </p>
                                  <p
                                    className="m-0 text-center"
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {calculateLayoverTime(
                                      ele1?.ArrTime,
                                      segment[idx + 1]?.DepTime
                                    )}
                                    Layover in
                                    {segment[idx + 1]?.Origin?.CityName}
                                  </p>
                                </div>
                              )}
                            </span>
                          </div>

                          <div
                            className="col-md-4 col-5 m-0 p-0 text-end"
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            <p className="p-0 m-0">
                              {ele1?.Destination?.CityName}
                            </p>
                            <p className="p-0 m-0">
                              {ele1?.Destination?.AirportName}
                            </p>
                            <p className="p-0 m-0">
                              {convertDateTime(ele1?.ArrTime)}
                            </p>
                            <p
                              className="m-0"
                              style={{ fontSize: "13px", fontWeight: "600" }}
                            >
                              {formatDate(depardate)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </>
        );

      case "faredetails":
        return (
          <>
            <table className="border strip w-100">
              <tbody>
                <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                  <td className="py-2">
                    <p className="ps-3 m-0">Base Price</p>
                  </td>
                  <td>
                    <p
                      className="ps-3"
                      style={{ borderLeft: "1px solid lightgray" }}
                    >{`₹${parseInt(
                      selectedFlight?.FareDataMultiple?.slice(0, 1)?.map(
                        (ele1) => ele1?.Fare?.BaseFare
                      )
                    ).toLocaleString()}`}</p>
                  </td>
                </tr>
                <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                  <td className="py-2">
                    <p className="ps-3 m-0">Offered Fare</p>
                  </td>
                  <td>
                    <p
                      className="ps-3"
                      style={{ borderLeft: "1px solid lightgray" }}
                    >{`₹${parseInt(
                      selectedFlight?.FareDataMultiple?.slice(0, 1)?.map(
                        (ele1) => ele1?.Fare?.OfferedFare
                      )
                    ).toLocaleString()}`}</p>
                  </td>
                </tr>
                <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                  <td className="py-2">
                    <p className="ps-3 m-0">Tax</p>
                  </td>
                  <td>
                    <p
                      className="ps-3"
                      style={{ borderLeft: "1px solid lightgray" }}
                    >{`₹${parseInt(
                      selectedFlight?.FareDataMultiple?.slice(0, 1)?.map(
                        (ele1) => ele1?.Fare?.Tax
                      )
                    ).toLocaleString()}`}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        );

      case "luggagedetails":
        return (
          <>
            <table className="border strip w-100">
              <tbody>
                <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                  <td className="py-2">
                    <p className="ps-3 m-0">Luggage(Kg)</p>
                  </td>
                  <td>
                    <p
                      className="m-0 ps-3"
                      style={{ borderLeft: "1px solid lightgray" }}
                    >
                      {selectedFlight?.FareDataMultiple[0]?.FareSegments[0]
                        ?.Baggage
                        ? selectedFlight?.FareDataMultiple[0]?.FareSegments[0]
                            ?.Baggage
                        : "6kg"}
                    </p>
                  </td>
                </tr>
                <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                  <td className="py-2">
                    <p className="ps-3 m-0">Cabin(Kg)</p>
                  </td>
                  <td>
                    <p
                      className="m-0 ps-3"
                      style={{ borderLeft: "1px solid lightgray" }}
                    >
                      
                      {
                        selectedFlight?.FareDataMultiple[0]?.FareSegments[0]
                          ?.CabinBaggage
                      }
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        );

      default:
        return null;
    }
  };

  const [logo, setLogo] = useState({});

  async function handleFlightLogo(flight) {
    try {
      const airlineCode = flight?.Segments?.[0][0]?.Airline?.AirlineCode;
      if (airlineCode && !logo[airlineCode]) {
        const res = await getAirlineLogo(airlineCode);
        setLogo((prevLogo) => ({
          ...prevLogo,
          [airlineCode]: res?.data,
        }));
      } else {
        console.log("Airline code not found in the flight object.");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const parseFare = (fare) => {
    if (!fare) return 0;
    return parseInt(String(fare).replace(/,/g, ""), 10) || 0;
  };

  const calculateTotalFare = (flight, returnFlight) => {
    const offeredFare = flight ? parseFare(flight.OfferedFare) : 0;
    const offeredReturnFare = returnFlight
      ? parseFare(returnFlight.OfferedFare)
      : 0;
    return offeredFare + offeredReturnFare;
  };

  const totalFare = calculateTotalFare(selectedFlight, selectedReturnFlight);

  // Onward_Filter

  const [departureIntervalsOnward, setDepartureIntervalsOnward] = useState([]);
  const [arrivalIntervalsOnward, setArrivalIntervalsOnward] = useState([]);

  const filterFlightsByIntervalOnward = (
    flights,
    depIntervals,
    arrIntervals
  ) => {
    if (depIntervals.length === 0 && arrIntervals.length === 0) return flights;

    return flights.filter((flight) => {
      const departureTime = new Date(
        flight?.Segments?.[0]?.[0]?.DepTime
      ).getHours();
      const arrivalTime = new Date(
        flight?.Segments?.[0]?.[flight?.Segments[0].length - 1]?.ArrTime
      ).getHours();

      const depMatch =
        depIntervals.length === 0 ||
        depIntervals.some((interval) => {
          const [start, end] = interval.split("-").map(Number);
          return departureTime >= start && departureTime < end;
        });

      const arrMatch =
        arrIntervals.length === 0 ||
        arrIntervals.some((interval) => {
          const [start, end] = interval.split("-").map(Number);
          return arrivalTime >= start && arrivalTime < end;
        });

      return depMatch && arrMatch;
    });
  };

  const filteredOneWayData = filterFlightsByIntervalOnward(
    OneWayData?.filter(
      (flight) =>
        parseInt(flight?.OfferedFare) <= maxOnwardPrice &&
        (selectedFlightNames?.length === 0 ||
          selectedFlightNames?.includes(
            flight?.Segments?.[0]?.[0]?.Airline?.AirlineName
          ))
    ),
    departureIntervalsOnward,
    arrivalIntervalsOnward
  );

  const handleFlightNameChange = (name) => {
    setSelectedFlightNames((prevState) =>
      prevState.includes(name)
        ? prevState.filter((selectedName) => selectedName !== name)
        : [...prevState, name]
    );
  };

  const handlePriceOnwardChange = (e) => {
    setMaxOnwardPrice(parseInt(e.target.value));
  };

  useEffect(() => {
    if (Array.isArray(OneWayData)) {
      OneWayData.forEach((flight) => {
        handleFlightLogo(flight);
      });
    }
  }, [OneWayData]);

  const handleIntervalChangeOnward = (interval, isChecked) => {
    setDepartureIntervalsOnward((prev) =>
      isChecked ? [...prev, interval] : prev.filter((i) => i !== interval)
    );
  };

  const handleArrivalIntervalChangeOnward = (interval, isChecked) => {
    setArrivalIntervalsOnward((prev) =>
      isChecked ? [...prev, interval] : prev.filter((i) => i !== interval)
    );
  };

  // Return_Filter

  const [departureIntervals, setDepartureIntervals] = useState([]);
  const [arrivalIntervals, setArrivalIntervals] = useState([]);

  const filterFlightsByInterval = (flights, depIntervals, arrIntervals) => {
    if (depIntervals.length === 0 && arrIntervals.length === 0) return flights;

    return flights.filter((flight) => {
      const departureTime = new Date(
        flight?.Segments?.[0]?.[0]?.DepTime
      ).getHours();
      const arrivalTime = new Date(
        flight?.Segments?.[0]?.[flight?.Segments[0].length - 1]?.ArrTime
      ).getHours();

      const depMatch =
        depIntervals.length === 0 ||
        depIntervals.some((interval) => {
          const [start, end] = interval.split("-").map(Number);
          return departureTime >= start && departureTime < end;
        });

      const arrMatch =
        arrIntervals.length === 0 ||
        arrIntervals.some((interval) => {
          const [start, end] = interval.split("-").map(Number);
          return arrivalTime >= start && arrivalTime < end;
        });

      return depMatch && arrMatch;
    });
  };

  const filteredTwoWayData = filterFlightsByInterval(
    TwoWayData?.filter(
      (flight) =>
        parseInt(flight?.OfferedFare) <= maxReturnPrice &&
        (selectedFlightNamesReturn?.length === 0 ||
          selectedFlightNamesReturn?.includes(
            flight?.Segments?.[0]?.[0]?.Airline?.AirlineName
          ))
    ),
    departureIntervals,
    arrivalIntervals
  );

  const handleFlightNameReturn = (name) => {
    setSelectedFlightNamesReturn((prevState) =>
      prevState.includes(name)
        ? prevState.filter((selectedName) => selectedName !== name)
        : [...prevState, name]
    );
  };

  const handlePriceReturnChange = (e) => {
    setMaxReturnPrice(parseInt(e.target.value));
  };

  const handleIntervalChange = (interval, isChecked) => {
    const newIntervals = isChecked
      ? [...departureIntervals, interval]
      : departureIntervals.filter((i) => i !== interval);
    setDepartureIntervals(newIntervals);
  };

  const handleArrivalIntervalChange = (interval, isChecked) => {
    const newIntervals = isChecked
      ? [...arrivalIntervals, interval]
      : arrivalIntervals.filter((i) => i !== interval);
    setArrivalIntervals(newIntervals);
  };

  const getIconOnward = (interval) => {
    switch (interval) {
      case "0-6":
        return <PiSunHorizonLight />;
      case "6-12":
        return <LiaSun />;
      case "12-18":
        return <PiCloudSunDuotone />;
      case "18-24":
        return <PiCloudMoon />;
      default:
        return null;
    }
  };

  return (
    <>
      <div>
        {loading ? (
          <div className="row d-flex justify-content-center p-4">
            <div className="col-6">
              <div className="loader_container">
                <div
                  className="  d-flex align-items-center justify-content-center"
                  style={{ height: "200px" }}
                >
                  <img
                    src={images.Flight_Loader_Transparent}
                    height={170}
                    width={170}
                  ></img>
                </div>
                <p
                  className="text-center m-0"
                  style={{ fontSize: "20px", fontWeight: "500" }}
                >
                  Please Wait ...
                </p>
                <p className="text-center">
                  We are searching best flights for you
                </p>
                <p className="text-center">
                  <span style={{ fontSize: "20px", fontWeight: "600" }}>
                    {fromAirportLocationName}
                  </span>
                  <span
                    className="px-2"
                    style={{ fontSize: "20px", fontWeight: "700" }}
                  >
                    &#8644;
                  </span>
                  <span style={{ fontSize: "20px", fontWeight: "600" }}>
                    {toAirportLocationName}
                  </span>
                </p>
                <p className="text-center text-danger m-0">
                  Do not refresh or close the window
                </p>
                <div className="d-flex justify-content-center pb-3">
                  <div>
                    <span className="pe-2" style={{ fontWeight: "600" }}>
                      Departure Date :
                    </span>
                    <span className="pe-2">{formatDate(depardate)}</span>
                  </div>
                  <div className="pe-3">
                    <span style={{ fontWeight: "600" }}>Arrival Date : </span>
                    <span> {formatDate(arrivalDate)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fluid" style={{ position: "relative" }}>
            {/* Flights Detail Modal  */}

            <div className="row  px-2">
              <Modal
                show={showOnwardFlights}
                onHide={handleOnwardFlightModalClose}
                size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Flight Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {selectedFlight && (
                    <>
                      <div className="gap-2 d-flex pb-3">
                        <span
                          className={`py-1 px-3 ${
                            flightDetails === "flightdetails"
                              ? "activeOfferBtnClass"
                              : "inactiveOfferBtnClass"
                          }`}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleItemClick("flightdetails")}
                        >
                          Flights Details
                        </span>
                        <span
                          className={` py-1 px-3 ${
                            flightDetails === "faredetails"
                              ? "activeOfferBtnClass"
                              : "inactiveOfferBtnClass"
                          }`}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleItemClick("faredetails")}
                        >
                          Fare Details
                        </span>
                        <span
                          className={` py-1 px-3 ${
                            flightDetails === "luggagedetails"
                              ? "activeOfferBtnClass"
                              : "inactiveOfferBtnClass"
                          }`}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleItemClick("luggagedetails")}
                        >
                          Luggage Details
                        </span>
                      </div>
                      <div>{renderForm()}</div>
                    </>
                  )}
                </Modal.Body>
              </Modal>

              <Modal
                show={showReturnFlights}
                onHide={handleReturnFlightModalClose}
                size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Return Flight Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="gap-2 d-flex pb-3">
                    <span
                      className={`py-1 px-3 ${
                        returnFlightDetails === "flightdetails"
                          ? "activeOfferBtnClass"
                          : "inactiveOfferBtnClass"
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleReturnItemClick("flightdetails")}
                    >
                      Flights Details
                    </span>
                    <span
                      className={`py-1 px-3 ${
                        returnFlightDetails === "faredetails"
                          ? "activeOfferBtnClass"
                          : "inactiveOfferBtnClass"
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleReturnItemClick("faredetails")}
                    >
                      Fare Details
                    </span>
                    <span
                      className={`py-1 px-3 ${
                        returnFlightDetails === "luggagedetails"
                          ? "activeOfferBtnClass"
                          : "inactiveOfferBtnClass"
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleReturnItemClick("luggagedetails")}
                    >
                      Luggage Details
                    </span>
                  </div>
                  <div>{renderReturnFlightDetails()}</div>
                </Modal.Body>
              </Modal>
            </div>

            {/* Bottom Round Trip Flights */}

            <div className="row  px-2">
              <div>
                {selectedFlight || selectedReturnFlight ? (
                  <div className="row">
                    <div
                      style={{
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 3,
                        marginLeft: "7px",
                        // backgroundColor: "white",
                        // borderTop: "1px solid gray",
                      }}
                      className="d-flex justify-content-end"
                    >
                      <div className="col-3 p-1 bg-white">
                        <div>
                          {selectedFlight ? (
                            <div
                              className="p-2"
                              style={{
                                border: "1px solid gray",
                                borderRadius: "10px",
                              }}
                            >
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex">
                                  <div>
                                    {logo[
                                      selectedFlight?.Segments?.[0]?.[0]
                                        ?.Airline?.AirlineCode
                                    ] ? (
                                      <img
                                        src={
                                          logo[
                                            selectedFlight?.Segments?.[0]?.[0]
                                              .Airline?.AirlineCode
                                          ]
                                        }
                                        height="25px"
                                        width="25px"
                                        alt={`${selectedFlight?.Segments?.[0]?.[0].Airline?.AirlineCode} logo`}
                                      />
                                    ) : (
                                      <MdFlight className="fs-3 text-info" />
                                    )}
                                  </div>
                                  <div className="ps-2">
                                    <span className="m-0">
                                      {
                                        selectedFlight?.Segments?.[0]?.[0]
                                          ?.Airline?.AirlineName
                                      }
                                    </span>
                                    <span
                                      className="text-secondary m-0 ps-2"
                                      style={{ fontSize: "11px" }}
                                    >
                                      (
                                      {
                                        selectedFlight?.Segments?.[0]?.[0]
                                          ?.Airline?.AirlineCode
                                      }
                                      |
                                      {
                                        selectedFlight?.Segments?.[0]?.[0]
                                          ?.Airline?.FlightNumber
                                      }
                                      )
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <span
                                    className="m-0 text-end"
                                    style={{
                                      color: "green",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {`₹${parseFare(
                                      selectedFlight?.OfferedFare
                                    ).toLocaleString()}`}
                                  </span>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between">
                                <div>
                                  <span>
                                    {
                                      selectedFlight?.Segments?.[0]?.[0]?.Origin
                                        ?.CityName
                                    }
                                  </span>
                                  <p className="m-0">
                                    {convertDateTime(
                                      selectedFlight?.Segments?.[0]?.[0]
                                        ?.DepTime
                                    )}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center">
                                  <FaArrowRightLong />
                                </div>
                                <div>
                                  <span>
                                    {
                                      selectedFlight?.Segments?.[0]?.[
                                        selectedFlight?.Segments?.[0]?.length -
                                          1
                                      ]?.Destination?.CityName
                                    }
                                  </span>
                                  <p className="m-0">
                                    {convertDateTime(
                                      selectedFlight?.Segments?.[0]?.[
                                        selectedFlight?.Segments?.[0]?.length -
                                          1
                                      ]?.ArrTime
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="col-3 p-1 bg-white">
                        <div>
                          {selectedReturnFlight ? (
                            <div
                              className="p-2"
                              style={{
                                border: "1px solid gray",
                                borderRadius: "10px",
                              }}
                            >
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex">
                                  <div>
                                    {logo[
                                      selectedReturnFlight?.Segments?.[0]?.[0]
                                        ?.Airline?.AirlineCode
                                    ] ? (
                                      <img
                                        src={
                                          logo[
                                            selectedReturnFlight
                                              ?.Segments?.[0]?.[0].Airline
                                              ?.AirlineCode
                                          ]
                                        }
                                        height="25px"
                                        width="25px"
                                        alt={`${selectedReturnFlight?.Segments?.[0]?.[0].Airline?.AirlineCode} logo`}
                                      />
                                    ) : (
                                      <MdFlight className="fs-3 text-info" />
                                    )}
                                  </div>
                                  <div className="ps-2">
                                    <span className="m-0">
                                      {
                                        selectedReturnFlight?.Segments?.[0]?.[0]
                                          ?.Airline?.AirlineName
                                      }
                                    </span>
                                    <span
                                      className="text-secondary m-0 ps-2"
                                      style={{ fontSize: "11px" }}
                                    >
                                      (
                                      {
                                        selectedReturnFlight?.Segments?.[0]?.[0]
                                          ?.Airline?.AirlineCode
                                      }
                                      |
                                      {
                                        selectedReturnFlight?.Segments?.[0]?.[0]
                                          ?.Airline?.FlightNumber
                                      }
                                      )
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <span
                                    className="m-0 text-end"
                                    style={{
                                      color: "green",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {`₹${parseFare(
                                      selectedReturnFlight?.OfferedFare
                                    ).toLocaleString()}`}
                                  </span>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between">
                                <div>
                                  <span>
                                    {
                                      selectedReturnFlight?.Segments?.[0]?.[0]
                                        ?.Origin?.CityName
                                    }
                                  </span>
                                  <p className="m-0">
                                    {convertDateTime(
                                      selectedReturnFlight?.Segments?.[0]?.[0]
                                        ?.DepTime
                                    )}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center">
                                  <FaArrowRightLong />
                                </div>
                                <div>
                                  <span>
                                    {
                                      selectedReturnFlight?.Segments?.[0]?.[
                                        selectedReturnFlight?.Segments?.[0]
                                          ?.length - 1
                                      ]?.Destination?.CityName
                                    }
                                  </span>
                                  <p className="m-0">
                                    {convertDateTime(
                                      selectedReturnFlight?.Segments?.[0]?.[
                                        selectedReturnFlight?.Segments?.[0]
                                          ?.length - 1
                                      ]?.ArrTime
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="col-3 p-1 bg-white">
                        <div
                          className="p-2"
                          style={{
                            border: "1px solid gray",
                            borderRadius: "10px",
                          }}
                        >
                          <span className="fw-bold">Total Amount : </span>
                          <span
                            style={{ color: "green", fontWeight: "600" }}
                          >{`₹${totalFare.toLocaleString()}`}</span>
                          <div>
                            <button className="Bus_search_btn mt-3">
                              Book Flight Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div>
              {TwoWayData && OneWayData ? (
                <div className="row d-flex justify-content-between px-4  my-3">
                  {/* <div
                                            className="col-md-3 Section_Sidebar_Container"
                                            style={{
                                                border: "1.5px solid rgb(1,128,253)",
                                                overflow: "hidden",
                                                position: "sticky",
                                                maxHeight: "calc(100vh - 95px)",
                                                overflowY: "auto",
                                                top: "85px",
                                            }}
                                        > */}

                  <div
                    className="col-md-3 Section_Sidebar_Container custom_scrollbar"
                    style={{
                      border: "1.5px solid rgb(1,128,253)",
                      overflow: "hidden",
                      position: "sticky",
                      maxHeight: "calc(100vh - 95px)", // Adjusted maxHeight
                      overflowY: "auto",
                      top: "85px",
                      zIndex: "5",
                      backgroundColor: "gray",
                    }}
                  >
                    <div
                      className="row text-white d-flex justify-content-between py-2"
                      style={{
                        backgroundImage:
                          "linear-gradient(45deg, #1556FC, #04A9FE)",
                        borderBottom: "1.5px solid rgb(1,128,253)",
                      }}
                    >
                      <div className="col-5">
                        <span className="m-0">Filter by</span>
                      </div>
                      <div
                        className="col-6 d-flex justify-content-end align-items-center"
                        style={{ cursor: "pointer" }}
                        onClick={handleResetAll}
                      >
                        <span className="pe-2">Reset all </span>
                        <img
                          src={images.reset}
                          alt=""
                          width="20px"
                          height="20px"
                        />
                      </div>
                    </div>

                    <div
                      className="row py-0 px-3"
                      style={{ background: "#fff" }}
                    >
                      <div
                        className="col-12 p-0 m-0 pb-2"
                        style={{ borderBottom: "2px solid black" }}
                      >
                        <p className="filter_sub_heading pt-2">
                          
                          Onward Journey
                        </p>

                        <div className="p-0 m-0">
                          <div
                            className="row py-2"
                            style={{ borderBottom: "2px solid #e9e9e9" }}
                          >
                            <div>
                              <div className="d-flex justify-content-between">
                                <span
                                  className="p-0 m-0"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Price
                                </span>
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    color: "green",
                                  }}
                                >
                                  ₹ {maxOnwardPrice.toLocaleString()}
                                </span>
                              </div>
                            </div>

                            <div
                              className="pt-1"
                              style={{ fontSize: "12px", fontWeight: "500" }}
                            >
                              <div>
                                <input
                                  type="range"
                                  className="w-100"
                                  min={minOnwardFlightPrice}
                                  max={maxOnwardFlightPrice}
                                  value={maxOnwardPrice}
                                  onChange={handlePriceOnwardChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div
                            className="m-0 p-0 row py-2"
                            style={{ borderBottom: "2px solid #e9e9e9" }}
                          >
                            <div
                              className="p-0 m-0"
                              style={{ fontSize: "16px", fontWeight: "500" }}
                            >
                              Airline Name
                            </div>
                            <div
                              className="p-0 pt-1"
                              style={{ fontSize: "12px", fontWeight: "500" }}
                            >
                              {flightNames?.map((name, index) => (
                                <div
                                  className="mb-1 d-flex align-items-center"
                                  key={index}
                                  style={{
                                    fontSize: "12px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleFlightNameChange(name)}
                                >
                                  <input
                                    type="checkbox"
                                    value={name}
                                    style={{ cursor: "pointer" }}
                                    checked={selectedFlightNames.includes(name)}
                                    onClick={(e) => e.stopPropagation()} // Prevent the div onClick from firing
                                  />
                                  <label className="ps-1">{name}</label>
                                </div>
                              ))}
                            </div>
                          </div>

                          <div
                            className="m-0 p-0 row py-2"
                            style={{ borderBottom: "2px solid #e9e9e9" }}
                          >
                            <p
                              className="p-0 m-0"
                              style={{ fontSize: "16px", fontWeight: "500" }}
                            >
                              Departure Time
                            </p>
                            <div
                              className="p-0 pt-1"
                              style={{ fontSize: "12px", fontWeight: "500" }}
                            >
                              {["0-6", "6-12", "12-18", "18-24"].map(
                                (interval) => (
                                  <div
                                    key={interval}
                                    className="d-flex align-items-center"
                                  >
                                    <input
                                      type="checkbox"
                                      style={{ height: "15px", width: "15px" }}
                                      value={interval}
                                      checked={departureIntervalsOnward.includes(
                                        interval
                                      )}
                                      onChange={(e) =>
                                        handleIntervalChangeOnward(
                                          interval,
                                          e.target.checked
                                        )
                                      }
                                    />
                                    <span className="ps-1">
                                      <span>
                                        {interval === "0-6"
                                          ? " 12AM - 6AM"
                                          : interval === "6-12"
                                          ? " 6AM - 12PM"
                                          : interval === "12-18"
                                          ? " 12PM - 6PM"
                                          : " 6PM - 12AM"}
                                      </span>
                                      <span
                                        className="ps-1"
                                        style={{ fontSize: "18px" }}
                                      >
                                        {getIconOnward(interval)}
                                      </span>
                                    </span>
                                  </div>
                                )
                              )}
                            </div>
                          </div>

                          <div className="m-0 p-0 row py-2">
                            <p
                              className="p-0 m-0"
                              style={{ fontSize: "16px", fontWeight: "500" }}
                            >
                              Arrival Time
                            </p>
                            <div
                              className="p-0 pt-1"
                              style={{ fontSize: "12px", fontWeight: "500" }}
                            >
                              {["0-6", "6-12", "12-18", "18-24"].map(
                                (interval) => (
                                  <div
                                    key={interval}
                                    className="d-flex align-items-center"
                                  >
                                    <input
                                      type="checkbox"
                                      style={{ height: "15px", width: "15px" }}
                                      value={interval}
                                      checked={arrivalIntervalsOnward.includes(
                                        interval
                                      )}
                                      onChange={(e) =>
                                        handleArrivalIntervalChangeOnward(
                                          interval,
                                          e.target.checked
                                        )
                                      }
                                    />
                                    <span className="ps-1">
                                      <span>
                                        {interval === "0-6"
                                          ? " 12AM - 6AM"
                                          : interval === "6-12"
                                          ? " 6AM - 12PM"
                                          : interval === "12-18"
                                          ? " 12PM - 6PM"
                                          : " 6PM - 12AM"}
                                      </span>
                                      <span
                                        className="ps-1"
                                        style={{ fontSize: "18px" }}
                                      >
                                        {getIconOnward(interval)}
                                      </span>
                                    </span>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 p-0 m-0 ">
                        <p className="filter_sub_heading"> Return Journey</p>

                        <div>
                          <div style={{ borderBottom: "2px solid #e9e9e9" }}>
                            <div>
                              <div className="d-flex justify-content-between">
                                <span
                                  className="p-0 m-0"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Price
                                </span>
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    color: "green",
                                  }}
                                >
                                  ₹ {maxReturnPrice.toLocaleString()}
                                </span>
                              </div>
                            </div>

                            <div>
                              <input
                                type="range"
                                className="w-100"
                                min={minReturnFlightPrice}
                                max={maxReturnFlightPrice}
                                value={maxReturnPrice}
                                onChange={handlePriceReturnChange}
                              />
                            </div>
                          </div>

                          <div
                            className="m-0 p-0 row py-2"
                            style={{ borderBottom: "2px solid #e9e9e9" }}
                          >
                            <p
                              className="p-0 m-0"
                              style={{ fontSize: "16px", fontWeight: "500" }}
                            >
                              Departure Time
                            </p>
                            <div
                              className="p-0 pt-1"
                              style={{ fontSize: "12px", fontWeight: "500" }}
                            >
                              {["0-6", "6-12", "12-18", "18-24"].map(
                                (interval) => (
                                  <div
                                    key={interval}
                                    className="d-flex align-items-center"
                                  >
                                    <input
                                      type="checkbox"
                                      style={{ height: "15px", width: "15px" }}
                                      value={interval}
                                      checked={departureIntervals.includes(
                                        interval
                                      )}
                                      onChange={(e) =>
                                        handleIntervalChange(
                                          interval,
                                          e.target.checked
                                        )
                                      }
                                    />
                                    <span className="ps-1">
                                      <span>
                                        {interval === "0-6"
                                          ? " 12AM - 6AM"
                                          : interval === "6-12"
                                          ? " 6AM - 12PM"
                                          : interval === "12-18"
                                          ? " 12PM - 6PM"
                                          : " 6PM - 12AM"}
                                      </span>
                                      <span
                                        className="ps-1"
                                        style={{ fontSize: "18px" }}
                                      >
                                        {getIconOnward(interval)}
                                      </span>
                                    </span>
                                  </div>
                                )
                              )}
                            </div>
                          </div>

                          <div
                            className="m-0 p-0 row py-2"
                            style={{ borderBottom: "2px solid #e9e9e9" }}
                          >
                            <p
                              className="p-0 m-0"
                              style={{ fontSize: "16px", fontWeight: "500" }}
                            >
                              Arrival Time
                            </p>
                            <div
                              className="p-0 pt-1"
                              style={{ fontSize: "12px", fontWeight: "500" }}
                            >
                              {["0-6", "6-12", "12-18", "18-24"].map(
                                (interval) => (
                                  <div
                                    key={interval}
                                    className="d-flex align-items-center"
                                  >
                                    <input
                                      type="checkbox"
                                      style={{ height: "15px", width: "15px" }}
                                      value={interval}
                                      checked={arrivalIntervals.includes(
                                        interval
                                      )}
                                      onChange={(e) =>
                                        handleArrivalIntervalChange(
                                          interval,
                                          e.target.checked
                                        )
                                      }
                                    />
                                    <span className="ps-1">
                                      <span>
                                        {interval === "0-6"
                                          ? " 12AM - 6AM"
                                          : interval === "6-12"
                                          ? " 6AM - 12PM"
                                          : interval === "12-18"
                                          ? " 12PM - 6PM"
                                          : " 6PM - 12AM"}
                                      </span>
                                      <span
                                        className="ps-1"
                                        style={{ fontSize: "18px" }}
                                      >
                                        {getIconOnward(interval)}
                                      </span>
                                    </span>
                                  </div>
                                )
                              )}
                            </div>
                          </div>

                          <div>
                            <div className="p-0 mt-2 mb-3">
                              <p
                                className="p-0 m-0"
                                style={{ fontSize: "16px", fontWeight: "500" }}
                              >
                                Return Airline Name
                              </p>
                              <div
                                style={{ fontSize: "12px", fontWeight: "500" }}
                              >
                                {flightNamesReturn?.map((name, index) => (
                                  <div
                                    className="pb-1 d-flex align-items-center"
                                    key={index}
                                    style={{ fontSize: "12px" }}
                                    onClick={() => handleFlightNameReturn(name)}
                                  >
                                    <input
                                      type="checkbox"
                                      value={name}
                                      checked={selectedFlightNamesReturn.includes(
                                        name
                                      )}
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                    <label className="ps-1">{name}</label>
                                  </div>
                                ))}
                              </div>

                              {/* <div className="p-0 pt-1" style={{ fontSize: "12px", fontWeight: "500" }}>
                                                                    {flightNames?.map((name, index) => (
                                                                        <div
                                                                            className="mb-1 d-flex align-items-center bg-warning"
                                                                            key={index}
                                                                            style={{ fontSize: "12px", cursor: 'pointer' }}
                                                                            onClick={() => handleFlightNameChange(name)}
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                value={name}
                                                                                style={{ cursor: 'pointer' }}
                                                                                checked={selectedFlightNames.includes(name)}
                                                                                onClick={(e) => e.stopPropagation()} // Prevent the div onClick from firing
                                                                            />
                                                                            <label className="ps-1">{name}</label>
                                                                        </div>
                                                                    ))}
                                                                </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Flights Cards */}

                  <div className="col-md-9 pe-0">
                    <div className="row">
                      <h5
                        className="text-center m-0"
                        style={{ fontSize: "30px", fontWeight: "600" }}
                      >{`Flights from ${fromAirportLocationName} to ${toAirportLocationName} and back`}</h5>

                      <div className="col-md-6">
                        <h5 className="filter_sub_heading pb-2">
                          Onward Journey Flights
                        </h5>
                        <div
                          className="row"
                          style={{ padding: "0px 0px 0px 10px" }}
                        >
                          {filteredOneWayData?.length === 0 ? (
                            <p className="text-center pt-5">
                              Sorry No Onward Flights Found
                            </p>
                          ) : (
                            <div className="p-0 m-0">
                              {filteredOneWayData?.map((ele, index) => {
                                const segment = ele?.Segments?.[0]?.[0]; // Assuming only one segment per flight
                                const isSelected = selectedFlight === ele;
                                return (
                                  <div
                                    key={index}
                                    className="row p-0 m-0 pb-2 "
                                    onClick={() => handleFlightSelection(ele)}
                                  >
                                    <div
                                      className="Onward_Flights_Cards"
                                      style={{
                                        backgroundColor: isSelected
                                          ? "#f1f1f1"
                                          : "transparent",
                                      }}
                                    >
                                      <div>
                                        <div
                                          className="row d-flex justify-content-between"
                                          style={{ paddingTop: "10px" }}
                                        >
                                          <div className="col-md-12">
                                            <div className="d-flex align-items-center">
                                              <div>
                                                {logo[
                                                  segment?.Airline?.AirlineCode
                                                ] ? (
                                                  <img
                                                    src={
                                                      logo[
                                                        segment?.Airline
                                                          ?.AirlineCode
                                                      ]
                                                    }
                                                    height="30px"
                                                    width="40px"
                                                    alt={`${segment?.Airline?.AirlineCode} logo`}
                                                  />
                                                ) : (
                                                  <MdFlight className="fs-3 text-info" />
                                                )}
                                              </div>

                                              <div className="ps-2">
                                                <span className="m-0">
                                                  {
                                                    segment?.Airline
                                                      ?.AirlineName
                                                  }
                                                </span>

                                                <span
                                                  className="text-secondary m-0 ps-2"
                                                  style={{ fontSize: "11px" }}
                                                >
                                                  (
                                                  {
                                                    segment?.Airline
                                                      ?.AirlineCode
                                                  }
                                                  |
                                                  {
                                                    segment?.Airline
                                                      ?.FlightNumber
                                                  }
                                                  )
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-4 d-flex justify-content-end"></div>
                                        </div>

                                        <div className="row d-flex justify-content-between align-items-center px-3 py-2">
                                          <div className="col p-0">
                                            <p className="p-0 m-0">
                                              <span
                                                style={{ fontWeight: "600" }}
                                              >
                                                {convertDateTime(
                                                  ele?.Segments[0]
                                                    ?.slice(0, 1)
                                                    ?.map(
                                                      (ele1) => ele1?.DepTime
                                                    )
                                                )}
                                              </span>
                                            </p>
                                            <p className="text-secondary p-0 m-0 ">
                                              {ele?.Segments[0]
                                                ?.slice(0, 1)
                                                ?.map(
                                                  (ele1) =>
                                                    ele1?.Origin?.CityName
                                                )}
                                            </p>
                                          </div>

                                          <div className="col">
                                            <div>
                                              <p className="m-0 text-secondary text-center">
                                                <span
                                                  style={{ fontSize: "11px" }}
                                                >
                                                  {(ele?.Segments[0]).length > 1
                                                    ? ele?.Segments[0]
                                                        ?.slice(1, 2)
                                                        ?.map((ele1) =>
                                                          convertMinutesToHHMM(
                                                            parseFloat(
                                                              ele1?.AccumulatedDuration
                                                            )
                                                          )
                                                        )
                                                    : ele?.Segments[0]?.map(
                                                        (ele1) =>
                                                          convertMinutesToHHMM(
                                                            parseFloat(
                                                              ele1?.Duration
                                                            )
                                                          )
                                                      )}
                                                </span>
                                              </p>
                                              <p
                                                className="m-0 text-secondary text-center"
                                                style={{ fontSize: "11px" }}
                                              >
                                                {(ele?.Segments[0]).length > 1
                                                  ? `${
                                                      (ele?.Segments[0])
                                                        .length - 1
                                                    } Stop`
                                                  : "Non Stop"}
                                              </p>
                                            </div>
                                          </div>

                                          <div className="col">
                                            <p className="text-center m-0">
                                              <span
                                                style={{ fontWeight: "600" }}
                                              >
                                                {(() => {
                                                  let arrayLastIndex =
                                                    ele?.Segments[0].length - 1;
                                                  let arrivalTime =
                                                    ele?.Segments[0][
                                                      arrayLastIndex
                                                    ]?.ArrTime;
                                                  return arrivalTime ? (
                                                    <span>
                                                      {convertDateTime(
                                                        arrivalTime
                                                      )}
                                                    </span>
                                                  ) : (
                                                    <span>No city found</span>
                                                  );
                                                })()}
                                              </span>
                                            </p>
                                            <p className="text-secondary text-center m-0">
                                              {(() => {
                                                let arrayLastIndex =
                                                  ele?.Segments[0].length - 1;
                                                let cityName =
                                                  ele?.Segments[0][
                                                    arrayLastIndex
                                                  ]?.Destination?.CityName;
                                                return cityName ? (
                                                  <span>{cityName}</span>
                                                ) : (
                                                  <span>No city found</span>
                                                );
                                              })()}
                                            </p>
                                          </div>

                                          <div className="col">
                                            <div>
                                              <p
                                                className="text-secondary m-0 text-end"
                                                style={{ fontSize: "14px" }}
                                              >
                                                {ele?.Fare?.TotalAmount}
                                              </p>
                                              <p
                                                className="m-0 text-end"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "600",
                                                }}
                                              >{`₹${parseInt(
                                                ele?.OfferedFare
                                              ).toLocaleString()}`}</p>

                                              <p
                                                className="m-0 text-end"
                                                style={{ fontSize: "11px" }}
                                              >
                                                
                                                (per adult)
                                              </p>
                                            </div>

                                            <div className="text-end m-0">
                                              {(ele?.FareDataMultiple).slice(
                                                0,
                                                1
                                              )?.map(
                                                (ele1) =>
                                                  ele1?.FareSegments[0]
                                                    ?.NoOfSeatAvailable
                                              ) === "not set" ? (
                                                <p className="text-danger m-0">
                                                  No Seat Available
                                                </p>
                                              ) : (
                                                (ele?.FareDataMultiple).slice(
                                                  0,
                                                  1
                                                )?.map((ele1) => (
                                                  <p
                                                    className="m-0 text-success"
                                                    style={{ fontSize: "11px" }}
                                                  >
                                                    {
                                                      ele1?.FareSegments[0]
                                                        ?.NoOfSeatAvailable
                                                    }
                                                    <span className="ps-1">
                                                      Seats
                                                    </span>
                                                  </p>
                                                ))
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        className="row d-flex justify-content-between px-3 py-2"
                                        style={{
                                          borderTop: "1px solid lightblue",
                                        }}
                                      >
                                        <div className="col-md-5 d-flex align-items-center">
                                          <span
                                            className="Bus_Cards_items"
                                            onClick={() =>
                                              handleOnwardFlightDetails(ele)
                                            }
                                          >
                                            Flights Details
                                          </span>
                                        </div>

                                        <div className="col-md-5 p-0 d-flex justify-content-end">
                                          <div>
                                            <div
                                              className="book-car-btn no-underline"
                                              style={{
                                                fontSize: "12px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              <button className="Bus_search_btn w-100">
                                                View Details
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <h5 className="filter_sub_heading pb-2">
                          Return Journey Flights
                        </h5>
                        <div
                          className="row"
                          style={{ padding: "0px 0px 0px 10px" }}
                        >
                          {filteredTwoWayData?.length === 0 ? (
                            <p className="text-center pt-5">
                              Sorry No Return Flights Found
                            </p>
                          ) : (
                            <div className="p-0 m-0">
                              {filteredTwoWayData?.map((ele, index) => {
                                const segment = ele?.Segments?.[0]?.[0]; // Assuming only one segment per flight
                                const isSelected = selectedReturnFlight === ele;
                                return (
                                  <div
                                    key={index}
                                    className="row p-0 m-0 pb-2"
                                    onClick={() =>
                                      handleReturnFlightSelection(ele)
                                    }
                                  >
                                    <div
                                      className="Return_Flights_Cards"
                                      style={{
                                        backgroundColor: isSelected
                                          ? "#f1f1f1"
                                          : "transparent",
                                      }}
                                    >
                                      <div>
                                        <div
                                          className="row d-flex justify-content-between"
                                          style={{ paddingTop: "10px" }}
                                        >
                                          <div className="col-md-12">
                                            <div className="d-flex align-items-center">
                                              <div>
                                                {logo[
                                                  segment?.Airline?.AirlineCode
                                                ] ? (
                                                  <img
                                                    src={
                                                      logo[
                                                        segment?.Airline
                                                          ?.AirlineCode
                                                      ]
                                                    }
                                                    height="30px"
                                                    width="40px"
                                                    alt={`${segment?.Airline?.AirlineCode} logo`}
                                                  />
                                                ) : (
                                                  <MdFlight className="fs-3 text-info" />
                                                )}
                                              </div>

                                              <div className="ps-2">
                                                <span className="m-0">
                                                  {
                                                    segment?.Airline
                                                      ?.AirlineName
                                                  }
                                                </span>

                                                <span
                                                  className="text-secondary m-0 ps-2"
                                                  style={{ fontSize: "11px" }}
                                                >
                                                  (
                                                  {
                                                    segment?.Airline
                                                      ?.AirlineCode
                                                  }
                                                  |
                                                  {
                                                    segment?.Airline
                                                      ?.FlightNumber
                                                  }
                                                  )
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-4 d-flex justify-content-end"></div>
                                        </div>

                                        <div className="row d-flex justify-content-between align-items-center   py-2">
                                          <div className="col">
                                            <p className="m-0">
                                              <span
                                                style={{ fontWeight: "600" }}
                                              >
                                                {convertDateTime(
                                                  ele?.Segments[0]
                                                    ?.slice(0, 1)
                                                    ?.map(
                                                      (ele1) => ele1?.DepTime
                                                    )
                                                )}
                                              </span>
                                            </p>
                                            <p className="text-secondary p-0 m-0 ">
                                              {ele?.Segments[0]
                                                ?.slice(0, 1)
                                                ?.map(
                                                  (ele1) =>
                                                    ele1?.Origin?.CityName
                                                )}
                                            </p>
                                          </div>

                                          <div className="col">
                                            <div>
                                              <p className="m-0 text-secondary text-center">
                                                <span
                                                  style={{ fontSize: "11px" }}
                                                >
                                                  {(ele?.Segments[0]).length > 1
                                                    ? ele?.Segments[0]
                                                        ?.slice(1, 2)
                                                        ?.map((ele1) =>
                                                          convertMinutesToHHMM(
                                                            parseFloat(
                                                              ele1?.AccumulatedDuration
                                                            )
                                                          )
                                                        )
                                                    : ele?.Segments[0]?.map(
                                                        (ele1) =>
                                                          convertMinutesToHHMM(
                                                            parseFloat(
                                                              ele1?.Duration
                                                            )
                                                          )
                                                      )}
                                                </span>
                                              </p>
                                              <p
                                                className="m-0 text-secondary text-center"
                                                style={{ fontSize: "11px" }}
                                              >
                                                {(ele?.Segments[0]).length > 1
                                                  ? `${
                                                      (ele?.Segments[0])
                                                        .length - 1
                                                    } Stop`
                                                  : "Non Stop"}
                                              </p>
                                            </div>
                                          </div>

                                          <div className="col">
                                            <p className="text-center m-0">
                                              <span
                                                style={{ fontWeight: "600" }}
                                              >
                                                {(() => {
                                                  let arrayLastIndex =
                                                    ele?.Segments[0].length - 1;
                                                  let arrivalTime =
                                                    ele?.Segments[0][
                                                      arrayLastIndex
                                                    ]?.ArrTime;
                                                  return arrivalTime ? (
                                                    <span>
                                                      {convertDateTime(
                                                        arrivalTime
                                                      )}
                                                    </span>
                                                  ) : (
                                                    <span>No city found</span>
                                                  );
                                                })()}
                                              </span>
                                            </p>
                                            <p className="text-secondary text-center m-0">
                                              {(() => {
                                                let arrayLastIndex =
                                                  ele?.Segments[0].length - 1;
                                                let cityName =
                                                  ele?.Segments[0][
                                                    arrayLastIndex
                                                  ]?.Destination?.CityName;
                                                return cityName ? (
                                                  <span>{cityName}</span>
                                                ) : (
                                                  <span>No city found</span>
                                                );
                                              })()}
                                            </p>
                                          </div>

                                          <div className="col">
                                            <div>
                                              <p
                                                className="text-secondary m-0 text-end"
                                                style={{ fontSize: "14px" }}
                                              >
                                                {ele?.Fare?.TotalAmount}
                                              </p>
                                              <p
                                                className="m-0 text-end"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "600",
                                                }}
                                              >{`₹${parseInt(
                                                ele?.OfferedFare
                                              ).toLocaleString()}`}</p>

                                              <p
                                                className="m-0 text-end"
                                                style={{ fontSize: "11px" }}
                                              >
                                                
                                                (per adult)
                                              </p>
                                            </div>

                                            <div className="text-end m-0">
                                              {(ele?.FareDataMultiple).slice(
                                                0,
                                                1
                                              )?.map(
                                                (ele1) =>
                                                  ele1?.FareSegments[0]
                                                    ?.NoOfSeatAvailable
                                              ) === "not set" ? (
                                                <p className="text-danger m-0">
                                                  No Seat Available
                                                </p>
                                              ) : (
                                                (ele?.FareDataMultiple).slice(
                                                  0,
                                                  1
                                                )?.map((ele1) => (
                                                  <p
                                                    className="m-0 text-success"
                                                    style={{ fontSize: "11px" }}
                                                  >
                                                    {
                                                      ele1?.FareSegments[0]
                                                        ?.NoOfSeatAvailable
                                                    }
                                                    <span className="ps-1">
                                                      Seats
                                                    </span>
                                                  </p>
                                                ))
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        className="row d-flex justify-content-between px-3 py-2"
                                        style={{
                                          borderTop: "1px solid lightblue",
                                        }}
                                      >
                                        <div className="col-md-5 d-flex align-items-center">
                                          <span
                                            className="Bus_Cards_items"
                                            onClick={() =>
                                              handleReturnFlightDetails(ele)
                                            }
                                          >
                                            Flights Details
                                          </span>
                                        </div>

                                        <div className="col-md-5 p-0 d-flex justify-content-end">
                                          <div>
                                            <div
                                              className="book-car-btn no-underline"
                                              style={{
                                                fontSize: "12px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              <button className="Bus_search_btn w-100">
                                                View Details
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row d-flex justify-content-center p-4">
                  <div className="col-6">
                    <div className="loader_container">
                      <div
                        className="  d-flex align-items-center justify-content-center"
                        style={{ height: "200px" }}
                      >
                        <img
                          src={images.Flight_Loader_Transparent}
                          height={170}
                          width={170}
                        ></img>
                      </div>
                      <p
                        className="text-center m-0"
                        style={{ fontSize: "20px", fontWeight: "500" }}
                      >
                        Please Wait ...
                      </p>
                      <p className="text-center">
                        We are searching best flights for you
                      </p>
                      <p className="text-center">
                        <span style={{ fontSize: "20px", fontWeight: "600" }}>
                          {fromAirportLocationName}
                        </span>
                        <span
                          className="px-2"
                          style={{ fontSize: "20px", fontWeight: "700" }}
                        >
                          &#8644;
                        </span>
                        <span style={{ fontSize: "20px", fontWeight: "600" }}>
                          {toAirportLocationName}
                        </span>
                      </p>
                      <p className="text-center text-danger m-0">
                        Do not refresh or close the window
                      </p>
                      <div className="d-flex justify-content-center pb-3">
                        <div>
                          <span className="pe-2">
                            Departure Date : {formatDate(depardate)}
                          </span>
                        </div>
                        <div>
                          <span>Arrival Date : {formatDate(arrivalDate)}</span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center pb-3">
                        <a href="/">Back to home page</a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
