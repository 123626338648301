import React from 'react'
import { ETOInsightList } from '../../data/FooterItems';

export const ETOInsight = () => {
    return (
        <div>
            <p className="fw-bold">A guide to connect with the world.</p>
            <div className="row ">
                {ETOInsightList.map((ele) => {
                    return (
                        <div key={ele.id} className="col-md-3 col-6 pb-2 pointerUnderLine" >
                            <a href={ele.link} className='text-white px-2' style={{ textDecoration: "none" }}>{ele.title}</a>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}
