import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { flightToGetData, getAirlineLogo } from "../../api/FlightsAPI";
import { MdFlight } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import images from "../../assets/Images";
import { MyContexts } from "../../context/MyContext";
import { Button } from "react-bootstrap";
import { PiSunHorizonLight } from "react-icons/pi";
import { LiaSun } from "react-icons/lia";
import { PiCloudSunDuotone } from "react-icons/pi";
import { PiCloudMoon } from "react-icons/pi";

export const SearchFlights = () => {
  const {
    fromData,
    fromAirportLocationName,
    toData,
    toAirportLocationName,
    dateData,
  } = useParams();
  const [flightData, setFlightData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [flightlogoURL, setflightlogoURL] = useState("");
  const [AirlineName, SetAirlineName] = useState();
  const [selectedAirlineNames, setSelectedAirlineNames] = useState([]);

  const { setSelectedFlightFunction } = useContext(MyContexts);
  const handleCardClick = (element) => {
    setSelectedFlightFunction(element);
  };

  const handleAirlineNameChange = (event) => {
    const airlineName = event.target.value;
    setSelectedAirlineNames((prevSelectedNames) => {
      if (prevSelectedNames.includes(airlineName)) {
        return prevSelectedNames.filter((name) => name !== airlineName);
      } else {
        return [...prevSelectedNames, airlineName];
      }
    });
  };

  const handleFlightDetailsClick = async (flight) => {
    setSelectedFlight(flight);
    try {
      const res = await getAirlineLogo(
        flight?.FareDataMultiple[0]?.Segments[0][0]?.Airline?.AirlineCode
      );
      setflightlogoURL(res?.data);
    } catch (error) {
      console.log(error);
    }
    handleShow();
  };

  function convertMinutesToHHMM(minutes) {
    if (typeof minutes !== "number" || minutes < 0) {
      throw new Error(
        "Invalid input. Please provide a non-negative number of minutes."
      );
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(remainingMinutes).padStart(2, "0");
    return `${formattedHours} h:${formattedMinutes} m`;
  }

  function getDateFromString(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0"); // Get day and pad with 0 if needed
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month (months are 0-indexed)
    const year = date.getFullYear().toString(); // Get last two digits of the year
    return `${year}-${month}-${day}`;
  }

  const [depardate, setdepardate] = useState();

  const formatDate = (dateString) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const [SrdvType, setSrdvType] = useState("");
  const [TraceId, setTraceId] = useState("");

  async function getAllCabsDetails() {
    const dateFormat = getDateFromString(dateData);
    const originSend = fromData;
    const departureSend = toData;
    const dateSend = dateFormat;
    if (dateSend) {
      setdepardate(dateSend);
    }

    const dataSend = { originSend, departureSend, dateSend };
    const res = await flightToGetData(dataSend);
    console.log(res);

    if (res?.status === "success") {
      if (
        res &&
        res?.data &&
        Array.isArray(res?.data?.Results) &&
        res?.data?.Results?.length > 0
      ) {
        setSrdvType(res?.data?.SrdvType);
        setTraceId(res?.data?.TraceId);

        const flights = res?.data?.Results[0];

        const airlineNames = []; // Declare airlineNames before the loop

        flights?.forEach((item) => {
          item?.FareDataMultiple?.forEach((fareData) => {
            fareData?.FareSegments?.forEach((segment) => {
              const airlineName = segment?.AirlineName;
              if (!airlineNames?.includes(airlineName)) {
                airlineNames?.push(airlineName);
              }
            });
          });
        });

        if (airlineNames) {
          SetAirlineName(airlineNames);
        }
        setFlightData(flights);
        setLoading(false);
        let minPrice = Infinity;
        let maxPrice = 0;
        flights?.forEach((flight) => {
          const baseFare = parseFloat(
            flight?.FareDataMultiple?.slice(0, 1)?.map(
              (ele1) => ele1?.Fare?.BaseFare
            )
          );
          if (baseFare < minPrice) {
            minPrice = baseFare;
          }
          if (baseFare > maxPrice) {
            maxPrice = baseFare;
          }
        });

        setMinPrice(minPrice);
        setMaxPrice(maxPrice);
      } else {
      }
    }
  }

  useEffect(() => {
    getAllCabsDetails();
  }, []);

  const convertDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const hours = dateTime.getHours().toString().padStart(2, "0");
    const minutes = dateTime.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const calculateLayoverTime = (arrivalTime, departureTime) => {
    const arrTime = new Date(arrivalTime);
    const depTime = new Date(departureTime);
    const diffMs = depTime - arrTime;
    const diffHrs = Math.floor(diffMs / 1000 / 60 / 60);
    const diffMins = Math.floor((diffMs / 1000 / 60) % 60);
    return `${diffHrs}h ${diffMins}m`;
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showFlightFilterModal, setShowFlightFilterModal] = useState(false);

  const handleCloseFlightFilterModal = () => setShowFlightFilterModal(false);
  const handleShowFlightFilterModal = () => setShowFlightFilterModal(true);

  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(200000); // Set a default max price
  const [nonStopOnly, setNonStopOnly] = useState(false);
  const [singleStopOnly, setSingleStopOnly] = useState(false);
  const [doubleStopOnly, setDoubleStopOnly] = useState(false);
  const [filterFlight, setFlightFlight] = useState(flightData);
  const [selectedRefundable, setSelectedRefundable] = useState("");
  const [departureIntervals, setDepartureIntervals] = useState([]);
  const [arrivalIntervals, setArrivalIntervals] = useState([]);

  const filterFlights = () => {
    const filteredFlights = flightData?.filter((flight) => {
      const baseFare = parseFloat(
        flight?.FareDataMultiple?.slice(0, 1)?.map(
          (ele1) => ele1?.Fare?.BaseFare
        )
      );
      const stopCount = flight?.Segments[0]?.length - 1;
      const isRefundableCash = flight?.FareDataMultiple[0]?.IsRefundable;
      const airlineName =
        flight?.FareDataMultiple[0]?.FareSegments[0]?.AirlineName;

      // Get departure and arrival times
      const departureTime = new Date(
        flight?.Segments[0][0]?.DepTime
      ).getHours();

      const arrivalTime = new Date(
        flight?.Segments[0][flight?.Segments[0].length - 1]?.ArrTime
      ).getHours();

      // Function to check if the time is within the selected intervals
      const isWithinIntervals = (time, intervals) => {
        if (intervals.length === 0) return true;
        return intervals.some((interval) => {
          const [start, end] = interval.split("-").map(Number);
          return time >= start && time < end;
        });
      };

      const meetsStoppageCriteria =
        (!nonStopOnly && !singleStopOnly && !doubleStopOnly) ||
        (nonStopOnly && stopCount === 0) ||
        (singleStopOnly && stopCount === 1) ||
        (doubleStopOnly && stopCount === 2);

      return (
        baseFare >= minPrice &&
        baseFare <= maxPrice &&
        meetsStoppageCriteria &&
        (selectedRefundable === "" ||
          isRefundableCash === selectedRefundable) &&
        (selectedAirlineNames.length === 0 ||
          selectedAirlineNames.includes(airlineName)) &&
        isWithinIntervals(departureTime, departureIntervals) &&
        isWithinIntervals(arrivalTime, arrivalIntervals)
      );
    });
    return filteredFlights;
  };

  useEffect(() => {
    const filteredFlightsData = filterFlights();
    setFlightFlight(filteredFlightsData);
  }, [
    minPrice,
    maxPrice,
    nonStopOnly,
    singleStopOnly,
    doubleStopOnly,
    selectedRefundable,
    selectedAirlineNames,
    departureIntervals,
    arrivalIntervals,
  ]);

  const handleResetAll = () => {
    window.location.reload();
  };

  const [logo, setLogo] = useState({});
  const navigate = useNavigate();

  async function handleFlightLogo(flight) {
    try {
      const airlineCode = flight?.Segments?.[0][0]?.Airline?.AirlineCode;
      if (airlineCode) {
        const res = await getAirlineLogo(airlineCode);
        setLogo((prevLogo) => ({
          ...prevLogo,
          [airlineCode]: res?.data,
        }));
      } else {
        console.log("Airline code not found in the flight object.");
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleFlightInfo(flight) {
    const ResultIndex = flight?.FareDataMultiple?.[0]?.ResultIndex;
    const SrdvIndex = flight?.FareDataMultiple?.[0]?.SrdvIndex;

    navigate(`${ResultIndex}/${SrdvIndex}/${SrdvType}/${TraceId}`);
  }

  useEffect(() => {
    flightData.forEach((flight) => {
      handleFlightLogo(flight);
    });
  }, [flightData]);

  const [flightDetails, setFlightDetails] = useState("flightdetails");
  const handleItemClick = (id) => {
    setFlightDetails(id === flightDetails ? "flightdetails" : id);
  };

  const renderForm = () => {
    switch (flightDetails) {
      case "flightdetails":
        return (
          <>
            {selectedFlight.Segments.map((segment, index) => (
              <div className="row px-3" key={index}>
                {segment.map((ele1, idx) => (
                  <div key={idx}>
                    <div className="row d-flex justify-content-between pb-1">
                      <div
                        className="col-md-12 d-flex justify-content-between"
                        style={{ border: "1px solid lightgray" }}
                      >
                        <div className="d-flex align-items-center py-2">
                          <div>
                            {logo[ele1?.Airline?.AirlineCode] ? (
                              <img
                                src={logo[ele1?.Airline?.AirlineCode]}
                                alt={`${ele1?.Airline?.AirlineCode} logo`}
                                height="50px"
                                width="50px"
                              />
                            ) : (
                              <MdFlight className="fs-3 text-info" />
                            )}
                          </div>

                          <div>
                            <p className="m-0">
                              <div className="px-2">
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {ele1?.Airline?.AirlineName}
                                </p>
                                <p className="m-0" style={{ fontSize: "10px" }}>
                                  {ele1?.Airline?.AirlineCode} |
                                  {ele1?.Airline?.FlightNumber}
                                </p>
                              </div>
                            </p>
                          </div>
                        </div>

                        <div className="d-flex align-items-center">
                          <div className="" style={{ fontSize: "13px" }}>
                            <span className="text-center p-0 m-0">
                              {selectedFlight?.Segments[0]
                                ?.slice(0, 1)
                                ?.map((ele1) => ele1?.Origin?.CityName)}
                            </span>
                            <span className="px-1">
                              <i className="fa fa-arrow-right"></i>
                            </span>
                            <span>
                              {(() => {
                                let arrayLastIndex =
                                  selectedFlight?.Segments[0].length - 1;
                                let cityName =
                                  selectedFlight?.Segments[0][arrayLastIndex]
                                    ?.Destination?.CityName;
                                return cityName ? (
                                  <span>{cityName}</span>
                                ) : (
                                  <span>No city found</span>
                                );
                              })()}
                            </span>
                          </div>

                          <div className="ps-2">
                            <p
                              className="text-end m-0"
                              style={{ fontSize: "13px", fontWeight: "600" }}
                            >
                              {formatDate(depardate)}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-md-12 py-2 d-flex justify-content-between"
                        style={{ border: "1px solid lightgray" }}
                      >
                        <div className="row w-100 d-flex justify-content-between">
                          <div
                            className="col-md-4 col-5 m-0 px-3"
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            <p className="p-0 m-0">{ele1?.Origin?.CityName}</p>
                            <p className="p-0 m-0">
                              {ele1?.Origin?.AirportName}
                            </p>
                            <p className="m-0">
                              {convertDateTime(ele1?.DepTime)}
                            </p>
                            <p
                              className="m-0"
                              style={{ fontSize: "13px", fontWeight: "600" }}
                            >
                              {formatDate(depardate)}
                            </p>
                          </div>

                          <div className="col-md-4 col-1 fs-1 text-center">
                            <span className="px-1">
                              <i className="fa fa-arrow-right fs-5"></i>
                            </span>
                            <span className="bg-info">
                              {segment[idx + 1] && (
                                <div className="col-md-12">
                                  <p
                                    className="m-0 text-center"
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "500",
                                      color: "#04a9fe",
                                    }}
                                  >
                                    Change of planes :
                                  </p>
                                  <p
                                    className="m-0 text-center"
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {calculateLayoverTime(
                                      ele1?.ArrTime,
                                      segment[idx + 1]?.DepTime
                                    )}
                                    Layover in
                                    {segment[idx + 1]?.Origin?.CityName}
                                  </p>
                                </div>
                              )}
                            </span>
                          </div>

                          <div
                            className="col-md-4 col-5 m-0 p-0 text-end"
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            <p className="p-0 m-0">
                              {ele1?.Destination?.CityName}
                            </p>
                            <p className="p-0 m-0">
                              {ele1?.Destination?.AirportName}
                            </p>
                            <p className="p-0 m-0">
                              {convertDateTime(ele1?.ArrTime)}
                            </p>
                            <p
                              className="m-0"
                              style={{ fontSize: "13px", fontWeight: "600" }}
                            >
                              {formatDate(depardate)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </>
        );

      case "faredetails":
        return (
          <>
            <table className="border strip w-100">
              <tbody>
                <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                  <td className="py-2">
                    <p className="ps-3 m-0">Base Price</p>
                  </td>
                  <td>
                    <p
                      className="ps-3"
                      style={{ borderLeft: "1px solid lightgray" }}
                    >{`₹${parseInt(
                      selectedFlight?.FareDataMultiple?.slice(0, 1)?.map(
                        (ele1) => ele1?.Fare?.BaseFare
                      )
                    ).toLocaleString()}`}</p>
                  </td>
                </tr>
                <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                  <td className="py-2">
                    <p className="ps-3 m-0">Offered Fare</p>
                  </td>
                  <td>
                    <p
                      className="ps-3"
                      style={{ borderLeft: "1px solid lightgray" }}
                    >{`₹${parseInt(
                      selectedFlight?.FareDataMultiple?.slice(0, 1)?.map(
                        (ele1) => ele1?.Fare?.OfferedFare
                      )
                    ).toLocaleString()}`}</p>
                  </td>
                </tr>
                <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                  <td className="py-2">
                    <p className="ps-3 m-0">Tax</p>
                  </td>
                  <td>
                    <p
                      className="ps-3"
                      style={{ borderLeft: "1px solid lightgray" }}
                    >{`₹${parseInt(
                      selectedFlight?.FareDataMultiple?.slice(0, 1)?.map(
                        (ele1) => ele1?.Fare?.Tax
                      )
                    ).toLocaleString()}`}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        );
      case "luggagedetails":
        return (
          <>
            <table className="border strip w-100">
              <tbody>
                <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                  <td className="py-2">
                    <p className="ps-3 m-0">Luggage(Kg)</p>
                  </td>
                  <td>
                    <p
                      className="m-0 ps-3"
                      style={{ borderLeft: "1px solid lightgray" }}
                    >
                      {selectedFlight?.FareDataMultiple[0]?.FareSegments[0]
                        ?.Baggage
                        ? selectedFlight?.FareDataMultiple[0]?.FareSegments[0]
                            ?.Baggage
                        : "6kg"}
                    </p>
                  </td>
                </tr>
                <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                  <td className="py-2">
                    <p className="ps-3 m-0">Cabin(Kg)</p>
                  </td>
                  <td>
                    <p
                      className="m-0 ps-3"
                      style={{ borderLeft: "1px solid lightgray" }}
                    >
                      {
                        selectedFlight?.FareDataMultiple[0]?.FareSegments[0]
                          ?.CabinBaggage
                      }
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        );
      default:
        return null;
    }
  };

  const getIconOnward = (interval) => {
    switch (interval) {
      case "0-6":
        return <PiSunHorizonLight />;
      case "6-12":
        return <LiaSun />;
      case "12-18":
        return <PiCloudSunDuotone />;
      case "18-24":
        return <PiCloudMoon />;
      default:
        return null;
    }
  };

  return (
    <>
      <div
        className="container-fluid mobile-view"
        style={{ position: "relative" }}
      >
        {/* Amenities Modal */}

        <div className="row px-2 mobile-view">
          <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Flight Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedFlight && (
                <>
                  <div className="gap-2 d-flex pb-3">
                    <span
                      className={`py-1 px-3 ${
                        flightDetails === "flightdetails"
                          ? "activeOfferBtnClass"
                          : "inactiveOfferBtnClass"
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleItemClick("flightdetails")}
                    >
                      Flights Details
                    </span>
                    <span
                      className={` py-1 px-3 ${
                        flightDetails === "faredetails"
                          ? "activeOfferBtnClass"
                          : "inactiveOfferBtnClass"
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleItemClick("faredetails")}
                    >
                      Fare Details
                    </span>
                    <span
                      className={` py-1 px-3 ${
                        flightDetails === "luggagedetails"
                          ? "activeOfferBtnClass"
                          : "inactiveOfferBtnClass"
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleItemClick("luggagedetails")}
                    >
                      Luggage Details
                    </span>
                  </div>
                  <div>{renderForm()}</div>
                </>
              )}
            </Modal.Body>
          </Modal>
        </div>

        <div className="row px-2">
          <Modal
            show={showFlightFilterModal}
            onHide={handleCloseFlightFilterModal}
          >
            <Modal.Header
              closeButton
              style={{
                backgroundImage: "linear-gradient(45deg, #1556FC, #04A9FE)",
                borderBottom: "1.5px solid rgb(1,128,253)",
              }}
            >
              <div className="d-flex text-white">
                <div style={{ fontSize: "12px" }}>Filter by</div>
                <div className="px-5" onClick={handleResetAll}>
                  <span className="pe-2" style={{ fontSize: "12px" }}>
                    Resets all
                  </span>
                  <img src={images?.reset} alt="" width="20px" height="20px" />
                </div>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div
                className="row d-flex justify-content-between  py-0 px-2"
                style={{ background: "#fff" }}
              >
                <div
                  className="m-0 p-0 row "
                  style={{ borderBottom: "2px solid #e9e9e9" }}
                >
                  <p
                    className="p-0 m-0"
                    style={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    Price
                  </p>
                  <div className="p-0 d-flex justify-content-between">
                    <span>{`₹${parseInt(minPrice).toLocaleString()}`}</span>
                    <span>{`₹${parseInt(maxPrice).toLocaleString()}`}</span>
                  </div>
                  <span className="p-0">
                    <input
                      type="range"
                      className="w-100"
                      min={minPrice}
                      max={200000}
                      value={maxPrice}
                      onChange={(e) => setMaxPrice(parseInt(e.target.value))}
                    />
                  </span>

                  <div
                    className="m-0 p-0 row py-2"
                    style={{ borderBottom: "2px solid #e9e9e9" }}
                  >
                    <p className="p-0 m-0" style={{ fontSize: "16px" }}>
                      STOPPAGE
                    </p>
                    <div className="d-flex p-0 pt-1 align-items-center">
                      <input
                        type="checkbox"
                        style={{ height: "15px", width: "15px" }}
                        checked={nonStopOnly}
                        onChange={() => setNonStopOnly(!nonStopOnly)}
                      />
                      <span className="ps-2">Non-Stop </span>
                    </div>
                    <div className="d-flex p-0 align-items-center pt-1">
                      <input
                        type="checkbox"
                        style={{ height: "15px", width: "15px" }}
                        checked={singleStopOnly}
                        onChange={() => setSingleStopOnly(!singleStopOnly)}
                      />
                      <span className="ps-2">1-Stop</span>
                    </div>
                    <div className="d-flex p-0 align-items-center pt-1">
                      <input
                        type="checkbox"
                        style={{ height: "15px", width: "15px" }}
                        checked={doubleStopOnly}
                        onChange={() => setDoubleStopOnly(!doubleStopOnly)}
                      />
                      <span className="ps-2">2-Stops </span>
                    </div>
                  </div>

                  <div
                    className="m-0 p-0 row py-2"
                    style={{ borderBottom: "2px solid #e9e9e9" }}
                  >
                    <p className="p-0 m-0" style={{ fontSize: "16px" }}>
                      Airline Name
                    </p>
                    <div className="p-0 pt-1">
                      {AirlineName?.map((ele) => {
                        return (
                          <div key={ele}>
                            <div className="d-flex align-items-center">
                              <input
                                type="checkbox"
                                style={{ height: "15px", width: "15px" }}
                                value={ele}
                                checked={selectedAirlineNames.includes(ele)}
                                onChange={handleAirlineNameChange}
                              />
                              <span className="ps-2">{ele}</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseFlightFilterModal}>
                Apply
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <div className="row d-flex justify-content-between px-3 my-3">
          {loading ? (
            <div
              className="  d-flex align-items-center justify-content-center"
              style={{ height: "400px" }}
            >
              <img
                src={images.Flight_Loader_Transparent}
                height={170}
                width={170}
              ></img>
            </div>
          ) : (
            <>
              <div className="col-12 pe-0">
                <div>
                  {flightData || filterFlight.length > 0 ? (
                    <div className="mb-5">
                      <div className="d-flex align-items-center my-2 ">
                        <div
                          className="m-0 py-1 px-2"
                          style={{
                            border: "1px solid green",
                            borderRadius: "5px",
                          }}
                        >
                          Showing Flights
                          <span className="ps-1" style={{ fontWeight: "600" }}>
                            {fromData}
                          </span>
                          <span className="px-1">
                            <i class="fa fa-arrow-right"></i>
                          </span>
                          <span className="ps-1" style={{ fontWeight: "600" }}>
                            {toData}
                          </span>
                        </div>

                        <div
                          className="px-2"
                          onClick={handleShowFlightFilterModal}
                        >
                          <img
                            src={images?.filter}
                            alt=""
                            height="20px"
                            width="20px"
                          ></img>
                        </div>
                      </div>

                      {filterFlight?.map((ele, index) => (
                        <div
                          className="mb-3"
                          onClick={() => {
                            handleFlightLogo(ele);
                          }}
                        >
                          <div className="Bus_Cards ">
                            <div
                              style={{
                                borderBottom:
                                  "1px solid rgba(201, 228, 255, 1)",
                              }}
                            >
                              <div className="row d-flex justify-content-between px-3 pt-2">
                                <div className="col-md-5 ">
                                  <p
                                    className="text-secondary m-0"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {ele?.Fare?.VehicleCategory}
                                  </p>
                                </div>
                                <div className="col-md-4 d-flex justify-content-end"></div>
                              </div>

                              <div className="row d-flex justify-content-between align-items-center px-3 pb-1">
                                <div
                                  className="col-7 d-flex align-items-center"
                                  style={{ fontSize: "12px" }}
                                >
                                  <div className="d-flex align-items-center">
                                    <div>
                                      {logo[
                                        ele?.Segments[0][0]?.Airline
                                          ?.AirlineCode
                                      ] ? (
                                        <img
                                          src={
                                            logo[
                                              ele?.Segments[0][0]?.Airline
                                                ?.AirlineCode
                                            ]
                                          }
                                          height="40px"
                                          width="40px"
                                          alt={`${ele?.Segments[0][0]?.Airline?.AirlineCode} logo`}
                                        />
                                      ) : (
                                        <MdFlight className="fs-3 text-info" />
                                      )}
                                    </div>
                                    <div className="m-0 ">
                                      {ele?.Segments[0]
                                        ?.slice(0, 1)
                                        ?.map((ele1) => (
                                          <div className="px-2">
                                            <p
                                              className="m-0"
                                              style={{
                                                fontSize: "16px",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {ele1?.Airline?.AirlineName}
                                            </p>
                                            <p className="m-0">
                                              {ele1?.Airline?.AirlineCode} |
                                              {ele1?.Airline?.FlightNumber}
                                            </p>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="col-4"
                                  style={{ fontSize: "10px" }}
                                >
                                  <div>
                                    <p className="text-secondary m-0 text-end">
                                      {ele?.Fare?.TotalAmount}
                                    </p>
                                    <p
                                      className="m-0 text-end"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "600",
                                      }}
                                    >{`₹${parseInt(
                                      ele?.FareDataMultiple?.slice(0, 1)?.map(
                                        (ele1) => ele1?.Fare?.BaseFare
                                      )
                                    ).toLocaleString()}`}</p>

                                    <p className="m-0 text-end"> (per adult)</p>
                                  </div>

                                  <p className="text-end m-0">
                                    {(ele?.FareDataMultiple).slice(0, 1)?.map(
                                      (ele1) =>
                                        ele1?.FareSegments[0]?.NoOfSeatAvailable
                                    ) === "not set" ? (
                                      <p className="text-danger m-0">
                                        No Seat Available
                                      </p>
                                    ) : (
                                      (ele?.FareDataMultiple).slice(0, 1)?.map(
                                        (ele1) => (
                                          <p className="m-0 text-success">
                                            {
                                              ele1?.FareSegments[0]
                                                ?.NoOfSeatAvailable
                                            }
                                            Seat Available
                                          </p>
                                        )
                                      )
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="row d-flex justify-content-between px-3 py-2">
                              <div
                                className="col-12"
                                style={{ fontSize: "14px" }}
                              >
                                <div className="row ">
                                  <div className="col-3">
                                    <p className="text-center m-0">
                                      <span style={{ fontWeight: "600" }}>
                                        {convertDateTime(
                                          ele?.Segments[0]
                                            ?.slice(0, 1)
                                            ?.map((ele1) => ele1?.DepTime)
                                        )}
                                      </span>
                                    </p>
                                    <p className="text-secondary text-center m-0 ">
                                      {ele?.Segments[0]
                                        ?.slice(0, 1)
                                        ?.map((ele1) => ele1?.Origin?.CityName)}
                                    </p>
                                  </div>
                                  <div className="col-6 bg-white">
                                    <div className="horizontal-line">
                                      <div className="line"></div>
                                      <div>
                                        <p className="m-0 text-secondary text-center">
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            {(ele?.Segments[0]).length > 1
                                              ? ele?.Segments[0]
                                                  ?.slice(1, 2)
                                                  ?.map((ele1) =>
                                                    convertMinutesToHHMM(
                                                      parseFloat(
                                                        ele1?.AccumulatedDuration
                                                      )
                                                    )
                                                  )
                                              : ele?.Segments[0]?.map((ele1) =>
                                                  convertMinutesToHHMM(
                                                    parseFloat(ele1?.Duration)
                                                  )
                                                )}
                                          </span>
                                        </p>
                                        <p
                                          className="m-0 text-secondary text-center"
                                          style={{ fontSize: "12px" }}
                                        >
                                          {(ele?.Segments[0]).length > 1
                                            ? `${
                                                (ele?.Segments[0]).length - 1
                                              } Stop`
                                            : "Non Stop"}
                                        </p>
                                      </div>
                                      <div className="line"></div>
                                    </div>
                                  </div>

                                  <div className="col-3">
                                    <p className="text-center m-0">
                                      <span style={{ fontWeight: "600" }}>
                                        {(() => {
                                          let arrayLastIndex =
                                            ele?.Segments[0].length - 1;
                                          let arrivalTime =
                                            ele?.Segments[0][arrayLastIndex]
                                              ?.ArrTime;
                                          return arrivalTime ? (
                                            <span>
                                              {convertDateTime(arrivalTime)}
                                            </span>
                                          ) : (
                                            <span>No city found</span>
                                          );
                                        })()}
                                      </span>
                                    </p>
                                    <p className="text-secondary text-center m-0">
                                      {(() => {
                                        let arrayLastIndex =
                                          ele?.Segments[0].length - 1;
                                        let cityName =
                                          ele?.Segments[0][arrayLastIndex]
                                            ?.Destination?.CityName;
                                        return cityName ? (
                                          <span>{cityName}</span>
                                        ) : (
                                          <span>No city found</span>
                                        );
                                      })()}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row d-flex justify-content-between px-3 pt-1 pb-2">
                              <div className="col-5 d-flex align-items-center">
                                <span
                                  className="Bus_Cards_items"
                                  onClick={() => handleFlightDetailsClick(ele)}
                                >
                                  Flights Details
                                </span>
                              </div>

                              <div className="col-7 d-flex justify-content-end ">
                                <Link
                                  to="/searchflight/flightcheckout"
                                  className="book-car-btn no-underline"
                                  onClick={() => handleCardClick(ele)}
                                >
                                  <button className="Bus_search_btn">
                                    View Details
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center mt-5">
                      <h6>Sorry, No Hotels for this Search</h6>
                      <p className="text-secondary">
                        We cannot find any Hotels for your search. Please modify
                        your search criteria and try again.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div
        className="container-fluid desktop-view"
        style={{ position: "relative" }}
      >
        <div className="row d-flex justify-content-between px-4 my-3">
          {loading ? (
            <div className="row d-flex justify-content-center p-4">
              <div className="col-6">
                <div className="loader_container">
                  <div
                    className="  d-flex align-items-center justify-content-center"
                    style={{ height: "200px" }}
                  >
                    <img
                      src={images.Flight_Loader_Transparent}
                      height={170}
                      width={170}
                    ></img>
                  </div>
                  <p
                    className="text-center m-0"
                    style={{ fontSize: "20px", fontWeight: "500" }}
                  >
                    Please Wait ...
                  </p>
                  <p className="text-center">
                    We are searching best flights for you
                  </p>
                  <p className="text-center">
                    <span style={{ fontSize: "20px", fontWeight: "600" }}>
                      {fromAirportLocationName}
                    </span>
                    <span
                      className="px-2"
                      style={{ fontSize: "20px", fontWeight: "700" }}
                    >
                      &#8594;
                    </span>
                    <span style={{ fontSize: "20px", fontWeight: "600" }}>
                      {toAirportLocationName}
                    </span>
                  </p>
                  <p className="text-center text-danger m-0">
                    Do not refresh or close the window
                  </p>
                  <div className="d-flex justify-content-center pb-3">
                    <div>
                      <span className="pe-2" style={{ fontWeight: "600" }}>
                        Departure Date :
                      </span>
                      <span className="pe-2"> {formatDate(dateData)}</span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center pb-3">
                    <a href="/">Back to home page</a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div
                className="col-md-3 Section_Sidebar_Container custom_scrollbar"
                style={{
                  border: "1.5px solid rgb(1,128,253)",
                  overflow: "hidden",
                  position: "sticky",
                  maxHeight: "calc(100vh - 95px)", // Adjusted maxHeight
                  overflowY: "auto",
                  top: "85px",
                }}
              >
                <div
                  className="row text-white d-flex justify-content-between py-2"
                  style={{
                    backgroundImage: "linear-gradient(45deg, #1556FC, #04A9FE)",
                    borderBottom: "1.5px solid rgb(1,128,253)",
                  }}
                >
                  <div className="col-5">
                    <span className="m-0" style={{ fontSize: "16px" }}>
                      Filter by
                    </span>
                  </div>
                  <div
                    className="col-6 d-flex justify-content-end align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={handleResetAll}
                  >
                    <span className="pe-2" style={{ fontSize: "16px" }}>
                      Reset all
                    </span>
                    <img src={images.reset} alt="" width="20px" height="20px" />
                  </div>
                </div>

                <div
                  className="row d-flex justify-content-between  py-0 px-2"
                  style={{ background: "#fff" }}
                >
                  <div
                    className="m-0 p-0 row px-2 pt-2"
                    style={{ borderBottom: "2px solid #e9e9e9" }}
                  >
                    <p
                      className="p-0 m-0"
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      Price
                    </p>
                    <div className="p-0 d-flex justify-content-between">
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                          color: "green",
                        }}
                      >{`₹${parseInt(minPrice).toLocaleString()}`}</span>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                          color: "green",
                        }}
                      >{`₹${parseInt(maxPrice).toLocaleString()}`}</span>
                    </div>
                    <span className="p-0">
                      <input
                        type="range"
                        className="w-100"
                        min={minPrice}
                        max={200000}
                        value={maxPrice}
                        onChange={(e) => setMaxPrice(parseInt(e.target.value))}
                      />
                    </span>

                    <div
                      className="m-0 p-0 row py-2"
                      style={{ borderBottom: "2px solid #e9e9e9" }}
                    >
                      <p
                        className="p-0 m-0"
                        style={{ fontSize: "16px", fontWeight: "500" }}
                      >
                        STOPPAGE
                      </p>
                      <div
                        className="p-0"
                        style={{ fontSize: "12px", fontWeight: "500" }}
                      >
                        <div className="d-flex p-0 pt-1 align-items-center">
                          <input
                            type="checkbox"
                            style={{ height: "15px", width: "15px" }}
                            checked={nonStopOnly}
                            onChange={() => setNonStopOnly(!nonStopOnly)}
                          />
                          <span className="ps-2">Non-Stop </span>
                        </div>
                        <div className="d-flex p-0 align-items-center pt-1">
                          <input
                            type="checkbox"
                            style={{ height: "15px", width: "15px" }}
                            checked={singleStopOnly}
                            onChange={() => setSingleStopOnly(!singleStopOnly)}
                          />
                          <span className="ps-2">1-Stop</span>
                        </div>
                        <div className="d-flex p-0 align-items-center pt-1">
                          <input
                            type="checkbox"
                            style={{ height: "15px", width: "15px" }}
                            checked={doubleStopOnly}
                            onChange={() => setDoubleStopOnly(!doubleStopOnly)}
                          />
                          <span className="ps-2">2-Stops </span>
                        </div>
                      </div>
                    </div>

                    <div
                      className="m-0 p-0 row py-2"
                      style={{ borderBottom: "2px solid #e9e9e9" }}
                    >
                      <p
                        className="p-0 m-0"
                        style={{ fontSize: "16px", fontWeight: "500" }}
                      >
                        Departure Time
                      </p>
                      <div
                        className="p-0 pt-1"
                        style={{ fontSize: "12px", fontWeight: "500" }}
                      >
                        {["0-6", "6-12", "12-18", "18-24"].map((interval) => (
                          <div
                            key={interval}
                            className="d-flex align-items-center"
                          >
                            <input
                              type="checkbox"
                              style={{ height: "15px", width: "15px" }}
                              value={interval}
                              checked={departureIntervals.includes(interval)}
                              onChange={(e) => {
                                const newIntervals = e.target.checked
                                  ? [...departureIntervals, interval]
                                  : departureIntervals.filter(
                                      (i) => i !== interval
                                    );
                                setDepartureIntervals(newIntervals);
                              }}
                            />
                            <span className="ps-1">
                              <span>
                                {interval === "0-6"
                                  ? " 12AM - 6AM"
                                  : interval === "6-12"
                                  ? " 6AM - 12PM"
                                  : interval === "12-18"
                                  ? " 12PM - 6PM"
                                  : " 6PM - 12AM"}
                              </span>
                              <span
                                className="ps-1"
                                style={{ fontSize: "16px" }}
                              >
                                {getIconOnward(interval)}
                              </span>
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div
                      className="m-0 p-0 row py-2"
                      style={{ borderBottom: "2px solid #e9e9e9" }}
                    >
                      <p
                        className="p-0 m-0"
                        style={{ fontSize: "16px", fontWeight: "500" }}
                      >
                        Arrival Time
                      </p>
                      <div className="p-0 pt-1">
                        {["0-6", "6-12", "12-18", "18-24"].map((interval) => (
                          <div
                            key={interval}
                            className="d-flex align-items-center"
                            style={{ fontSize: "12px", fontWeight: "500" }}
                          >
                            <input
                              type="checkbox"
                              style={{ height: "15px", width: "15px" }}
                              value={interval}
                              checked={arrivalIntervals.includes(interval)}
                              onChange={(e) => {
                                const newIntervals = e.target.checked
                                  ? [...arrivalIntervals, interval]
                                  : arrivalIntervals.filter(
                                      (i) => i !== interval
                                    );
                                setArrivalIntervals(newIntervals);
                              }}
                            />
                            <span className="ps-1">
                              <span>
                                {interval === "0-6"
                                  ? " 12AM - 6AM"
                                  : interval === "6-12"
                                  ? " 6AM - 12PM"
                                  : interval === "12-18"
                                  ? " 12PM - 6PM"
                                  : " 6PM - 12AM"}
                              </span>
                              <span
                                className="ps-1"
                                style={{ fontSize: "16px" }}
                              >
                                {getIconOnward(interval)}
                              </span>
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="m-0 p-0 row py-2 mb-4">
                      <p
                        className="p-0 m-0"
                        style={{ fontSize: "16px", fontWeight: "500" }}
                      >
                        Airline Name
                      </p>
                      <div className="p-0 pt-1">
                        {AirlineName?.map((ele) => {
                          return (
                            <div
                              key={ele}
                              style={{ fontSize: "12px", fontWeight: "500" }}
                            >
                              <div className="d-flex align-items-center">
                                <input
                                  type="checkbox"
                                  style={{ height: "15px", width: "15px" }}
                                  value={ele}
                                  checked={selectedAirlineNames.includes(ele)}
                                  onChange={handleAirlineNameChange}
                                />
                                <span className="ps-2">{ele}</span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-9 pe-0">
                <div>
                  <h5
                    className="text-center"
                    style={{ fontSize: "30px", fontWeight: "600" }}
                  >{`Flights from ${fromAirportLocationName} to ${toAirportLocationName}`}</h5>
                  <div>
                    {flightData && filterFlight.length > 0 ? (
                      <div>
                        {filterFlight?.map((ele, index) => (
                          <div
                            className="mb-3"
                            onClick={() => {
                              handleFlightLogo(ele);
                            }}
                          >
                            <div
                              className="Bus_Cards "
                              onClick={() => {
                                handleFlightInfo(ele);
                              }}
                            >
                              <div
                                style={{
                                  borderBottom:
                                    "1px solid rgba(201, 228, 255, 1)",
                                }}
                              >
                                <div className="row d-flex justify-content-between px-3 pt-2">
                                  <div className="col-md-5 ">
                                    <p
                                      className="text-secondary m-0"
                                      style={{ fontSize: "14px" }}
                                    >
                                      {ele?.Fare?.VehicleCategory}
                                    </p>
                                  </div>
                                  <div className="col-md-4 d-flex justify-content-end"></div>
                                </div>

                                <div className="row d-flex justify-content-between align-items-center px-3 py-2">
                                  <div
                                    className="col-md-3   d-flex align-items-center"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <div>
                                        {logo[
                                          ele?.Segments[0][0]?.Airline
                                            ?.AirlineCode
                                        ] ? (
                                          <img
                                            src={
                                              logo[
                                                ele?.Segments[0][0]?.Airline
                                                  ?.AirlineCode
                                              ]
                                            }
                                            alt={`${ele?.Segments[0][0]?.Airline?.AirlineCode} logo`}
                                          />
                                        ) : (
                                          <MdFlight className="fs-3 text-info" />
                                        )}
                                      </div>
                                      <div className="m-0 ">
                                        {ele?.Segments[0]
                                          ?.slice(0, 1)
                                          ?.map((ele1) => (
                                            <div className="px-2">
                                              <p
                                                className="m-0"
                                                style={{
                                                  fontSize: "18px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {ele1?.Airline?.AirlineName}
                                              </p>
                                              <p className="m-0">
                                                {ele1?.Airline?.AirlineCode} |
                                                {ele1?.Airline?.FlightNumber}
                                              </p>
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className="col-md-6"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <div className="row ">
                                      <div className="col-md-4">
                                        <p className="text-center m-0">
                                          <span className="fw-bold">
                                            {convertDateTime(
                                              ele?.Segments[0]
                                                ?.slice(0, 1)
                                                ?.map((ele1) => ele1?.DepTime)
                                            )}
                                          </span>
                                        </p>
                                        <p className="text-secondary text-center p-0 m-0 ">
                                          {ele?.Segments[0]
                                            ?.slice(0, 1)
                                            ?.map(
                                              (ele1) => ele1?.Origin?.CityName
                                            )}
                                        </p>
                                      </div>
                                      <div className="col-md-4 bg-white">
                                        <div className="horizontal-line">
                                          <div className="line"></div>
                                          <div>
                                            <p className="m-0 text-secondary text-center">
                                              <span>
                                                {(ele?.Segments[0]).length > 1
                                                  ? ele?.Segments[0]
                                                      ?.slice(1, 2)
                                                      ?.map((ele1) =>
                                                        convertMinutesToHHMM(
                                                          parseFloat(
                                                            ele1?.AccumulatedDuration
                                                          )
                                                        )
                                                      )
                                                  : ele?.Segments[0]?.map(
                                                      (ele1) =>
                                                        convertMinutesToHHMM(
                                                          parseFloat(
                                                            ele1?.Duration
                                                          )
                                                        )
                                                    )}
                                              </span>
                                            </p>
                                            <p
                                              className="m-0 text-secondary text-center"
                                              style={{ fontSize: "11px" }}
                                            >
                                              {(ele?.Segments[0]).length > 1
                                                ? `${
                                                    (ele?.Segments[0]).length -
                                                    1
                                                  } Stop`
                                                : "Non Stop"}
                                            </p>
                                          </div>
                                          <div className="line"></div>
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <p className="text-center m-0">
                                          <span className="fw-bold">
                                            {(() => {
                                              let arrayLastIndex =
                                                ele?.Segments[0].length - 1;
                                              let arrivalTime =
                                                ele?.Segments[0][arrayLastIndex]
                                                  ?.ArrTime;
                                              return arrivalTime ? (
                                                <span>
                                                  {convertDateTime(arrivalTime)}
                                                </span>
                                              ) : (
                                                <span>No city found</span>
                                              );
                                            })()}
                                          </span>
                                        </p>
                                        <p className="text-secondary text-center m-0">
                                          {(() => {
                                            let arrayLastIndex =
                                              ele?.Segments[0].length - 1;
                                            let cityName =
                                              ele?.Segments[0][arrayLastIndex]
                                                ?.Destination?.CityName;
                                            return cityName ? (
                                              <span>{cityName}</span>
                                            ) : (
                                              <span>No city found</span>
                                            );
                                          })()}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className="col-md-3  "
                                    style={{ fontSize: "12px" }}
                                  >
                                    <div>
                                      <p
                                        className="text-secondary m-0 text-end"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {ele?.Fare?.TotalAmount}
                                      </p>
                                      <p
                                        className="fw-bold m-0 text-end"
                                        style={{ fontSize: "18px" }}
                                      >{`₹${parseInt(
                                        ele?.FareDataMultiple?.slice(0, 1)?.map(
                                          (ele1) => ele1?.Fare?.BaseFare
                                        )
                                      ).toLocaleString()}`}</p>

                                      <p className="m-0 text-end">
                                        (per adult)
                                      </p>
                                    </div>

                                    <p className="text-end m-0">
                                      {(ele?.FareDataMultiple).slice(0, 1)?.map(
                                        (ele1) =>
                                          ele1?.FareSegments[0]
                                            ?.NoOfSeatAvailable
                                      ) === "not set" ? (
                                        <p className="text-danger m-0">
                                          No Seat Available
                                        </p>
                                      ) : (
                                        (ele?.FareDataMultiple).slice(
                                          0,
                                          1
                                        )?.map((ele1) => (
                                          <p className="m-0 text-success">
                                            {
                                              ele1?.FareSegments[0]
                                                ?.NoOfSeatAvailable
                                            }
                                            Seat Available
                                          </p>
                                        ))
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="row d-flex justify-content-between px-3 py-2">
                                <div className="col-md-5 d-flex align-items-center">
                                  <span
                                    className="Bus_Cards_items"
                                    onClick={() =>
                                      handleFlightDetailsClick(ele)
                                    }
                                  >
                                    Flights Details
                                  </span>
                                </div>

                                <div className="col-md-3 d-flex justify-content-end ">
                                  <div onClick={() => handleFlightInfo(ele)}>
                                    <div
                                      className="book-car-btn no-underline"
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      <button className="Bus_search_btn w-100">
                                        view details
                                      </button>
                                    </div>
                                  </div>

                                  {/*  */}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-center mt-5">
                        <h6>Sorry, No Flights for this Search</h6>
                        <p className="text-secondary">
                          We cannot find any Flights for your search. Please
                          modify your search criteria and try again.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
