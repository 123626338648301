import React, { useState, useEffect } from "react";
import { getHotels } from "../../../api/HotelAPI";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import "../../../style/Hotel.css";
import { Col } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { CiCircleMinus } from "react-icons/ci";
import { CiCirclePlus } from "react-icons/ci";

export const HotelForm = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [checkInDate, setCheckInDate] = useState(
    today.toISOString().split("T")[0]
  );
  const [checkOutDate, setCheckOutDate] = useState(
    tomorrow.toISOString().split("T")[0]
  );

  useEffect(() => {
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    setCheckOutDate(nextDay.toISOString().split("T")[0]);
  }, []);

  const handleCheckInDateChange = (event) => {
    const selectedDate = event.target.value;
    setCheckInDate(selectedDate);
    if (!checkOutDate || selectedDate > checkOutDate) {
      setCheckOutDate(selectedDate);
    }
  };

  const handleCheckOutDateChange = (event) => {
    const selectedDate = event.target.value;
    if (selectedDate > checkInDate) {
      setCheckOutDate(selectedDate);
    } else {
      setCheckOutDate(checkInDate);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const navigate = useNavigate();

  const handleSearchHotels = () => {
    const destinationTo = searchTerm || "Jaipur";
    if (destinationTo && checkInDate && checkOutDate) {
      navigate(`/searchhotels/${destinationTo}/${checkInDate}/${checkOutDate}`);
    } else {
      window.alert("Please Enter Valid Details");
    }
  };

  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [room, setRoom] = useState(1);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for managing dropdown visibility

  const dropdownRef = useRef(null); // Ref for dropdown element

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const totalQuantity = adults + children;

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("New Delhi");
  const [filteredHotelData, setFilteredHotelData] = useState([]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setIsOpen(true);
    setSelectedState("");
    setSelectedCountry("");
  };

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("Delhi");

  const handleOptionClick = (filteredHotelData) => {
    setSearchTerm(`${filteredHotelData.name}`);
    setSelectedState(filteredHotelData?.state);
    setSelectedCountry(filteredHotelData?.country);

    console.log(filteredHotelData?.name);

    setIsOpen(false);
  };

  useEffect(() => {
    const myFilteredHotels = async () => {
      try {
        const data = { city: searchTerm };
        const res = await getHotels(data);

        console.log(res);

        if (res?.status === "success") {
          setFilteredHotelData(res?.data);
        } else {
          console.log("data not called properly");
        }
      } catch (error) {
        console.log(error + "error");
      }
    };

    if (searchTerm !== "") {
      myFilteredHotels();
    } else {
      setFilteredHotelData([]);
    }
  }, [searchTerm]);

  return (
    <>
      <div className="container desktop-view" style={{ minHeight: "200px" }}>
        <div
          className="row p-3 d-flex align-items-center"
          style={{
            borderRadius: "7px",
            border: "2px solid #01a9ff",
            backgroundColor: "white",
            height: "120px",
          }}
        >
          <div
            className="col-md-3 desktop-view"
            style={{ height: "90px", borderRight: "2px solid #01a9ff" }}
          >
            <p className="form_fields_Heading m-0 pb-2">CITY</p>

            <div>
              {typeof searchTerm === "string" ? (
                <>
                  <input
                    type="text"
                    className="w-100"
                    value={searchTerm}
                    onChange={handleInputChange}
                    placeholder="Search..."
                    style={{ fontSize: "25px", fontWeight: "600" }}
                  />
                  <div>
                    {selectedState ? (
                      <div>{selectedState}</div>
                    ) : (
                      <div>{selectedCountry}</div>
                    )}
                  </div>
                </>
              ) : (
                <div>
                  <div style={{ fontSize: "30px", fontWeight: "600" }}>
                    {searchTerm.split("\n")[0]}
                  </div>
                  <div
                    style={{
                      fontSize: "10px",
                      fontWeight: "600",
                      lineHeight: "10px",
                    }}
                  >
                    {searchTerm.split("\n")[1]}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            className="col-md-3"
            style={{ borderRight: "2px solid #01a9ff", height: "90px" }}
          >
            <p className="form_fields_Heading">CHECK IN DATE</p>
            <input
              type="date"
              value={checkInDate}
              min={formatDate(today)}
              onChange={handleCheckInDateChange}
              placeholder="Select a date"
              className="w-50"
            />
          </div>

          <div
            className="col-md-3"
            style={{ borderRight: "2px solid #01a9ff", height: "90px" }}
          >
            <div className="w-100">
              <p className="form_fields_Heading">CHECKOUT</p>
              <input
                type="date"
                value={checkOutDate}
                min={checkInDate}
                onChange={handleCheckOutDateChange}
                placeholder="Select a date"
                className="w-50"
                style={{ color: checkInDate ? "" : "" }}
              />
            </div>
          </div>

          <div className="col-md-3" style={{ height: "90px" }}>
            <div onClick={toggleDropdown}>
              <p className="text-secondary " style={{ fontSize: "15px" }}>
                ROOM
              </p>
              <span className="m-0">{totalQuantity} Guest</span>
              <span className="m-0 ps-2">{room} Rooms</span>
            </div>
          </div>
        </div>

        <div className="row d-flex justify-content-between ">
          <Col
            className="col-md-3 p-0 "
            style={{
              position: "absolute",
              zIndex: 2,
            }}
          >
            {isOpen && (
              <div className="my-select-component bg-white w-100">
                <div className="custom-scrollbar">
                  {filteredHotelData?.map((hotel) => (
                    <div
                      className="row px-3 py-1"
                      key={hotel.id} // Assuming hotel id is unique
                      onClick={() => handleOptionClick(hotel)}
                      style={{
                        borderBottom: "1px solid lightgray",
                        cursor: "pointer",
                      }}
                    >
                      <div className="d-flex m-0 hotelsSearchDataDropdown">
                        <div className="col-md-12">
                          <span
                            className="fw-bold"
                            style={{ fontSize: "14px" }}
                          >
                            {hotel.name} , {hotel.state}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Col>
        </div>

        <div className="row d-flex justify-content-end">
          <Col
            className="col-md-3  p-0"
            style={{
              position: "absolute",
              zIndex: 2,
            }}
          >
            {isDropdownOpen && (
              <div ref={dropdownRef}>
                <div
                  className="bg-white px-2"
                  style={{ border: "1.5px solid gray", fontSize: "16px" }}
                >
                  <div className="d-flex align-items-center ">
                    <label className="me-2">Adults:</label>
                    <button
                      className="bg-white"
                      onClick={() => setAdults(adults + 1)}
                    >
                      <CiCirclePlus className="fs-5" />
                    </button>
                    <span className="mx-2">{adults}</span>
                    <button
                      className="bg-white"
                      onClick={() => setAdults(adults > 1 ? adults - 1 : 1)}
                    >
                      <CiCircleMinus className="fs-5" />
                    </button>
                  </div>
                  <div className="d-flex align-items-center mt-1">
                    <label className="me-2">Children:</label>
                    <button
                      className="bg-white"
                      onClick={() => setChildren(children + 1)}
                    >
                      <CiCirclePlus className="fs-5" />
                    </button>
                    <span className="mx-2">{children}</span>
                    <button
                      className="bg-white"
                      onClick={() =>
                        setChildren(children > 0 ? children - 1 : 0)
                      }
                    >
                      <CiCircleMinus className="fs-5" />
                    </button>
                  </div>
                  <div className="d-flex align-items-center mt-1">
                    <label className="me-2">Rooms:</label>
                    <div className="d-flex align-items-center ">
                      <button
                        className="bg-white"
                        onClick={() => setRoom(room + 1)}
                      >
                        <CiCirclePlus className="fs-5" />
                      </button>
                      <span className="mx-2">{room}</span>
                      <button
                        className="bg-white"
                        onClick={() => setRoom(room > 1 ? room - 1 : 1)}
                      >
                        <CiCircleMinus className="fs-5" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Col>
        </div>

        <div className="row  d-flex justify-content-center ">
          <div className="col-2 p-0 d-flex justify-content-center">
            <button
              className="searchButton py-2 mt-3 "
              onClick={handleSearchHotels}
            >
              Explore Hotels
            </button>
          </div>
        </div>
      </div>

      <div className="mobile-view container">
        <div
          className="row pt-2 d-flex align-items-center"
          style={{
            borderRadius: "7px",
            backgroundColor: "white",
            height: "auto",
          }}
        >
          <div className="col-12 hotelformBorder">
            <p className="form_fields_Heading m-0 py-1">ENTER HOTEL CITY</p>
            <div>
              {typeof searchTerm === "string" ? (
                <>
                  <input
                    type="text"
                    className="w-100"
                    value={searchTerm}
                    onChange={handleInputChange}
                    placeholder="Search..."
                    style={{ fontSize: "15px", fontWeight: "600" }}
                  />
                  <div>
                    {selectedState ? (
                      <span>{selectedState}</span>
                    ) : (
                      <span>{selectedCountry}</span>
                    )}
                  </div>
                </>
              ) : (
                <div>
                  <div style={{ fontSize: "15px", fontWeight: "600" }}>
                    {searchTerm.split("\n")[0]}
                  </div>
                  <div
                    style={{
                      fontSize: "10px",
                      fontWeight: "600",
                      lineHeight: "10px",
                    }}
                  >
                    {searchTerm.split("\n")[1]}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="col-6 ps-0 " style={{ paddingRight: "3px" }}>
            <div className="w-100 hotelformBorder px-2">
              <p className="form_fields_Heading m-0 py-1">CHECK IN DATE</p>
              <input
                type="date"
                value={checkInDate}
                min={formatDate(today)}
                onChange={handleCheckInDateChange}
                placeholder="Select a date"
                className="w-100 bg-white"
                style={{ fontSize: "14px" }}
              />
            </div>
          </div>

          <div className="col-6  pe-0 " style={{ paddingLeft: "3px" }}>
            <div className="hotelformBorder px-2">
              <p className="form_fields_Heading m-0 py-1">CHECK-OUT DATE</p>
              <input
                type="date"
                value={checkOutDate}
                min={checkInDate}
                onChange={handleCheckOutDateChange}
                placeholder="Select a date"
                className="w-100 bg-white"
                style={{ fontSize: "14px" }}
              />
            </div>
          </div>

          <div className="row d-flex justify-content-between ">
            <Col
              className="col-12 p-0 "
              style={{
                position: "absolute",
                zIndex: 2,
                border: "1px solid lightgray",
              }}
            >
              {isOpen && (
                <div className="my-select-component bg-white w-100">
                  <div className="custom-scrollbar">
                    {filteredHotelData?.map((hotel) => (
                      <div
                        className="row px-3 py-1"
                        key={hotel.id} // Assuming hotel id is unique
                        onClick={() => handleOptionClick(hotel)}
                        style={{
                          borderBottom: "1px solid lightgray",
                          cursor: "pointer",
                        }}
                      >
                        <div className="d-flex m-0 hotelsSearchDataDropdown">
                          <div className="col-md-12">
                            <span
                              className="fw-bold"
                              style={{ fontSize: "14px" }}
                            >
                              {hotel.name} , {hotel.state}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Col>
          </div>

          <div className="col-12 hotelformBorder">
            <div onClick={toggleDropdown}>
              <p className="form_fields_Heading m-0 py-1">ROOM</p>
              <span className="m-0">{totalQuantity} Guest</span>
              <span className="m-0 ps-2">{room} Rooms</span>
            </div>
          </div>

          <div className="col-12 p-0 d-flex justify-content-center">
            <button
              className="searchButton py-2"
              onClick={handleSearchHotels}
              style={{ width: "200px" }}
            >
              Explore Hotels
            </button>
          </div>

          <Col
            className="col-12  p-0"
            style={{
              position: "absolute",
              zIndex: 2,
              top: "68%",
            }}
          >
            {isDropdownOpen && (
              <div ref={dropdownRef}>
                <div
                  className="bg-white px-2"
                  style={{ border: "1.5px solid lightgray", fontSize: "14px" }}
                >
                  <div className="d-flex align-items-center ">
                    <label className="me-2">Adults:</label>
                    <button
                      className="bg-white fs-6"
                      onClick={() => setAdults(adults + 1)}
                    >
                      <CiCirclePlus />
                    </button>
                    <span className="mx-2">{adults}</span>
                    <button
                      className="bg-white fs-6"
                      onClick={() => setAdults(adults > 1 ? adults - 1 : 1)}
                    >
                      <CiCircleMinus />
                    </button>
                  </div>
                  <div className="d-flex align-items-center mt-1">
                    <label className="me-2">Children:</label>
                    <button
                      className="bg-white fs-6"
                      onClick={() => setChildren(children + 1)}
                    >
                      <CiCirclePlus />
                    </button>
                    <span className="mx-2">{children}</span>
                    <button
                      className="bg-white fs-6"
                      onClick={() =>
                        setChildren(children > 0 ? children - 1 : 0)
                      }
                    >
                      <CiCircleMinus />
                    </button>
                  </div>
                  <div className="d-flex align-items-center mt-1">
                    <label className="me-2">Rooms:</label>
                    <div className="d-flex align-items-center ">
                      <button
                        className="bg-white fs-6"
                        onClick={() => setRoom(room + 1)}
                      >
                        <CiCirclePlus />
                      </button>
                      <span className="mx-2">{room}</span>
                      <button
                        className="bg-white fs-6"
                        onClick={() => setRoom(room > 1 ? room - 1 : 1)}
                      >
                        <CiCircleMinus />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Col>
        </div>
      </div>
    </>
  );
};
