import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import "../../style/CabCheckout.css";
import { BookCabs, getSingleCabs } from "../../api/CabAPI";
import { Toaster, toast } from "react-hot-toast";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { getUserProfile, sendPaymentDetails } from "../../api/ProfileAPI";

export const Cabcheckout = () => {
  const [selectedCarData, setselectedCarData] = useState("");
  const { fromData, toData, traceid, srdvindex } = useParams();

  const [selectedCity, setSelectedCity] = useState("");

  const [selectedDropCity, setSelectedDropCity] = useState("");

  const CabBookingApi = async () => {
    const data = {
      traceid,
      srdvindex,
    };
    const res = await BookCabs(data);

    const singleCarData = await getSingleCabs(srdvindex);

    if (singleCarData) {
      setselectedCarData(singleCarData?.data);
    }
  };

  useEffect(() => {
    CabBookingApi();
  }, []);

  const [countdown, setCountdown] = useState(60);
  const [bookingSuccess, setBookingSuccess] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (bookingSuccess) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            navigate("/cab");
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [bookingSuccess, navigate]);

  const initialValues = {
    pickUpAddress: "",
    dropOffAddress: "",
    title: "",
    firstName: "",
    email: "",
    phone: "",
  };

  const validationSchema = Yup.object().shape({
    pickUpAddress: Yup.string().required("Pick-up address is required"),
    dropOffAddress: Yup.string().required("Drop-off address is required"),
    title: Yup.string().required("Please select a title"),
    firstName: Yup.string().required("Please enter your first name"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string()
      .required("Please enter your phone number")
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits"),
  });

  const handleFormSubmit = async (values, actions) => {
    try {
      toast.success("Form submitted successfully!", { duration: 3000 });
      actions.resetForm();
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    }
    actions.setSubmitting(false);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
    }`;
  };

  // Fetch User Details

  const [userInformation, setUserInformation] = useState(null);

  const GetUserInformationApi = async () => {
    const userTokenFromCookies = Cookies.get("userToken");

    if (userTokenFromCookies) {
      const res = await getUserProfile(userTokenFromCookies);
      console.log(res, ":getUserProfile");
      setUserInformation(res?.data);
    }
  };

  useEffect(() => {
    GetUserInformationApi();
  }, []);

  // Generate Order Id

  const generateUniqueOrderId = () => {
    return Math.floor(1000000000 + Math.random() * 9000000000).toString();
  };

  const [OrderId, setOrderId] = useState(generateUniqueOrderId());
  const [isPaymentFormVisible, setIsPaymentFormVisible] = useState(false);

  useEffect(() => {
    generateUniqueOrderId();
  }, []);

  const handlePayment = async (e) => {
    e.preventDefault();

    if (!userInformation) {
      toast.error("Please Login for Book Cab", {
        duration: 5000,
      });

      setTimeout(() => {
        navigate("/login");
      }, 5000);

      return;
    }

    const formData = {
      order_id: OrderId,
      mobile: userInformation?.mobile,
      amount: "1",
      booking_type: "Cab",
      customer_name: "John Doe",
      email: "john.doe@example.com",
      payment_status: "Pending",
      TraceId: traceid,
      tracking_id: "",
      payment_mode: "Unified Payments",
      order_status: "pending",
      SrdvIndex: srdvindex,
    };

    console.log(formData);

    try {
      const res = await sendPaymentDetails(formData);
      console.log(res);
      if (res?.status === "success") {
        setIsPaymentFormVisible(true);
        setTimeout(() => {
          document.customerData.submit(); // Auto-submit the form after showing it
        }, 1000);
      } else {
        console.error("Error saving payment details");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div>
        <Toaster position="top-right" reverseOrder={false} />
        <h2 className="text-center mt-3">Cab Checkout</h2>

        <section className="cab-checkout">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="cab-book-overview">
                  <div className="route">
                    <p className="m-0">{fromData}</p>
                    <i className="fa fa-arrow-right"></i>
                    <p className="m-0">{toData}</p>
                  </div>
                  <div>
                    <div>{selectedCarData?.RequestData?.Trip}</div>

                    <div>
                      <span className="pe-1" style={{ fontWeight: "600" }}>
                        DropDate :
                      </span>
                      <span>
                        {new Date(
                          selectedCarData?.RequestData?.DropDate
                        ).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        })}
                      </span>
                    </div>

                    <div>
                      <span className="pe-1" style={{ fontWeight: "600" }}>
                        PickUpDate :
                      </span>
                      <span>
                        {new Date(
                          selectedCarData?.RequestData?.PickUpDate
                        ).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        })}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="book-car-item">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="book-car-item-img">
                        <img
                          src={selectedCarData?.Image}
                          alt=""
                          height="100%"
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="book-car-item-desc">
                        <div className="book-car-item-title-box">
                          <div className="book-car-item-title">
                            <p className="m-0">{selectedCarData?.Category}</p>
                          </div>
                        </div>
                        <div className="book-car-item-features">
                          <i className="fas fa-circle"></i>
                          <p className="m-0">
                            {selectedCarData?.AirConditioner ? "AC" : "Non-AC"}
                          </p>
                          <i className="fas fa-circle"></i>
                          <p className="m-0">
                            {selectedCarData?.SeatingCapacity} Seater
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {bookingSuccess ? (
                  <div className="d-flex align-items-center">
                    <span
                      className="fs-3"
                      style={{ color: "orange", fontWeight: "600" }}
                    >
                      <i class="bx bx-timer bx-tada"></i>
                    </span>
                    <span
                      className="px-1 text-warning"
                      style={{ color: "orange", fontWeight: "600" }}
                    >
                      {formatTime(countdown)} min
                    </span>
                    <span> left to pay and confirm your booking</span>
                  </div>
                ) : (
                  <div
                    className="p-3 mt-4"
                    style={{
                      border: "1px solid lightgray",
                      borderRadius: "10px",
                    }}
                  >
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleFormSubmit}
                    >
                      {({ isSubmitting, values, handleChange }) => (
                        <Form>
                          <div className="form-group">
                            <div>
                              <span
                                style={{ fontWeight: "600", fontSize: "18px" }}
                              >
                                Trip Details
                              </span>
                              <div className="col-md-12">
                                <p>Pick-up Address</p>
                                <select
                                  className="w-100 p-1"
                                  value={selectedCity}
                                  onChange={handleChange}
                                  style={{ border: "1px solid gray" }}
                                  name="pickUpAddress"
                                >
                                  <option value="">Select a City</option>
                                  {selectedCarData?.RequestData?.CityData?.map(
                                    (ele, id) => (
                                      <option key={id} value={ele?.Name}>
                                        {ele?.Name}
                                      </option>
                                    )
                                  )}
                                </select>
                                <ErrorMessage
                                  name="pickUpAddress"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                              <div className="col-md-12">
                                <p>Drop-Off Address</p>
                                <select
                                  className="w-100 p-1"
                                  value={selectedDropCity}
                                  onChange={handleChange}
                                  style={{ border: "1px solid gray" }}
                                  name="dropOffAddress"
                                >
                                  <option value="">Select a City</option>
                                  {selectedCarData?.RequestData?.CityData?.map(
                                    (ele, id) => (
                                      <option key={id} value={ele?.Name}>
                                        {ele?.Name}
                                      </option>
                                    )
                                  )}
                                </select>
                                <ErrorMessage
                                  name="dropOffAddress"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="pt-3">
                            <div
                              style={{ fontWeight: "600", fontSize: "18px" }}
                            >
                              Confirm Traveller information
                            </div>

                            <div className="row d-flex justify-content-between">
                              <div className="col-3">
                                <label>Title</label>
                                <Field
                                  as="select"
                                  name="title"
                                  className="Form_Field"
                                >
                                  <option value="">Select Title</option>
                                  <option value="Mr">Mr</option>
                                  <option value="Ms">Ms</option>
                                  <option value="Mrs">Mrs</option>
                                </Field>
                                <ErrorMessage
                                  name="title"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>

                              <div className="col-9">
                                <label>First Name</label>
                                <Field
                                  type="text"
                                  name="firstName"
                                  placeholder="Enter your first name"
                                  className="Form_Field"
                                />
                                <ErrorMessage
                                  name="firstName"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6">
                                <label>Email</label>
                                <Field
                                  type="email"
                                  name="email"
                                  placeholder="Enter your email"
                                  className="Form_Field"
                                />
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>

                              <div className="col-6">
                                <label>Phone Number</label>
                                <Field
                                  type="text"
                                  name="phone"
                                  placeholder="Enter your phone number"
                                  maxLength={10}
                                  className="Form_Field"
                                />
                                <ErrorMessage
                                  name="phone"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>

                          <button
                            type="submit"
                            className="btn btn-primary mt-3"
                            disabled={isSubmitting}
                          >
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                )}
              </div>

              <div
                className="col-lg-4 mb-5"
                style={{
                  overflow: "hidden",
                  position: "sticky",
                  top: "85px",
                  maxHeight: "400px",
                }}
              >
                <div
                  className="p-3 mt-3"
                  style={{
                    borderRadius: "15px",
                    border: "1px solid lightgray",
                  }}
                >
                  <div>
                    <span style={{ fontWeight: "600", fontSize: "24px" }}>
                      Fare Details
                    </span>
                  </div>

                  <div
                    className="py-1 d-flex justify-content-between"
                    style={{ borderBottom: "1px solid lightgray" }}
                  >
                    <div style={{ fontWeight: "600" }} className="title">
                      Base Fare
                    </div>
                    <div className="desc">{`₹${parseInt(
                      selectedCarData?.Fare?.BaseFare
                    ).toLocaleString()}`}</div>
                  </div>

                  <div
                    className="py-1 d-flex justify-content-between"
                    style={{ borderBottom: "1px solid lightgray" }}
                  >
                    <div style={{ fontWeight: "600" }} className="title">
                      Service Fee
                    </div>
                    <div className="desc">{`₹${parseInt(
                      selectedCarData?.Fare?.ServiceFee
                    ).toLocaleString()}`}</div>
                  </div>

                  <div
                    className="py-1 d-flex justify-content-between"
                    style={{ borderBottom: "1px solid lightgray" }}
                  >
                    <div style={{ fontWeight: "600" }} className="title">
                      Service Tax Percent
                    </div>
                    <div className="desc">{`${parseInt(
                      selectedCarData?.Fare?.ServiceTaxPercent
                    ).toLocaleString()}%`}</div>
                  </div>

                  <div
                    className="py-1 d-flex justify-content-between"
                    style={{ borderBottom: "1px solid lightgray" }}
                  >
                    <div style={{ fontWeight: "600" }} className="title">
                      Total Service Tax
                    </div>
                    <div className="desc">{`₹${parseInt(
                      selectedCarData?.Fare?.TotalServiceTax
                    ).toLocaleString()}`}</div>
                  </div>

                  <div className="py-1 d-flex justify-content-between">
                    <div style={{ fontWeight: "600" }} className="title">
                      Total Payable Amount
                    </div>
                    <div className="desc">{`₹${parseInt(
                      selectedCarData?.Fare?.TotalAmount
                    ).toLocaleString()}`}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div>
                  {isPaymentFormVisible && (
                    <form
                      method="POST"
                      name="customerData"
                      action="https://exploretriponline.com/api/ccavRequestHandler"
                      style={{ display: "none" }}
                    >
                      <table width="40%" height="100" border="1" align="center">
                        <caption>
                          <font size="4" color="blue">
                            <b>Integration Kit</b>
                          </font>
                        </caption>
                      </table>
                      <table width="40%" height="100" border="1" align="center">
                        <tr>
                          <td>Parameter Name:</td>
                          <td>Parameter Value:</td>
                        </tr>
                        <tr>
                          <td colspan="2">Compulsory information</td>
                        </tr>
                        <tr>
                          <td>Merchant Id</td>
                          <td>
                            <input
                              type="text"
                              name="merchant_id"
                              id="merchant_id"
                              value="3401584"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Order Id</td>
                          <td>
                            <input
                              type="text"
                              name="order_id"
                              value={OrderId}
                              readOnly
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Currency</td>
                          <td>
                            <input type="text" name="currency" value="INR" />
                          </td>
                        </tr>
                        <tr>
                          <td>Amount</td>
                          <td>
                            <input type="text" name="amount" value="1.00" />
                          </td>
                        </tr>
                        <tr>
                          <td>Redirect URL</td>
                          <td>
                            <input
                              type="text"
                              name="redirect_url"
                              value="https://exploretriponline.com/api/landing"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Cancel URL</td>
                          <td>
                            <input
                              type="text"
                              name="cancel_url"
                              value="https://exploretriponline.com/api/landing"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Language</td>
                          <td>
                            <input
                              type="text"
                              name="language"
                              id="language"
                              value="EN"
                            />
                          </td>
                        </tr>

                        <tr>
                          <label for="booking_type">Booking Type:</label>
                          <select id="booking_type" name="booking_type">
                            <option value="flight">Flight</option>
                            <option value="hotel">Hotel</option>
                            <option value="cab">Cab</option>
                            <option value="bus">Bus</option>
                            <option value="insurance">Insurance</option>
                          </select>
                        </tr>

                        <tr>
                          <td colspan="2">Billing information(optional):</td>
                        </tr>
                        <tr>
                          <td>Billing Name</td>
                          <td>
                            <input
                              type="text"
                              name="billing_name"
                              value={userInformation?.name}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Billing Address:</td>com
                          <td>
                            <input
                              type="text"
                              name="billing_address"
                              value="Santacruz"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Billing City:</td>
                          <td>
                            <input
                              type="text"
                              name="billing_city"
                              value="Mumbai"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Billing State:</td>
                          <td>
                            <input
                              type="text"
                              name="billing_state"
                              value="MH"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Billing Zip:</td>
                          <td>
                            <input
                              type="text"
                              name="billing_zip"
                              value="400054"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Billing Country:</td>
                          <td>
                            <input
                              type="text"
                              name="billing_country"
                              value="India"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Billing Tel:</td>
                          <td>
                            <input
                              type="text"
                              name="billing_tel"
                              value={userInformation?.mobile}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Billing Email:</td>
                          <td>
                            <input
                              type="text"
                              name="billing_email"
                              value="testing@domain.com"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">Shipping information(optional):</td>
                        </tr>
                        <tr>
                          <td>Shipping Name</td>
                          <td>
                            <input
                              type="text"
                              name="delivery_name"
                              value="Sam"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Shipping Address:</td>
                          <td>
                            <input
                              type="text"
                              name="delivery_address"
                              value="Vile Parle"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Shipping City:</td>
                          <td>
                            <input
                              type="text"
                              name="delivery_city"
                              value="Mumbai"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Shipping State:</td>
                          <td>
                            <input
                              type="text"
                              name="delivery_state"
                              value="Maharashtra"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Shipping Zip:</td>
                          <td>
                            <input
                              type="text"
                              name="delivery_zip"
                              value="400038"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Shipping Country:</td>
                          <td>
                            <input
                              type="text"
                              name="delivery_country"
                              value="India"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Shipping Tel:</td>
                          <td>
                            <input
                              type="text"
                              name="delivery_tel"
                              value="0123456789"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Merchant Param1</td>
                          <td>
                            <input
                              type="text"
                              name="merchant_param1"
                              value="additional Info."
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Merchant Param2</td>
                          <td>
                            <input
                              type="text"
                              name="merchant_param2"
                              value="additional Info."
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Merchant Param3</td>
                          <td>
                            <input
                              type="text"
                              name="merchant_param3"
                              value="additional Info."
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Merchant Param4</td>
                          <td>
                            <input
                              type="text"
                              name="merchant_param4"
                              value="additional Info."
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Merchant Param5</td>
                          <td>
                            <input
                              type="text"
                              name="merchant_param5"
                              value="additional Info."
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Promo Code:</td>
                          <td>
                            <input type="text" name="promo_code" value="" />
                          </td>
                        </tr>
                        <tr>
                          <td>Customer Id:</td>
                          <td>
                            <input
                              type="text"
                              name="customer_identifier"
                              value=""
                            />
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <input type="submit" value="Checkout" />
                          </td>
                        </tr>
                      </table>
                    </form>
                  )}
                </div>

                <button onClick={handlePayment} className="payButton my-2">
                  Pay Now
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
