import { useEffect, useState } from "react";
import { getallOrdersData } from "../api/Api";
import "../styles/Orders.css";
import { Table } from "react-bootstrap";
import { Toaster, toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

const Orders = () => {
  const [OrdersData, setOrdersData] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const FetchAllOrders = async () => {
    try {
      const res = await getallOrdersData();
      console.log(res);
      if (res?.status === "success") {
        setOrdersData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchAllOrders();
  }, []);

  const [showHotelDetailsModal, setShowHotelDetailsModal] = useState(false);

  const handleHotelDetailsModalClose = () => setShowHotelDetailsModal(false);
  const handleHotelDetailsModalShow = (order) => {
    setSelectedOrder(order);
    setShowHotelDetailsModal(true);
  };

  return (
    <>
      <div className="container">
        <Toaster position="top-right" reverseOrder={false} />

        <div className="row">
          <div className="d-flex justify-content-between">
            <div>
              <span style={{ fontWeight: "600", fontSize: "20px" }}>
                Orders Management
              </span>
            </div>
          </div>
        </div>

        <div className="row py-4 table_user_data" style={{ overflow: "auto" }}>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>S.no</th>
                <th>User Information</th>
                <th>Order Amount</th>
                <th>Date</th>
                <th>Status</th>
                <th>actions</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(OrdersData) && OrdersData.length > 0 ? (
                OrdersData.filter((ele) => ele?.status !== 2) // Filter out rows with status = 2
                  .map((ele, index) => (
                    <tr key={ele?._id}>
                      <td>{index + 1}</td>

                      <td>
                        <div>
                          <span style={{ fontWeight: "600" }}>Name -</span>
                          <span className="ps-1">
                            {ele?.customerInfo?.name}
                          </span>
                        </div>
                        <div>
                          <span style={{ fontWeight: "600" }}>Email -</span>
                          <span className="ps-1">
                            {ele?.customerInfo?.email}
                          </span>
                        </div>
                        <div>
                          <span style={{ fontWeight: "600" }}>Mobile -</span>
                          <span className="ps-1">
                            {ele?.customerInfo?.mobile}
                          </span>
                        </div>

                        <div>
                          <span style={{ fontWeight: "600" }}>Address -</span>
                          <span className="ps-1">
                            {ele?.customerInfo?.address},
                            {ele?.customerInfo?.state},
                            {ele?.customerInfo?.pincode}
                          </span>
                        </div>
                      </td>

                      <td>₹ {ele?.totalAmount}</td>

                      <td>
                        <div>
                          <span style={{ fontWeight: "600" }}>
                            Check In Date -
                          </span>
                          <span className="ps-1">
                            {new Date(ele?.checkInDate).toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                          </span>
                        </div>

                        <div>
                          <span style={{ fontWeight: "600" }}>
                            Check Out Date -
                          </span>
                          <span className="ps-1">
                            {new Date(ele?.checkOutDate).toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                          </span>
                        </div>

                        <div>
                          <span style={{ fontWeight: "600" }}>
                            Date of Booking -
                          </span>
                          <span className="ps-1">
                            {new Date(ele?.createdAt).toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                          </span>
                        </div>

                        <div>
                          <span style={{ fontWeight: "600" }}>
                            Time of Booking -
                          </span>
                          <span className="ps-1">
                            {new Date(ele?.createdAt).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              }
                            )}
                          </span>
                        </div>
                      </td>

                      <td>{ele?.BookResult?.HotelBookingStatus}</td>
                      <td>
                        <div
                          className="d-flex px-2 py-1"
                          style={{
                            border: "1px solid gray",
                            cursor: "pointer",
                          }}
                          onClick={() => handleHotelDetailsModalShow(ele)}
                        >
                          Show Details
                        </div>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="12" className="text-center">
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        <div className="row">
          <Modal
            show={showHotelDetailsModal}
            onHide={handleHotelDetailsModalClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Hotel Order Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedOrder && (
                <Tabs defaultActiveKey="bookResult" id="order-details-tabs">
                  <Tab eventKey="bookResult" title="BookResult">
                    <div className="p-3">
                      <p>
                        <strong>Voucher Status:</strong>
                        {selectedOrder.BookResult?.VoucherStatus}
                      </p>
                      <p>
                        <strong>Response Status:</strong>
                        {selectedOrder.BookResult?.ResponseStatus}
                      </p>
                      <p>
                        <strong>Trace ID:</strong>
                        {selectedOrder.BookResult?.TraceId}
                      </p>
                      <p>
                        <strong>Hotel Booking Status:</strong>
                        {selectedOrder.BookResult?.HotelBookingStatus}
                      </p>
                      <p>
                        <strong>Invoice Number:</strong>
                        {selectedOrder.BookResult?.InvoiceNumber}
                      </p>
                      <p>
                        <strong>Confirmation Number:</strong>
                        {selectedOrder.BookResult?.ConfirmationNo}
                      </p>
                      <p>
                        <strong>Booking Ref Number:</strong>
                        {selectedOrder.BookResult?.BookingRefNo}
                      </p>
                      <p>
                        <strong>Booking ID:</strong>
                        {selectedOrder.BookResult?.BookingId}
                      </p>
                      <p>
                        <strong>Is Price Changed:</strong>
                        {selectedOrder.BookResult?.IsPriceChanged
                          ? "Yes"
                          : "No"}
                      </p>
                      <p>
                        <strong>Is Cancellation Policy Changed:</strong>
                        {selectedOrder.BookResult?.IsCancellationPolicyChanged
                          ? "Yes"
                          : "No"}
                      </p>
                    </div>
                  </Tab>
                  <Tab eventKey="customerDetails" title="Customer Details">
                    <div className="p-3">
                      <p>
                        <strong>Name:</strong>
                        {selectedOrder.customerInfo?.name}
                      </p>
                      <p>
                        <strong>Email:</strong>
                        {selectedOrder.customerInfo?.email}
                      </p>
                      <p>
                        <strong>Mobile:</strong>
                        {selectedOrder.customerInfo?.mobile}
                      </p>
                      <p>
                        <strong>Address:</strong>
                        {selectedOrder.customerInfo?.address}
                      </p>
                      <p>
                        <strong>State:</strong>
                        {selectedOrder.customerInfo?.state}
                      </p>
                      <p>
                        <strong>Pincode:</strong>
                        {selectedOrder.customerInfo?.pincode}
                      </p>
                    </div>
                  </Tab>
                </Tabs>
              )}
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Orders;
