import React from "react";
import { InternationalDestinationsItems } from "../../data/FooterItems";

export const InternationalDestinations = () => {
    return (
        <div>
            <p className="fw-bold">A guide to connect with the world.</p>
            <div className="row">
                {InternationalDestinationsItems.map((ele) => {
                    return (
                        <div
                            key={ele.id}
                            className="col-md-3 col-6 pb-2 pointerUnderLine"
                        >
                            <p className="m-0">{ele.title}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
