import { FaInstagram, FaFacebookF, FaTwitter, FaYoutube, FaLinkedin } from "react-icons/fa";

export const FooterItemsList = [

    {
        id: 3,
        title: "POPULAR DESTINATION",
    },
    {
        id: 4,
        title: "INTERNATIONAL DESTINATION",
    },
    {
        id: 5,
        title: "POPULAR AIRLINE",
    },
    {
        id: 6,
        title: "CONNECT WITH US",
    },
    {
        id: 7,
        title: "OFFERS",
    },
    {
        id: 9,
        title: "CURRENT OPENINGS",
    },
];

export const OurOfferingsItems = [
    {
        id: 1,
        title: "Flights",
    },
    {
        id: 2,
        title: "Hotels",
    },
    {
        id: 3,
        title: "Trains",
    },
    {
        id: 4,
        title: "Holidays",
    },
    {
        id: 5,
        title: "Buses",
    },
    {
        id: 6,
        title: "Cabs",
    },
    {
        id: 7,
        title: "Cruise",
    },
    {
        id: 8,
        title: "Charters",
    },
    {
        id: 9,
        title: "Flight Status",
    },
    {
        id: 10,
        title: "Airlines",
    },
    {
        id: 11,
        title: "Airports",
    },
    {
        id: 12,
        title: "Travel Guides",
    },
    {
        id: 13,
        title: "Check PNR Status",
    },
    {
        id: 14,
        title: "ETO PRO",
    },
    {
        id: 15,
        title: "Activities",
    },
    {
        id: 16,
        title: "Travel Updates",
    },
    {
        id: 17,
        title: "Corporate travel",
    },
    {
        id: 18,
        title: "Blog",
    },
];

export const QuickLinksItems = [
    {
        id: 1,
        title: "Flight by City",
    },
    {
        id: 2,
        title: "Airfare Calendar",
    },
    {
        id: 3,
        title: "Domestic Flights",
    },
    {
        id: 4,
        title: "International Flights",
    },
    {
        id: 5,
        title: "Train Routes",
    },
    {
        id: 6,
        title: "Holiday Packages",
    },
    {
        id: 7,
        title: "Hotel Directory",
    },
    {
        id: 8,
        title: "Goa to Delhi Flights",
    },
    {
        id: 9,
        title: "Delhi to Shimla Flights",
    },
    {
        id: 10,
        title: "Delhi to Mumbai Flights",
    },
    {
        id: 11,
        title: "Mumbai to Delhi Flights",
    },
    {
        id: 12,
        title: "Delhi to Goa Flights",
    },
    {
        id: 13,
        title: "Mumbai to Bangalore Flights",
    },
    {
        id: 14,
        title: "Delhi to Bangkok Flights",
    },
];

export const PopularDestinationsItems = [
    {
        id: 1,
        title: "Manali",
    },
    {
        id: 2,
        title: "Ladakh",
    },
    {
        id: 3,
        title: "Kashmir",
    },
    {
        id: 4,
        title: "Srinagar",
    },
    {
        id: 5,
        title: "Kerala",
    },
    {
        id: 6,
        title: "Andaman",
    },
    {
        id: 7,
        title: "Nainital",
    },
    {
        id: 8,
        title: "Mumbai",
    },
    {
        id: 9,
        title: "Munnar",
    },
    {
        id: 10,
        title: "Agra",
    },
    {
        id: 11,
        title: "Rishikesh",
    },
    {
        id: 12,
        title: "Dehradun",
    },
    {
        id: 13,
        title: "Ranikhet",
    },
    {
        id: 14,
        title: "Orissa",
    },
    {
        id: 15,
        title: "Shimla",
    },
    {
        id: 16,
        title: "Goa",
    },
    {
        id: 17,
        title: "Dalhousie",
    },
    {
        id: 18,
        title: "Ooty",
    },
    {
        id: 19,
        title: "Amritsar",
    },
    {
        id: 20,
        title: "Himachal",
    },
];

export const InternationalDestinationsItems = [
    {
        id: 1,
        title: "Mauritius",
    },
    {
        id: 2,
        title: "Europe",
    },
    {
        id: 3,
        title: "Thailand",
    },
    {
        id: 4,
        title: "Singapore",
    },
    {
        id: 5,
        title: "Dubai",
    },
    {
        id: 6,
        title: "Maldives",
    },
    {
        id: 7,
        title: "Egypt",
    },
    {
        id: 8,
        title: "Malaysia",
    },
    {
        id: 9,
        title: "Australia",
    },
    {
        id: 10,
        title: "Spain",
    },
    {
        id: 11,
        title: "Jordan",
    },
    {
        id: 12,
        title: "Bali",
    },
    {
        id: 13,
        title: "Turkey",
    },
    {
        id: 14,
        title: "Africa",
    },
    {
        id: 15,
        title: "Vietnam",
    },
    {
        id: 16,
        title: "Sri Lanka",
    },
    {
        id: 17,
        title: "Canada",
    },
    {
        id: 18,
        title: "Germany",
    },
    {
        id: 19,
        title: "Switzerland",
    },
    {
        id: 20,
        title: "Japan",
    },
];

export const PopularAirlinesItems = [
    {
        id: 1,
        title: "IndiGo Airlines",
    },
    {
        id: 2,
        title: "Air India Airlines",
    },
    {
        id: 3,
        title: "Vistara Airlines",
    },
    {
        id: 4,
        title: "SpiceJet Airlines",
    },
    {
        id: 5,
        title: "Air Asia Airlines",
    },
    {
        id: 6,
        title: "British Airways",
    },
    {
        id: 7,
        title: "Qatar Airways",
    },
    {
        id: 8,
        title: "Singapore Airlines",
    },
    {
        id: 9,
        title: "Etihad Airways",
    },
    {
        id: 10,
        title: "Thai Airways",
    },
    {
        id: 11,
        title: "United Airlines",
    },
    {
        id: 12,
        title: "American Airlines",
    },
    {
        id: 13,
        title: "Malaysia Airlines",
    },
    {
        id: 14,
        title: "Akasa Air",
    },
    {
        id: 15,
        title: "Egypt Air",
    },
    {
        id: 16,
        title: "Emirates Airlines",
    },
];

export const Offers = [
    {
        id: 1,
        title: "Flight Offers",
    },
    {
        id: 2,
        title: "Cab Offers",
    },
    {
        id: 3,
        title: "Bus Offers",
    },
    {
        id: 4,
        title: "Hotel Offers",
    },
    {
        id: 5,
        title: "Insurance Offers",
    },
    {
        id: 6,
        title: "Special Offers",
    },
];

export const ConnectWithUsSocialMedia = [
    {
        id: 1,
        title: "Facebook",
        link: "https://www.facebook.com/",
        icon: FaFacebookF
    },
    {
        id: 2,
        title: "Instagram",
        link: "https://www.instagram.com/",
        icon: FaInstagram
    },
    {
        id: 3,
        title: "Twitter",
        link: "https://www.twitter.com/",
        icon: FaTwitter
    },
    {
        id: 4,
        title: "Youtube",
        link: "https://www.youtube.com/",
        icon: FaYoutube
    },
    {
        id: 5,
        title: "LinkedIn",
        link: "https://www.linkedin.com/",
        icon: FaLinkedin
    }
];

export const ETOInsightList = [
    {
        id: 1,
        title: "Privacy Policy",
        link: "/privacypolicy",
    },
    {
        id: 2,
        title: "Terms and Conditions",
        link: "termsandconditions",
    },
    {
        id: 3,
        title: "Refund and Cancellation",
        link: "refundandcancellation",
    }
];
