import { Base_Url } from "../Url/Url";

export const SpecialOffersListAPIData = async () => {
  const res = await fetch(`${Base_Url}/offercategory/getalloffercategory`, {
    method: "Get",
    headers: {
      "Content-type": "application/json",
    },
  });
  return await res.json();
};

export const SingleSpecialOffersListAPIData = async (CategoryName) => {
  const res = await fetch(
    `${Base_Url}/offer/getAllOffersByCategory/${CategoryName}`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    }
  );
  return await res.json();
};

export const SingleSpecialOffersAPIData = async (id) => {
  const res = await fetch(`${Base_Url}/offer/${id}`, {
    method: "Get",
    headers: {
      "Content-type": "application/json",
    },
  });
  return await res.json();
};
