import { Base_Url } from "../Url/Url";

export const getAllCabs = async (data) => {
  const res = await fetch(
    `${Base_Url}/cabs/getallcabs?origin=673&destination=336&departuretime=17/07/2024`,
    {
      method: "Post",
      headers: {
        "Content-type": "application/json",
      },
    }
  );
  return await res.json();
};

export const getSingleCabs = async (id) => {
  const res = await fetch(`${Base_Url}/cabs/${id}`, {
    method: "Get",
    headers: {
      "Content-type": "application/json",
    },
  });
  return await res.json();
};

export const CabsBookingAPI = async (data) => {
  const res = await fetch(
    `${Base_Url}/cabs/bookcabs?SrdvIndex=${data?.SrdvIndex}&TraceID=${data?.TraceID}`,
    {
      method: "Post",
      headers: {
        "Content-type": "application/json",
      },
    }
  );
  return await res.json();
};

export const BookCabs = async (data) => {
  try {
    const res = await fetch(
      `${Base_Url}/cabs/bookcabs?SrdvIndex=${data?.srdvindex}&TraceID=${data?.traceid}`
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const getCarFrom = async (data) => {
  const res = await fetch(
    `${Base_Url}/buscity/fetch_bus_city?city=${data.city}`,
    {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    }
  );
  return await res.json();
};
