import React, { useEffect, useState } from "react";
import "../style/GetConsultation.css"; // Link the CSS file
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, Toaster } from "react-hot-toast";
import { getUserProfile } from "../api/ProfileAPI";
import { createConsultationForm } from "../api/AuthAPI";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object({
  requirement: Yup.string().required("Please enter your requirements"),
  name: Yup.string().required("Please enter your name"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  mobile: Yup.string()
    .required("Please enter your phone number")
    .matches(/^[0-9]{10}$/, "Phone number must be 10 digits"),
});

export const GetConsultation = () => {
  const navigate = useNavigate();

  // Fetch User Details

  const [userInformation, setUserInformation] = useState(null);

  const GetUserInformationApi = async () => {
    const userTokenFromCookies = Cookies.get("userToken");

    if (userTokenFromCookies) {
      const res = await getUserProfile(userTokenFromCookies);
      console.log(res, ":getUserProfile");
      setUserInformation(res?.data);
    }
  };

  useEffect(() => {
    const apiOfUserData = async () => {
      await GetUserInformationApi();
    };
    apiOfUserData();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  const handleFormSubmit = async (values, actions) => {
    if (!userInformation) {
      toast.error("You need to login first to submit the Consultation Form", {
        duration: 5000,
      });
      navigate("/login"); // Redirect to login if not logged in
      return; // Prevent form submission
    }

    try {
      const response = await createConsultationForm(values);
      if (response?.data?.status === "success") {
        actions.resetForm();
        toast.success("Form Submitted Successfully", { duration: 3000 });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    }
    actions.setSubmitting(false);
  };

  useEffect(() => {
    console.log(userInformation?.mobile);
    if (userInformation && !userInformation?.mobile) {
      toast.error("You need to login first to Submit Consultation Form", {
        duration: 5000, // 3 seconds
      });

      // setTimeout(() => {
      //   navigate("/login"); // Redirect after 3 seconds
      // }, 5000);
    }
  }, [userInformation]);

  return (
    <div className="container py-4">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="row">
        <div className="col-lg-6">
          <div className="section-heading">
            <h2 className="section-title">Get Consultation</h2>
            <p>
              We value your feedback, enquiries, and suggestions. Feel free to
              reach out to us by filling up the form, and our dedicated team
              will respond to you as soon as possibl.
            </p>
          </div>
          <div className="contact-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d112063.630914422!2d77.14323029286784!3d28.630107779724362!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x390cfd3162aebc1f%3A0x75c5ced7031d6de8!2s18%2C%20Barakhamba%20Rd%2C%20Fire%20Brigade%20Lane%2C%20Barakhamba%2C%20New%20Delhi%2C%20Delhi%20110001!3m2!1d28.630132699999997!2d77.2256317!5e0!3m2!1sen!2sin!4v1726296270670!5m2!1sen!2sin"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Maps"
            ></iframe>
          </div>
        </div>

        <div className="col-lg-6 ">
          <Formik
            initialValues={{
              name: "",
              email: "",
              mobile: userInformation?.mobile || "",
              requirement: "",
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({ isSubmitting, values }) => {
              return (
                <Form className="consultation_form">
                  <div className="form_container">
                    <label className="form_input_title">Name:</label>
                    <Field
                      name="name"
                      type="text"
                      placeholder="Enter your name"
                      className="form_input_field"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error"
                    />
                  </div>

                  <div className="form_container">
                    <label className="form_input_title">Mobile:</label>
                    <Field
                      name="mobile"
                      type="text"
                      placeholder="Enter your mobile number"
                      className="form_input_field"
                      maxLength={10}
                    />
                  </div>

                  <div className="form_container">
                    <label className="form_input_title">Email:</label>
                    <Field
                      name="email"
                      type="email"
                      placeholder="Enter your email"
                      className="form_input_field"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error"
                    />
                  </div>

                  <div className="form_container">
                    <label className="form_input_title">Requirement:</label>
                    <Field
                      as="textarea"
                      name="requirement"
                      placeholder="Enter your requirement"
                      className="form_input_field"
                    />
                    <ErrorMessage
                      name="requirement"
                      component="div"
                      className="error"
                    />
                  </div>

                  <div className="d-flex justify-content-center">
                    <button
                      type="submit"
                      className="form_submit_button"
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};
