import React, { useEffect, useRef, useState } from "react";
import "../../../style/Flight.css";
import { flightFromGetData, getFlightsFrom } from "../../../api/FlightsAPI";
import { MdFlight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Col, Row, Form } from "react-bootstrap";
import { OutsideClickHandler } from "./OutsideClickHandler";
import images from "../../../assets/Images";
import { CiCircleMinus } from "react-icons/ci";
import { CiCirclePlus } from "react-icons/ci";
import Modal from "react-bootstrap/Modal";

export const FlightForm = () => {
  const [selectTripType, setSelectTripType] = useState("One Way Trip");
  const [activeFareType, setActiveFareType] = useState("Regular");

  const [showTraveller, setShowTraveller] = useState(false);
  const handleTravellerClose = () => setShowTraveller(false);
  const handleTravellerShow = () => setShowTraveller(true);

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const [checkInDate, setCheckInDate] = useState(
    today.toISOString().split("T")[0]
  );
  const [checkOutDate, setCheckOutDate] = useState(
    tomorrow.toISOString().split("T")[0]
  );

  useEffect(() => {
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    setCheckOutDate(nextDay.toISOString().split("T")[0]);
  }, []);

  const handleCheckInDateChange = (event) => {
    const selectedDate = event.target.value;
    setCheckInDate(selectedDate);
    if (!checkOutDate || selectedDate > checkOutDate) {
      setCheckOutDate(selectedDate);
    }
  };

  const handleCheckOutDateChange = (event) => {
    const selectedDate = event.target.value;
    if (selectedDate > checkInDate) {
      setCheckOutDate(selectedDate);
    } else {
      setCheckOutDate(checkInDate);
    }
  };

  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [room, setRoom] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for managing dropdown visibility

  const dropdownRef = useRef(null); // Ref for dropdown element

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const totalQuantity = adults + children;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [infant, setInfant] = useState(0);
  const [classType, setClassType] = useState("Business");
  const [errorMessage, setErrorMessage] = useState("");

  const handleClassTypeClick = (value) => {
    setClassType(value);
  };

  const handleAdultsClick = (value) => {
    if (value + children <= 9) {
      setAdults(value);
      if (infant > value) {
        setInfant(value);
      }
      setErrorMessage("");
    } else {
      setErrorMessage("Total passenger must be less than 9");
    }
  };

  const handleChildrenClick = (value) => {
    if (adults + value <= 9) {
      setChildren(value);
      setErrorMessage("");
    } else {
      setErrorMessage("Total passenger must be less than 9");
    }
  };

  const handleInfantClick = (value) => {
    if (value <= adults) {
      setInfant(value);
      setErrorMessage("");
    } else {
      setErrorMessage("Number of infants cannot exceed the number of adults.");
    }
  };

  const handleFareTypeClick = (fareType) => {
    setActiveFareType(fareType);
  };

  const HandleSelectTripTypeClick = (value) => {
    setSelectTripType(value);
  };

  const HandleSelectTripTypeChange = (e) => {
    setSelectTripType(e.target.value);
  };

  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [filteredFlightData, setFliteredFlightData] = useState([]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setIsOpen(true);
    setAirportNameFrom("");
    setAirportCodeFrom("");
  };

  const [selectedOptionTo, setSelectedOptionTo] = useState(null);
  const [isOpenTo, setIsOpenTo] = useState(false);
  const [filteredFlightDataTo, setFilteredFlightDataTo] = useState([]);
  const [flightToSelect, setFlightToSelect] = useState({
    City: null,
    CityNameTo: "--",
    AirportNameTo: "--",
    AirportNameCodeTo: "--",
  });
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [flightFromSelect, setFlightFromSelect] = useState({
    City: null,
    CityNameFrom: "--",
    AirportNameFrom: "--",
    AirportNameCodeFrom: "--",
  });
  const [searchTerm, setSearchTerm] = useState("--");
  const [AirportCodeFrom, setAirportCodeFrom] = useState("--");
  const [AirportNameFrom, setAirportNameFrom] = useState("--");
  const [searchTermTo, setSearchTermTo] = useState("--");
  const [AirportCodeTo, setAirportCodeTo] = useState("--");
  const [AirportNameTo, setAirportNameTo] = useState("--");

  const handleInputChangeTo = (e) => {
    setSearchTermTo(e.target.value);
    setIsOpenTo(true);
    setAirportCodeTo("");
    setAirportNameTo("");
  };

  const handleOptionClick = (filteredFlightData) => {
    setSelectedOption(filteredFlightData);
    setSearchTerm(filteredFlightData?.city_name || "--");
    setAirportNameFrom(filteredFlightData?.airport_name || "--");
    setAirportCodeFrom(filteredFlightData?.IATA_code || "--");

    setFlightFromSelect({
      City: filteredFlightData?.city_name,
      CityNameFrom: searchTerm,
      AirportNameFrom: filteredFlightData?.airport_name || "--",
      AirportNameCodeFrom: filteredFlightData?.IATA_code || "--",
    });

    setIsOpen(false);
    setIsSearchEnabled(!!flightToSelect);
  };

  const handleOptionClickTo = (filteredFlightDataTo) => {
    setSelectedOptionTo(filteredFlightDataTo);
    setSearchTermTo(filteredFlightDataTo?.city_name || "--");
    setAirportNameTo(filteredFlightDataTo?.airport_name || "--");
    setAirportCodeTo(filteredFlightDataTo?.IATA_code || "--");

    setFlightToSelect({
      City: filteredFlightDataTo?.city_name,
      CityNameTo: searchTermTo,
      AirportNameTo: filteredFlightDataTo?.airport_name || "--",
      AirportNameCodeTo: filteredFlightDataTo?.IATA_code || "--",
    });

    setIsOpenTo(false);
    setIsSearchEnabled(!!flightFromSelect);
  };

  useEffect(() => {
    const getFilteredFlightsTo = async () => {
      try {
        const data = { city: searchTermTo };

        const res = await getFlightsFrom(data);
        if (res) {
          setFilteredFlightDataTo(res?.data);
        }
      } catch (error) {
        console.error(error + "error");
      }
    };

    if (searchTermTo !== "") {
      getFilteredFlightsTo();
    } else {
      setFilteredFlightDataTo([]);
    }
  }, [searchTermTo]);

  useEffect(() => {
    const myFilteredFlights = async () => {
      try {
        const data = { city: searchTerm };
        const res = await getFlightsFrom(data);

        if (res) {
          setFliteredFlightData(res?.data);
        }
      } catch (error) {
        console.error(error + "error");
      }
    };

    if (searchTerm !== "") {
      myFilteredFlights();
    } else {
      setFliteredFlightData([]);
    }
  }, [searchTerm]);

  function handleReverseLocations(event) {
    event.preventDefault();
    if (searchTerm && searchTermTo) {
      setSearchTerm(searchTermTo);
      setSearchTermTo(searchTerm);
      setAirportCodeFrom(AirportCodeTo);
      setAirportCodeTo(AirportCodeFrom);
      setAirportNameFrom(AirportNameTo);
      setAirportNameTo(AirportNameFrom);
    } else {
      window.alert("Please enter the location");
    }
  }

  const navigate = useNavigate();

  const handleSearchFlights = () => {
    const fromCode = flightFromSelect?.AirportNameCodeFrom || "--";
    const fromAirportLocationName = flightFromSelect?.CityNameFrom || "--";
    const toAirportLocationName = flightToSelect?.CityNameTo || "--";
    const toCode = flightToSelect?.AirportNameCodeTo || "--";

    if (selectTripType === "One Way Trip") {
      navigate(
        `/searchflight/${fromAirportLocationName}/${fromCode}/${toAirportLocationName}/${toCode}/${checkInDate}`
      );
    }
    if (selectTripType === "Round Trip") {
      navigate(
        `/roundtripflights/${fromAirportLocationName}/${fromCode}/${toAirportLocationName}/${toCode}/${checkInDate}/${checkOutDate}`
      );
    }
  };

  return (
    <>
      <div className="desktop-view">
        <div>
          <div
            className="container FareTypeDivContainer "
            style={{
              overflow: "hidden",
              zIndex: 3,
              position: "relative",
            }}
          >
            <div
              className="row px-3"
              style={{
                borderTopLeftRadius: "7px",
                borderTopRightRadius: "7px",
                borderTop: "2px solid #01a9ff",
                borderLeft: "2px solid #01a9ff",
                borderRight: "2px solid #01a9ff",
                backgroundColor: "white",
                padding: "15px",
              }}
            >
              <div className="row d-flex justify-content-between ">
                <div className="col-md-4">
                  <div className="d-flex flight_radio_items p-0">
                    <div
                      className={`d-flex align-items-center custom_radio_btn ${
                        selectTripType === "One Way Trip" ? "selected" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => HandleSelectTripTypeClick("One Way Trip")}
                    >
                      <input
                        type="radio"
                        value="One Way Trip"
                        checked={selectTripType === "One Way Trip"}
                        onChange={HandleSelectTripTypeChange}
                        readOnly
                      />
                      <span
                        className="text-secondary ps-1"
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                          textTransform: "uppercase",
                        }}
                      >
                        One way
                      </span>
                    </div>

                    <div
                      className={`ps-2 d-flex align-items-center custom_radio_btn ${
                        selectTripType === "Round Trip" ? "selected" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => HandleSelectTripTypeClick("Round Trip")}
                    >
                      <input
                        type="radio"
                        value="Round Trip"
                        checked={selectTripType === "Round Trip"}
                        onChange={HandleSelectTripTypeChange}
                        readOnly
                      />
                      <span
                        className="text-secondary ps-1"
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                          textTransform: "uppercase",
                        }}
                      >
                        Round Trip
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 p-0">
                  <p
                    className="text-end text-secondary"
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      textTransform: "uppercase",
                    }}
                  >
                    Book International and Domestic Flights
                  </p>
                </div>
              </div>

              <div className=" d-flex align-items-center">
                <div className="me-2" style={{ width: "20%" }}>
                  <p className="form_fields_Heading">WHERE FROM</p>

                  <div className="my-select-component">
                    <input
                      type="text"
                      className="w-100"
                      value={searchTerm}
                      onChange={handleInputChange}
                      placeholder="Search..."
                      style={{ fontSize: "25px", fontWeight: "600" }}
                      readOnly
                    />
                    <div>
                      <span>{AirportCodeFrom} </span>

                      {AirportNameFrom.length > 20
                        ? "," + AirportNameFrom.slice(0, 15) + "..."
                        : AirportNameFrom}
                    </div>
                  </div>
                </div>

                <div className="me-2">
                  <button className="bg-white" onClick={handleReverseLocations}>
                    <img
                      src="../images/icons/reverse.png"
                      alt=""
                      height="100px"
                      width="60px"
                    ></img>
                  </button>
                </div>

                <div
                  className="me-2"
                  style={{
                    width: "20%",
                    height: "100px",
                    borderRight: "2px solid #01a9ff",
                  }}
                >
                  <p className="form_fields_Heading">WHERE TO</p>

                  <div className="my-select-component">
                    <input
                      type="text"
                      className="w-100"
                      value={searchTermTo}
                      onChange={handleInputChangeTo}
                      placeholder="Search..."
                      style={{ fontSize: "25px", fontWeight: "600" }}
                      readOnly
                    />
                    <div>
                      <span>{AirportCodeTo} </span>
                      <span>
                        {AirportNameTo.length > 20
                          ? "," + AirportNameTo.slice(0, 15) + "..."
                          : AirportNameTo}
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  className=""
                  style={{
                    width: "20%",
                    borderRight: "2px solid #01a9ff",
                    height: "100px",
                  }}
                >
                  <p className="form_fields_Heading">DEPARTURE DATE</p>
                  <div>
                    <input
                      type="date"
                      value={checkInDate}
                      min={formatDate(today)}
                      onChange={handleCheckInDateChange}
                      placeholder="Select a date"
                      className="w-75"
                      readOnly
                    />
                  </div>
                </div>

                <div
                  className="me-2 ps-2"
                  style={{
                    width: "20%",
                    height: "100px",
                    borderRight: "2px solid #01a9ff",
                    backgroundColor:
                      selectTripType === "Round Trip"
                        ? ""
                        : "rgba(225,237,255,0.6)",
                  }}
                >
                  <p className="text-secondary" style={{ fontSize: "15px" }}>
                    RETURN DATE
                  </p>
                  <div>
                    {selectTripType === "Round Trip" ? (
                      <div style={{ overflow: "hidden" }}>
                        <input
                          type="date"
                          value={checkOutDate}
                          min={checkInDate}
                          onChange={handleCheckOutDateChange}
                          placeholder="Select a date"
                          className="w-75"
                          style={{ color: checkInDate ? "" : "" }}
                          readOnly
                        />
                      </div>
                    ) : (
                      <div style={{ overflow: "hidden" }}>
                        <input
                          type="date"
                          value={checkOutDate}
                          min={checkInDate}
                          onChange={handleCheckOutDateChange}
                          placeholder="Select a date"
                          className="w-75"
                          disabled
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="me-2" style={{ width: "20%", height: "100px" }}>
                  <div
                    className="flight_form_cards "
                    // onClick={handleTravelersModal}
                    // onClick={openModal}
                  >
                    <p className="form_fields_Heading">TRAVELLAR & CLASSES</p>

                    {`${adults + children} Person${
                      adults + children !== 1 ? "s" : ""
                    } & ${classType} Class`}
                  </div>
                </div>
              </div>
            </div>

            <Row className="d-flex justify-content-end">
              <Col
                className="col-md-8 bg-danger p-0"
                style={{
                  position: "absolute",
                  zIndex: 10,
                  top: "50%",
                }}
              >
                {isModalOpen ? (
                  <OutsideClickHandler onOutsideClick={closeModal}>
                    <Row
                      onClose={closeModal}
                      className="bg-white p-1"
                      style={{
                        position: "absolute",
                        right: "2%",
                        top: "30%",
                        border: "1px solid gray",
                      }}
                    >
                      <Col>
                        <Row>
                          <Col>
                            <Form.Group
                              controlId="adults"
                              style={{ fontSize: "14px" }}
                            >
                              <Form.Label>ADULTS (12 YEARS +)</Form.Label>

                              <div style={{ display: "flex" }}>
                                {[...Array(9)]?.map((_, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "3px",
                                      border: "1px solid gray",
                                      padding: "15px",
                                      marginLeft: "5px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      cursor: "pointer",
                                      color:
                                        adults === index + 1
                                          ? "white"
                                          : "black",
                                      backgroundColor:
                                        adults === index + 1
                                          ? "rgb(196,63,11)"
                                          : "white",
                                    }}
                                    onClick={() => handleAdultsClick(index + 1)}
                                  >
                                    <div className="flightFormItemsNumber ">
                                      {index + 1}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </Form.Group>
                          </Col>

                          <Col>
                            {errorMessage && (
                              <Col>
                                <p
                                  className="text-danger"
                                  style={{ fontSize: "13px" }}
                                >
                                  {errorMessage}
                                </p>
                              </Col>
                            )}
                          </Col>
                        </Row>

                        <Row className="mt-3">
                          <Col>
                            <Form.Group controlId="children">
                              <Form.Label>CHILDREN (2 - 12 YEARS)</Form.Label>
                              <div style={{ display: "flex" }}>
                                {[...Array(9)]?.map((_, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "3px",
                                      border: "1px solid gray",
                                      padding: "15px",
                                      marginLeft: "5px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      cursor: "pointer",
                                      color:
                                        children === index ? "white" : "black",
                                      backgroundColor:
                                        children === index
                                          ? "rgb(196,63,11)"
                                          : "white",
                                    }}
                                    onClick={() => handleChildrenClick(index)}
                                  >
                                    {index}
                                  </div>
                                ))}
                              </div>
                            </Form.Group>
                          </Col>

                          <Col>
                            <Form.Group controlId="infant">
                              <Form.Label>INFANTS (BELOW 2YEARS)</Form.Label>
                              <div style={{ display: "flex" }}>
                                {[...Array(adults)]?.map((_, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "3px",
                                      border: "1px solid gray",
                                      padding: "15px",
                                      marginLeft: "5px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      cursor: "pointer",
                                      color:
                                        infant === index ? "white" : "black",
                                      backgroundColor:
                                        infant === index
                                          ? "rgb(196,63,11)"
                                          : "white",
                                    }}
                                    onClick={() => handleInfantClick(index)}
                                  >
                                    {index}
                                  </div>
                                ))}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="mt-3">
                          <Col>
                            <Form.Group controlId="classType">
                              <Form.Label>CHOOSE TRAVEL CLASS</Form.Label>
                              <div style={{ display: "flex" }}>
                                {[
                                  "All",
                                  "Economy",
                                  "Premium Economy",
                                  "Business",
                                  "First Class",
                                ]?.map((type) => (
                                  <div
                                    key={type}
                                    style={{
                                      height: "20px",
                                      borderRadius: "3px",
                                      border: "1px solid gray",
                                      padding: "15px",
                                      marginLeft: "5px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      cursor: "pointer",
                                      color:
                                        classType === type ? "white" : "black",
                                      backgroundColor:
                                        classType === type
                                          ? "rgb(196,63,11)"
                                          : "white",

                                      marginRight: "5px",
                                    }}
                                    onClick={() => handleClassTypeClick(type)}
                                  >
                                    {type}
                                  </div>
                                ))}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </OutsideClickHandler>
                ) : (
                  <div>
                    <div>{/* Your main div content that should update */}</div>
                  </div>
                )}
              </Col>
            </Row>

            <Row>
              <Col
                className="col-md-3 p-0 m-0 "
                style={{
                  position: "absolute",
                  zIndex: 2,
                }}
              >
                {isOpen && (
                  <div
                    className="my-select-component bg-white"
                    style={{ border: "1.5px solid gray" }}
                  >
                    {/* <div className="custom-scrollbar">
                                            {filteredFlightData
                                                .filter((option) =>
                                                    option.airport_name
                                                        .toLowerCase()
                                                        .includes(searchTerm.toLowerCase())
                                                )
                                                ?.map((ele, index) => (
                                                    <div
                                                        className="row px-3 py-1"
                                                        key={index}
                                                        onClick={() => handleOptionClick(ele)}
                                                        style={{
                                                            borderBottom: "1px solid lightgray",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <div className="d-flex m-0 ">
                                                            <div className="col-md-2 d-flex align-items-center">
                                                                <span>
                                                                    <MdFlight className="text-info fs-2" />
                                                                </span>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <span className="fw-bold" style={{ fontSize: "14px" }}>
                                                                    {ele.airport_name} - {ele.IATA_code}
                                                                </span>
                                                                <p
                                                                    className="m-0 text-secondary"
                                                                    style={{ fontSize: "14px" }}
                                                                >
                                                                    {ele.city_name}
                                                                </p>
                                                            </div>

                                                            <div className="col-md-8 d-flex align-items-center">
                                                                <span className="fw-bold" style={{ fontSize: "14px" }}>
                                                                    {ele.IATA_code}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div> */}

                    <div className="custom-scrollbar">
                      {filteredFlightData?.map((ele, index) => (
                        <div
                          className="row px-3 py-1"
                          key={index}
                          onClick={() => handleOptionClick(ele)}
                          style={{
                            borderBottom: "1px solid lightgray",
                            cursor: "pointer",
                          }}
                        >
                          <div className="d-flex m-0 ">
                            <div className="col-md-2 d-flex align-items-center">
                              <span>
                                <MdFlight className="text-info fs-2" />
                              </span>
                            </div>
                            <div className="col-md-8">
                              <span
                                className="fw-bold"
                                style={{ fontSize: "14px" }}
                              >
                                {ele.airport_name} -{ele.IATA_code}
                              </span>
                              <p
                                className="m-0 text-secondary"
                                style={{ fontSize: "14px" }}
                              >
                                {ele.city_name}
                              </p>
                            </div>

                            <div className="col-md-8 d-flex align-items-center">
                              <span
                                className="fw-bold"
                                style={{ fontSize: "14px" }}
                              >
                                {ele.IATA_code}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </Col>

              <Col
                className="col-md-3 p-0 m-0 "
                style={{
                  position: "absolute",
                  zIndex: 2,
                  left: "22%",
                }}
              >
                {isOpenTo && (
                  <div
                    className="my-select-component bg-white"
                    style={{ border: "1.5px solid gray" }}
                  >
                    <div className="custom-scrollbar">
                      {filteredFlightDataTo?.map((ele, index) => (
                        <div
                          className="row px-3 py-1"
                          key={index}
                          onClick={() => handleOptionClickTo(ele)}
                          style={{
                            borderBottom: "1px solid lightgray",
                            cursor: "pointer",
                          }}
                        >
                          <div className="d-flex m-0">
                            <div className="col-md-2 d-flex align-items-center">
                              <span>
                                <MdFlight className="text-info fs-2" />
                              </span>
                            </div>
                            <div className="col-md-8">
                              <span
                                className="fw-bold"
                                style={{ fontSize: "14px" }}
                              >
                                {ele.airport_name} -{ele.IATA_code}
                              </span>
                              <p
                                className="m-0 text-secondary"
                                style={{ fontSize: "14px" }}
                              >
                                {ele.city_name}
                              </p>
                            </div>

                            <div className="col-md-8 d-flex align-items-center">
                              <span
                                className="fw-bold"
                                style={{ fontSize: "14px" }}
                              >
                                {ele.IATA_code}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <div className="row" style={{ height: "150px" }}>
              <div
                style={{
                  height: "65px",
                  borderBottomLeftRadius: "7px",
                  borderBottomRightRadius: "7px",
                  borderBottom: "2px solid #01a9ff",
                  borderLeft: "2px solid #01a9ff",
                  borderRight: "2px solid #01a9ff",
                  backgroundColor: "white",
                }}
                className="bg-white"
              >
                <div className="d-flex">
                  <div
                    className={
                      "FareTypeDiv" +
                      (activeFareType === "Regular" ? " active" : "")
                    }
                    onClick={() => handleFareTypeClick("Regular")}
                  >
                    <p
                      className="m-0 text-center"
                      style={{ fontWeight: 500, fontSize: "13px" }}
                    >
                      Regular
                    </p>
                    <p className="m-0 text-center" style={{ fontSize: "12px" }}>
                      Regular Fair
                    </p>
                  </div>
                  <div
                    className={
                      "FareTypeDiv" +
                      (activeFareType === "Students" ? " active" : "")
                    }
                    onClick={() => handleFareTypeClick("Students")}
                  >
                    <p
                      className="m-0 text-center"
                      style={{ fontWeight: 500, fontSize: "13px" }}
                    >
                      Students
                    </p>
                    <p className="m-0 text-center" style={{ fontSize: "12px" }}>
                      Extra Discount
                    </p>
                  </div>
                  <div
                    className={
                      "FareTypeDiv" +
                      (activeFareType === "Senior Citizen" ? " active" : "")
                    }
                    onClick={() => handleFareTypeClick("Senior Citizen")}
                  >
                    <p
                      className="m-0 text-center"
                      style={{ fontWeight: 500, fontSize: "13px" }}
                    >
                      Senior Citizen
                    </p>
                    <p className="m-0 text-center" style={{ fontSize: "12px" }}>
                      upto 70% off
                    </p>
                  </div>
                  <div
                    className={
                      "FareTypeDiv" +
                      (activeFareType === "Armed Forces" ? " active" : "")
                    }
                    onClick={() => handleFareTypeClick("Armed Forces")}
                  >
                    <p
                      className="m-0 text-center"
                      style={{ fontWeight: 500, fontSize: "13px" }}
                    >
                      Armed Forces
                    </p>
                    <p className="m-0 text-center" style={{ fontSize: "12px" }}>
                      upto 50% off
                    </p>
                  </div>
                  <div
                    className={
                      "FareTypeDiv" +
                      (activeFareType === "Doctor and Nurses" ? " active" : "")
                    }
                    onClick={() => handleFareTypeClick("Doctor and Nurses")}
                  >
                    <p
                      className="m-0 text-center"
                      style={{ fontWeight: 500, fontSize: "13px" }}
                    >
                      Doctor and Nurses
                    </p>
                    <p className="m-0 text-center" style={{ fontSize: "12px" }}>
                      upto 30% off
                    </p>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <div className="col-md-2 pb-3 d-flex justify-content-center">
                  <button
                    className="searchButton"
                    // onClick={handleSearchFlights}
                  >
                    Explore Flights
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-view">
        <div className="container">
          <div
            className="row pt-2 d-flex align-items-center"
            style={{
              borderRadius: "7px",
              backgroundColor: "white",
              height: "auto",
            }}
          >
            {/* One way & round trip radio button */}

            <div className="col-12 d-flex py-2">
              <div
                className={`d-flex align-items-center custom_radio_btn ${
                  selectTripType === "One Way Trip" ? "selected" : ""
                }`}
                onClick={() => HandleSelectTripTypeClick("One Way Trip")}
              >
                <input
                  type="radio"
                  value="One Way Trip"
                  checked={selectTripType === "One Way Trip"}
                  onChange={HandleSelectTripTypeChange}
                  readOnly
                />
                <span
                  className="text-secondary ps-1"
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  One way
                </span>
              </div>

              <div
                className={`ps-2 d-flex align-items-center custom_radio_btn ${
                  selectTripType === "Round Trip" ? "selected" : ""
                }`}
                onClick={() => HandleSelectTripTypeClick("Round Trip")}
              >
                <input
                  type="radio"
                  value="Round Trip"
                  checked={selectTripType === "Round Trip"}
                  onChange={HandleSelectTripTypeChange}
                  readOnly
                />
                <span
                  className="text-secondary ps-1"
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  Round Trip
                </span>
              </div>
            </div>

            {/* Flight From & Flight To input */}

            <div className="col-12 hotelformBorder">
              <div
                className="row pt-2 d-flex justify-content-between align-items-center"
                style={{ fontSize: "13px", color: "gray", fontWeight: "500" }}
              >
                <div className="col-4">
                  <span className="m-0">FLIGHT FROM</span>
                </div>

                <div className="col-4 text-end">
                  <span className="m-0 ">FLIGHT TO</span>
                </div>
              </div>

              <div className="row">
                <div className="col-5">
                  <input
                    type="text"
                    className="w-100"
                    value={searchTerm}
                    onChange={handleInputChange}
                    placeholder="Search..."
                    style={{ fontSize: "16px", fontWeight: "600" }}
                    readOnly
                  />
                  <div style={{ fontSize: "13px" }}>
                    <span style={{ fontWeight: "600" }}>
                      {AirportCodeFrom + ","}
                    </span>

                    <span className="ps-1">
                      {AirportNameFrom.length > 5
                        ? AirportNameFrom.slice(0, 15) + "..."
                        : AirportNameFrom}
                    </span>
                  </div>
                </div>

                <div className="col-2 d-flex justify-content-center align-items-center ">
                  <button className="bg-white" onClick={handleReverseLocations}>
                    <img
                      src={images.reverse}
                      alt=""
                      height="100%"
                      width="60px"
                    ></img>
                  </button>
                </div>

                <div className="col-5 text-end">
                  <input
                    type="text"
                    className="w-100 text-end"
                    value={searchTermTo}
                    onChange={handleInputChangeTo}
                    placeholder="Search..."
                    style={{ fontSize: "16px", fontWeight: "600" }}
                    readOnly
                  />
                  <div style={{ fontSize: "13px" }}>
                    <span style={{ fontWeight: "600" }}>
                      {AirportCodeTo + ","}
                    </span>

                    <span className="ps-1">
                      {AirportNameTo.length > 5
                        ? AirportNameTo.slice(0, 20) + "..."
                        : AirportNameTo}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Flight From Search - Dropdown  */}

            <div
              className="col-12 p-0"
              style={{
                position: "absolute",
                zIndex: 2,
                top: "57%",
              }}
            >
              {isOpen && (
                <div className="my-select-component">
                  <div className="custom-scrollbar">
                    {filteredFlightData?.map((ele, index) => (
                      <div
                        className="row py-1"
                        key={index}
                        onClick={() => handleOptionClick(ele)}
                        style={{
                          border: "1px solid lightgray",
                          backgroundColor: "#f1f1f1",
                        }}
                      >
                        <div className="d-flex m-0 ">
                          <div
                            className="col-md-8"
                            style={{ fontSize: "14px" }}
                          >
                            <span>
                              {ele?.airport_name} -{ele?.IATA_code} ,{ele?.name}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* Flight From Search - Dropdown  */}

            <div
              className="col-12 p-0"
              style={{
                position: "absolute",
                zIndex: 2,
                top: "57%",
              }}
            >
              {isOpenTo && (
                <div className="my-select-component bg-white">
                  <div className="custom-scrollbar">
                    {filteredFlightDataTo?.map((ele, index) => (
                      <div
                        className="row px-3 py-1"
                        key={index}
                        onClick={() => handleOptionClickTo(ele)}
                        style={{
                          border: "1px solid lightgray",
                          backgroundColor: "#f1f1f1",
                        }}
                      >
                        <div className="d-flex m-0 ">
                          <div
                            className="col-md-8"
                            style={{ fontSize: "14px" }}
                          >
                            <span>
                              {ele?.airport_name} -{ele?.IATA_code} ,{ele?.name}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* Traveller details - Dropdown */}

            <div
              className="col-11  p-0"
              style={{
                position: "absolute",
                zIndex: 2,
                top: "83%",
              }}
            >
              {isDropdownOpen && (
                <div ref={dropdownRef}>
                  <div
                    className="bg-white px-2"
                    style={{ border: "1.5px solid gray", fontSize: "14px" }}
                  >
                    <div className="d-flex align-items-center ">
                      <label className="me-2">Adults:</label>
                      <button
                        className="bg-white fs-6"
                        onClick={() => setAdults(adults + 1)}
                      >
                        <CiCirclePlus />
                      </button>
                      <span className="mx-2">{adults}</span>
                      <button
                        className="bg-white fs-6"
                        onClick={() => setAdults(adults > 1 ? adults - 1 : 1)}
                      >
                        <CiCircleMinus />
                      </button>
                    </div>
                    <div className="d-flex align-items-center mt-1">
                      <label className="me-2">Children:</label>
                      <button
                        className="bg-white fs-6"
                        onClick={() => setChildren(children + 1)}
                      >
                        <CiCirclePlus />
                      </button>
                      <span className="mx-2">{children}</span>
                      <button
                        className="bg-white fs-6"
                        onClick={() =>
                          setChildren(children > 0 ? children - 1 : 0)
                        }
                      >
                        <CiCircleMinus />
                      </button>
                    </div>
                    <div className="d-flex align-items-center mt-1">
                      <label className="me-2">Infants:</label>
                      <div className="d-flex align-items-center ">
                        <button
                          className="bg-white fs-6"
                          onClick={() => setRoom(room + 1)}
                        >
                          <CiCirclePlus />
                        </button>
                        <span className="mx-2">{room}</span>
                        <button
                          className="bg-white fs-6"
                          onClick={() => setRoom(room > 0 ? room - 1 : 0)}
                        >
                          <CiCircleMinus />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Departure date */}

            <div
              className="col-6 ps-0 "
              style={{ paddingRight: "3px", fontSize: "10px" }}
            >
              <div className="w-100 hotelformBorder px-2">
                <p
                  className="form_fields_Heading m-0 py-1"
                  style={{ fontSize: "13px", color: "gray", fontWeight: "500" }}
                >
                  DEPARTURE DATE
                </p>

                <input
                  type="date"
                  value={checkInDate}
                  min={formatDate(today)}
                  onChange={handleCheckInDateChange}
                  className="w-100 bg-white"
                  style={{ fontSize: "13px" }}
                />
              </div>
            </div>

            {/* Return Date */}

            <div
              className="col-6 pe-0"
              style={{ paddingLeft: "3px", fontSize: "10px" }}
            >
              <div
                className="w-100 hotelformBorder px-2"
                style={{
                  backgroundColor:
                    selectTripType === "Round Trip"
                      ? ""
                      : "rgba(225,237,255,0.8)",
                }}
              >
                <p
                  className="form_fields_Heading m-0 py-1"
                  style={{ fontSize: "13px", color: "gray", fontWeight: "500" }}
                >
                  RETURN DATE
                </p>
                <div style={{ overflow: "hidden" }}>
                  <input
                    type="date"
                    value={checkOutDate}
                    min={checkInDate}
                    onChange={handleCheckOutDateChange}
                    placeholder="Select a date"
                    className="w-100"
                    style={{
                      fontSize: "13px",
                      backgroundColor:
                        selectTripType === "Round Trip" ? "white" : "",
                      color: selectTripType === "Round Trip" ? "black" : "gray",
                    }}
                    disabled={selectTripType !== "Round Trip"}
                  />
                </div>
              </div>
            </div>

            {/* Class type */}

            <div
              className="col-6 ps-0 "
              style={{ paddingRight: "3px", fontSize: "13px" }}
            >
              <div className="w-100 hotelformBorder px-2">
                <p
                  className="form_fields_Heading m-0 py-1"
                  style={{ fontSize: "13px", color: "gray", fontWeight: "500" }}
                >
                  CLASS TYPE
                </p>
                <select className="w-100 bg-white">
                  <option>ECONOMY</option>
                  <option>PREMIUM ECONOMY</option>
                  <option>BUSINESS</option>
                  <option>FIRST CLASS</option>
                </select>
              </div>
            </div>

            {/* Travellar count */}

            <div
              className="col-6  pe-0 "
              style={{ paddingLeft: "3px", fontSize: "13px" }}
            >
              <div
                className="w-100 hotelformBorder px-2"
                onClick={handleTravellerShow}
              >
                <p
                  className="form_fields_Heading m-0 py-1"
                  style={{ fontSize: "13px", color: "gray", fontWeight: "500" }}
                >
                  TRAVELLER COUNT
                </p>
                <span className="m-0">{totalQuantity} Person</span>
                <span className="m-0 ps-2">{room} Infants</span>
              </div>

              <Modal show={showTraveller} onHide={handleTravellerClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Travellar Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div
                    className="bg-white p-2"
                    style={{ border: "1px solid gray", fontSize: "14px" }}
                  >
                    <div className="d-flex align-items-center ">
                      <label className="me-2">Adults:</label>
                      <button
                        className="bg-white fs-2"
                        onClick={() => setAdults(adults + 1)}
                      >
                        <CiCirclePlus />
                      </button>
                      <span className="mx-2">{adults}</span>
                      <button
                        className="bg-white fs-2"
                        onClick={() => setAdults(adults > 1 ? adults - 1 : 1)}
                      >
                        <CiCircleMinus />
                      </button>
                    </div>
                    <div className="d-flex align-items-center mt-1">
                      <label className="me-2">Children:</label>
                      <button
                        className="bg-white fs-2"
                        onClick={() => setChildren(children + 1)}
                      >
                        <CiCirclePlus />
                      </button>
                      <span className="mx-2">{children}</span>
                      <button
                        className="bg-white fs-2"
                        onClick={() =>
                          setChildren(children > 0 ? children - 1 : 0)
                        }
                      >
                        <CiCircleMinus />
                      </button>
                    </div>
                    <div className="d-flex align-items-center mt-1">
                      <label className="me-2">Infants:</label>
                      <div className="d-flex align-items-center ">
                        <button
                          className="bg-white fs-2"
                          onClick={() => setRoom(room + 1)}
                        >
                          <CiCirclePlus />
                        </button>
                        <span className="mx-2">{room}</span>
                        <button
                          className="bg-white fs-2"
                          onClick={() => setRoom(room > 0 ? room - 1 : 0)}
                        >
                          <CiCircleMinus />
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>

            {/* Regular , Student , Senior Citizens offers */}

            <div className="col-12 px-0" style={{ fontSize: "11px" }}>
              <div className="d-flex py-2 ">
                <div
                  className={`d-flex align-items-center me-1 ${
                    activeFareType === "Regular" ? "selected" : ""
                  }`}
                  onClick={() => handleFareTypeClick("Regular")}
                >
                  <input
                    type="radio"
                    name="Regular"
                    id="regularRadio"
                    value="Regular"
                    checked={activeFareType === "Regular"}
                    style={{ height: "11px", width: "15px" }}
                    onChange={() => handleFareTypeClick("Regular")}
                    readOnly
                  />
                  <span className="ps-1" htmlFor="regularRadio">
                    Regular
                  </span>
                </div>

                <div
                  className={`d-flex align-items-center me-1 ${
                    activeFareType === "Students" ? "selected" : ""
                  }`}
                  onClick={() => handleFareTypeClick("Students")}
                >
                  <input
                    type="radio"
                    name="Students"
                    id="StudentsRadio"
                    value="Regular"
                    style={{ height: "11px", width: "15px" }}
                    checked={activeFareType === "Students"}
                    onChange={() => handleFareTypeClick("Students")}
                    readOnly
                  />
                  <span className="ps-1" htmlFor="regularRadio">
                    Students
                  </span>
                </div>

                <div
                  className={`d-flex align-items-center me-1 ${
                    activeFareType === "Senior Citizen" ? "selected" : ""
                  }`}
                  onClick={() => handleFareTypeClick("Senior Citizen")}
                >
                  <input
                    type="radio"
                    name="Senior Citizen"
                    id="SeniorCitizenRadio"
                    value="Regular"
                    style={{ height: "11px", width: "15px" }}
                    checked={activeFareType === "Senior Citizen"}
                    onChange={() => handleFareTypeClick("Senior Citizen")}
                    readOnly
                  />
                  <span
                    className="ps-1"
                    htmlFor="regularRadio"
                    style={{ width: "80px" }}
                  >
                    Senior Citizen
                  </span>
                </div>

                <div
                  className={`d-flex align-items-center ${
                    activeFareType === "Armed Forces" ? "selected" : ""
                  }`}
                  onClick={() => handleFareTypeClick("Armed Forces")}
                >
                  <input
                    type="radio"
                    name="ArmedForces"
                    id="ArmedForcesRadio"
                    value="Regular"
                    style={{ height: "11px", width: "15px" }}
                    checked={activeFareType === "Armed Forces"}
                    onChange={() => handleFareTypeClick("Armed Forces")}
                    readOnly
                  />
                  <span
                    className="ps-1"
                    htmlFor="Armed ForcesRadio"
                    style={{ width: "90px" }}
                  >
                    Armed Forces
                  </span>
                </div>
              </div>
            </div>

            {/* Search Button */}

            <div className="mt-2 col-12 p-0 d-flex justify-content-center">
              <div>
                <button
                  className="searchButton py-2"
                  style={{ width: "200px" }}
                  // onClick={handleSearchFlights}
                >
                  Explore Flights
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
