import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DailyTravelBlogsAPIData } from "../api/DailyTravelBlogsAPI";
import { Loader } from "../component/Loader";
import { SlCalender } from "react-icons/sl";

export const AllDailyTravelBlogs = () => {
  const [blogsData, setBlogsData] = useState(null);
  const [imgBaseUrl, setImgBaseUrl] = useState("");
  const navigate = useNavigate();

  const handleBlogsData = async () => {
    try {
      const res = await DailyTravelBlogsAPIData();
      console.log(res);
      if (res?.data) {
        setBlogsData(res?.data);
        setImgBaseUrl(res?.URL);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSingleBlogsData = (data) => {
    navigate(`/blogs/${data.Id}`);
  };

  useEffect(() => {
    handleBlogsData();
  }, []);

  const handleResetAll = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="container-fluid mobile-view">
        <div
          className="row d-flex justify-content-between"
          style={{ position: "relative" }}
        >
          <h3 className="text-center m-0 py-1">All Daily Travel Blogs</h3>
          <div
            className="col-md-3 Section_Sidebar_Container custom_scrollbar desktop-view"
            style={{
              border: "1.5px solid rgb(1,128,253)",
              overflowY: "scroll",
              position: "sticky",
              top: "80px",
              maxHeight: "calc(100vh - 60px)",
            }}
          >
            <div
              className="row text-white d-flex justify-content-between py-3"
              style={{
                background: " rgb(25, 36, 186)",
                borderBottom: "1.5px solid rgb(1,128,253)",
              }}
            >
              <div className="col-12">
                <h5 className="m-0">Blogs Categories</h5>
              </div>
            </div>

            <div
              className="row d-flex justify-content-between  py-3"
              style={{ background: "#fff" }}
            >
              <div className="col-md-12 p-0">
                <ul className="blogsItems">
                  <li>All</li>
                  <li>Destination Guides</li>
                  <li>Travel Tips & Tricks</li>
                  <li>Adventure Stories</li>
                  <li>Hidden Gems</li>
                  <li>Food & Culinary Adventures</li>
                  <li>Budget Travel Hacks</li>
                  <li>Family-Friendly Fun</li>
                  <li>Eco-Tourism & Sustainability</li>
                  <li>Solo Traveler Chronicles</li>
                  <li>Luxury Escapes</li>
                  <li>Off-the-Beaten-Path</li>
                  <li>Urban Adventures</li>
                  <li>Beach Bliss</li>
                  <li>Wanderlust Diaries</li>
                  <li>Road Trip Adventures</li>
                  <li>Historic Journeys</li>
                  <li>Wildlife Encounters</li>
                  <li>Backpacker's Corner</li>
                  <li>Travel Photography & Inspiration</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-12 p-0 px-1 mb-5">
            {blogsData !== null ? (
              <div className="row m-0 p-0">
                {blogsData?.map((ele) => {
                  return (
                    <div key={ele?.Id} className="col-6 p-0 p-1">
                      <a
                        href={`/blogs/${ele.Id}`}
                        className="text-black"
                        style={{ textDecoration: "none" }}
                      >
                        <div className="Section_Cards1">
                          <img
                            src={`${imgBaseUrl}${ele?.Image}`}
                            alt="img"
                            height="130px"
                            className="Section_image"
                          ></img>
                          <div
                            className="Section_text_container "
                            style={{
                              backgroundColor: "rgba(133, 60, 191, 0.11)",
                            }}
                          >
                            <div className="p-0 m-0 text-danger d-flex align-items-center">
                              <span>
                                <SlCalender />
                              </span>
                              <span className="px-1"> {ele?.Date}</span>
                            </div>

                            <div
                              style={{ fontSize: "12px", fontWeight: "600" }}
                            >
                              {ele?.Title.length > 50
                                ? ele?.Title.slice(0, 40) + " ..."
                                : ele?.Title}
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>

      <div className="container-fluid desktop-view">
        <div
          className="row d-flex justify-content-between px-4"
          style={{ position: "relative" }}
        >
          <h3 className="text-center py-4">All Daily Travel Blogs</h3>
          {/* <div
                        className="col-md-3 Section_Sidebar_Container custom_scrollbar desktop-view"
                        style={{ border: "1.5px solid rgb(1,128,253)", overflowY: "scroll", position: "sticky", top: "80px", maxHeight: "calc(100vh - 60px)" }}
                    >
                        <div
                            className="row text-white d-flex justify-content-between py-3"
                            style={{ background: " rgb(25, 36, 186)", borderBottom: "1.5px solid rgb(1,128,253)" }}
                        >
                            <div className="col-12">
                                <h5 className="m-0">Blogs Categories</h5>
                            </div>
                        </div>

                        <div className="row d-flex justify-content-between  py-3" style={{ background: "#fff" }}>

                            <div className="col-md-12 p-0">
                                <ul className="blogsItems">
                                    <li>All</li>
                                    <li>Destination Guides</li>
                                    <li>Travel Tips & Tricks</li>
                                    <li>Adventure Stories</li>
                                    <li>Hidden Gems</li>
                                    <li>Food & Culinary Adventures</li>
                                    <li>Budget Travel Hacks</li>
                                    <li>Family-Friendly Fun</li>
                                    <li>Eco-Tourism & Sustainability</li>
                                    <li>Solo Traveler Chronicles</li>
                                    <li>Luxury Escapes</li>
                                    <li>Off-the-Beaten-Path</li>
                                    <li>Urban Adventures</li>
                                    <li>Beach Bliss</li>
                                    <li>Wanderlust Diaries</li>
                                    <li>Road Trip Adventures</li>
                                    <li>Historic Journeys</li>
                                    <li>Wildlife Encounters</li>
                                    <li>Backpacker's Corner</li>
                                    <li>Travel Photography & Inspiration</li>
                                </ul>
                            </div>
                        </div>
                    </div> */}

          <div className="col-md-12  py-3">
            {blogsData !== null ? (
              <div className="row m-0 p-0">
                {blogsData?.map((ele) => {
                  return (
                    <div key={ele?.Id} className="col-md-3  m-0">
                      <div className="Section_Cards1">
                        <img
                          src={`https://api.exploretriponline.com${ele?.Image}`}
                          alt="img"
                          height="150px"
                          className="Section_image"
                        ></img>
                        <div
                          className="Section_text_container "
                          style={{
                            backgroundColor: "rgba(133, 60, 191, 0.11)",
                          }}
                        >
                          <div className="fw-bold py-2">
                            {ele?.Title.length > 50
                              ? ele?.Title.slice(0, 40) + " ..."
                              : ele?.Title}
                          </div>
                          <div>
                            {ele?.Description.length > 100
                              ? ele?.Description.slice(0, 150) + " ..."
                              : ele?.Description}
                          </div>
                          <div className="pt-2 d-flex justify-content-center">
                            <button className="activeOfferBtnClass py-2 px-4">
                              <a
                              className="text-white"
                                href={`/blogs/${ele._id}`}
                                style={{ textDecoration: "none" }}
                              >
                                Read More !
                              </a>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
