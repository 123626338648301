import { Base_Url } from "../Url/Url";

export const SearchAllHotelsAPI = async (data) => {
  const res = await fetch(
    `${Base_Url}/hotel/getallHotels?destination=130443&room=1&adult=1&checkin=30/04/2020&child=0
    `,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
    }
  );
  return await res.json();
};

export const getHotels = async (data) => {
  const res = await fetch(
    `${Base_Url}/hotelscity/fetch_hotel_city?city=${data.city}`,
    {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    }
  );
  return await res.json();
};

export const getSingleHotelRooms = async () => {
  const res = await fetch(
    `${Base_Url}/hotel/gethotelroom?SrdvIndex=SrdvTB&SrdvType=SingleTB&ResultIndex=9&TraceId=1&HotelCode=92G|DEL`,
    {
      method: "Post",
      headers: {
        "Content-type": "application/json",
      },
    }
  );
  return await res.json();
};

export const SearchSingleHotelsAPI = async () => {
  const res = await fetch(
    `${Base_Url}/hotel/gethotelinfo?SrdvIndex=SrdvTB&SrdvType=SingleTB&ResultIndex=9&TraceId=1&HotelCode=92G|DEL
    `,
    {
      method: "Post",
      headers: {
        "Content-type": "application/json",
      },
    }
  );
  return await res.json();
};

export const BookHotelRoom = async () => {
  const res = await fetch(`${Base_Url}/hotel/bookroom`, {
    method: "Post",
    headers: {
      "Content-type": "application/json",
    },
  });
  return await res.json();
};
export const OrderHotel = async (formData) => {
  try {
    const res = await fetch(`${Base_Url}/hotelorders/createhotelorders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    return await res.json();
  } catch (error) {
    console.error("Error in OrderHotel function:", error);
    throw error;
  }
};
