import { Base_Url } from "../Url/Url";

export const HolidayPackageAPIData = async () => {
  const res = await fetch(
    `${Base_Url}/holidaypackage/getallholidaypackage`,
    {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    }
  );
  return await res.json();
};

export const SingleHolidayPackageAPIData = async (id) => {
  const res = await fetch(
    `${Base_Url}/holidaypackage/getsingleholidaypackage/${id}`,
    {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    }
  );
  return await res.json();
};
