import React, { useEffect, useState } from "react";
import { SpecialOffersCarousel } from "./SpecialOffersCarousel";
import {
  SingleSpecialOffersListAPIData,
  SpecialOffersListAPIData,
} from "../../api/SpecialOffersAPI";

export const SpecialOffers = () => {
  const [touristData, setTouristData] = useState([]);
  const [activeItem, setActiveItem] = useState("Flights");
  const [offersData, setOffersData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await SpecialOffersListAPIData();
        if (res.status === "success") {
          setTouristData(res.data);
          fetchOffersByCategory(activeItem); // Fetch initial offers
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const fetchOffersByCategory = async (categoryName) => {
    try {
      const res = await SingleSpecialOffersListAPIData(categoryName);
      if (res.status === "success") {
        setOffersData(res.data);
      }
    } catch (error) {
      console.error("Error fetching offers:", error);
    }
  };

  const handleItemClick = (category) => {
    setActiveItem(category.OfferCategoryName);
    fetchOffersByCategory(category.OfferCategoryName);
  };

  return (
    <>
      <div className="">
        <div className="container pt-2">
          <div className="row d-flex justify-content-center">
            <div className="col-md-3 d-flex justify-content-center">
              <div className="homepage_component_heading_txt">
                Special Offers
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-md-2 d-flex justify-content-center">
              <div className="Section_text_small_underline"></div>
            </div>
          </div>

          <div className="desktop-view">
            <div className="row d-flex justify-content-center mt-4">
              <div className="col-md-12">
                <div className="row d-flex justify-content-between">
                  <div
                    className="col-md-6 p-0 d-flex"
                    style={{
                      borderRadius: "20px",
                    }}
                  >
                    {touristData.map((ele) => (
                      <div
                        className={`col d-flex justify-content-center align-items-center p-2 ${
                          activeItem === ele.OfferCategoryName
                            ? "activeOfferBtnClass"
                            : "inactiveOfferBtnClass"
                        }`}
                        key={ele._id}
                        onClick={() => handleItemClick(ele)}
                        style={{ cursor: "pointer" }}
                      >
                        <p className="m-0">{ele.OfferCategoryName}</p>
                      </div>
                    ))}
                  </div>

                  <div className="col-md-2 Section_view_all_link d-flex justify-content-end">
                    {/* <Link
                      to="/blogs"
                      className="text-black"
                      style={{ textDecoration: "none" }}
                    >
                      <span className="">View all</span>
                      <span>
                        <FaAngleRight />
                      </span>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mobile-view">
            <div className="row d-flex justify-content-center mt-4">
              <div className="col-md-11">
                <div className="row d-flex justify-content-between">
                  <div
                    className="col-md-8 p-0 pb-2 d-flex custom_scrollbar_horizontal"
                    style={{
                      borderRadius: "20px",
                      overflow: "auto",
                    }}
                  >
                    {touristData.map((ele) => (
                      <div
                        className={`col d-flex justify-content-center align-items-center p-2  ${
                          activeItem === ele.OfferCategoryName
                            ? "activeOfferBtnClass"
                            : "inactiveOfferBtnClass"
                        }`}
                        key={ele._id}
                        onClick={() => handleItemClick(ele)}
                        style={{ cursor: "pointer" }}
                      >
                        <p className="m-0">{ele.OfferCategoryName}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-0">
          <div className="row p-0 m-0 mt-4">
            <SpecialOffersCarousel data={offersData} />
          </div>
        </div>
      </div>
    </>
  );
};
