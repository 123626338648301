import React, { useEffect, useState } from "react";
import "../../style/TopDestination.css";
import { TopTouristAttractionAPIData } from "../../api/TopDestinationAPI";
import { Loader } from "../Loader";
import { Base_Url } from "../../Url/Url";
import { Link } from "react-router-dom";

export const TopDestination = () => {
  const [touristData, setTouristData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await TopTouristAttractionAPIData();

        if (res?.status === "success") {
          setTouristData(res?.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <div className="desktop-view mt-2">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-5 d-flex justify-content-center">
              <div className="homepage_component_heading_txt">
                Top Tourist Attractions
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="col-md-2 d-flex justify-content-center">
              <div className="Section_text_small_underline"></div>
            </div>
          </div>

          <div className="row tourist_bgImage mt-3 ">
            <div className="col-md-4">
              <div className="homepage_component_sub_heading_txt">
                International Locations
              </div>
              <div className="Section_text_small_underline"></div>
            </div>
            <div className="row py-2 d-flex justify-content-center">
              <div className="mt-4">
                {touristData ? (
                  <div className="row d-flex justify-content-center">
                    {touristData
                      ?.filter(
                        (location) =>
                          location.DestinationType === "International"
                      )
                      ?.map((ele, index) => {
                        return (
                          <div
                            key={ele?._id || index}
                            className="col d-flex justify-content-center"
                            style={{ cursor: "pointer" }}
                          >
                            <Link to="/getconsultation">
                              <div>
                                <div
                                  className="TouristPlace_ImageDiv"
                                  style={{ overflow: "hidden" }}
                                >
                                  <img
                                    src={`${Base_Url}${ele?.Image}`}
                                    alt="img"
                                    width="100%"
                                    height="100%"
                                    style={{
                                      objectFit: "auto",
                                      overflow: "hidden",
                                    }}
                                  ></img>
                                </div>
                                <div className="text-center fw-bold m-0 pt-2 text-black">
                                  {ele.Name}
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            </div>

            <div className="row py-2">
              <div className="col-md-4">
                <div className="homepage_component_sub_heading_txt">
                  Domestic Locations
                </div>
                <div className="Section_text_small_underline"></div>
              </div>

              <div className="mt-4">
                {touristData ? (
                  <div className="row d-flex justify-content-center">
                    {touristData
                      ?.filter(
                        (location) => location.DestinationType === "Domestic"
                      )
                      ?.map((ele, index) => {
                        return (
                          <div
                            key={ele?.id || index}
                            className="col d-flex justify-content-center"
                            style={{ cursor: "pointer" }}
                          >
                            <Link to="/getconsultation">
                              <div
                                className="TouristPlace_ImageDiv"
                                style={{ overflow: "hidden" }}
                              >
                                <img
                                  src={`${Base_Url}${ele?.Image}`}
                                  alt="img"
                                  width="100%"
                                  height="100%"
                                  style={{
                                    objectFit: "auto",
                                    overflow: "hidden",
                                  }}
                                ></img>
                              </div>
                              <div className="text-center fw-bold m-0 pt-2 text-black">
                                {ele?.Name}
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-view ">
        <div className="container tourist_bgImage">
          <div className="row">
            <div className="col-12">
              <p className="section_heading">Top Tourist Attractions</p>
            </div>
            <div className="row">
              <div className="col-md-2">
                <div className="Section_text_small_underline_mobile"></div>
              </div>
            </div>
          </div>

          <div className="row pb-3 mt-1">
            <div className="col-12">
              <div className="row d-flex justify-content-center">
                <div className="col-12 d-flex justify-content-center ">
                  <p className="section_subheading">International Locations</p>
                </div>
                <div className="row d-flex justify-content-center">
                  <div className="col-md-2 d-flex justify-content-center">
                    <div className="Section_text_small_underline_mobile"></div>
                  </div>
                </div>
              </div>

              <div
                className="d-flex mt-2 m-0 custom_scrollbar_horizontal"
                style={{ overflow: "auto" }}
              >
                {touristData
                  ?.filter(
                    (location) => location.DestinationType === "International"
                  )
                  ?.map((ele, index) => {
                    return (
                      <div
                        style={{ paddingRight: "15px" }}
                        key={ele?.id || index}
                      >
                        <Link to="/getconsultation">
                          <div
                            className="TopTourist_ImageDiv_mobile"
                            style={{ overflow: "hidden" }}
                          >
                            <img
                              src={`${Base_Url}${ele?.Image}`}
                              alt="img"
                              width="100%"
                              height="100%"
                              style={{
                                objectFit: "auto",
                                overflow: "hidden",
                              }}
                            ></img>
                          </div>
                        </Link>
                        <div
                          className="text-center m-0 py-1 pb-2"
                          style={{ fontSize: "13px", fontWeight: "500" }}
                        >
                          {ele.Name}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className="col-12 mt-2">
              <div className="row d-flex justify-content-center">
                <div className="col-12 d-flex justify-content-center ">
                  <p className="section_subheading">Domestic Locations</p>
                </div>
                <div className="row d-flex justify-content-center">
                  <div className="col-md-2 d-flex justify-content-center">
                    <div className="Section_text_small_underline_mobile"></div>
                  </div>
                </div>
              </div>

              <div
                className="d-flex mt-2 m-0 custom_scrollbar_horizontal"
                style={{ overflow: "auto" }}
              >
                {touristData
                  ?.filter(
                    (location) => location.DestinationType === "Domestic"
                  )
                  ?.map((ele, index) => {
                    return (
                      <div
                        style={{ paddingRight: "15px" }}
                        key={ele?.id || index}
                      >
                        <Link to="/getconsultation">
                          <div
                            className="TopTourist_ImageDiv_mobile"
                            style={{ overflow: "hidden" }}
                          >
                            <img
                              src={`${Base_Url}${ele?.Image}`}
                              alt="img"
                              width="100%"
                              height="100%"
                              style={{
                                objectFit: "auto",
                                overflow: "hidden",
                              }}
                            ></img>
                          </div>
                        </Link>
                        <div
                          className="text-center m-0 py-1 pb-2 text-black"
                          style={{ fontSize: "13px", fontWeight: "500" }}
                        >
                          {ele.Name}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
