import React, { useEffect, useState } from "react";
import { Get_Single_Insurance } from "../../api/InsuranceApi";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

export const InsuranceDetails = () => {
  const { TraceId, ResultIndex, id } = useParams();
  const [selectedInsuranceData, setselectedInsuranceData] = useState("");

  const getInsuranceCheckoutData = async () => {
    try {
      const singleInsurancedata = await Get_Single_Insurance(id);
      if (singleInsurancedata) {
        setselectedInsuranceData(singleInsurancedata?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInsuranceCheckoutData();
  }, []);

  const initialValues = {
    pickUpAddress: "",
    dropOffAddress: "",
    name: "",
    gender: "",
    email: "",
    phone: "",
    pincode: "",
    state: "",
    address: "",
  };

  const validationSchema = Yup.object().shape({
    pickUpAddress: Yup.string().required("Pick-up address is required"),
    dropOffAddress: Yup.string().required("Drop-off address is required"),
    name: Yup.string().required("Name is required"),
    gender: Yup.string().required("Gender is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone number is required"),
    pincode: Yup.string().required("Pincode is required"),
    state: Yup.string().required("State is required"),
    address: Yup.string().optional(),
  });

  const navigate = useNavigate();

  const handleInsuranceBook = () => {
    navigate(`insurancebook`);
  };

  return (
    <div>
      <div>
        <section className="cab-checkout">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="cab-book-overview">
                  <div className="route">Insurance Details</div>
                  <div>
                    <div className="d-flex justify-content-start align-items-center">
                      <div>
                        <img
                          src={`https://www.sankash.in/assets/images/sankash-logo.svg`}
                          alt=""
                          height="60px"
                          width="100%"
                        />
                      </div>
                      <span className="ps-2">
                        {selectedInsuranceData?.PlanName}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="book-cab-form">
                  <h3>Insurance Details</h3>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                  >
                    {({ isSubmitting, actions }) => (
                      <Form className="row">
                        <div className="form-break"></div>
                        <h4>Confirm Insurance information</h4>
                        <div className="form-group col-md-6">
                          <label>Name</label>
                          <Field
                            type="text"
                            name="name"
                            placeholder="Enter your full name"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label>Gender</label>
                          <div className="gender-radios">
                            <div className="gender-radio">
                              <Field type="radio" name="gender" value="Male" />
                              <label>Male</label>
                            </div>
                            <div className="gender-radio">
                              <Field
                                type="radio"
                                name="gender"
                                value="Female"
                              />
                              <label>Female</label>
                            </div>
                            <div className="gender-radio">
                              <Field type="radio" name="gender" value="Other" />
                              <label>Other</label>
                            </div>
                          </div>
                          <ErrorMessage
                            name="gender"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label>
                            Email
                            <span>
                              (Your booking confirmation will be sent here)
                            </span>
                          </label>
                          <Field
                            type="text"
                            name="email"
                            placeholder="Enter your email"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label>Contact No.</label>
                          <Field
                            type="text"
                            name="phone"
                            placeholder="+91 --------------"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <h4>Pincode and State</h4>
                        <div className="form-group col-md-4">
                          <label>Pincode</label>
                          <Field
                            type="text"
                            name="pincode"
                            placeholder="Your Pincode"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="pincode"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label>State</label>
                          <Field
                            type="text"
                            name="state"
                            placeholder="Delhi"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="state"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label>Address</label>
                          <Field
                            type="text"
                            name="address"
                            placeholder="Your Address (Optional)"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <p className="form-auth">
                          By proceeding to book, I Agree to Get Visaa's
                          <a href="/">Privacy Policy</a> and
                          <a href="/">Terms of Service</a>
                        </p>
                        <div className="form-group col-md-12">
                          <button
                            type="submit"
                            className="payment-btn"
                            disabled={isSubmitting}
                            onClick={handleInsuranceBook}
                          >
                            Make Payment
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>

              <div
                className="col-lg-4"
                style={{
                  overflow: "hidden",
                  position: "sticky",
                  top: "85px",
                  maxHeight: "400px",
                }}
              >
                <div className="check-summary-box">
                  <h2 className="text-center">Fare Details</h2>

                  <div className="check-flex">
                    <p className="title">Offered Price</p>
                    <p className="desc">
                      Rs. {selectedInsuranceData?.Price?.OfferedPrice}
                    </p>
                  </div>
                  <div className="check-flex">
                    <p className="title">Commission Earned</p>
                    <p className="desc">
                      Rs. {selectedInsuranceData?.Price?.CommissionEarned}
                    </p>
                  </div>
                  <div className="check-flex">
                    <p className="title">Tds On Commission</p>
                    <p className="desc">
                      Rs. {selectedInsuranceData?.Price?.TdsOnCommission}
                    </p>
                  </div>
                  <div className="check-flex">
                    <p className="title">Service Tax</p>
                    <p className="desc">
                      Rs. {selectedInsuranceData?.Price?.ServiceTax}
                    </p>
                  </div>
                  <div className="check-flex">
                    <p className="title">Swachh Bharat Tax</p>
                    <p className="desc">
                      Rs. {selectedInsuranceData?.Price?.SwachhBharatTax}
                    </p>
                  </div>
                  <div className="check-flex">
                    <p className="title">Krishi Kalyan Tax</p>
                    <p className="desc">
                      Rs. {selectedInsuranceData?.Price?.KrishiKalyanTax}
                    </p>
                  </div>
                  <div className="check-flex">
                    <p className="title">Published Price</p>
                    <p className="desc text-success">
                      Rs. {selectedInsuranceData?.Price?.PublishedPrice}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
