import React, { useEffect, useState } from "react";

import { sendPaymentDetails } from "../api/ProfileAPI";

export const Payment = () => {
  const generateUniqueOrderId = () => {
    return Math.floor(1000000000 + Math.random() * 9000000000).toString();
  };

  const [OrderId, setOrderId] = useState(generateUniqueOrderId());

  useEffect(() => {
    generateUniqueOrderId();
    console.log(OrderId);
  }, []);

  const handlePayment = async (e) => {
    e.preventDefault();
    console.log("Button working");
  

    const formData = {
      order_id: OrderId,
      mobile: "7835967867", // Add mobile dynamically or from the user's input
      amount: "1", // Add the amount dynamically
      booking_type: "cab", // Define the booking type
      customer_name: "John Doe", // Add customer name
      email: "john.doe@example.com", // Add email
      payment_status: "pending", // Set the payment status
      tracking_id: "XYZ12345", // Add tracking ID (if available)
      payment_mode: "card", // Define the payment mode
      order_status: "pending", // Add order status (if applicable)
    };

    try {
      const res = await sendPaymentDetails(formData);

      console.log(res, "savepaymentdetails");

      if (res.data.success) {
        // Redirect to the payment gateway
        document.customerData.submit();
      } else {
        console.error("Error saving payment details");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <div>
        <button onClick={handlePayment} className="py-2 px-3 bg-warning">
          Data send to Api
        </button>
      </div>

      <div>
        <form
          method="POST"
          name="customerData"
          action="https://exploretriponline.com/api/ccavRequestHandler"
        >
          <table width="40%" height="100" border="1" align="center">
            <caption>
              <font size="4" color="blue">
                <b>Integration Kit</b>
              </font>
            </caption>
          </table>
          <table width="40%" height="100" border="1" align="center">
            <tr>
              <td>Parameter Name:</td>
              <td>Parameter Value:</td>
            </tr>
            <tr>
              <td colspan="2">Compulsory information</td>
            </tr>
            <tr>
              <td>Merchant Id</td>
              <td>
                <input
                  type="text"
                  name="merchant_id"
                  id="merchant_id"
                  value="3401584"
                />
              </td>
            </tr>
            <tr>
              <td>Order Id</td>
              <td>
                <input type="text" name="order_id" value={OrderId} readOnly />
              </td>
            </tr>
            <tr>
              <td>Currency</td>
              <td>
                <input type="text" name="currency" value="INR" />
              </td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>
                <input type="text" name="amount" value="1.00" />
              </td>
            </tr>
            <tr>
              <td>Redirect URL</td>
              <td>
                <input
                  type="text"
                  name="redirect_url"
                  value="https://exploretriponline.com/api/ccavResponseHandler"
                />
              </td>
            </tr>
            <tr>
              <td>Cancel URL</td>
              <td>
                <input
                  type="text"
                  name="cancel_url"
                  value="https://exploretriponline.com/api/ccavResponseHandler"
                />
              </td>
            </tr>
            <tr>
              <td>Language</td>
              <td>
                <input type="text" name="language" id="language" value="EN" />
              </td>
            </tr>

            <tr>
              <label for="booking_type">Booking Type:</label>
              <select id="booking_type" name="booking_type">
                <option value="flight">Flight</option>
                <option value="hotel">Hotel</option>
                <option value="cab">Cab</option>
                <option value="bus">Bus</option>
                <option value="insurance">Insurance</option>
              </select>
            </tr>

            <tr>
              <td colspan="2">Billing information(optional):</td>
            </tr>
            <tr>
              <td>Billing Name</td>
              <td>
                <input type="text" name="billing_name" value="Peter" />
              </td>
            </tr>
            <tr>
              <td>Billing Address:</td>com
              <td>
                <input type="text" name="billing_address" value="Santacruz" />
              </td>
            </tr>
            <tr>
              <td>Billing City:</td>
              <td>
                <input type="text" name="billing_city" value="Mumbai" />
              </td>
            </tr>
            <tr>
              <td>Billing State:</td>
              <td>
                <input type="text" name="billing_state" value="MH" />
              </td>
            </tr>
            <tr>
              <td>Billing Zip:</td>
              <td>
                <input type="text" name="billing_zip" value="400054" />
              </td>
            </tr>
            <tr>
              <td>Billing Country:</td>
              <td>
                <input type="text" name="billing_country" value="India" />
              </td>
            </tr>
            <tr>
              <td>Billing Tel:</td>
              <td>
                <input type="text" name="billing_tel" value="7835967867" />
              </td>
            </tr>
            <tr>
              <td>Billing Email:</td>
              <td>
                <input
                  type="text"
                  name="billing_email"
                  value="testing@domain.com"
                />
              </td>
            </tr>
            <tr>
              <td colspan="2">Shipping information(optional):</td>
            </tr>
            <tr>
              <td>Shipping Name</td>
              <td>
                <input type="text" name="delivery_name" value="Sam" />
              </td>
            </tr>
            <tr>
              <td>Shipping Address:</td>
              <td>
                <input type="text" name="delivery_address" value="Vile Parle" />
              </td>
            </tr>
            <tr>
              <td>Shipping City:</td>
              <td>
                <input type="text" name="delivery_city" value="Mumbai" />
              </td>
            </tr>
            <tr>
              <td>Shipping State:</td>
              <td>
                <input type="text" name="delivery_state" value="Maharashtra" />
              </td>
            </tr>
            <tr>
              <td>Shipping Zip:</td>
              <td>
                <input type="text" name="delivery_zip" value="400038" />
              </td>
            </tr>
            <tr>
              <td>Shipping Country:</td>
              <td>
                <input type="text" name="delivery_country" value="India" />
              </td>
            </tr>
            <tr>
              <td>Shipping Tel:</td>
              <td>
                <input type="text" name="delivery_tel" value="0123456789" />
              </td>
            </tr>
            <tr>
              <td>Merchant Param1</td>
              <td>
                <input
                  type="text"
                  name="merchant_param1"
                  value="additional Info."
                />
              </td>
            </tr>
            <tr>
              <td>Merchant Param2</td>
              <td>
                <input
                  type="text"
                  name="merchant_param2"
                  value="additional Info."
                />
              </td>
            </tr>
            <tr>
              <td>Merchant Param3</td>
              <td>
                <input
                  type="text"
                  name="merchant_param3"
                  value="additional Info."
                />
              </td>
            </tr>
            <tr>
              <td>Merchant Param4</td>
              <td>
                <input
                  type="text"
                  name="merchant_param4"
                  value="additional Info."
                />
              </td>
            </tr>
            <tr>
              <td>Merchant Param5</td>
              <td>
                <input
                  type="text"
                  name="merchant_param5"
                  value="additional Info."
                />
              </td>
            </tr>
            <tr>
              <td>Promo Code:</td>
              <td>
                <input type="text" name="promo_code" value="" />
              </td>
            </tr>
            <tr>
              <td>Customer Id:</td>
              <td>
                <input type="text" name="customer_identifier" value="" />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <input type="submit" value="Checkout" />
              </td>
            </tr>
          </table>
        </form>
      </div>
    </div>
  );
};
