import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa6";
import { TestimonalApiData } from "../../api/TestimonialAPI";
import { Loader } from "../Loader";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const Testimonial = () => {
  const [userData, setUserData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await TestimonalApiData();
        if (res?.status === "success") {
          setUserData(res?.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const renderStarRating = (rating) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < rating) {
        stars.push(<FaStar key={i} style={{ color: "goldenrod" }} />);
      }
    }
    return stars;
  };

  var TestimonialsSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div>
      {/* Desktop View */}

      <div className="container-fluid desktop-view Section_Container">
        <div className="row px-2">
          <div className="col-md-6">
            <div className="homepage_component_heading_txt">Testimonials</div>
            <div className="Section_text_small_underline"></div>
          </div>
        </div>
        {userData ? (
          <div className="row pt-3 px-3">
            <div className="col-12 p-0 m-0">
              <Slider {...TestimonialsSettings}>
                {userData?.map((ele) => {
                  return (
                    <div key={ele.Id} className="col px-1">
                      <div className="Section_Cards1">
                        <div
                          className="Section_text_container "
                          style={{ backgroundColor: "#EEEDF3" }}
                        >
                          <div className="d-flex align-items-center">
                            <div style={{ display: "inline-block" }}>
                              <div className="profile_photo_container">
                                <img
                                  src={`https://api.exploretriponline.com${ele?.Image}`}
                                  alt=""
                                  className="profile_photo"
                                ></img>
                              </div>
                            </div>

                            <div style={{ display: "inline-block" }}>
                              <div className="px-2">
                                <p className="fw-bold m-0">{ele.Name}</p>
                                <div className="d-flex align-items-center">
                                  {renderStarRating(parseInt(ele.Rating))}
                                  <span className="px-2">({ele?.Rating})</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="m-0 py-2">
                            {ele?.Description?.length > 100
                              ? ele?.Description.slice(0, 80) + "..."
                              : ele?.Description}
                          </div>

                          <div className="d-flex justify-content-center ">
                            <div className="bg-danger Section_text_small_underline_large"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>

      {/* Mobile View */}

      {/* <div className="mobile-view">

                <Slider {...SpecialOffersSettings}>
                    {userData?.map((ele, id) => {
                        return (
                            <div
                                key={id}
                                className="col-9 col-md-6 align-items-center  d-flex pt-3 px-2"
                                style={{ marginRight: "20px" }}
                            >
                                <div className="Section_Cards1 w-100">
                                    <div className="Section_text_container" style={{ backgroundColor: "#EEEDF3" }}>
                                        <div className="d-flex align-items-center">
                                            <div style={{ display: "inline-block" }}>
                                                <div className="profile_photo_container">
                                                    <img
                                                        src={`https://exploretriponline.com/admin/images/${ele.Image}`}
                                                        alt=""
                                                        className="profile_photo"
                                                    ></img>
                                                </div>
                                            </div>
                                            <div style={{ display: "inline-block" }}>
                                                <div className="px-1">
                                                    <p className="fw-bold m-0">{ele.Name}</p>
                                                    <div className="d-flex align-items-center">
                                                        {renderStarRating(parseInt(ele?.Rating))}
                                                        <span className="px-2">({ele?.Rating})</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="m-0 py-3">
                                            {ele?.Description.length > 20
                                                ? ele?.Description.slice(0, 50) + " " + "..."
                                                : ele?.Description}
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div className="bg-danger Section_text_small_underline_large"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Slider>
            </div> */}
    </div>
  );
};
