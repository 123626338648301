import logo_white from "../assets/logo_white.png";
import logo_red_white from "../assets/logo_red_white.png";
import reset from "../assets/reset.png";
import playstore from "../assets/playstore.png";
import appstore from "../assets/appstore.png";
import logo from "../assets/logo.png";
import helpline from "../assets/helpline.png";
import qrcode from "../assets/qrcode.png";
import travel from "../assets/travel.jpg";
import Flight_Loader_Transparent from "../assets/Flight_Loader_Transparent.gif";
import ETO_App from "../assets/ETO_App.png";
import Services from "../assets/Services.png";
import Slider1 from "../assets/Slider1.png";
import Slider2 from "../assets/Slider2.png";
import Slider3 from "../assets/Slider3.png";
import logo_mark from "../assets/logo_mark.png";
import reverse from "../assets/reverse.png";
import ScrollToTop from "../assets/ScrollToTop.png";
import ScrollToTop1 from "../assets/ScrollToTop1.png";
import ScrollToTop_flight from "../assets/ScrollToTop_flight.png";
import flight from "../assets/flight.png";
import flight_color from "../assets/flight_color.png";
import cab_color from "../assets/cab_color.png";
import hotel_color from "../assets/hotel_color.png";
import bus_color from "../assets/bus_color.png";
import default_icon from "../assets/default_icon.png";
import banner1 from "../assets/banner1.png";

import banner2 from "../assets/banner2.png";
import banner3 from "../assets/banner3.png";
import banner4 from "../assets/banner4.png";
import easy_booking from "../assets/easy_booking.png";
import lowest_price from "../assets/lowest_price.png";
import support from "../assets/support.png";
import special_deal from "../assets/special_deal.png";
import user1 from "../assets/user1.png";
import cancel from "../assets/cancel.png";
import arrow from "../assets/arrow.png";
import mybookings from "../assets/mybookings.png";
import testimonial from "../assets/testimonial.png";
import faq from "../assets/faq.png";
import aboutus from "../assets/aboutus.png";
import termsandconditions from "../assets/termsandconditions.png";
import helpcenter from "../assets/helpcenter.png";
import privacypolicies from "../assets/privacypolicies.png";
import refund from "../assets/refund.png";
import logout from "../assets/logout.png";
import search from "../assets/search.png";
import hamburger from "../assets/hamburger.png";
import homebuttonbar from "../assets/homebuttonbar.png";
import home from "../assets/home.png";
import home_color from "../assets/home_color.png";
import user from "../assets/user.png";
import user_color from "../assets/user_color.png";
import ticket from "../assets/ticket.png";
import ticket_color from "../assets/ticket_color.png";
import explore from "../assets/explore.png";
import Profile_bg from "../assets/Profile_bg.png";
import Profile_bg1 from "../assets/Profile_bg1.png";
import raise_ticket from "../assets/raise_ticket.png";
import newsletter from "../assets/newsletter.png";
import rate_us from "../assets/rate_us.png";
import edit_profile from "../assets/edit_profile.png";
import filter from "../assets/filter.png";
import health_insurance from "../assets/health_insurance.png";

import Banner1 from "../assets/images/Banner1.png";

const images = {
    flight,
    flight_color,
    cab_color,
    hotel_color,
    bus_color,
    logo,
    logo_white,
    logo_red_white,
    reset,
    playstore,
    appstore,
    helpline,
    qrcode,
    travel,
    Flight_Loader_Transparent,
    ETO_App,
    Services,
    Slider1,
    Slider2,
    Slider3,
    logo_mark,
    reverse,
    ScrollToTop,
    ScrollToTop1,
    ScrollToTop_flight,
    default_icon,
    banner1,
    banner2,
    banner3,
    banner4,
    user1,
    cancel,
    arrow,

    // follow us icons

    easy_booking,
    lowest_price,
    special_deal,
    support,

    // Sidebar icons

    mybookings,
    testimonial,
    faq,
    aboutus,
    termsandconditions,
    helpcenter,
    privacypolicies,
    refund,
    logout,
    search,
    filter,
    hamburger,
    homebuttonbar,
    Profile_bg,
    Profile_bg1,
    raise_ticket,
    newsletter,
    rate_us,
    edit_profile,

    // Home bar icons

    home,
    home_color,
    user,
    user_color,
    ticket,
    ticket_color,
    explore,

    // Insurance icons
    health_insurance,
};

export default images;
