import React, { useState } from "react";
import images from "../../assets/Images";
import { useNavigate } from "react-router-dom";

export const HomeButtons = () => {
  const [activeButton, setActiveButton] = useState("/");
  const navigate = useNavigate();
  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
    navigate(buttonName);
  };

  return (
    <div
      className="container-fluid mobile-view d-flex align-items-end m-0 p-0 w-100"
      style={{ position: "fixed", bottom: "0px", backgroundColor: "white" }}
    >
      <div
        className="row p-0 m-0"
        style={{
          width: "100%",
          fontSize: "11px",
          boxShadow: "0px 5px 10px 3px lightgray",
        }}
      >
        <div
          className={`col py-2 ${
            activeButton === "/" ? "text-black" : "text-secondary"
          }`}
          onClick={() => handleClick("/")}
        >
          <div className="d-flex justify-content-center">
            {activeButton === "/" ? (
              <img
                src={images?.home_color}
                alt=""
                height="20px"
                width="20px"
              ></img>
            ) : (
              <img src={images?.home} alt="" height="20px" width="20px"></img>
            )}
          </div>
          <p className={`m-0 text-center`}>HOME</p>
        </div>

        <div
          className={`col py-2 ${
            activeButton === "/myorders" ? "text-black" : "text-secondary"
          }`}
          onClick={() => handleClick("/myorders")}
        >
          <div className="d-flex justify-content-center">
            {activeButton === "/myorders" ? (
              <img
                src={images?.ticket_color}
                alt=""
                height="20px"
                width="20px"
              ></img>
            ) : (
              <img src={images?.ticket} alt="" height="20px" width="20px"></img>
            )}
          </div>
          <p className={`m-0 text-center `}>BOOKINGS</p>
        </div>

        <div
          className={`col py-2 ${
            activeButton === "/support" ? "text-black" : "text-secondary"
          }`}
          onClick={() => handleClick("/support")}
        >
          <div className="d-flex justify-content-center">
            {activeButton === "/support" ? (
              <img
                src={images?.explore}
                alt=""
                height="20px"
                width="20px"
              ></img>
            ) : (
              <img
                src={images?.explore}
                alt=""
                height="20px"
                width="20px"
              ></img>
            )}
          </div>
          <p className={`m-0 text-center `}>EXPLORE</p>
        </div>

        <div
          className={`col py-2 ${
            activeButton === "/raiseticket" ? "text-black" : "text-secondary"
          }`}
          onClick={() => handleClick("/raiseticket")}
        >
          <div className="d-flex justify-content-center">
            {activeButton === "/raiseticket" ? (
              <img
                src={images?.ticket_color}
                alt=""
                height="20px"
                width="20px"
              ></img>
            ) : (
              <img src={images?.ticket} alt="" height="20px" width="20px"></img>
            )}
          </div>
          <p className={`m-0 text-center `}>TICKET</p>
        </div>

        <div
          className={`col py-2 ${
            activeButton === "/myprofile" ? "text-black" : "text-secondary"
          }`}
          onClick={() => handleClick("/myprofile")}
        >
          <div className="d-flex justify-content-center">
            {activeButton === "/myprofile" ? (
              <img
                src={images?.user_color}
                alt=""
                height="20px"
                width="20px"
              ></img>
            ) : (
              <img src={images?.user} alt="" height="20px" width="20px"></img>
            )}
          </div>
          <p className={`m-0 text-center `}>PROFILE</p>
        </div>
      </div>
    </div>
  );
};
