import React, { useContext, useEffect, useState } from "react";
import "../../style/Bus.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAllCabs, getSingleCabs } from "../../api/CabAPI";
import { FaArrowRightLong } from "react-icons/fa6";
import images from "../../assets/Images";
import { MyContexts } from "../../context/MyContext";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

export const CabSearch = () => {
  const { fromData, toData, dateData } = useParams();
  const [cabData, setCabData] = useState([]);
  const [date, setDate] = useState();
  const [loading, setLoading] = useState(true);

  const { setSelectedCarFunction } = useContext(MyContexts);
  const [TraceId, setTraceId] = useState("");

  const navigate = useNavigate();

  const handleCardClick = async (element) => {
    setSelectedCarFunction(element);
    navigate(
      `/searchcab/${fromData}/${toData}/${dateData}/${TraceId}/${element.SrdvIndex}`
    );
  };

  function getDateFromString(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0"); // Get day and pad with 0 if needed
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month (months are 0-indexed)
    const year = date.getFullYear().toString(); // Get last two digits of the year
    return `${day}/${month}/${year}`;
  }

  async function getAllCabsDetails() {
    try {
      const dateFormat = getDateFromString(dateData);
      setDate(dateFormat);
      const res = await getAllCabs();
      if (res?.data) {
        setTraceId(res?.data?.Result?.TraceID);
        setCabData(res?.data?.Result?.TaxiData);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllCabsDetails();
  }, []);

  const [selectedAC, setSelectedAC] = useState("");
  const [selectedRefundable, setSelectedRefundable] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const handleSeatingCapacityChange = (e) => {
    const newSeatingCapacity = parseInt(e.target.value);
    setSelectedSeatingCapacity(newSeatingCapacity);
  };

  const handleACChange = (e) => {
    setSelectedAC(e.target.value);
  };

  const handleRefundableChange = (e) => {
    setSelectedRefundable(e.target.value);
  };

  const [priceRange, setPriceRange] = useState({ min: 0, max: 0 });
  const [selectedPrice, setSelectedPrice] = useState(priceRange.max);
  const [seatingCapacityRange, setSeatingCapacityRange] = useState({
    min: 0,
    max: 0,
  });
  const [selectedSeatingCapacity, setSelectedSeatingCapacity] = useState(
    seatingCapacityRange.max
  );
  const [LuggageCapacityRange, setLuggageCapacityRange] = useState({
    max: 0,
    min: 0,
  });
  const [selectedLuggageCapacity, setSelectedLuggageCapacity] = useState(
    LuggageCapacityRange.max
  );

  useEffect(() => {
    if (cabData?.length > 0) {
      const prices = cabData.map((cab) => cab.Fare.TotalAmount);
      const maxPrice = Math.max(...prices);
      const minPrice = Math.min(...prices);
      setSelectedPrice(minPrice);
      setPriceRange({ min: minPrice, max: maxPrice });

      const seatingCapacities = cabData.map((cab) => cab.SeatingCapacity);
      const maxSeatingCapacity = Math.max(...seatingCapacities);
      const minSeatingCapacity = Math.min(...seatingCapacities);
      setSelectedSeatingCapacity(minSeatingCapacity);
      setSeatingCapacityRange({
        min: minSeatingCapacity,
        max: maxSeatingCapacity,
      });

      const luggageCapacities = cabData.map((cab) => cab.LuggageCapacity);
      const maxLuggageCapacity = Math.max(...luggageCapacities);
      const minLuggageCapacity = Math.min(...luggageCapacities);
      setSelectedLuggageCapacity(minLuggageCapacity);
      setLuggageCapacityRange({
        min: minLuggageCapacity,
        max: maxLuggageCapacity,
      });
    }
  }, [cabData]);

  const handleLuggageCapacityChange = (e) => {
    const newLuggageCapacity = parseInt(e.target.value);
    setSelectedLuggageCapacity(newLuggageCapacity);
  };

  const handlePriceChange = (e) => {
    const newPrice = parseInt(e.target.value);
    setSelectedPrice(newPrice);
  };

  useEffect(() => {
    if (cabData?.length > 0) {
      const maxPrice = Math.max(
        ...cabData?.map((cab) => cab?.Fare?.TotalAmount)
      );
      setSelectedPrice(maxPrice);

      const maxSeatingCapacity = Math.max(
        ...cabData?.map((cab) => cab?.SeatingCapacity)
      );
      setSelectedSeatingCapacity(maxSeatingCapacity);
    }
  }, [cabData]);

  useEffect(() => {
    const applyFilters = () => {
      const filtered = cabData?.filter((item) => {
        return (
          (selectedPrice === 0 || item.Fare.TotalAmount <= selectedPrice) &&
          (selectedSeatingCapacity === 0 ||
            item.SeatingCapacity <= selectedSeatingCapacity) &&
          (selectedLuggageCapacity === 0 ||
            item.LuggageCapacity >= selectedLuggageCapacity) &&
          (selectedAC === "" ||
            item.AirConditioner === (selectedAC === "AC")) &&
          (selectedRefundable === "" ||
            item.Fare.Refundable === (selectedRefundable === "Refundable"))
        );
      });
      setFilteredData(filtered);
    };

    applyFilters();
  }, [
    selectedPrice,
    selectedSeatingCapacity,
    cabData,
    selectedLuggageCapacity,
    selectedAC,
    selectedRefundable,
  ]);

  function handleResetAll() {
    window.location.reload();
  }

  function handleOfferedPrice(price) {
    const roundedPrice = (price * 1.1).toFixed(0);
    return parseFloat(roundedPrice);
  }

  function handleOffPrice(price) {
    const roundedPrice = (price * 0.1).toFixed(0);
    return parseFloat(roundedPrice);
  }

  function handlePrice(price) {
    const roundedPrice = price;
    return parseFloat(roundedPrice);
  }

  const formatDate = (dateString) => {
    // Split the date string into day, month, and year
    const [day, month, year] = dateString.split("/");

    // Create a Date object using the provided date components
    const date = new Date(`${year}-${month}-${day}`);

    // Array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get the month name from the month number (subtract 1 since months are 0-indexed)
    const monthName = monthNames[date.getMonth()];

    // Return the formatted date string
    return `${day} ${monthName}`;
  };

  const [showAmenities, setShowAmenities] = useState(false);

  const handleAmenitiesClose = () => setShowAmenities(false);
  const handleAmenitiesShow = () => setShowAmenities(true);

  const [showCabFilterModal, setShowCabFilterModal] = useState(false);

  const handleCloseCabFilterModal = () => setShowCabFilterModal(false);
  const handleShowCabFilterModal = () => setShowCabFilterModal(true);

  return (
    <>
      <div
        className="container-fluid mobile-view"
        style={{ position: "relative" }}
      >
        <div className="d-flex align-items-center p-2 mt-2">
          <div style={{ border: "1px solid green", borderRadius: "5px" }}>
            <p className="m-0 py-1 px-2">
              Showing Cabs
              <span className="ps-1" style={{ fontWeight: "600" }}>
                {fromData}
              </span>
              <span className="px-1">
                <i class="fa fa-arrow-right"></i>
              </span>
              <span className="ps-1" style={{ fontWeight: "600" }}>
                {toData}
              </span>
            </p>
          </div>
          <div className="px-2" onClick={handleShowCabFilterModal}>
            <img src={images?.filter} alt="" height="20px" width="20px"></img>
          </div>

          <Modal show={showCabFilterModal} onHide={handleCloseCabFilterModal}>
            <Modal.Header
              closeButton
              style={{
                backgroundImage: "linear-gradient(45deg, #1556FC, #04A9FE)",
                borderBottom: "1.5px solid rgb(1,128,253)",
              }}
            >
              <div className="d-flex text-white" style={{ fontSize: "16px" }}>
                <div>Filter by</div>
                <div className="px-5" onClick={handleResetAll}>
                  <span className="pe-2">Resets all </span>
                  <img src={images?.reset} alt="" width="20px" height="20px" />
                </div>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div
                className="row d-flex justify-content-between  px-2"
                style={{ background: "#fff" }}
              >
                <div
                  className="m-0 p-0 row py-2"
                  style={{ borderBottom: "2px solid #e9e9e9" }}
                >
                  <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                    PRICE
                  </p>
                  <div className="p-0 d-flex justify-content-between">
                    <span>
                      Min {`₹${parseInt(priceRange.min).toLocaleString()}`}
                    </span>
                    <span>
                      Max {`₹${parseInt(priceRange.max).toLocaleString()}`}
                    </span>
                  </div>
                  <div className="py-2 px-0 d-flex justify-content-between">
                    <input
                      type="range"
                      max={priceRange.max}
                      min={priceRange.min}
                      value={selectedPrice}
                      className="w-100"
                      onChange={handlePriceChange}
                    />
                    <span className="px-2">{`₹${parseInt(
                      selectedPrice
                    ).toLocaleString()}`}</span>
                  </div>
                </div>

                <div
                  className="m-0 p-0 row py-2"
                  style={{ borderBottom: "2px solid #e9e9e9" }}
                >
                  <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                    Seating Capacity
                  </p>
                  <div className="p-0 d-flex justify-content-between">
                    <span>Min {seatingCapacityRange.min}</span>
                    <span>Max {seatingCapacityRange.max}</span>
                  </div>
                  <div className="py-2 px-0 d-flex justify-content-between">
                    <input
                      type="range"
                      max={seatingCapacityRange.max}
                      min={seatingCapacityRange.min}
                      value={selectedSeatingCapacity}
                      className="w-100"
                      onChange={handleSeatingCapacityChange}
                    />
                    <span className="px-2">{selectedSeatingCapacity}</span>
                  </div>
                </div>

                <div
                  className="m-0 p-0 row py-2"
                  style={{ borderBottom: "2px solid #e9e9e9" }}
                >
                  <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                    Luggage Capacity
                  </p>
                  <div className="p-0 d-flex justify-content-between">
                    <span>Min {LuggageCapacityRange.min}</span>
                    <span>Max {LuggageCapacityRange.max}</span>
                  </div>
                  <div className="py-2 px-0 d-flex justify-content-between">
                    <input
                      type="range"
                      max={LuggageCapacityRange.max}
                      min={LuggageCapacityRange.min}
                      value={selectedLuggageCapacity}
                      className="w-100"
                      onChange={handleLuggageCapacityChange}
                    />
                    <span className="px-2">{selectedLuggageCapacity}</span>
                  </div>
                </div>

                <div
                  className="m-0 p-0 row py-2"
                  style={{ borderBottom: "2px solid #e9e9e9" }}
                >
                  <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                    AC or Non-AC
                  </p>
                  <div className="p-0">
                    <div>
                      <input
                        type="radio"
                        id="ac"
                        value="AC"
                        checked={selectedAC === "AC"}
                        onChange={handleACChange}
                      />
                      <label htmlFor="ac" className="px-2">
                        AC
                      </label>
                    </div>
                    <div>
                      <div>
                        <input
                          type="radio"
                          id="non-ac"
                          value="Non-AC"
                          checked={selectedAC === "Non-AC"}
                          onChange={handleACChange}
                        />
                        <label htmlFor="non-ac" className="px-2">
                          Non-AC
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="m-0 p-0 row py-2"
                  style={{ borderBottom: "2px solid #e9e9e9" }}
                >
                  <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                    REFUNDABLE
                  </p>
                  <div className="p-0">
                    <div>
                      <input
                        type="radio"
                        id="Refundable"
                        value="Refundable"
                        checked={selectedRefundable === "Refundable"}
                        onChange={handleRefundableChange}
                      />
                      <label htmlFor="Refundable" className="px-2">
                        REFUNDABLE
                      </label>
                    </div>
                    <div>
                      <div>
                        <input
                          type="radio"
                          id="Non-Refundable"
                          value="Non-Refundable"
                          checked={selectedRefundable === "Non-Refundable"}
                          onChange={handleRefundableChange}
                        />
                        <label htmlFor="Non-Refundable" className="px-2">
                          NON-REFUNDABLE
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseCabFilterModal}>
                Apply
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <div>
          {loading ? (
            <div
              className="  d-flex align-items-center justify-content-center"
              style={{ height: "400px" }}
            >
              <img
                src={images.Flight_Loader_Transparent}
                height={170}
                width={170}
              ></img>
            </div>
          ) : (
            <div className="row d-flex justify-content-between">
              <div className="col-12 pe-0 pb-5">
                <div>
                  {filteredData?.length === 0 ? (
                    <div className="Section_Container mb-3">
                      <div className="row">
                        <div className="col-md-12">
                          <h6>Sorry, No Cabs for this Search</h6>
                          <p className="m-0 text-secondary">
                            We cannnot find any Cabs for your search. Please
                            modify your search criteria and try again.
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {filteredData?.map((ele, index) => (
                        <div className="Section_Container " key={index}>
                          <div className="Bus_Cards ">
                            <div
                              style={{
                                borderBottom:
                                  "1px solid rgba(201, 228, 255, 1)",
                              }}
                            >
                              <div className="row d-flex justify-content-between px-3 pt-3 pb-2">
                                <div className="col-7">
                                  <p
                                    className="m-0"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {ele?.Category}
                                  </p>
                                  <p
                                    className="text-secondary m-0"
                                    style={{ fontSize: "10px" }}
                                  >
                                    <span>{ele?.SeatingCapacity}-Seater</span>
                                    <span className="px-2">|</span>
                                    <span>
                                      {ele?.LuggageCapacity}-Luggage capacity
                                    </span>
                                  </p>
                                </div>
                                <div className="col-5 d-flex justify-content-end">
                                  <div>
                                    <span
                                      className="text-decoration-line-through text-secondary "
                                      style={{ fontSize: "12px" }}
                                    >
                                      {`₹${parseInt(
                                        handleOfferedPrice(
                                          ele?.Fare?.TotalAmount
                                        )
                                      ).toLocaleString()}`}
                                    </span>
                                    <span
                                      className="ps-2 fw-bold "
                                      style={{ fontSize: "14px" }}
                                    >
                                      {`₹${parseInt(
                                        handlePrice(ele?.Fare?.TotalAmount)
                                      ).toLocaleString()}`}
                                      <span className="pe-1"></span>
                                    </span>
                                    <p
                                      className="text-success m-0 p-0 text-end"
                                      style={{ fontSize: "10px" }}
                                    >
                                      <span className="fw-bold ">
                                        Save ₹
                                        {handleOffPrice(ele?.Fare?.TotalAmount)}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="row d-flex justify-content-between align-items-center px-3">
                                <div
                                  className="col-3"
                                  style={{ fontSize: "12px" }}
                                >
                                  <span>
                                    {ele?.Fare?.Refundable === true
                                      ? "Refundable"
                                      : "Non-Refundable"}
                                  </span>
                                </div>

                                <div
                                  className="col-6"
                                  style={{ fontSize: "14px" }}
                                >
                                  <span>{fromData}</span>
                                  <span className="px-1">
                                    <FaArrowRightLong />
                                  </span>
                                  <span>{toData}</span>
                                </div>

                                <div
                                  className="col-2 d-flex justify-content-end"
                                  style={{ fontSize: "9px" }}
                                >
                                  <span className="text-success">
                                    {ele?.Availability}-Seats Available
                                  </span>
                                  <span className="px-1"> | </span>
                                  <span>
                                    {ele.AirConditioner ? "AC" : "Non-AC"}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="row d-flex justify-content-between px-3 py-2">
                              <div className="col-5 d-flex align-items-center"></div>
                              <div className="col-6 d-flex justify-content-end">
                                <Link
                                  className="book-car-btn no-underline"
                                  to={`/searchcab/${fromData}/${toData}/${dateData}/${TraceId}/${ele.SrdvIndex}`}
                                >
                                  <button className="Bus_search_btn">
                                    Explore Cabs
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        className="container-fluid desktop-view"
        style={{ position: "relative" }}
      >
        <h2 className="text-center mt-4">
          Showing Cabs {fromData}
          <span className="px-1">
            <i class="fa fa-arrow-right"></i>
          </span>
          {toData}
        </h2>

        <div>
          {loading ? (
            <div
              className="  d-flex align-items-center justify-content-center"
              style={{ height: "400px" }}
            >
              <img
                src={images.Flight_Loader_Transparent}
                height={170}
                width={170}
              ></img>
            </div>
          ) : (
            <div className="row d-flex justify-content-between px-4 ">
              <div
                className="col-md-3 desktop-view mt-4 mb-3 custom_scrollbar Section_Sidebar_Container"
                style={{
                  border: "1.5px solid rgb(1,128,253)",
                  overflowY: "scroll",
                  position: "sticky",
                  top: "100px",
                  maxHeight: "calc(100vh - 80px)",
                }}
              >
                <div
                  className="row text-white d-flex justify-content-between py-3"
                  style={{
                    backgroundImage: "linear-gradient(45deg, #1556FC, #04A9FE)",
                    borderBottom: "1.5px solid rgb(1,128,253)",
                  }}
                >
                  <div className="col-5">
                    <h5 className="m-0">Filter by</h5>
                  </div>
                  <div
                    className="col-6 d-flex justify-content-end align-items-center"
                    onClick={handleResetAll}
                    style={{ cursor: "pointer" }}
                  >
                    <span className="pe-2">Resets all </span>
                    <img src={images.reset} alt="" width="20px" height="20px" />
                  </div>
                </div>

                <div
                  className="row d-flex justify-content-between  px-2"
                  style={{ background: "#fff" }}
                >
                  <div
                    className="m-0 p-0 row py-2"
                    style={{ borderBottom: "2px solid #e9e9e9" }}
                  >
                    <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                      PRICE
                    </p>
                    <div className="p-0 d-flex justify-content-between">
                      <span>
                        Min {`₹${parseInt(priceRange.min).toLocaleString()}`}
                      </span>
                      <span>
                        Max {`₹${parseInt(priceRange.max).toLocaleString()}`}
                      </span>
                    </div>
                    <div className="py-2 px-0 d-flex justify-content-between">
                      <input
                        type="range"
                        max={priceRange.max}
                        min={priceRange.min}
                        value={selectedPrice}
                        className="w-100"
                        onChange={handlePriceChange}
                      />
                      <span className="px-2">{`₹${parseInt(
                        selectedPrice
                      ).toLocaleString()}`}</span>
                    </div>
                  </div>

                  <div
                    className="m-0 p-0 row py-2"
                    style={{ borderBottom: "2px solid #e9e9e9" }}
                  >
                    {/* <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                                    Seating Capacity
                                </p>
                                <div className="p-0 d-flex justify-content-between">
                                    <input
                                        type="range"
                                        max={17}
                                        className="w-100"
                                        value={selectedSeatingCapacity}
                                        onChange={handleSeatingCapacityChange}
                                    />
                                    <span className="px-2"> {selectedSeatingCapacity}</span>
                                </div> */}
                    <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                      Seating Capacity
                    </p>
                    <div className="p-0 d-flex justify-content-between">
                      <span>Min {seatingCapacityRange.min}</span>
                      <span>Max {seatingCapacityRange.max}</span>
                    </div>
                    <div className="py-2 px-0 d-flex justify-content-between">
                      <input
                        type="range"
                        max={seatingCapacityRange.max}
                        min={seatingCapacityRange.min}
                        value={selectedSeatingCapacity}
                        className="w-100"
                        onChange={handleSeatingCapacityChange}
                      />
                      <span className="px-2">{selectedSeatingCapacity}</span>
                    </div>
                  </div>

                  <div
                    className="m-0 p-0 row py-2"
                    style={{ borderBottom: "2px solid #e9e9e9" }}
                  >
                    {/* <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                                    Luggage Capacity
                                </p>
                                <div className="p-0 d-flex justify-content-between">
                                    <input
                                        type="range"
                                        max={8} 
                                        value={selectedLuggageCapacity}
                                        onChange={handleLuggageCapacityChange}
                                        className="w-100"
                                    />
                                    <span className="px-2"> {selectedLuggageCapacity}</span>
                                </div> */}
                    <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                      Luggage Capacity
                    </p>
                    <div className="p-0 d-flex justify-content-between">
                      <span>Min {LuggageCapacityRange.min}</span>
                      <span>Max {LuggageCapacityRange.max}</span>
                    </div>
                    <div className="py-2 px-0 d-flex justify-content-between">
                      <input
                        type="range"
                        max={LuggageCapacityRange.max}
                        min={LuggageCapacityRange.min}
                        value={selectedLuggageCapacity}
                        className="w-100"
                        onChange={handleLuggageCapacityChange}
                      />
                      <span className="px-2">{selectedLuggageCapacity}</span>
                    </div>
                  </div>

                  <div
                    className="m-0 p-0 row py-2"
                    style={{ borderBottom: "2px solid #e9e9e9" }}
                  >
                    <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                      AC or Non-AC
                    </p>
                    <div className="p-0">
                      <div>
                        <input
                          type="radio"
                          id="ac"
                          value="AC"
                          checked={selectedAC === "AC"}
                          onChange={handleACChange}
                        />
                        <label htmlFor="ac" className="px-2">
                          AC
                        </label>
                      </div>
                      <div>
                        <div>
                          <input
                            type="radio"
                            id="non-ac"
                            value="Non-AC"
                            checked={selectedAC === "Non-AC"}
                            onChange={handleACChange}
                          />
                          <label htmlFor="non-ac" className="px-2">
                            Non-AC
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="m-0 p-0 row py-2"
                    style={{ borderBottom: "2px solid #e9e9e9" }}
                  >
                    <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                      REFUNDABLE
                    </p>
                    <div className="p-0">
                      <div>
                        <input
                          type="radio"
                          id="Refundable"
                          value="Refundable"
                          checked={selectedRefundable === "Refundable"}
                          onChange={handleRefundableChange}
                        />
                        <label htmlFor="Refundable" className="px-2">
                          REFUNDABLE
                        </label>
                      </div>
                      <div>
                        <div>
                          <input
                            type="radio"
                            id="Non-Refundable"
                            value="Non-Refundable"
                            checked={selectedRefundable === "Non-Refundable"}
                            onChange={handleRefundableChange}
                          />
                          <label htmlFor="Non-Refundable" className="px-2">
                            NON-REFUNDABLE
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-9 pe-0 pb-3">
                <div>
                  <div>
                    {filteredData?.length === 0 ? (
                      <div className="Section_Container mb-3">
                        <div className="row">
                          <div className="col-md-12">
                            <h6>Sorry, No Cabs for this Search</h6>
                            <p className="m-0 text-secondary">
                              We cannnot find any Cabs for your search. Please
                              modify your search criteria and try again.
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {filteredData?.map((ele, index) => (
                          <div className="Section_Container " key={index}>
                            <div className="Bus_Cards ">
                              <div
                                style={{
                                  borderBottom:
                                    "1px solid rgba(201, 228, 255, 1)",
                                }}
                              >
                                <div className="row d-flex justify-content-between px-3 pt-2">
                                  <div className="col-md-5 ">
                                    <p className="m-0 fw-bold">
                                      {ele?.Category}
                                    </p>
                                    <p
                                      className="text-secondary m-0"
                                      style={{ fontSize: "14px" }}
                                    >
                                      <span>{ele?.SeatingCapacity}-Seater</span>
                                      <span className="px-2">|</span>
                                      <span>
                                        {ele?.LuggageCapacity}-Luggage capacity
                                      </span>
                                    </p>
                                  </div>
                                  <div className="col-md-4 d-flex justify-content-end">
                                    <div>
                                      <span
                                        className="text-decoration-line-through text-secondary "
                                        style={{ fontSize: "14px" }}
                                      >
                                        {`₹${parseInt(
                                          handleOfferedPrice(
                                            ele?.Fare?.TotalAmount
                                          )
                                        ).toLocaleString()}`}
                                      </span>
                                      <span
                                        className="ps-2 fw-bold "
                                        style={{ fontSize: "18px" }}
                                      >
                                        {`₹${parseInt(
                                          handlePrice(ele?.Fare?.TotalAmount)
                                        ).toLocaleString()}`}
                                        <span className="pe-1"></span>
                                      </span>
                                      <p
                                        className="text-success m-0 p-0 text-end"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <span>Save </span>
                                        <span className="fw-bold ">
                                          Save ₹
                                          {handleOffPrice(
                                            ele?.Fare?.TotalAmount
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="row d-flex justify-content-between align-items-center px-3 py-2">
                                  <div
                                    className="col-md-2"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <span>
                                      {ele?.Fare?.Refundable === true
                                        ? "Refundable"
                                        : "Non-Refundable"}
                                    </span>
                                  </div>

                                  <div
                                    className="col-md-7"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <div className="row">
                                      <div className="col-md-4">
                                        <p className="text-center p-0 m-0 ">
                                          {fromData}
                                        </p>
                                      </div>
                                      <div className="col-md-4 bg-white d-flex justify-content-center">
                                        <span className="">
                                          <FaArrowRightLong />
                                        </span>
                                      </div>

                                      <div className="col-md-4">
                                        <p className="text-center m-0">
                                          {toData}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className="col-md-3 d-flex justify-content-end"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <span className="text-success">
                                      {ele?.Availability}-Seats Available
                                    </span>
                                    <span className="px-1"> | </span>
                                    <span>
                                      {ele.AirConditioner ? "AC" : "Non-AC"}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="row d-flex justify-content-between px-3 py-2">
                                <div className="col-md-5 d-flex align-items-center">
                                  {/* <span className="Bus_Cards_items">Pickups & Drops</span> */}
                                </div>
                                <div className="col-md-3 d-flex justify-content-end">
                                  <Link
                                    className="book-car-btn no-underline"
                                    to={`/searchcab/${fromData}/${toData}/${dateData}/${TraceId}/${ele.SrdvIndex}`}
                                  >
                                    <button className="Bus_search_btn">
                                      Explore Cabs
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
