import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import images from "../../assets/Images";
import {
  BoardingPointDetails,
  BookBusSeat,
  GetSeatLayout,
} from "../../api/BusAPI";
import { getUserProfile, sendPaymentDetails } from "../../api/ProfileAPI";
import toast from "react-hot-toast";

export const BusSeatLayout = () => {
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [busData, setBusData] = useState([]);
  const [BoardingPointsDetails, setBoardingPointsDetails] = useState([]);
  const [DroppingPointsDetails, setDroppingPointsDetails] = useState([]);
  const { TraceID } = useParams();

  const SeatBooking = async () => {
    try {
      const response = await GetSeatLayout();
      if (response) {
        setBusData(
          response?.data?.Result?.SeatLayout?.SeatLayoutDetails?.Layout
            ?.seatDetails
        );
      } else {
        console.error("Failed to fetch data:", response.status);
      }

      const boardingpoints = await BoardingPointDetails();
      if (boardingpoints?.status == "success") {
        setBoardingPointsDetails(
          boardingpoints?.data?.GetBusRouteDetailResult?.BoardingPointsDetails
        );
        setDroppingPointsDetails(
          boardingpoints?.data?.GetBusRouteDetailResult?.DroppingPointsDetails
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    SeatBooking();
  }, []);

  const handleSeatSelect = (row, col) => {
    const isAlreadySelected = selectedSeats.some(
      (s) => s.row === row && s.col === col
    );

    if (!isAlreadySelected) {
      const updatedSelectedSeats = [...selectedSeats];
      updatedSelectedSeats.push({ row, col });
      setSelectedSeats(updatedSelectedSeats);
    } else {
      const updatedSelectedSeats = selectedSeats.filter(
        (s) => !(s.row === row && s.col === col)
      );
      setSelectedSeats(updatedSelectedSeats);
    }
  };

  const handleCancelSeat = (row, col) => {
    const updatedSelectedSeats = selectedSeats.filter(
      (seat) => !(seat.row === row && seat.col === col)
    );
    setSelectedSeats(updatedSelectedSeats);
  };

  const calculateTotalAmount = () => {
    let totalAmount = 0;
    selectedSeats.forEach((seat) => {
      totalAmount += busData[seat.row][seat.col].SeatFare;
    });
    return totalAmount;
  };

  const [BusBookData, setBusBookData] = useState("");
  const navigate = useNavigate();

  const handleBusBook = async () => {
    const res = await BookBusSeat();
    console.log(res?.data?.Result);
    setBusBookData(res?.data?.Result);
    navigate("bookingStatus");
  };

  // Fetch User Details

  const [userInformation, setUserInformation] = useState(null);

  const GetUserInformationApi = async () => {
    const userTokenFromCookies = Cookies.get("userToken");
    if (userTokenFromCookies) {
      const parsedUserData = JSON.parse(userTokenFromCookies);
      const res = await getUserProfile(parsedUserData);
      console.log(res, "getUserProfile");
      setUserInformation(res?.data);
    }
  };

  useEffect(() => {
    GetUserInformationApi();
  }, []);

  // Generate Order Id

  const generateUniqueOrderId = () => {
    return Math.floor(1000000000 + Math.random() * 9000000000).toString();
  };

  const [OrderId, setOrderId] = useState(generateUniqueOrderId());
  const [isPaymentFormVisible, setIsPaymentFormVisible] = useState(false);

  useEffect(() => {
    generateUniqueOrderId();
  }, []);

  const handlePayment = async (e) => {
    e.preventDefault();

    if (!userInformation) {
      toast.error("Please Login for Book Bus", {
        duration: 5000,
      });

      setTimeout(() => {
        navigate("/login");
      }, 5000);

      return;
    }

    const formData = {
      order_id: OrderId,
      mobile: userInformation?.mobile,
      amount: "1",
      booking_type: "Bus",
      customer_name: "John Doe",
      email: "john.doe@example.com",
      payment_status: "Pending",
      tracking_id: "",
      payment_mode: "Unified Payments",
      order_status: "pending",
    };

    try {
      const res = await sendPaymentDetails(formData);

      console.log(res, "savepaymentdetails");

      if (res?.status === "success") {
        // Make the form visible and submit it
        setIsPaymentFormVisible(true);
        setTimeout(() => {
          document.customerData.submit();
        }, 1000); // Auto-submit the form after showing it
      } else {
        console.error("Error saving payment details");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <h3 className="text-center mt-4">Select Bus Seats</h3>
      <Container className="mb-5">
        <Row className="py-2">
          <Col md={4} className="d-flex justify-content-center "></Col>
        </Row>

        <Row>
          <div className="col-md-12">
            <div className="container">
              <div className="row"></div>
              <div className="row mt-3">
                <div className="col-md-1">Lower seats</div>

                <div className="col-md-5 ">
                  <div className=" p-3">
                    {busData?.length > 0 ? (
                      busData?.map((row, rowIndex) => (
                        <div key={rowIndex} className="row">
                          {row.map((seat, colIndex) => (
                            <div
                              key={colIndex}
                              className="col px-2 mb-3 cursor-pointer"
                              onClick={() =>
                                handleSeatSelect(rowIndex, colIndex)
                              }
                            >
                              <div
                                className="card p-2"
                                style={{
                                  backgroundColor: selectedSeats.some(
                                    (s) =>
                                      s.row === rowIndex && s.col === colIndex
                                  )
                                    ? "lightgreen"
                                    : "lightgray",
                                }}
                              >
                                <img
                                  src="https://cdn-icons-png.freepik.com/512/474/474421.png?ga=GA1.1.1038351476.1710929384&"
                                  alt=""
                                  width="50px"
                                  height="50px"
                                ></img>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))
                    ) : (
                      <div
                        className="  d-flex align-items-center justify-content-center"
                        style={{ height: "400px" }}
                      >
                        <img
                          src={images.Flight_Loader_Transparent}
                          height={170}
                          width={170}
                        ></img>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-3"></div>

                <div
                  className="col-md-3 mb-3"
                  style={{ border: "1px solid #04a9fe" }}
                >
                  <h4 className="pt-2">ExploreTrip Bus Travels</h4>
                  <div className="mb-3">
                    <label style={{ fontWeight: "600" }}>
                      Choose Boarding Point
                    </label>
                    <div>
                      <select
                        className="w-100 p-1"
                        style={{ border: "1px solid gray" }}
                      >
                        <option value="">Select a City</option>
                        {BoardingPointsDetails?.map((ele, id) => (
                          <option key={id} value={ele?.CityPointLocation}>
                            {ele?.CityPointLocation}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label style={{ fontWeight: "600" }}>
                      Choose Dropping Point
                    </label>
                    <div>
                      <select
                        className="w-100 p-1"
                        style={{ border: "1px solid gray" }}
                      >
                        <option value="">Select a City</option>
                        {DroppingPointsDetails?.map((ele, id) => (
                          <option key={id} value={ele?.CityPointLocation}>
                            {ele?.CityPointLocation}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label style={{ fontWeight: "600" }}>Selected Seats</label>
                    <div>
                      {selectedSeats?.map((seat, index) => (
                        <div
                          key={index}
                          className="d-flex align-items-center"
                          style={{ borderBottom: "1px solid #f1f1f1" }}
                        >
                          Seat No. {busData[seat?.row][seat?.col]?.SeatName} ( ₹
                          {busData[seat?.row][seat?.col]?.SeatFare})
                          <button
                            className="bg-white"
                            onClick={() =>
                              handleCancelSeat(seat?.row, seat?.col)
                            }
                          >
                            <div className="px-2">
                              <MdOutlineCancel className="text-danger bg-white" />
                            </div>
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="mb-3">
                    <label style={{ fontWeight: "600" }}>You pay</label>
                    <h4 className="text-success fw-bold">
                      ₹{calculateTotalAmount()}
                    </h4>
                  </div>

                  <div className="mb-3">
                    <button
                      className="px-2 activeOfferBtnClass"
                      onClick={handleBusBook}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>

        <div className="row">
          <div className="col-12">
            <div>
              {isPaymentFormVisible && (
                <form
                  method="POST"
                  name="customerData"
                  action="https://exploretriponline.com/api/ccavRequestHandler"
                  style={{ display: "none" }}
                >
                  <table width="40%" height="100" border="1" align="center">
                    <caption>
                      <font size="4" color="blue">
                        <b>Integration Kit</b>
                      </font>
                    </caption>
                  </table>
                  <table width="40%" height="100" border="1" align="center">
                    <tr>
                      <td>Parameter Name:</td>
                      <td>Parameter Value:</td>
                    </tr>
                    <tr>
                      <td colspan="2">Compulsory information</td>
                    </tr>
                    <tr>
                      <td>Merchant Id</td>
                      <td>
                        <input
                          type="text"
                          name="merchant_id"
                          id="merchant_id"
                          value="3401584"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Order Id</td>
                      <td>
                        <input
                          type="text"
                          name="order_id"
                          value={OrderId}
                          readOnly
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Currency</td>
                      <td>
                        <input type="text" name="currency" value="INR" />
                      </td>
                    </tr>
                    <tr>
                      <td>Amount</td>
                      <td>
                        <input type="text" name="amount" value="1.00" />
                      </td>
                    </tr>
                    <tr>
                      <td>Redirect URL</td>
                      <td>
                        <input
                          type="text"
                          name="redirect_url"
                          value="https://exploretriponline.com/api/landing"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Cancel URL</td>
                      <td>
                        <input
                          type="text"
                          name="cancel_url"
                          value="https://exploretriponline.com/api/landing"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Language</td>
                      <td>
                        <input
                          type="text"
                          name="language"
                          id="language"
                          value="EN"
                        />
                      </td>
                    </tr>

                    <tr>
                      <label for="booking_type">Booking Type:</label>
                      <select id="booking_type" name="booking_type">
                        <option value="flight">Flight</option>
                        <option value="hotel">Hotel</option>
                        <option value="cab">Cab</option>
                        <option value="bus">Bus</option>
                        <option value="insurance">Insurance</option>
                      </select>
                    </tr>

                    <tr>
                      <td colspan="2">Billing information(optional):</td>
                    </tr>
                    <tr>
                      <td>Billing Name</td>
                      <td>
                        <input
                          type="text"
                          name="billing_name"
                          value={userInformation?.name}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Billing Address:</td>com
                      <td>
                        <input
                          type="text"
                          name="billing_address"
                          value="Santacruz"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Billing City:</td>
                      <td>
                        <input type="text" name="billing_city" value="Mumbai" />
                      </td>
                    </tr>
                    <tr>
                      <td>Billing State:</td>
                      <td>
                        <input type="text" name="billing_state" value="MH" />
                      </td>
                    </tr>
                    <tr>
                      <td>Billing Zip:</td>
                      <td>
                        <input type="text" name="billing_zip" value="400054" />
                      </td>
                    </tr>
                    <tr>
                      <td>Billing Country:</td>
                      <td>
                        <input
                          type="text"
                          name="billing_country"
                          value="India"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Billing Tel:</td>
                      <td>
                        <input
                          type="text"
                          name="billing_tel"
                          value={userInformation?.mobile}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Billing Email:</td>
                      <td>
                        <input
                          type="text"
                          name="billing_email"
                          value="testing@domain.com"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">Shipping information(optional):</td>
                    </tr>
                    <tr>
                      <td>Shipping Name</td>
                      <td>
                        <input type="text" name="delivery_name" value="Sam" />
                      </td>
                    </tr>
                    <tr>
                      <td>Shipping Address:</td>
                      <td>
                        <input
                          type="text"
                          name="delivery_address"
                          value="Vile Parle"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Shipping City:</td>
                      <td>
                        <input
                          type="text"
                          name="delivery_city"
                          value="Mumbai"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Shipping State:</td>
                      <td>
                        <input
                          type="text"
                          name="delivery_state"
                          value="Maharashtra"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Shipping Zip:</td>
                      <td>
                        <input type="text" name="delivery_zip" value="400038" />
                      </td>
                    </tr>
                    <tr>
                      <td>Shipping Country:</td>
                      <td>
                        <input
                          type="text"
                          name="delivery_country"
                          value="India"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Shipping Tel:</td>
                      <td>
                        <input
                          type="text"
                          name="delivery_tel"
                          value="0123456789"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Merchant Param1</td>
                      <td>
                        <input
                          type="text"
                          name="merchant_param1"
                          value="additional Info."
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Merchant Param2</td>
                      <td>
                        <input
                          type="text"
                          name="merchant_param2"
                          value="additional Info."
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Merchant Param3</td>
                      <td>
                        <input
                          type="text"
                          name="merchant_param3"
                          value="additional Info."
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Merchant Param4</td>
                      <td>
                        <input
                          type="text"
                          name="merchant_param4"
                          value="additional Info."
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Merchant Param5</td>
                      <td>
                        <input
                          type="text"
                          name="merchant_param5"
                          value="additional Info."
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Promo Code:</td>
                      <td>
                        <input type="text" name="promo_code" value="" />
                      </td>
                    </tr>
                    <tr>
                      <td>Customer Id:</td>
                      <td>
                        <input
                          type="text"
                          name="customer_identifier"
                          value=""
                        />
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <input type="submit" value="Checkout" />
                      </td>
                    </tr>
                  </table>
                </form>
              )}
            </div>

            <button onClick={handlePayment} className="payButton my-2">
              Pay Now
            </button>
          </div>
        </div>
      </Container>
    </>
  );
};
