import React, { useEffect, useState } from "react";
import { BookBusSeat } from "../../api/BusAPI";

export const BusBooking = () => {
  const [BusBookData, setBusBookData] = useState("");

  const handleBusBook = async () => {
    const res = await BookBusSeat();
    console.log(res?.data?.Result);
    setBusBookData(res?.data?.Result);
  };

  useEffect(() => {
    handleBusBook();
  }, []);

  return (
    <div>
      <div className="my-4">
        <h3
          className="text-center"
          style={{ color: "green", fontWeight: "600" }}
        >
          Congratulations, your Bus Booking is confirmed
        </h3>
        <div className="d-flex justify-content-center">
          <table className="border strip w-75">
            <tbody>
              <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                <td className="py-2">
                  <span className="ps-3 m-0">Bus Booking Status</span>
                </td>
                <td>
                  <span
                    className="ps-3"
                    style={{ borderLeft: "1px solid lightgray" }}
                  >
                    {BusBookData?.BusBookingStatus}
                  </span>
                </td>
              </tr>
              <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                <td className="py-2">
                  <span className="ps-3 m-0">Invoice Amount</span>
                </td>
                <td>
                  <span
                    className="ps-3"
                    style={{ borderLeft: "1px solid lightgray" }}
                  >
                    {BusBookData?.InvoiceAmount}
                  </span>
                </td>
              </tr>
              <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                <td className="py-2">
                  <span className="ps-3 m-0">Bus Id</span>
                </td>
                <td>
                  <span
                    className="ps-3"
                    style={{ borderLeft: "1px solid lightgray" }}
                  >
                    {BusBookData?.BusId}
                  </span>
                </td>
              </tr>
              <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                <td className="py-2">
                  <span className="ps-3 m-0">Ticket No</span>
                </td>
                <td>
                  <span
                    className="ps-3"
                    style={{ borderLeft: "1px solid lightgray" }}
                  >
                    {BusBookData?.TicketNo}
                  </span>
                </td>
              </tr>
              <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                <td className="py-2">
                  <span className="ps-3 m-0">Travel Operator PNR</span>
                </td>
                <td>
                  <span
                    className="ps-3"
                    style={{ borderLeft: "1px solid lightgray" }}
                  >
                    {BusBookData?.TravelOperatorPNR}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
