import axios from "axios";
import { Base_Url } from "../Url/Url";

export const OtpVerify = async (otpData) => {
  try {
    const res = await fetch(`${Base_Url}/userAuth/userlogin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(otpData),
    });
    if (!res.ok) {
      throw new Error("Network response was not ok " + res.statusText);
    }
    return await res.json();
  } catch (error) {
    console.error("Failed to fetch data from the server:", error);
    throw error;
  }
};

export const LoginAPI = async (loginData) => {
  try {
    const res = await fetch(`${Base_Url}/userAuth/sendOTP`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginData),
    });
    if (!res.ok) {
      throw new Error("Network response was not ok " + res.statusText);
    }
    return await res.json();
  } catch (error) {
    console.error("Failed to fetch data from the server:", error);
    throw new Error("Failed to fetch data from the server: " + error.message);
  }
};

export const createConsultationForm = async (formData) => {
  try {
    console.log(formData);
    const response = await axios.post(
      `${Base_Url}/userAuth/consultation/createconsultation`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log(formData);
    console.log(response);
    return response;
  } catch (error) {
    console.error("Error creating Consulation:", error);
    throw error;
  }
};
