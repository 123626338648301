import { Base_Url } from "../Url/Url";

export const getUserProfile = async (token) => {
  try {
    const res = await fetch(`${Base_Url}/userAuth/getProfile`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    // Additional error handling
    if (!res.ok) {
      const errorText = await res.text();
      console.error("API response error:", errorText);
      throw new Error(`HTTP error! Status: ${res.status}`);
    }

    return await res.json(); // Parse response
  } catch (error) {
    console.log("Error in getUserProfile:", error);
  }
};

export const editUserProfile = async (data, token) => {
  try {
    const res = await fetch(`${Base_Url}/userAuth/updateProfile`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mobile: data?.mobile,
        name: data?.name,
        email: data?.email,
        birthday: data?.birthday,
        gender: data?.gender,
        marital_status: data?.marital_status,
        address: data?.address,
        pincode: data?.pincode,
        state: data?.state,
      }),
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const sendPaymentDetails = async (data) => {
  try {
    const res = await fetch(`${Base_Url}/userAuth/savepaymentdetails`, {
      method: "Post",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

