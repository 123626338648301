import React, { useEffect, useState } from "react";
import { BusForm } from "./Forms/BusForm";
import { CabForm } from "./Forms/CabForm";
import { FlightForm } from "./Forms/FlightForm";
import { HotelForm } from "./Forms/HotelForm";
import { BookingsItems } from "../../data/BookingItems";
import { useNavigate, useParams } from "react-router-dom";
import { RechargeForm } from "./Forms/RechargeForm";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../../style/Topbar.css";
import { InsuranceForm } from "./Forms/InsuranceForm";

export const TopBar = () => {
  const [activeItem, setActiveItem] = useState("Flights");
  const [activeItemMobile, setActiveItemMobile] = useState("Flights");

  const navigate = useNavigate();

  const { formType } = useParams();

  useEffect(() => {
    if (formType) {
      setActiveItemMobile(formType);
    }
  }, [formType]);

  useEffect(() => {
    if (formType) {
      setActiveItem(formType);
    }
  }, [formType]);

  const handleItemClick = (formType) => {
    setActiveItem(formType === activeItem ? "Flights" : formType);
    navigate(`/${formType}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleItemMobileClick = (formType) => {
    setActiveItemMobile(formType === activeItemMobile ? "" : formType);
    navigate(`/${formType}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const renderForm = () => {
    switch (activeItem) {
      case "Flights":
        return <FlightForm />;
      case "Cabs":
        return <CabForm />;
      case "Buses":
        return <BusForm />;
      case "Hotels":
        return <HotelForm />;
      case "Recharge":
        return <RechargeForm />;
      case "T Insurance":
        return <InsuranceForm />;
      default:
        return null;
    }
  };

  const renderMobileForm = () => {
    switch (activeItemMobile) {
      case "Flights":
        return <FlightForm />;
      case "Cabs":
        return <CabForm />;
      case "Buses":
        return <BusForm />;
      case "Hotels":
        return <HotelForm />;
      case "Recharge":
        return <RechargeForm />;
      case "T Insurance":
        return <InsuranceForm />;
      default:
        return null;
    }
  };

  var AirlineBrandSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  return (
    <div
      className="container-fluid BannerBackgroundImage pt-3"
      style={{ position: "relative" }}
    >
      <div className="row  d-flex justify-content-center align-items-center p-0">
        <div className="col-md-6 p-0 ">
          <div className="desktop-view">
            <div
              className="row d-flex align-items-center "
              style={{
                borderRadius: "40px",
                backgroundColor: "rgba(210, 223, 242, 1)",
                border: "2px solid rgba(225, 237, 255, 0.99) ",
              }}
            >
              {BookingsItems.map((ele) => (
                <div
                  className={` col cursor-pointer ${
                    ele?.title === activeItem
                      ? "selectedItemDiv"
                      : "deselectedItemDiv"
                  }`}
                  key={ele.id}
                  onClick={() => handleItemClick(ele?.title)}
                  style={{
                    borderRadius: "40px",
                    fontSize: "14px",
                    cursor: "pointer",
                    padding: "10px",
                  }}
                >
                  <div className="text-center">
                    <img
                      src={
                        ele?.title === activeItem
                          ? ele.imgPath_desktop_color
                          : ele.imgPath_desktop_black
                      }
                      alt=""
                      width="25px"
                      height="25px"
                    />
                  </div>
                  <div className="topbar_font_txt"> {ele.title}</div>
                </div>
              ))}
            </div>
          </div>

          <div className="mobile-view">
            <div
              className="row d-flex pb-3 m-0 justify-content-center align-items-center"
              style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}
            >
              <Slider {...AirlineBrandSettings}>
                {BookingsItems?.map((ele) => {
                  return (
                    <div className="col-3 px-1" key={ele?.id}>
                      <div
                        className={`justify-content-center align-items-center ${
                          ele?.title === activeItemMobile
                            ? "selectedItemDiv_mobile"
                            : ""
                        }`}
                        key={ele?.id}
                        onClick={() => handleItemMobileClick(ele?.title)}
                        style={{
                          border: "1.5px solid rgba(0,0,0,0.1)",
                          borderRadius: "10px",
                          overFlowX: "scroll",
                          padding: "5px 15px",
                        }}
                      >
                        <div className="d-flex justify-content-center pb-2">
                          <img
                            src={
                              ele?.title_mobile === activeItemMobile
                                ? ele?.imgPath_desktop_color
                                : ele?.imgPath_desktop_black
                            }
                            alt=""
                            width="35px"
                            height="35px"
                          />
                        </div>
                        <div>
                          <div className="top_bar_text_heading">{ele?.title_mobile}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <div className="desktop-view">
        <div
          className="row d-flex justify-content-center"
          style={{ marginTop: "3px" }}
        >
          {activeItem !== null && (
            <div className="row">
              <div className="col p-0">
                <div className="">{renderForm()}</div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mobile-view">
        <div
          className="row d-flex justify-content-center "
          style={{ marginTop: "3px" }}
        >
          {activeItemMobile !== null && (
            <div className="row">
              <div className="col p-0">
                <div className="">{renderMobileForm()}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
