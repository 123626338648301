import React, { useEffect, useState } from "react";
import {
  createTouristDestination,
  DeleteSingleTouristDestination,
  getalltouristdestination,
  GetSingleTouristDestination,
  updateSingleTouristDestination,
} from "../api/Api";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { IoTrashBinOutline } from "react-icons/io5";
import { HiPencilSquare } from "react-icons/hi2";
import { IoEye } from "react-icons/io5";
import { GrFormAdd } from "react-icons/gr";
import { formatDistanceToNow } from "date-fns";
import Modal from "react-bootstrap/Modal";
import { Toaster, toast } from "react-hot-toast";

const TouristDestinationSchema = Yup.object().shape({
  Name: Yup.string().required("Name is required"),
  Image: Yup.mixed().required("Image is required"),
  DestinationType: Yup.string().required("Destination Type is required"),
});

export const TouristDestination = () => {
  const [TouristDestinationData, setTouristDestinationData] = useState([]);
  const [SingleTouristDestinationData, setSingleTouristDestinationData] =
    useState([]);

  const [
    showCreateTouristDestinationModal,
    setShowCreateTouristDestinationModal,
  ] = useState(false);

  const handleCreateTouristDestinationModalClose = () =>
    setShowCreateTouristDestinationModal(false);
  const handleCreateTouristDestinationModalShow = () =>
    setShowCreateTouristDestinationModal(true);

  const FetchAllTouristDestination = async () => {
    try {
      const res = await getalltouristdestination();
      console.log(res);
      if (res?.status === "success") {
        setTouristDestinationData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchAllTouristDestination();
  }, []);

  const [
    showUpdateTouristDestinationModal,
    setShowUpdateTouristDestinationModal,
  ] = useState(false);

  const handleUpdateTouristDestinationModalClose = () =>
    setShowUpdateTouristDestinationModal(false);
  const handleUpdateTouristDestinationModalShow = () =>
    setShowUpdateTouristDestinationModal(true);

  const [
    showUpdateTouristDestinationData,
    setShowUpdateTouristDestinationData,
  ] = useState(null);

  const handleUpdateTouristDestination = async (ele) => {
    try {
      console.log(ele);
      handleUpdateTouristDestinationModalShow();
      const res = await GetSingleTouristDestination(ele?._id);
      console.log(res);
      if (res?.status === "success") {
        setShowUpdateTouristDestinationData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteTouristDestination = async (ele) => {
    try {
      console.log(ele?._id)
      const res = await DeleteSingleTouristDestination(ele?._id);
      console.log(res);
      if (res?.status === "success") {
        setTouristDestinationData(
          TouristDestinationData.filter((item) => item._id !== ele._id)
        );
        toast.success("Top Tourist Destination Location Deleted Successfully!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSingleTouristDestination = async (ele) => {
    try {
      handleSingleTouristDestinationModalShow();
      const res = await GetSingleTouristDestination(ele?._id);
      if (res?.status === "success") {
        setSingleTouristDestinationData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [
    showSingleTouristDestinationModal,
    setShowSingleTouristDestinationModal,
  ] = useState(false);

  const handleSingleTouristDestinationModalClose = () =>
    setShowSingleTouristDestinationModal(false);
  const handleSingleTouristDestinationModalShow = () =>
    setShowSingleTouristDestinationModal(true);

  const timeSinceCreation = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Invalid date"; // Return a fallback value
    }
    return formatDistanceToNow(date, { addSuffix: true });
  };

  const handleCreate = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("Name", values.Name);
    formData.append("Image", values.Image);
    formData.append("DestinationType", values.DestinationType);

    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      const res = await createTouristDestination(formData);
      if (res?.data?.status === "success") {
        setTouristDestinationData((prevData) => [...prevData, res.data.data]);
        toast.success("Tourist destination added successfully!");
        handleCreateTouristDestinationModalClose();
      }
    } catch (error) {
      console.error("Error creating tourist destination:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleUpdateDestination = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("Name", values.Name);
    formData.append("Image", values.Image);
    formData.append("DestinationType", values.DestinationType);

    console.log(showUpdateTouristDestinationData._id);

    try {
      const res = await updateSingleTouristDestination(
        showUpdateTouristDestinationData._id,
        formData
      );

      console.log(res);

      if (res?.data?.status === "success") {
        setTouristDestinationData((prev) =>
          prev.map((item) =>
            item._id === TouristDestinationData._id
              ? res.data.topdestination
              : item
          )
        );
        toast.success("Tourist Destination Updated Successfully!");
        handleUpdateTouristDestinationModalClose();
        window.location.reload();
      }
    } catch (error) {
      console.error("Error updating testimonial:", error);
      toast.error("Oops! Some error occurred");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="container">
      <Toaster position="top-right" reverseOrder={false} />

      <div className="row">
        <div className="d-flex justify-content-between">
          <div>
            <span style={{ fontWeight: "600", fontSize: "20px" }}>
              Top Tourist Destinations
            </span>
          </div>
          <div>
            <button
              className="addbutton"
              onClick={handleCreateTouristDestinationModalShow}
            >
              Add Tourist Destination
              <GrFormAdd style={{ fontWeight: "800", fontSize: "25px" }} />
            </button>
          </div>
        </div>
      </div>

      <div className="row py-4">
        <Table responsive="sm">
          <thead>
            <tr>
              <th>S.no</th>
              <th>Name</th>
              <th>Image</th>
              <th>Destination Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(TouristDestinationData) &&
            TouristDestinationData.length > 0 ? (
              TouristDestinationData?.map((ele, index) => (
                <tr key={ele?._id}>
                  <td>{index + 1}</td>
                  <td>{ele?.Name}</td>
                  <td>
                    <img
                      src={`https://api.exploretriponline.com${ele?.Image}`}
                      alt={ele?.Name}
                      style={{ width: "100px", height: "40px" }}
                    />
                  </td>

                  <td>{ele?.DestinationType}</td>
                  <td>
                    <div className="d-flex">
                      <div
                        onClick={() => handleSingleTouristDestination(ele)}
                        className="action_button_view"
                      >
                        <IoEye />
                      </div>
                      <div
                        className="px-2 action_button_update"
                        onClick={() => handleUpdateTouristDestination(ele)}
                      >
                        <HiPencilSquare className="text-success" />
                      </div>
                      <div
                        className="action_button_delete"
                        onClick={() => handleDeleteTouristDestination(ele)}
                      >
                        <IoTrashBinOutline className="text-danger" />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  Data Not Available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Show single Destination */}

      <div className="row">
        <Modal
          show={showSingleTouristDestinationModal}
          onHide={handleSingleTouristDestinationModalClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Tourist Destination </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center align-items-center">
              <img
                src={`https://api.exploretriponline.com${SingleTouristDestinationData?.Image}`}
                alt=""
                style={{ height: "40vh", width: "100%" }}
              />
            </div>

            <div className="d-flex align-items-center justify-content-center pt-1"></div>

            <div className="py-2" style={{ fontWeight: "600" }}>
              {SingleTouristDestinationData?.DestinationType}
            </div>
            <div className="py-2">{SingleTouristDestinationData?.Name}</div>
            <div className="d-flex justify-content-between">
              <div>
                <div className="px-1 text-secondary">
                  ~ {timeSinceCreation(SingleTouristDestinationData?.createdAt)}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      {/* Create Destination */}

      <div className="row">
        <Modal
          show={showCreateTouristDestinationModal}
          onHide={handleCreateTouristDestinationModalClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Tourist Destination</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                Name: "",
                Image: null,
                DestinationType: "",
              }}
              validationSchema={TouristDestinationSchema}
              onSubmit={handleCreate}
            >
              {({ setFieldValue, values, isSubmitting }) => (
                <Form>
                  <div>
                    <div>Name</div>
                    <Field type="text" name="Name" className="Form_Field" />
                    <ErrorMessage
                      name="Name"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="mt-2">
                    <div>Image</div>
                    <input
                      type="file"
                      name="Image"
                      className="Form_Field"
                      onChange={(event) => {
                        setFieldValue("Image", event.currentTarget.files[0]);
                      }}
                    />
                    <ErrorMessage
                      name="Image"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="mt-2">
                    <div>Destination Type</div>
                    <Field
                      type="textarea"
                      name="DestinationType"
                      className="Form_Field"
                    />
                    <ErrorMessage
                      name="DestinationType"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <Modal.Footer>
                    <Button
                      onClick={handleCreateTouristDestinationModalClose}
                      disabled={isSubmitting}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </div>

      {/* Update Destination */}

      <div className="row">
        <Modal
          show={showUpdateTouristDestinationModal}
          onHide={handleUpdateTouristDestinationModalClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Tourist Destination</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showUpdateTouristDestinationData && (
              <Formik
                initialValues={{
                  Name: showUpdateTouristDestinationData?.Name,
                  Image: null,
                  DestinationType:
                    showUpdateTouristDestinationData?.DestinationType,
                }}
                validationSchema={TouristDestinationSchema}
                onSubmit={handleUpdateDestination}
              >
                {({ setFieldValue, values, isSubmitting }) => (
                  <Form>
                    <div>
                      <div>Name</div>
                      <Field type="text" name="Name" className="Form_Field" />
                      <ErrorMessage
                        name="Name"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="mt-2">
                      <div>Image</div>
                      <input
                        type="file"
                        name="Image"
                        className="Form_Field"
                        onChange={(event) => {
                          setFieldValue("Image", event.currentTarget.files[0]);
                        }}
                      />
                      <ErrorMessage
                        name="Image"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="mt-2">
                      <div>Destination Type</div>
                      <Field
                        type="textarea"
                        name="DestinationType"
                        className="Form_Field"
                      />
                      <ErrorMessage
                        name="DestinationType"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <Modal.Footer>
                      <Button
                        onClick={handleUpdateTouristDestinationModalClose}
                        disabled={isSubmitting}
                      >
                        Close
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};
