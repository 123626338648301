import Cookies from "js-cookie";
import React, {createContext,useState } from "react";

const MyContexts = createContext();

export const MyContextFunction = ({ children }) => {
    const [userData, setUserData] = useState("");
    const [selectedCarData, setSelectedCarData] = useState(null);
    const [selectedFlightData, setSelectedFlightData] = useState(null);

    const setUserDataFunction = (data) => {
        const userDataResult = JSON.stringify(data);
        Cookies.set("userData", JSON.stringify(data), { expires: 7 });
        setUserData(userDataResult);
    };

    const setSelectedCarFunction = (Car) => {
        setSelectedCarData(Car);
    };

    const setSelectedFlightFunction = (Flight) => {
        setSelectedFlightData(Flight);
    };

    return (
        <MyContexts.Provider
            value={{
                userData,
                setUserDataFunction,
                selectedCarData,
                setSelectedCarFunction,
                selectedFlightData,
                setSelectedFlightFunction,
            }}
        >
            {children}
        </MyContexts.Provider>
    );
};

export { MyContexts };
