import React, { useEffect, useState } from "react";
import AdminuserTable from "../components/AdminuserTable";
import { GetAllTestimonials, getallUserData } from "../api/Api";

const AdminDashboard = () => {
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [UserData, setUserData] = useState([]);

  const FetchAllTestimonials = async () => {
    try {
      const res = await GetAllTestimonials();
      if (res?.status === "success") {
        setTestimonialsData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FetchAllUser = async () => {
    try {
      const res = await getallUserData();
      if (res?.status === "success") {
        setUserData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchAllTestimonials();
    FetchAllUser();
  }, []);

  return (
    <>
      <div className="overview-boxes">
        <div className="box">
          <div className="right-side">
            <div style={{ fontWeight: "600", fontSize: "14px" }}>
              Total Testimonials
            </div>
            <div className="number">{testimonialsData?.length}</div>
            <div className="indicator">
              <i className="bx bx-up-arrow-alt"></i>
              <span className="text">Up from yesterday</span>
            </div>
          </div>
          <i className="bx bx-cart-alt cart"></i>
        </div>

        <div className="box">
          <div className="right-side">
            <div style={{ fontWeight: "600", fontSize: "14px" }}>
              Total Users
            </div>
            <div className="number">{UserData?.length}</div>
            <div className="indicator">
              <i className="bx bx-up-arrow-alt"></i>
              <span className="text">Up from yesterday</span>
            </div>
          </div>
          <i className="bx bx-cart-alt cart"></i>
        </div>

        

        <div className="box">
          <div className="right-side">
            <div className="box-topic">User</div>
            <div className="number">789</div>
            <div className="indicator">
              <i className="bx bx-up-arrow-alt"></i>
              <span className="text">Up from yesterday</span>
            </div>
          </div>
          <i className="bx bx-cart cart three"></i>
        </div>

        <div className="box">
          <div className="right-side">
            <div className="box-topic">Total Return</div>
            <div className="number">11,086</div>
            <div className="indicator">
              <i className="bx bx-up-arrow-alt"></i>
              <span className="text">Down From Today</span>
            </div>
          </div>
          <i className="bx bx-cart-download cart four"></i>
        </div>
      </div>

      <div className="sales-boxes">
        <div className="recent-sales box">
          <div className="title">Recent Sales</div>
          <div className="sales-details">
            <AdminuserTable />
          </div>
        </div>

        <div className="top-sales box">
          <div className="title">Top Selling Product</div>
          <ul className="top-sales-details">
            <li>
              <a href="#">
                <img src="/shoes.png" alt="Nike Shoes" />
                <span className="product">Nike Shoes</span>
              </a>
              <span className="price">₹ 400</span>
            </li>
            <li>
              <a href="#">
                <img src="/shoes.png" alt="Nike Shoes" />
                <span className="product">Nike Shoes</span>
              </a>
              <span className="price">₹ 400</span>
            </li>
            <li>
              <a href="#">
                <img src="/shoes.png" alt="Nike Shoes" />
                <span className="product">Nike Shoes</span>
              </a>
              <span className="price">₹ 400</span>
            </li>
            <li>
              <a href="#">
                <img src="/shoes.png" alt="Nike Shoes" />
                <span className="product">Nike Shoes</span>
              </a>
              <span className="price">₹ 400</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
