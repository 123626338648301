import React from "react";
import { FAQ } from "../component/homepagecomponents/FAQ";
import { Testimonial } from "../component/homepagecomponents/Testimonial";
import { WhyBookWithUs } from "../component/homepagecomponents/WhyBookWithUs";
import { DailyTravelBlogs } from "../component/homepagecomponents/DailyTravelBlogs";
import { HolidayPackages } from "../component/homepagecomponents/HolidayPackages";
import { TopDestination } from "../component/homepagecomponents/TopDestination";
import { SpecialOffers } from "../component/homepagecomponents/SpecialOffers";
import { TopBar } from "../component/homepagecomponents/TopBar";
import { AirlineBrand } from "../component/homepagecomponents/AirlineBrand";
import { ETOApp } from "../component/homepagecomponents/ETOApp";
import { Banners } from "../component/homepagecomponents/Banners";
import { Banner2 } from "../component/homepagecomponents/Banner2";

export const Dashboard = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-md-12 p-0">
            <TopBar />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 p-0">
            <SpecialOffers />
            <TopDestination />
            <Banners />
            <HolidayPackages />
            <Banner2 />
            <DailyTravelBlogs />
            <WhyBookWithUs />
            <ETOApp />
            {/* <Testimonial /> */}
            <AirlineBrand />
            <FAQ />
          </div>
        </div>
      </div>
    </>
  );
};
