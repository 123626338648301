import React, { useEffect, useState } from "react";
import {
  deleteSingleUserData,
  getallUserData,
  updateSingleUserStatus,
} from "../api/Api";
import { GrFormAdd } from "react-icons/gr"; // Import GrFormAdd from react-icons
import Table from "react-bootstrap/Table";
import { IoTrashBinOutline } from "react-icons/io5";

import { SiTicktick } from "react-icons/si";
import { MdBlock } from "react-icons/md";
import { Toaster, toast } from "react-hot-toast";

export const UsersData = () => {
  const [userData, setUserData] = useState([]); // Renamed for clarity

  const FetchAllUserDetails = async () => {
    try {
      const res = await getallUserData();
      console.log(res);
      if (res?.status === "success") {
        setUserData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    FetchAllUserDetails();
  }, []);

  const handleDeleteUser = async (ele) => {
    try {
      const res = await deleteSingleUserData(ele?._id);
      if (res?.status === "success") {
        setUserData(userData.filter((item) => item._id !== ele._id));
        toast.success("User Deleted Successfully!");
      }
    } catch (error) {
      console.log("Error fetching single FAQ:", error);
    }
  };

  const handleUpdateActivate = async (ele, newStatus) => {
    try {
      console.log(ele, newStatus);
      const res = await updateSingleUserStatus(ele?.mobile, newStatus);
      console.log(res);
      if (res?.success) {
        const updatedData = userData.map((user) =>
          user._id === ele._id ? { ...user, status: newStatus } : user
        );
        setUserData(updatedData);
        toast.success("User status updated successfully!");
      }
    } catch (error) {
      console.log("Error updating user status:", error);
      toast.error("Failed to update user status.");
    }
  };

  const handleActionChange = (ele, action) => {
    switch (action) {
      case "activate":
        handleUpdateActivate(ele, 1);
        break;
      case "deactivate":
        handleUpdateActivate(ele, 0);
        break;
      case "delete":
        handleUpdateActivate(ele, 2);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div className="container">
        <Toaster position="top-right" reverseOrder={false} />

        <div className="row">
          <div className="d-flex justify-content-between">
            <div>
              <span style={{ fontWeight: "600", fontSize: "20px" }}>
                User Management
              </span>
            </div>
          </div>
        </div>

        <div className="row py-4 table_user_data" style={{ overflow: "auto" }}>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>S.no</th>
                <th>User Information</th>
                <th>Dates</th>

                <th>status</th>
                <th>actions</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(userData) && userData.length > 0 ? (
                userData
                  .filter((ele) => ele?.status !== 2) // Filter out rows with status = 2
                  .map((ele, index) => (
                    <tr key={ele?._id}>
                      <td>{index + 1}</td>

                      <td>
                        <div>
                          <span style={{ fontWeight: "600" }}>Name -</span>
                          <span className="ps-1">{ele?.name}</span>
                        </div>
                        <div>
                          <span style={{ fontWeight: "600" }}>Email -</span>
                          <span className="ps-1">{ele?.email}</span>
                        </div>
                        <div>
                          <span style={{ fontWeight: "600" }}>Mobile -</span>
                          <span className="ps-1">{ele?.mobile}</span>
                        </div>

                        <div>
                          <span style={{ fontWeight: "600" }}>Address -</span>
                          <span className="ps-1">
                            {ele?.address},{ele?.state},{ele?.pincode}
                          </span>
                        </div>
                      </td>

                      <td>
                        <div>
                          <span className="ps-1" style={{ fontWeight: "600" }}>
                            Date of Birth -
                          </span>
                          <span>
                            {new Date(ele?.birthday).toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                          </span>
                        </div>
                        <div>
                          <span className="ps-1" style={{ fontWeight: "600" }}>
                            Last Login Date -
                          </span>
                          <span>
                            {new Date(ele?.login_time).toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                          </span>
                        </div>
                        <div>
                          <span className="ps-1" style={{ fontWeight: "600" }}>
                            Last Login Time -
                          </span>
                          <span>
                            {new Date(ele?.login_time).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              }
                            )}
                          </span>
                        </div>
                      </td>

                      <td>{ele?.status}</td>
                      <td>
                        <div className="d-flex px-2">
                          <div>
                            <select
                              onChange={(e) =>
                                handleActionChange(ele, e.target.value)
                              }
                              defaultValue=""
                            >
                              <option value="" disabled>
                                Action
                              </option>
                              <option value="activate">Activate</option>
                              <option value="deactivate">Deactivate</option>
                              <option value="delete">Delete</option>
                            </select>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="12" className="text-center">
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};
