import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "../component/Loader";
import { SingleSpecialOffersAPIData } from "../api/SpecialOffersAPI";
import { SlCalender } from "react-icons/sl";

import { Base_Url } from "../Url/Url";

export const SingleSpecialOffer = () => {
  const { id } = useParams();
  const [SingleOffersData, setSingleOffersData] = useState(null);

  const SingleSpecialOfferAPIData = async () => {
    try {
      const res = await SingleSpecialOffersAPIData(id);
      if (res?.status === "success") {
        setSingleOffersData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    SingleSpecialOfferAPIData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const convertDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const hours = dateTime.getHours() % 12 || 12; // Convert 24-hour to 12-hour format
    const minutes = dateTime.getMinutes().toString().padStart(2, "0");
    const ampm = dateTime.getHours() >= 12 ? "PM" : "AM";
    return `${hours}:${minutes}${ampm}`;
  };

  // Function to convert date to "dd Month yyyy"
  function getDateFromString(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0"); // Get day and pad with 0 if needed
    const month = date.toLocaleString("default", { month: "long" }); // Get full month name
    const year = date.getFullYear().toString(); // Get full year
    return `${day} ${month} ${year}`;
  }

  const formattedDateTime = SingleOffersData
    ? `${getDateFromString(SingleOffersData.OfferDate)} till ${convertDateTime(
        SingleOffersData.OfferDate
      )}`
    : "";

  return (
    <>
      <div className="desktop-view">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center align-items-center py-4">
            {SingleOffersData !== null ? (
              <div className="col-md-12">
                <div className="col-md-12">
                  <div className="row ">
                    <div
                      className="col-md-12 p-0 "
                      style={{ overflow: "hidden" }}
                    >
                      <p
                        className="text-center "
                        style={{ fontSize: "40px", fontWeight: "500" }}
                      >
                        {SingleOffersData?.OfferTitle}
                      </p>
                      <img
                        src={`${Base_Url}/image/${SingleOffersData?.OfferImage}`}
                        alt="img"
                        height="450px"
                        className="Section_image"
                        style={{ objectFit: "fill" }}
                      ></img>
                    </div>

                    <div className="col-md-12">
                      <div>
                        <div>
                          <p
                            className="text-center pt-2"
                            style={{ fontSize: "24px", fontWeight: "500" }}
                          >
                            {SingleOffersData?.OfferDescription}
                          </p>
                        </div>
                        <div className="p-0 m-0 pb-3 text-danger d-flex justify-content-center">
                          <span>
                            <SlCalender />
                          </span>
                          <span className="px-1">{formattedDateTime}</span>

                          {/* <span>
                          with these LAST-MINUTE deals live till 11th August:
                        </span> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 d-flex justify-content-center">
                      <div className="d-flex" style={{ fontSize: "24px" }}>
                        <div>
                          <p
                            className="text-white px-2"
                            style={{ backgroundColor: "blue" }}
                          >
                            Use Code
                          </p>
                        </div>
                        <div>
                          <p
                            className="text-black px-2"
                            style={{
                              backgroundColor: "white",
                              border: "1px dashed red",
                            }}
                          >
                            {SingleOffersData?.OfferCouponCode}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>

      <div className="mobile-view">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center align-items-center py-4">
            {SingleOffersData !== null ? (
              <div className="col-md-12">
                <div className="col-md-12">
                  <div className="row ">
                    <div
                      className="col-md-12 p-0 "
                      style={{ overflow: "hidden" }}
                    >
                      <p
                        className="text-center "
                        style={{ fontSize: "18px", fontWeight: "500" }}
                      >
                        {SingleOffersData?.OfferTitle}
                      </p>
                      <img
                        src={`${Base_Url}${SingleOffersData?.OfferImage}`}
                        alt="img"
                        height="250px"
                        className="Section_image"
                        style={{ objectFit: "fill" }}
                      ></img>
                    </div>

                    <div className="col-md-12">
                      <div>
                        <div>
                          <p
                            className="text-center pt-2"
                            style={{ fontSize: "24px", fontWeight: "500" }}
                          >
                            {SingleOffersData?.OfferDescription}
                          </p>
                        </div>
                        <div className="p-0 m-0 pb-3 text-danger d-flex justify-content-center">
                          <span>
                            <SlCalender />
                          </span>
                          <span className="px-1">{formattedDateTime}</span>

                          {/* <span>
                          with these LAST-MINUTE deals live till 11th August:
                        </span> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 d-flex justify-content-center">
                      <div className="d-flex" style={{ fontSize: "24px" }}>
                        <div>
                          <p
                            className="text-white px-2"
                            style={{ backgroundColor: "blue" }}
                          >
                            Use Code
                          </p>
                        </div>
                        <div>
                          <p
                            className="text-black px-2"
                            style={{
                              backgroundColor: "white",
                              border: "1px dashed red",
                            }}
                          >
                            {SingleOffersData?.OfferCouponCode}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
