import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCarFrom } from "../../../api/CabAPI";
import { Col } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import images from "../../../assets/Images";

export const CabForm = () => {
  const navigate = useNavigate();

  const today = new Date();

  const [checkInDate, setCheckInDate] = useState(
    today.toISOString().split("T")[0]
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const handleCheckInDateChange = (event) => {
    const selectedDate = event.target.value;
    setCheckInDate(selectedDate);
  };

  function handleReverseLocations(event) {
    event.preventDefault();
    if (searchTermFrom && searchTermTo) {
      setSearchTermFrom(searchTermTo);
      setSearchTermTo(searchTermFrom);
    } else {
      window.alert("Please enter the location");
    }
  }

  // To Search Manage

  const [searchTermTo, setSearchTermTo] = useState("Jaipur");
  const [isOpenTo, setIsOpenTo] = useState(false);

  const handleInputToChange = (e) => {
    setSearchTermTo(e.target.value);
    setIsOpenTo(true);
  };

  const [filteredCarToData, setFilteredCarToData] = useState([]);

  useEffect(() => {
    const getFilteredCarsTo = async () => {
      try {
        const data = { city: searchTermTo };
        const res = await getCarFrom(data);

        if (res.status == "success") {
          setFilteredCarToData(res.data); // Update with res.data, not res
        } else {
          console.error("Data not fetched properly");
        }
      } catch (error) {
        console.error(error + "error");
      }
    };

    if (searchTermTo !== "") {
      getFilteredCarsTo();
    } else {
      setFilteredCarToData([]);
    }
  }, [searchTermTo]);

  // From Search Place onClick

  const [selectedoptionfrom, setselectedoptionfrom] = useState(null);
  const [selectedoptionto, setselectedoptionto] = useState(null);

  const handleOptionClickTo = (filteredCabs) => {
    setSearchTermTo(filteredCabs.name);
    setselectedoptionto(filteredCabs.name);
    setIsOpenTo(false);
  };

  // From Search Manage

  const [searchTermFrom, setSearchTermFrom] = useState("New Delhi");
  const [isOpenFrom, setIsOpenFrom] = useState(false);

  const handleInputFromChange = (e) => {
    setSearchTermFrom(e.target.value);
    setIsOpenFrom(true);
  };

  // From Search Place onClick

  const handleOptionClickFrom = (filteredCabs) => {
    setSearchTermFrom(filteredCabs.name);
    setselectedoptionfrom(filteredCabs.name);
    setIsOpenFrom(false);
  };

  const [filteredCarData, setFilteredCarData] = useState([]);

  useEffect(() => {
    const getFilteredCars = async () => {
      try {
        const data = { city: searchTermFrom };
        const res = await getCarFrom(data);

        if (res.status == "success") {
          setFilteredCarData(res.data); // Update with res.data, not res
        } else {
          console.error("Data not fetched properly");
        }
      } catch (error) {
        console.error(error + "error");
      }
    };

    if (searchTermFrom !== "") {
      getFilteredCars();
    } else {
      setFilteredCarData([]);
    }
  }, [searchTermFrom]);

  const handleSearchCabs = () => {
    const destinationTo = selectedoptionto || "Jaipur";
    const destinationFrom = selectedoptionfrom || " New Delhi";

    if (destinationFrom && destinationTo) {
      navigate(`/searchcab/${destinationFrom}/${destinationTo}/${checkInDate}`);
    } else {
      window.alert("Please Enter Valid Details");
    }
  };

  return (
    <>
      <div className="container desktop-view">
        <div
          className="row p-3 d-flex align-items-center"
          style={{
            borderRadius: "7px",
            border: "2px solid #01a9ff",
            backgroundColor: "white",
            height: "100px",
          }}
        >
          <div className="col-md-4">
            <p className="form_fields_Heading">CAB FROM CITY</p>
            <input
              type="text"
              className="w-100"
              value={searchTermFrom}
              onChange={handleInputFromChange}
              placeholder="Search..."
              style={{ fontSize: "25px", fontWeight: "600" }}
            />
          </div>

          <div className="col-md-1 d-flex justify-content-center align-items-end ">
            <button className="bg-white" onClick={handleReverseLocations}>
              <img
                src="../images/icons/reverse.png"
                alt=""
                height="100%"
                width="37px"
              ></img>
            </button>
          </div>

          <div
            className="col-md-4"
            style={{ borderRight: "2px solid #01a9ff" }}
          >
            <p className="form_fields_Heading">CAB TO CITY</p>
            <div>
              <input
                type="text"
                className="w-100"
                value={searchTermTo}
                onChange={handleInputToChange}
                placeholder="Search..."
                style={{ fontSize: "25px", fontWeight: "600" }}
              />
            </div>
          </div>

          <div className="col-md-3">
            <p className="form_fields_Heading">PICK-UP DATE</p>
            <div>
              <input
                type="date"
                value={checkInDate}
                min={formatDate(today)}
                onChange={handleCheckInDateChange}
                placeholder="Select a date"
                className="w-50"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <Col
            className="col-md-4 p-0"
            style={{
              position: "absolute",
              zIndex: 2,
            }}
          >
            {isOpenFrom && (
              <div
                style={{
                  maxHeight: "150px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {filteredCarData.length === 0 ? (
                  <div className="bg-white p-2 cursor-pointer">
                    No city found
                  </div>
                ) : (
                  <div>
                    {filteredCarData.map((ele, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => handleOptionClickFrom(ele)}
                          className="bg-white"
                        >
                          <div
                            className="p-2 cursor-pointer"
                            style={{ borderBottom: "1px solid #f1f1f1" }}
                          >
                            <span>{ele.name} , </span>
                            <span className="ps-1">{ele.state}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </Col>
        </div>

        <div className="row d-flex justify-content-center">
          <Col
            className="col-md-4 p-0"
            style={{
              position: "absolute",
              zIndex: 2,
            }}
          >
            {isOpenTo && (
              <div
                style={{
                  maxHeight: "150px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {filteredCarToData.length === 0 ? (
                  <div className="bg-white p-2 cursor-pointer">
                    No city found
                  </div>
                ) : (
                  <div>
                    {filteredCarToData.map((ele, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => handleOptionClickTo(ele)}
                          className="bg-white"
                        >
                          <div
                            className="p-2 cursor-pointer"
                            style={{ borderBottom: "1px solid #f1f1f1" }}
                          >
                            <span>{ele.name} , </span>
                            <span className="ps-1">{ele.state}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </Col>
        </div>

        <div className="d-flex justify-content-center">
          <div className="col-md-2 p-0 pb-3 d-flex justify-content-center">
            <button
              className="searchButton py-2 mt-3"
              onClick={handleSearchCabs}
            >
              Explore Cabs
            </button>
          </div>
        </div>
      </div>

      <div className="container mobile-view">
        <div
          className="row pt-2 d-flex align-items-center"
          style={{
            borderRadius: "7px",
            backgroundColor: "white",
            height: "auto",
          }}
        >
          <div className="col-12 hotelformBorder">
            <div
              className="row d-flex justify-content-between align-items-center pt-2"
              style={{ fontSize: "14px" }}
            >
              <div className="col-4">
                <p className="form_fields_Heading m-0">CAB WHERE FROM</p>
              </div>

              <div className="col-4">
                <p className="form_fields_Heading m-0">CAB WHERE TO</p>
              </div>
            </div>

            <div className="row d-flex justify-content-between align-items-center py-1">
              <div className="col-4">
                <input
                  type="text"
                  className="w-100"
                  value={searchTermFrom}
                  onChange={handleInputFromChange}
                  placeholder="Search..."
                  style={{ fontSize: "15px", fontWeight: "600" }}
                />
              </div>

              <div className="col-4 d-flex justify-content-center align-items-end ">
                <button className="bg-white" onClick={handleReverseLocations}>
                  <img
                    src={images.reverse}
                    alt=""
                    height="100%"
                    width="37px"
                  ></img>
                </button>
              </div>

              <div className="col-4">
                <input
                  type="text"
                  className="w-100"
                  value={searchTermTo}
                  onChange={handleInputToChange}
                  placeholder="Search..."
                  style={{ fontSize: "15px", fontWeight: "600" }}
                />
              </div>
            </div>
          </div>

          <div className="col-12 hotelformBorder py-2">
            <p className="form_fields_Heading m-0 py-1">PICKUP DATE</p>
            <input
              type="date"
              value={checkInDate}
              min={formatDate(today)}
              onChange={handleCheckInDateChange}
              placeholder="Select a date"
              className="bg-white"
            />
          </div>

          <div
            className="col-11 p-0"
            style={{
              position: "absolute",
              zIndex: 2,
              top: "40%",
            }}
          >
            {isOpenTo && (
              <div
                style={{
                  maxHeight: "150px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {filteredCarToData.length === 0 ? (
                  <div
                    className="p-2 cursor-pointer"
                    style={{
                      border: "1px solid lightgray",
                      backgroundColor: "#f1f1f1",
                    }}
                  >
                    No city found
                  </div>
                ) : (
                  <div>
                    {filteredCarToData.map((ele, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => handleOptionClickTo(ele)}
                          style={{
                            border: "1px solid lightgray",
                            backgroundColor: "#f1f1f1",
                          }}
                        >
                          <div
                            className="p-2 cursor-pointer"
                            style={{ borderBottom: "1px solid #f1f1f1" }}
                          >
                            <span>{ele.name} , </span>
                            <span className="ps-1">{ele.state}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>

          <div
            className="col-11 p-0"
            style={{
              position: "absolute",
              zIndex: 2,
              top: "40%",
            }}
          >
            {isOpenFrom && (
              <div
                style={{
                  maxHeight: "150px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {filteredCarData.length === 0 ? (
                  <div
                    className="p-2 cursor-pointer"
                    style={{
                      border: "1px solid lightgray",
                      backgroundColor: "#f1f1f1",
                    }}
                  >
                    No city found
                  </div>
                ) : (
                  <div>
                    {filteredCarData.map((ele, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => handleOptionClickFrom(ele)}
                          // className="bg-white"
                          style={{
                            border: "1px solid lightgray",
                            backgroundColor: "#f1f1f1",
                          }}
                        >
                          <div
                            className="p-2 cursor-pointer"
                            style={{ borderBottom: "1px solid #f1f1f1" }}
                          >
                            <span>{ele.name} , </span>
                            <span className="ps-1">{ele.state}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="col-md-3 p-0 d-flex justify-content-center">
            <button
              className="searchButton py-2"
              onClick={handleSearchCabs}
              style={{ width: "200px" }}
            >
              Explore Cabs
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
