import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Loader";
import { Base_Url } from "../../Url/Url";

export const SpecialOffersCarousel = ({ data }) => {
  const [SpecialOffersData, setSpecialOffersAPIData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setSpecialOffersAPIData(data);
    setLoading(false);
  }, [data]);

  const SpecialOffersSettings = {
    dots: false,
    centerMode: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
          centerMode: false,
        },
      },
    ],
  };

  const navigate = useNavigate();

  const handleSingleOfferDetails = (ele) => {
    navigate(`/singlespecialoffers/${ele?._id}`);
  };

  return (
    <>
      <div className="desktop-view">
        {loading ? (
          <Loader />
        ) : (
          <Slider {...SpecialOffersSettings}>
            {SpecialOffersData?.map((ele) => (
              <div className="col-md-12 col-12 col-xs-12 m-0 p-2" key={ele._id}>
                <div
                  // href={`/singlespecialoffers/${ele.OfferId}`}
                  // style={{ textDecoration: "none" }}
                  onClick={() => handleSingleOfferDetails(ele)}
                >
                  <div className="Section_Cards1 p-2">
                    <div
                      className="bg-purple-200"
                      style={{ borderRadius: "15px", overflow: "hidden" }}
                    >
                      <img
                        src={`${Base_Url}/image/${ele?.OfferImage}`}
                        alt=""
                        height="160px"
                        className="Section_image"
                      ></img>
                    </div>
                    <div className="Section_text_container">
                      <span
                        className="single-line-text"
                        style={{ fontWeight: "600" }}
                      >
                        {ele.OfferDescription.length > 40
                          ? ele.OfferDescription.slice(0, 30) + "..."
                          : ele.OfferDescription}
                      </span>
                      <p className="m-0">
                        Use Coupon Code : {ele?.OfferCouponCode}
                      </p>
                      <p className="text-secondary m-0">
                        Valid Till :
                        {new Date(ele.OfferDate).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>

      <div className="mobile-view">
        {loading ? (
          <Loader />
        ) : (
          <Slider {...SpecialOffersSettings}>
            {SpecialOffersData?.map((ele) => (
              <div
                className="col-md-12 col-12 col-xs-12 m-0 px-1"
                key={ele._id}
              >
                <div onClick={() => handleSingleOfferDetails(ele)}>
                  <div className="Section_Cards1_mobile p-1">
                    <div
                      className="bg-purple-200"
                      style={{ borderRadius: "7px", overflow: "hidden" }}
                    >
                      <img
                        src={`${Base_Url}/image/${ele?.OfferImage}`}
                        alt=""
                        height="140px"
                        className="Section_image"
                      ></img>
                    </div>
                    <div className="p-1" style={{ fontWeight: "500" }}>
                      <span className="text-black">
                        {ele.OfferDescription.length > 30
                          ? ele.OfferDescription.slice(0, 30) + "..."
                          : ele.OfferDescription}
                      </span>
                      <p
                        className="text-secondary m-0"
                        style={{ fontSize: "10px" }}
                      >
                        Valid till
                        {new Date(ele.OfferDate).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </>
  );
};
