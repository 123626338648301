import React, { useEffect, useState } from "react";
import "../../style/FAQ.css";
import { FaqAPIData } from "../../api/FAQapi";
import { Loader } from "../Loader";
import images from "../../assets/Images";
import { FaChevronRight, FaChevronDown } from "react-icons/fa6";

export const FAQ = () => {
  const [faqData, setFaqData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await FaqAPIData();

        if (res?.status === "success") {
          setFaqData(res?.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const [openSections, setOpenSections] = useState({});

  const toggleOpen = (id) => {
    setOpenSections((prevOpenSections) => ({
      ...prevOpenSections,
      [id]: !prevOpenSections[id],
    }));
  };
  return (
    <div className="container-fluid Section_Container desktop-view mb-3">
      <div className="row px-2">
        <div className="col-md-6">
          <div className="homepage_component_heading_txt">FAQ's</div>
          <div className="Section_text_small_underline"></div>
        </div>
      </div>

      <div className="row ">
        <div className="col-md-6 pt-2">
          {faqData ? (
            <div className="row px-2">
              {faqData?.map((ele, id) => (
                <div key={id}>
                  <div>
                    <div
                      className="accordion-item"
                      style={{ borderBottom: "1px solid #01a9ff" }}
                    >
                      <div
                        className="accordion-title"
                        onClick={() => toggleOpen(id)}
                      >
                        <p className="m-0" style={{ fontWeight: "600" }}>
                          {ele?.title}
                        </p>
                        <p className="m-0">
                          {openSections[id] ? (
                            <FaChevronRight />
                          ) : (
                            <FaChevronDown />
                          )}
                        </p>
                      </div>
                      {openSections[id] && (
                        <div className="accordion-content">
                          {ele?.description}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <Loader />
          )}
        </div>

        <div className="col-md-6 ">
          <img
            className="pt-2 px-2"
            src={images?.Services}
            alt=""
            width="100%"
            height="300px"
          ></img>
        </div>
      </div>
    </div>
  );
};
