import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../style/Recharge.css";
import { getInsuranceCity } from "../../../api/InsuranceApi";

export const InsuranceForm = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredHotelData, setFilteredHotelData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(0);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setDropdownOpen(true);

    // Debounce the API call
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        if (e.target.value.length >= 3) {
          fetchInsuranceCities(e.target.value);
        }
      }, 500) // 500ms delay after the last keystroke
    );
  };

  const fetchInsuranceCities = async (city) => {
    try {
      const data = { city };
      const res = await getInsuranceCity(data);

      if (res.status === "success") {
        setFilteredHotelData(res.data);
      } else {
        setFilteredHotelData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setFilteredHotelData([]);
    }
  };

  const handleOptionClick = (hotel) => {
    setSelectedOption(hotel);
    setSearchTerm(hotel.name);
    setDropdownOpen(false);
  };

  const handlePopularItemClick = (item) => {
    setSearchTerm(item);
    setDropdownOpen(true);
    fetchInsuranceCities(item);
  };

  const handleInsurance = () => {
    navigate(`/searchinsurance`);
  };

  return (
    <div>
      <div className="container desktop-view">
        <div
          className="row p-3 d-flex align-items-center"
          style={{
            borderRadius: "7px",
            border: "2px solid #01a9ff",
            backgroundColor: "white",
            height: "auto",
          }}
        >
          <div className="col-md-7" style={{ position: "relative", zIndex: 1 }}>
            <div style={{ fontSize: "20px", fontWeight: "600" }}>
              Where are you travelling?
            </div>

            <div style={{ position: "relative" }}>
              <div className="row">
                <div className="col-md-6 w-100">
                  <input
                    type="text"
                    placeholder="Search for a country"
                    value={searchTerm}
                    onChange={handleInputChange}
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    style={{ border: "1px solid gray" }}
                    className="p-2 w-100"
                  />
                  {dropdownOpen && (
                    <div
                      className="bg-white w-100"
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        border: "1px solid #ddd",
                        backgroundColor: "white",
                        zIndex: 1000,
                        maxHeight: "200px",
                        overflowY: "auto",
                        borderRadius: "0 0 5px 5px",
                      }}
                    >
                      {filteredHotelData.length > 0 ? (
                        filteredHotelData.map((hotel, index) => (
                          <div
                            key={index}
                            onClick={() => handleOptionClick(hotel)}
                            style={{
                              padding: "10px",
                              cursor: "pointer",
                              borderBottom: "1px solid lightgray",
                            }}
                          >
                            <div className="d-flex">
                              <div className="col-md-7">
                                <span
                                  className="fw-bold"
                                  style={{ fontSize: "14px" }}
                                >
                                  {hotel.name}
                                </span>
                                <p
                                  className="m-0 text-secondary"
                                  style={{ fontSize: "14px" }}
                                >
                                  {hotel.stateprovince}
                                </p>
                              </div>
                              <div className="col-md-5 d-flex align-items-center">
                                <p
                                  className="m-0 text-secondary"
                                  style={{ fontSize: "14px" }}
                                >
                                  {hotel.country}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="w-100" style={{ padding: "10px" }}>
                          No results found
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-2">
              <p style={{ fontSize: "16px", fontWeight: "600" }}>
                Popular countries
              </p>
              <div>
                <ul style={{ listStyleType: "none" }} className="d-flex p-0">
                  <li
                    className="me-2 px-2 py-1"
                    style={{ border: "1px solid gray", cursor: "pointer" }}
                    onClick={() => handlePopularItemClick("India")}
                  >
                    India
                  </li>
                  <li
                    className="me-2 px-2 py-1"
                    style={{ border: "1px solid gray", cursor: "pointer" }}
                    onClick={() => handlePopularItemClick("Australia")}
                  >
                    Australia
                  </li>
                  <li
                    className="me-2 px-2 py-1"
                    style={{ border: "1px solid gray", cursor: "pointer" }}
                    onClick={() => handlePopularItemClick("Singapore")}
                  >
                    Singapore
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-5">
            <img
              src="https://www.careinsurance.com/kitextproxy/cms-careinsurance-com/upload_master/travelsempages/upload/shutterstock-2007294998-converted-.png"
              alt=""
              style={{ height: "200px", width: "100%" }}
            />
          </div>
        </div>

        <div className="d-flex pt-3 justify-content-center">
          <div className="col-md-3 pb-3 px-3 d-flex justify-content-center">
            <button className="searchButton py-2" onClick={handleInsurance}>
              Explore Insurance
            </button>
          </div>
        </div>
      </div>

      <div className="container mobile-view">
        <div
          className="row py-3 d-flex align-items-center"
        >
          <div className="col-md-7" style={{ position: "relative", zIndex: 1 }}>
            <div style={{ fontSize: "20px", fontWeight: "600" }}>
              Where are you travelling?
            </div>

            <div style={{ position: "relative" }}>
              <div className="row">
                <div className="col-md-6 w-100">
                  <input
                    type="text"
                    placeholder="Search for a country"
                    value={searchTerm}
                    onChange={handleInputChange}
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    style={{ border: "1px solid gray" }}
                    className="p-2 w-100"
                  />
                  {dropdownOpen && (
                    <div
                      className="bg-white w-100"
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        border: "1px solid #ddd",
                        backgroundColor: "white",
                        zIndex: 1000,
                        maxHeight: "200px",
                        overflowY: "auto",
                        borderRadius: "0 0 5px 5px",
                      }}
                    >
                      {filteredHotelData.length > 0 ? (
                        filteredHotelData.map((hotel, index) => (
                          <div
                            key={index}
                            onClick={() => handleOptionClick(hotel)}
                            style={{
                              padding: "10px",
                              cursor: "pointer",
                              borderBottom: "1px solid lightgray",
                            }}
                          >
                            <div className="d-flex">
                              <div className="col-md-7">
                                <span
                                  className="fw-bold"
                                  style={{ fontSize: "14px" }}
                                >
                                  {hotel.name}
                                </span>
                                <p
                                  className="m-0 text-secondary"
                                  style={{ fontSize: "14px" }}
                                >
                                  {hotel.stateprovince}
                                </p>
                              </div>
                              <div className="col-md-5 d-flex align-items-center">
                                <p
                                  className="m-0 text-secondary"
                                  style={{ fontSize: "14px" }}
                                >
                                  {hotel.country}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="w-100" style={{ padding: "10px" }}>
                          No results found
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-2">
              <p style={{ fontSize: "16px", fontWeight: "600" }}>
                Popular countries
              </p>
              <div>
                <ul style={{ listStyleType: "none" }} className="d-flex p-0">
                  <li
                    className="me-2 px-2 py-1"
                    style={{ border: "1px solid gray", cursor: "pointer" }}
                    onClick={() => handlePopularItemClick("India")}
                  >
                    India
                  </li>
                  <li
                    className="me-2 px-2 py-1"
                    style={{ border: "1px solid gray", cursor: "pointer" }}
                    onClick={() => handlePopularItemClick("Australia")}
                  >
                    Australia
                  </li>
                  <li
                    className="me-2 px-2 py-1"
                    style={{ border: "1px solid gray", cursor: "pointer" }}
                    onClick={() => handlePopularItemClick("Singapore")}
                  >
                    Singapore
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <div className="col-md-3 pb-3 px-3 d-flex justify-content-center">
            <button className="searchButton py-2" onClick={handleInsurance}>
              Explore Insurance
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
