import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { IoTrashBinOutline } from "react-icons/io5";
import { HiPencilSquare } from "react-icons/hi2";
import { IoEye } from "react-icons/io5";
import { GrFormAdd } from "react-icons/gr";
import { formatDistanceToNow } from "date-fns";
import Modal from "react-bootstrap/Modal";
import { Toaster, toast } from "react-hot-toast";
import axios from "axios";
import {
  DeleteSingleHolidayPackages,
  getallHolidayPackages,
  GetSingleHolidayPackages,
} from "../api/Api";

export const HolidayPackages = () => {
  const [HolidayPackagesData, setHolidayPackagesData] = useState([]);
  const [SingleHolidayPackagesData, setSingleHolidayPackagesData] = useState(
    []
  );

  const [showSingleHolidayPackagesModal, setShowSingleHolidayPackagesModal] =
    useState(false);

  const handleSingleHolidayPackagesModalClose = () =>
    setShowSingleHolidayPackagesModal(false);
  const handleSingleHolidayPackagesModalShow = () =>
    setShowSingleHolidayPackagesModal(true);

  const [
    showDeleteSingleHolidayPackagesModal,
    setShowDeleteSingleHolidayPackagesModal,
  ] = useState(false);

  const handleDeleteSingleHolidayPackagesModalClose = () =>
    setShowDeleteSingleHolidayPackagesModal(false);
  const handleDeleteSingleHolidayPackagesModalShow = () =>
    setShowDeleteSingleHolidayPackagesModal(true);

  const FetchAllHolidayPackages = async () => {
    try {
      const res = await getallHolidayPackages();
      if (res?.status === "success") {
        setHolidayPackagesData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching FAQs:", error);
    }
  };

  useEffect(() => {
    FetchAllHolidayPackages();
  }, []);

  const handleCreateHolidayPackagesModalShow = "";
  const handleUpdateHolidayPackages = "";

  const handleSingleHolidayPackages = async (ele) => {
    try {
      const res = await GetSingleHolidayPackages(ele?._id);
      if (res?.status === "success") {
        setSingleHolidayPackagesData(res?.data);
      }
      handleSingleHolidayPackagesModalShow();
    } catch (error) {
      console.log("Error fetching single FAQ:", error);
    }
  };

  const handleDeleteHolidayPackages = async (ele) => {
    try {
      const res = await DeleteSingleHolidayPackages(ele?._id);
      if (res?.status === "success") {
        setHolidayPackagesData(
          HolidayPackagesData.filter((item) => item._id !== ele._id)
        );
        toast.success("Holiday Package Deleted Successfully!");
      }
      handleDeleteSingleHolidayPackagesModalShow();
    } catch (error) {
      console.log("Error fetching single FAQ:", error);
    }
  };

  const timeSinceCreation = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime())
      ? "Invalid date"
      : formatDistanceToNow(date, { addSuffix: true });
  };

  return (
    <div className="container">
      <Toaster position="top-right" reverseOrder={false} />

      <div className="row">
        <div className="d-flex justify-content-between">
          <div>
            <span style={{ fontWeight: "600", fontSize: "20px" }}>
              Top Tourist Destinations
            </span>
          </div>
          <div>
            <button
              className="addbutton"
              onClick={handleCreateHolidayPackagesModalShow}
            >
              Add Tourist Destination
              <GrFormAdd style={{ fontWeight: "800", fontSize: "25px" }} />
            </button>
          </div>
        </div>
      </div>

      <div className="row py-4" style={{ overflow: "auto" }}>
        <Table responsive="sm">
          <thead>
            <tr>
              <th>S.no</th>
              <th>destination</th>
              <th>title</th>
              <th>Image</th>
              <th>duration</th>
              <th>price</th>
              <th>Accommodation</th>
              <th>itinerary</th>
              <th>availableDates</th>
              <th>inclusions</th>
              <th>exclusions</th>
              <th>actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(HolidayPackagesData) &&
            HolidayPackagesData.length > 0 ? (
              HolidayPackagesData?.map((ele, index) => (
                <tr key={ele?._id}>
                  <td>{index + 1}</td>
                  <td>{ele?.destination}</td>
                  <td>{ele?.title}</td>
                  <td>
                    <img
                      src={`https://api.exploretriponline.com${ele?.Image}`}
                      alt={ele?.Name}
                      style={{ width: "100px", height: "40px" }}
                    />
                  </td>

                  <td>{ele?.duration}</td>
                  <td>{ele?.price}</td>
                  <td>{ele?.accommodation}</td>
                  <td>{ele?.itinerary}</td>
                  <td> {new Date(ele?.availableDates).toLocaleDateString()}</td>
                  <td>{ele?.inclusions}</td>
                  <td>{ele?.exclusions}</td>

                  <td>
                    <div className="d-flex">
                      <div
                        onClick={() => handleSingleHolidayPackages(ele)}
                        className="action_button_view"
                      >
                        <IoEye />
                      </div>
                      <div
                        className="px-2 action_button_update"
                        onClick={() => handleUpdateHolidayPackages(ele)}
                      >
                        <HiPencilSquare className="text-success" />
                      </div>
                      <div
                        className="action_button_delete"
                        onClick={() => handleDeleteHolidayPackages(ele)}
                      >
                        <IoTrashBinOutline className="text-danger" />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  Data Not Available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Single Holiday Package */}

      <div className="row">
        <Modal
          show={showSingleHolidayPackagesModal}
          onHide={handleSingleHolidayPackagesModalClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Holiday Package</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {SingleHolidayPackagesData && (
              <>
                <div>
                  <img
                    src={`https://api.exploretriponline.com${SingleHolidayPackagesData?.Image}`}
                    alt=""
                    style={{ height: "40vh", width: "100%" }}
                  />
                </div>

                <div className="py-2" style={{ fontWeight: "600" }}>
                  {SingleHolidayPackagesData?.title}
                </div>
                <div className="py-2">
                  {SingleHolidayPackagesData?.destination}
                </div>
                <div className="py-2">
                  {SingleHolidayPackagesData?.duration}
                </div>
                <div className="py-2">{SingleHolidayPackagesData?.price}</div>
                <div className="py-2">
                  {SingleHolidayPackagesData?.accommodation}
                </div>
                <div className="py-2">
                  {SingleHolidayPackagesData?.itinerary}
                </div>
                <div className="py-2">
                  {new Date(
                    SingleHolidayPackagesData.availableDates
                  ).toLocaleDateString()}
                </div>

                <div className="py-2">
                  {SingleHolidayPackagesData?.inclusions}
                </div>
                <div className="py-2">
                  {SingleHolidayPackagesData?.exclusions}
                </div>
                <div className="d-flex justify-content-between">
                  <div className="px-1 text-secondary">
                    ~ {timeSinceCreation(SingleHolidayPackagesData?.createdAt)}
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>
      </div>

      {/* Delete Holiday Package */}

      {/* <div className="row">
        <Modal
          show={showDeleteSingleHolidayPackagesModal}
          onHide={handleDeleteSingleHolidayPackagesModalClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Holiday Package</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure to delete this Holiday Package</p>
            <button>Yes</button>
            <button onClick={handleDeleteSingleHolidayPackagesModalClose}>
              No
            </button>
          </Modal.Body>
        </Modal>
      </div> */}
    </div>
  );
};
