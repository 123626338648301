import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import images from "../../assets/Images";
import { useNavigate } from "react-router-dom";
import { SearchInsurance } from "../../api/InsuranceApi";
import Modal from "react-bootstrap/Modal";

export const InsuranceSearch = () => {
  const [loading, setLoading] = useState(false);

  const [insurancedata, setInsuranceData] = useState("");
  const [showInsurance, setShowInsurance] = useState(false);
  const [InsuranceDetails, setInsuranceDetails] = useState("coveragedetails");
  const [singleInsuranceData, setSingleInsuranceData] = useState("");
  const [TraceID, setTraceID] = useState();

  const dodo = async () => {
    const insurance_data = {
      TravelStartDate: "2024-08-26",
      PlanCategory: "1",
      PlanType: "1",
      PlanCoverage: "4",
      NoOfPax: 1,
      PaxAge: [25],
    };
    const abc = await SearchInsurance(insurance_data);
    console.log(abc);
    console.log(abc?.data?.TraceId);
    if (abc?.status === "success") {
      setTraceID(abc?.data?.TraceId);
      setInsuranceData(abc?.data?.Results);
    }
  };

  useEffect(() => {
    dodo();
  }, []);

  const navigate = useNavigate();

  const handleCardClick = async (element) => {
    console.log(element);
    console.log(element?.ResultIndex);
    navigate(`${TraceID}/${element?.ResultIndex}`);
  };

  function handleResetAll() {
    window.location.reload();
  }

  function handleOfferedPrice(price) {
    const roundedPrice = (price * 1.1).toFixed(0);
    return parseFloat(roundedPrice);
  }

  function handleOffPrice(price) {
    const roundedPrice = (price * 0.1).toFixed(0);
    return parseFloat(roundedPrice);
  }

  // insurance Details Modal

  const handleInsuranceModalClose = () => setShowInsurance(false);
  const handleInsuranceModalShow = () => setShowInsurance(true);

  const handleInsuranceItemClick = (id) => {
    setInsuranceDetails(id === InsuranceDetails ? "insurancedetails" : id);
  };

  const handleReturnInsuranceDetails = async (insurance) => {
    console.log(insurance);
    setSingleInsuranceData(insurance);
    handleInsuranceModalShow();
  };

  const renderInsuranceDetails = () => {
    switch (InsuranceDetails) {
      case "coveragedetails":
        return (
          <>
            <div className="row">
              {singleInsuranceData?.CoverageDetails?.map((ele) => {
                return (
                  <div className="col-md-4 p-2 p-0">
                    <div className="coverdetails_cards">
                      <div style={{ fontSize: "11px" }}>{ele?.Coverage}</div>
                      <div>₹ {ele?.SumInsured}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        );

      case "PremiumDetails":
        return (
          <>
            {singleInsuranceData?.PremiumList?.map((ele) => {
              return (
                <div>
                  <table className="border strip w-100">
                    <tbody>
                      <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                        <td className="py-2">
                          <p className="ps-3 m-0">Gross Fare</p>
                        </td>
                        <td>
                          <p
                            className="ps-3"
                            style={{ borderLeft: "1px solid lightgray" }}
                          >
                            {ele?.Price?.GrossFare}
                          </p>
                        </td>
                      </tr>
                      <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                        <td className="py-2">
                          <p className="ps-3 m-0">Published Price</p>
                        </td>
                        <td>
                          <p
                            className="ps-3"
                            style={{ borderLeft: "1px solid lightgray" }}
                          >
                            {ele?.Price?.PublishedPrice}
                          </p>
                        </td>
                      </tr>
                      <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                        <td className="py-2">
                          <p className="ps-3 m-0">Published Price RoundedOff</p>
                        </td>
                        <td>
                          <p
                            className="ps-3"
                            style={{ borderLeft: "1px solid lightgray" }}
                          >
                            {ele?.Price?.PublishedPriceRoundedOff}
                          </p>
                        </td>
                      </tr>
                      <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                        <td className="py-2">
                          <p className="ps-3 m-0">Published Price RoundedOff</p>
                        </td>
                        <td>
                          <p
                            className="ps-3"
                            style={{ borderLeft: "1px solid lightgray" }}
                          >
                            {ele?.Price?.PublishedPriceRoundedOff}
                          </p>
                        </td>
                      </tr>
                      <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                        <td className="py-2">
                          <p className="ps-3 m-0">Offered Price RoundedOff</p>
                        </td>
                        <td>
                          <p
                            className="ps-3"
                            style={{ borderLeft: "1px solid lightgray" }}
                          >
                            {ele?.Price?.OfferedPriceRoundedOff}
                          </p>
                        </td>
                      </tr>
                      <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                        <td className="py-2">
                          <p className="ps-3 m-0">Commission Earned</p>
                        </td>
                        <td>
                          <p
                            className="ps-3"
                            style={{ borderLeft: "1px solid lightgray" }}
                          >
                            {ele?.Price?.CommissionEarned}
                          </p>
                        </td>
                      </tr>
                      <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                        <td className="py-2">
                          <p className="ps-3 m-0">Tds On Commission</p>
                        </td>
                        <td>
                          <p
                            className="ps-3"
                            style={{ borderLeft: "1px solid lightgray" }}
                          >
                            {ele?.Price?.TdsOnCommission}
                          </p>
                        </td>
                      </tr>
                      <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                        <td className="py-2">
                          <p className="ps-3 m-0">Service Tax</p>
                        </td>
                        <td>
                          <p
                            className="ps-3"
                            style={{ borderLeft: "1px solid lightgray" }}
                          >
                            {ele?.Price?.ServiceTax}
                          </p>
                        </td>
                      </tr>
                      <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                        <td className="py-2">
                          <p className="ps-3 m-0">Swachh Bharat Tax</p>
                        </td>
                        <td>
                          <p
                            className="ps-3"
                            style={{ borderLeft: "1px solid lightgray" }}
                          >
                            {ele?.Price?.SwachhBharatTax}
                          </p>
                        </td>
                      </tr>
                      <tr style={{ borderTop: "1px solid #f1f1f1" }}>
                        <td className="py-2">
                          <p className="ps-3 m-0">Krishi Kalyan Tax</p>
                        </td>
                        <td>
                          <p
                            className="ps-3"
                            style={{ borderLeft: "1px solid lightgray" }}
                          >
                            {ele?.Price?.KrishiKalyanTax}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
            {/* "PremiumList": [
                    {
                        "PassengerCount": 2,
                        "MinAge": 1,
                        "MaxAge": 70,
                        "Price": {
                            "Currency": "INR",
                            "GrossFare": 120,
                            "PublishedPrice": 120,
                            "PublishedPriceRoundedOff": 120,
                            "OfferedPrice": 78,
                            "OfferedPriceRoundedOff": 78,
                            "CommissionEarned": 42,
                            "TdsOnCommission": 16.8,
                            "ServiceTax": 0,
                            "SwachhBharatTax": 0,
                            "KrishiKalyanTax": 0
                        },
                        "CancellationCharge": 10
                    }
                ], */}
          </>
        );

      default:
        return null;
    }
  };

  return (
    <Container fluid className="desktop-view">
      <Row>
        <div>
          <Modal
            show={showInsurance}
            onHide={handleInsuranceModalClose}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Insurance Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="gap-2 d-flex pb-3">
                <span
                  className={`py-1 px-3 ${
                    InsuranceDetails === "coveragedetails"
                      ? "activeOfferBtnClass"
                      : "inactiveOfferBtnClass"
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleInsuranceItemClick("coveragedetails")}
                >
                  Coverage Details
                </span>
                <span
                  className={`py-1 px-3 ${
                    InsuranceDetails === "PremiumDetails"
                      ? "activeOfferBtnClass"
                      : "inactiveOfferBtnClass"
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleInsuranceItemClick("PremiumDetails")}
                >
                  Premium Details
                </span>
              </div>
              <div>{renderInsuranceDetails()}</div>
            </Modal.Body>
          </Modal>
        </div>

        <Col>
          <h3 className="text-center mt-4">
            Showing 3 Travel Insurance Plans for 1 member
          </h3>
          <div>
            {loading ? (
              <div
                className="  d-flex align-items-center justify-content-center"
                style={{ height: "400px" }}
              >
                <img
                  src={images?.Flight_Loader_Transparent}
                  height={170}
                  width={170}
                  alt=""
                ></img>
              </div>
            ) : (
              <section className="row px-2">
                <div
                  className="col-md-3 mt-4 mb-3 Section_Sidebar_Container"
                  style={{
                    border: "1.5px solid rgb(1,128,253)",
                    overflow: "hidden",
                    position: "sticky",
                    top: "110px",
                    maxHeight: "350px",
                  }}
                >
                  <div
                    className="row text-white d-flex justify-content-between py-3"
                    style={{
                      backgroundImage:
                        "linear-gradient(45deg, #1556FC, #04A9FE)",
                      borderBottom: "1.5px solid rgb(1,128,253)",
                    }}
                  >
                    <div className="col-5">
                      <h5 className="m-0">Filter by</h5>
                    </div>
                    <div
                      className="col-6 d-flex justify-content-end align-items-center"
                      onClick={handleResetAll}
                      style={{ cursor: "pointer" }}
                    >
                      <span className="pe-2">Resets all </span>
                      <img
                        src={images?.reset}
                        alt=""
                        width="20px"
                        height="20px"
                      />
                    </div>
                  </div>

                  <div
                    className="row d-flex justify-content-between px-2"
                    style={{ background: "#fff" }}
                  >
                    <div
                      className="m-0 p-0 row py-2"
                      style={{ borderBottom: "2px solid #e9e9e9" }}
                    >
                      <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                        PRICE
                      </p>
                      <div className="p-0 d-flex justify-content-between">
                        {/* <span>Min {`₹${parseInt(priceRange.min)?.toLocaleString()}`}</span>
                                                <span>Max {`₹${parseInt(priceRange.max)?.toLocaleString()}`}</span> */}
                      </div>
                      <div className="py-2 px-0 d-flex justify-content-between">
                        <input
                          type="range"
                          // max={priceRange?.max}
                          // min={priceRange?.min}
                          // value={selectedPrice}
                          className="w-100"
                          // onChange={handlePriceChange}
                        />
                        {/* <span className="px-2">{`₹${parseInt(selectedPrice)?.toLocaleString()}`}</span> */}
                      </div>
                    </div>

                    <div
                      className="m-0 p-0 row py-2"
                      style={{ borderBottom: "2px solid #e9e9e9" }}
                    >
                      <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                        STAR RATING
                      </p>

                      <div className="p-0">
                        {[1, 2, 3, 4, 5]?.map((star) => (
                          <div key={star} className="d-flex align-items-center">
                            <input
                              type="checkbox"
                              id={`star_${star}`}
                              value={star}
                              style={{ marginRight: "5px" }}
                              // checked={selectedStars?.includes(star)}
                              // onChange={handleStarFilterChange}
                            />
                            <label htmlFor={`star_${star}`} className={`px-2`}>
                              {star} Star
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-9 ">
                  {insurancedata ? (
                    insurancedata?.map((ele) => {
                      return (
                        <div className="Section_Container mb-3">
                          <div className="row">
                            <div className="col-md-1">
                              <div>
                                <img
                                  src={`https://www.sankash.in/assets/images/sankash-logo.svg`}
                                  alt=""
                                  height="60px"
                                  width="100%"
                                />
                              </div>
                            </div>
                            <div className="col-md-8 d-flex">
                              <div className="w-50">
                                <p
                                  className="m-0"
                                  style={{ fontWeight: "600" }}
                                >
                                  <span>{ele?.PlanName}</span>
                                </p>
                                <span
                                  className="Bus_Cards_items pt-3"
                                  onClick={() =>
                                    handleReturnInsuranceDetails(ele)
                                  }
                                  style={{ fontSize: "16px" }}
                                >
                                  View Insurance Details
                                </span>
                              </div>

                              {/* <div className="ps-3">
                                                                <p className="fw-bold m-0">
                                                                    <span> Medical Expenses </span>
                                                                </p>

                                                                <p
                                                                    className="m-0 text-secondary"
                                                                    style={{ fontSize: "14px" }}
                                                                >
                                                                    ₹ 11
                                                                </p>
                                                            </div>

                                                            <div className="ps-3">
                                                                <p className="fw-bold m-0">
                                                                    <span> Baggage Loss </span>
                                                                </p>

                                                                <p
                                                                    className="m-0 text-secondary"
                                                                    style={{ fontSize: "14px" }}
                                                                >
                                                                    ₹ 11
                                                                </p>
                                                            </div> */}

                              <div className="ps-3">
                                {/* <p className="fw-bold m-0">
                                                                    <span> Other Expenses </span>
                                                                </p>

                                                                <p
                                                                    className="m-0 text-secondary"
                                                                    style={{ fontSize: "14px" }}
                                                                >
                                                                    ₹ 11
                                                                </p> */}
                                {/* <p>
                                                                    <span className="Bus_Cards_items"
                                                                        onClick={() => handleReturncoveragedetails(ele)}>
                                                                        View Insurance Details
                                                                    </span>
                                                                </p> */}
                              </div>
                            </div>

                            <div className="col-md-3 d-flex justify-content-end">
                              <div>
                                <div>
                                  <p className="m-0">
                                    <span className="text-decoration-line-through text-secondary ">{`₹${parseInt(
                                      handleOfferedPrice(
                                        ele?.Price?.OfferedPriceRoundedOff
                                      )
                                    ).toLocaleString()}`}</span>

                                    <span
                                      className="ps-2 text-success"
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "600",
                                      }}
                                    >{`₹${parseInt(
                                      ele?.Price?.OfferedPriceRoundedOff
                                    ).toLocaleString()}`}</span>

                                    {/* <span className="text-decoration-line-through text-secondary ">₹ {ele?.Price?.PublishedPrice}</span>

                                                                        <span className="ps-2 fw-bold text-success"
                                                                            style={{ fontSize: "18px" }}>₹100</span> */}

                                    <span style={{ fontSize: "14px" }}>
                                      (GST Includes)
                                    </span>
                                    <p className="text-end">
                                      <span
                                        className="pe-2 text-success"
                                        style={{ fontSize: "14px" }}
                                      >
                                        Save ₹
                                        {handleOffPrice(
                                          ele?.Price?.OfferedPriceRoundedOff
                                        )}
                                      </span>
                                      {/* <span className="Bus_Cards_Star">
                                                                                ★ 5
                                                                            </span> */}
                                    </p>
                                    <div className="d-flex justify-content-end">
                                      <button
                                        className="Bus_search_btn"
                                        onClick={() => handleCardClick(ele)}
                                      >
                                        Explore Insurance
                                      </button>
                                    </div>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div
                      className="  d-flex align-items-center justify-content-center"
                      style={{ height: "400px" }}
                    >
                      <img
                        src={images?.Flight_Loader_Transparent}
                        height={170}
                        width={170}
                        alt=""
                      ></img>
                    </div>
                  )}
                </div>
              </section>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
