import React from "react";
import images from "../../assets/Images";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export const Sidenavbar = () => {
    const navigate = useNavigate();

    const userDataFromCookies = Cookies.get("userInformation");
    const decodedData = userDataFromCookies ? decodeURIComponent(userDataFromCookies) : null;
    const parsedUserData = decodedData ? JSON.parse(decodedData) : null;

    const handleLogout = () => {
        navigate("/");
        Cookies.remove("userInformation");
    };

    return (
        <>
            {/* side bar nav main div start */}
            <div className="sidebar_main_div " style={{ zIndex: "30" }}>
                <div
                    className="offcanvas offcanvas-start d-block d-lg-none p-2"
                    data-bs-scroll="true"
                    data-bs-backdrop="false"
                    tabIndex="-1"
                    id="sidenavbar"
                    aria-labelledby="sidenavbarLabel"
                >
                    {/* start header */}

                    <div className="Sidebar_Container" style={{ fontSize: "14px", fontWeight: "500", padding: "10px" }}>

                        <div className="row ">
                            <div className=" d-flex justify-content-end pb-2">
                                <button className="bg-white" data-bs-dismiss="offcanvas" aria-label="Close">
                                    <img src={images?.cancel} className="bg-white" height="20px" width="20px" alt=""></img>
                                </button>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between Sidebar_Profile_Container" style={{ fontSize: "14px", backgroundImage: `linear-gradient(90deg, rgba(172,227,255,1) 6%, rgba(160,187,255,1) 90%)` }}>
                            <div className="d-flex align-items-center">
                                <div className="sidebar_profilephoto">
                                    {
                                        parsedUserData?.data ? (
                                            <a href="/myprofile" className="text-black">
                                                <img src={images?.user1} alt=""></img>
                                            </a>
                                        ) :
                                            (<a href="/login" className="text-black">
                                                <div className="d-flex align-items-center justify-content-center" style={{ height: "80px", width: "80px", borderRadius: "50%", border: "2px solid blue", overflow: "hidden", objectFit: "auto" }}>
                                                    <img src={images?.user} alt="" height="60px" width="60px">
                                                    </img>
                                                </div>
                                            </a>)
                                    }

                                </div>

                                <div>
                                    {
                                        parsedUserData?.data ? (

                                            <div className="ps-2">
                                                <h6 className="m-0">{parsedUserData?.data?.customer_name}</h6>
                                                <p className="m-0 text-secondary" style={{ fontSize: "12px", fontWeight: "400" }}>Najafgarh, New Delhi</p>
                                                <a href="/myprofile" style={{ fontSize: "12px", fontWeight: "400" }}>
                                                    <div className="m-0 text-primary d-flex align-items-center">
                                                        <span className="pe-1">Edit profile </span>
                                                        <img src={images?.edit_profile} alt="" height="12px" width="12px"></img>
                                                    </div>
                                                </a>
                                            </div>) : (
                                            <div className="ps-2 d-flex align-items-center">
                                                <a href="/login">
                                                    <h6 className="m-0 pb-1" style={{ textDecoration: "underline" }}>Signin / Signup Now</h6>
                                                    <p className="text-black m-0">Signin for best deals & offers</p>
                                                </a>
                                                {/* <p className="m-0 text-secondary" style={{ fontSize: "12px", fontWeight: "400" }}>Najafgarh, New Delhi</p>
                                            <a href="/myprofile" style={{ fontSize: "12px", fontWeight: "400" }}>
                                                <div className="m-0 text-primary d-flex align-items-center">
                                                    <span className="pe-1">Edit profile </span>
                                                    <img src={images?.edit_profile} alt="" height="12px" width="12px"></img>
                                                </div>
                                            </a> */}
                                            </div>
                                        )
                                    }
                                </div>



                            </div>
                        </div>

                        <div style={{ border: "1px solid lightgray", borderRadius: "10px", marginTop: "10px" }}>
                            <div>
                                <a href="/myorders" className="text-black">
                                    <div className="d-flex justify-content-between sidebar_row">
                                        <div className="d-flex">
                                            <img src={images?.mybookings} height="20px" width="20px" alt=""></img>
                                            <span className="m-0 ps-2">My bookings</span>
                                        </div>
                                        <div>
                                            <img src={images?.arrow} height="14px" width="7px" alt=""></img>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div>
                                <a href="/raiseticket" className="text-black">
                                    <div className="d-flex justify-content-between sidebar_row">
                                        <div className="d-flex">
                                            <img src={images?.raise_ticket} height="20px" width="20px" alt=""></img>
                                            <span className="m-0 ps-2">Raise Ticket</span>
                                        </div>
                                        <div>
                                            <img src={images?.arrow} height="14px" width="7px" alt=""></img>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div>
                                <div>
                                    <a href="/feedback" className="text-black">
                                        <div className="d-flex justify-content-between sidebar_row">
                                            <div className="d-flex">
                                                <img src={images?.testimonial} height="20px" width="20px" alt=""></img>
                                                <span className="m-0 ps-2">Feedback</span>
                                            </div>
                                            <div>
                                                <img src={images?.arrow} height="14px" width="7px" alt=""></img>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div>
                                    <a href="/newsletter" className="text-black">
                                        <div className="d-flex justify-content-between sidebar_row">
                                            <div className="d-flex">
                                                <img src={images?.newsletter} height="20px" width="20px" alt=""></img>
                                                <span className="m-0 ps-2">Subscribe to Newsletter</span>
                                            </div>
                                            <div>
                                                <img src={images?.arrow} height="14px" width="7px" alt=""></img>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div>
                                    <a href="https://www.google.com/search?q=getvisaa&sca_esv=e95759a7a4ff9902&rlz=1C1ONGR_en-GBIN1104IN1104&sxsrf=ADLYWII_c25pGkdgudLUXG2iB0S1gek9fg%3A1715758090103&ei=CmREZpv4BbOqseMPmtyl8Aw&ved=0ahUKEwibpez7kI-GAxUzVWwGHRpuCc4Q4dUDCBA&uact=5&oq=getvisaa&gs_lp=Egxnd3Mtd2l6LXNlcnAiCGdldHZpc2FhMgsQLhiABBjHARivATICECYyCBAAGIAEGKIEMggQABiABBiiBDIIEAAYgAQYogQyGhAuGIAEGMcBGK8BGJcFGNwEGN4EGOAE2AEBSOkIUKMCWKMCcAF4AJABAJgBvwGgAb8BqgEDMC4xuAEDyAEA-AEBmAICoALVAcICDhAuGIAEGLADGMcBGK8BwgIKEAAYgAQYsAMYCsICCRAAGLADGAcYHsICDRAAGIAEGLADGLEDGArCAhAQABiABBiwAxixAxiDARgKmAMAiAYBkAYKugYGCAEQARgUkgcFMS4wLjGgB_UG&sclient=gws-wiz-serp#ip=1&lrd=0x390cfd4954c4067b:0xe80a5d55d9bcba8f,3,,,," className="text-black">
                                        <div className="d-flex justify-content-between sidebar_row">
                                            <div className="d-flex">
                                                <img src={images?.rate_us} height="20px" width="20px" alt=""></img>
                                                <span className="m-0 ps-2">Rate us</span>
                                            </div>
                                            <div>
                                                <img src={images?.arrow} height="14px" width="7px" alt=""></img>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div>
                                    <a href="/termsandconditions" className="text-black">
                                        <div className="d-flex justify-content-between sidebar_row">
                                            <div className="d-flex">
                                                <img src={images?.termsandconditions} height="20px" width="20px" alt=""></img>
                                                <span className="m-0 ps-2">Terms and Conditions</span>
                                            </div>
                                            <div>
                                                <img src={images?.arrow} height="14px" width="7px" alt=""></img>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                {/* <div>
                                    <a href="/helpcenter" className="text-black">
                                        <div className="d-flex justify-content-between sidebar_row">
                                            <div className="d-flex">
                                                <img src={images?.helpcenter} height="20px" width="20px" alt=""></img>
                                                <span className="m-0 ps-2">Help center</span>
                                            </div>
                                            <div>
                                                <img src={images?.arrow} height="14px" width="7px" alt=""></img>
                                            </div>
                                        </div>
                                    </a>
                                </div> */}

                                <div>
                                    <a href="/privacypolicy" className="text-black">
                                        <div className="d-flex justify-content-between sidebar_row">
                                            <div className="d-flex">
                                                <img src={images?.privacypolicies} height="20px" width="20px" alt=""></img>
                                                <span className="m-0 ps-2">Privacy Policies</span>
                                            </div>
                                            <div>
                                                <img src={images?.arrow} height="14px" width="7px" alt=""></img>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div>
                                    <a href="/refundandcancellation" className="text-black">
                                        <div className="d-flex justify-content-between sidebar_row">
                                            <div className="d-flex">
                                                <img src={images?.refund} height="20px" width="20px" alt=""></img>
                                                <span className="m-0 ps-2">Refund and Cancellation</span>
                                            </div>
                                            <div>
                                                <img src={images?.arrow} height="14px" width="7px" alt=""></img>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div className="d-flex justify-content-between sidebar_row" onClick={handleLogout}>
                                    <div className="d-flex">
                                        <img src={images?.logout} height="20px" width="20px" alt=""></img>
                                        <span className="m-0 ps-2">Logout</span>
                                    </div>
                                    <div>
                                        <img src={images?.arrow} height="14px" width="7px" alt=""></img>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center" style={{ fontSize: "12px", margin: "10px" }}>
                            <a href="aboutus">
                                About us
                            </a>
                            <span className="px-1 text-primary">|</span>
                            <a href="termsandconditions">
                                Terms and Conditions
                            </a>
                            <span className="px-1 text-primary">|</span>
                            <a href="faq">
                                FAQ
                            </a>
                        </div>

                        <div>
                            <img src={images?.playstore} height="40px" width="50%" alt="" style={{ objectFit: "cover" }}></img>
                            <img src={images?.appstore} height="40px" width="50%" alt="" style={{ objectFit: "cover" }}></img>
                        </div>

                        <div className="d-flex justify-content-center" style={{ fontSize: "8px", marginTop: "10px" }}>
                            <span style={{ fontWeight: "400" }}>Copyright © 2014 </span>
                            <span className="ps-1" style={{ fontWeight: "600" }}> Explore Trip Online.</span>
                            <span className="ps-1" style={{ fontWeight: "400" }}> | Powered by </span>
                            <a
                                href="https://www.geniustravelservice.com/"
                                target="_blank"
                                className="text-black ps-1"
                                style={{ fontWeight: "600" }}
                            > Genius Travel Service.
                            </a>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};
